<template>
  <EditorDialog
    title="Создание нового задания"
    :max-width="720"
    :save-func="save"
    @closed="closeDialog"
  >
    <v-form ref="formPfk" lazy-validation>
      <v-text-field
        label="Название фильтра"
        v-model="model.key"
        :rules="validation.keyRules(isSystemKey)"
        hide-details="auto"
      ></v-text-field>
    </v-form>
  </EditorDialog>
</template>

<script>
import editorDialogMixin from '@/global-components/dialog/mixins';
export default {
  mixins: [editorDialogMixin],

  name: 'AddParticipantFilterKeyDialog',

  props: {
    object: {
      type: Object,
      default: () => {
        return {
          key: undefined,
        }
      }
    },
    participantFilter: Object,
    isSystemKey: Function,
    saveFunc: Function,
  },

  data: () => ({
    saving: false,
    valid: false,
    validation: { 
      keyRules(isSystemKey) {
        return [  
          v => !!v || 'Обязательное поле.',
          v => (v && v.length <= 64) || 'Не более 64 символов.',
          v => /^[A-Za-z0-9]+$/.test(v) || 'Допускается написание только букв латинского алфавита и цифр.',
          v => !isSystemKey(v) || 'Это системное значение, его нельзя указать.',
        ];
      },
    },
  }),

  methods: {
    async save() {
      this.saving = true;
      this.valid = await this.$refs.formPfk.validate();

      if (!this.valid)
        return;

      this.saveFunc(this.model.key);
    },

    closeDialog() {
      const saving = this.saving;
      this.saving = false;

      if (saving && !this.valid)
        return;

      this.close();
    }
  },
}
</script>