var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DataTable',{attrs:{"controller":"inRoute","headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.total,"sort-by":"date","sort-desc":true,"search-title":"Найти номинацию","search-fields":_vm.searchFields,"loading":_vm.updating},on:{"update:table-options":_vm.tableUpdated,"click:row":_vm.tableRowClicked},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{staticClass:"ml-3",attrs:{"large":""},on:{"click":_vm.goToCreateNomination}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-circle")]),_vm._v(" Создать номинацию ")],1),_c('v-btn',{staticClass:"ml-3",attrs:{"large":""},on:{"click":function($event){_vm.nominationFromTempalteDialogShow = true}}},[_vm._v("Добавить из каталога")])]},proxy:true},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.title)+" "),_c('div',{staticClass:"nomination-id"},[_vm._v("ID: "+_vm._s(item.nominationId))])]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.startDate))+" ")]}},{key:"item.finishDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.finishDate))+" ")]}},{key:"item.settings",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"#2196F3"}},'v-icon',attrs,false),on),[_vm._v("mdi-information")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"dense":""}},[_c('v-list-item-title',[_vm._v("Пол: "+_vm._s(_vm.nominationGender(item)))])],1),_c('v-list-item',{attrs:{"dense":""}},[_c('v-list-item-title',[_vm._v("Параметры: "+_vm._s(item.settings.calculationSettings.coefficients))])],1)],1)],1)]}}],null,true)}),(_vm.nominationFromTempalteDialogShow)?_c('NominationFromTempalteDialog',{attrs:{"save-func":_vm.createNominationFromTemplate},on:{"closed":function($event){_vm.nominationFromTempalteDialogShow = false}}}):_vm._e(),_c('v-dialog',{attrs:{"width":"720"},model:{value:(_vm.dialog.opened),callback:function ($$v) {_vm.$set(_vm.dialog, "opened", $$v)},expression:"dialog.opened"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(_vm._s(_vm.dialog.title))]),_c('v-card-text',{domProps:{"innerHTML":_vm._s(_vm.dialog.text)}}),_c('v-card-actions',[_c('v-btn',{on:{"click":_vm.closeDialog}},[_vm._v("Закрыть")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }