<template>
  <div class="mt-6 px-4 pb-8 faq">
    <v-row>
      <v-col class="d-flex align-center">
        <h4 class="question__title">{{ questionTitle }}</h4>
      </v-col>
      <v-col cols="3">
        <v-btn large class="mr-4" @click="saveAnswer">
          <v-icon left>mdi-content-save</v-icon>
          сохранить
        </v-btn>
        <v-btn large @click="deleteAnswer">
          <v-icon>mdi-trash-can-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-8">
      <v-col cols="6">
        <v-text-field
          v-model="questionTitle"
          label="Название вопроса"
          dense
          readonly
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <p class="answer__title">Ответ</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="7" class="pl-0">
        <vue-editor v-if="paragraph || valueParagraph" v-model="answerParagraph" :editorToolbar="customToolbar"></vue-editor>
        <!-- <div v-if="paragraph || valueParagraph" class="character-count">{{ characterCount }}/ {{ maxCharacters }}</div> -->
      </v-col>
      <!-- <v-col cols="7" v-if="valueImage || image" class="pl-0">
        <v-img max-height="320" :src="imagePreview" alt="" v-if="imagePreview"></v-img>
        <v-img max-height="320" :src="selectedImage" alt=""></v-img>
      </v-col> -->
      <p>{{ previewImage }}</p>

      <v-col cols="7" class="d-flex align-start pt-2 pl-0">
        <!-- <v-file-input
          class="pt-0"
          v-if="valueImage || image"
          type="file"
          accept="image/*"
          label="Загрузить изображение"
          v-model="selectedImage"
          @change="previewImage"
          :clearable="false"
          prepend-icon="mdi-pencil"
        >
        </v-file-input> -->
        <!-- <fullImage
          :preview-url="previewImage"
          @show-preview="showImagePreview"
          :save-image-func="changeImage"
          accept=".jpg"
          title="Подробное изображение (1181x545)"
        ></fullImage> -->
      </v-col>
    </v-row>

      <v-row>
        <v-btn v-if="valueParagraphOne" large class="mr-4" @click="addParagraph">
          <v-icon left>mdi-plus-circle</v-icon>
          Добавить абзац
        </v-btn>
        <!-- <v-btn v-if="!valueParagraphOne && !valueImage" large class="mr-4" @click="addImage">
          <v-icon left>mdi-plus-circle</v-icon>
          Добавить изображение
        </v-btn> -->
      </v-row>
    <notifications :duration="5000" position="bottom right"/>

  </div>
</template>

<script>
import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
import onboardingApiInitializer from '@/api/onboardingApi';
import { uuid } from "vue-uuid";
import { VueEditor } from "vue2-editor";

    export default {
      components: {
        VueEditor
      },
      data: () => ({
        customToolbar: [
        ["bold",{ list: "ordered"}, {list: "bullet" }, "link"],
      ],
      
        // maxCharacters: 450, // Максимальное количество символов
        questionData: null,
        ansverData: null,
        paragraph: false,
        image: false,
        answerParagraph: '',
        themeId: '',
        questionId: '',
        files: '',
        selectedImage: null,
        imagePreview: null,
        previewImage: null,
        imageFile: null,
        ansverId: "",
        showPreview: false
      }),
      setup() {
        const marketingProgramStore = useMarketingProgramStore()
        return { marketingProgramStore }
      },
      watch: {
        answerParagraph(newHTML) {
          const parser = new DOMParser();
          const doc = parser.parseFromString(newHTML, 'text/html');
          const text = doc.body.textContent || '';
          
          if (text.length > this.maxCharacters) {
            const truncatedText = text.slice(0, this.maxCharacters);
            this.answerParagraph = `<div>${truncatedText}</div>`;
          }
        }
      },
      computed: {
        mp() {
          if (this.marketingProgramStore.isInitialized === false)
            return null;
          return this.marketingProgramStore;
        },
        onboardingApi() {
          if (!this.mp)
            return null;
          return onboardingApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
        },
        questionTitle() {
          return this.questionData ? this.questionData.title : ''
        },
        valueParagraph() {
          return this.ansverData?.values.length > 0 ? true : false
        },
        valueParagraphOne() {
          return this.ansverData?.values.length >= 1 ? false : true
        },
        valueImage() {
          return this.ansverData?.values[0]?.imageLink ? true : false
        },
        // characterCount() {
        //   // Используем DOMParser для извлечения текста из HTML
        //   const parser = new DOMParser();
        //   const doc = parser.parseFromString(this.answerParagraph, 'text/html');
        //   const text = doc.body.textContent || '';
          
        //   return text.length;
        // },
      },
      methods: {
        showImagePreview() {
        this.showPreview = true;
      },
        changeImage(file) {
          this.previewImage = file.name;
          // this.previewImage = `${file.name} (${file.size / 1024}KB)`;
        },
        // previewImage() {
        //   if (this.selectedImage) {
        //     const reader = new FileReader();
        //     reader.onload = (e) => {
        //       this.imagePreview = e.target.result;
        //     };
        //     reader.readAsDataURL(this.selectedImage);
        //     console.log(this.selectedImage);
        //   } else {
        //     this.imagePreview = null;
        //   }
        // },
        saveAnswer(){
          if(this.ansverData?.values.length >= 1) {
            this.updateAnswer()
            console.log('update');
          } else {
            this.createdAnswer()
          } 
        },
        async updateAnswer() {
          
          const model = {
          paragraph: this.answerParagraph,
          }
          await this.onboardingApi
          .updateFAQThemeQuestionAnswer(this.themeId, this.questionId, this.ansverId, model)
          .then((response) => {
            console.log(response.data);
            if(response.success) {
              console.log(response);
              if(this.previewImage) {
              this.onboardingApi.uploadAnswerImage(this.themeId, this.questionId, this.ansverId, this.previewImage )
              }
              this.getFAQThemeQuestionAnswer()
              this.image = false
              
              this.$notify({ type: 'success', text: 'Ответ успешно обновлен' });
            } else {
                this.$notify({ type: 'error', text: `Ответ не обновлен. Ошибка: ${response.error.detail}` });
              }
          })
          .catch((error) => {
            console.error('Error creating slide:', error);
          });
        },
        createdAnswer() {
          var faqThemeAnswerId = uuid.v4();
          const model = {
          paragraph: this.answerParagraph,
          faqThemeAnswerId: faqThemeAnswerId 
          }
          this.onboardingApi
          .createFAQThemeQuestionAnswer(this.themeId, this.questionId, model)
          .then((response) => {
            console.log(response);
            if(response.success) {
              this.$notify({ type: 'success', text: 'Ответ успешно создан' });
              this.getFAQThemeQuestionAnswer()
            } else {
                this.$notify({ type: 'error', text: `Ответ не создан. Ошибка: ${response.error.detail}` });
              }
          })
          .catch((error) => {
            console.error('Error creating slide:', error);
          });
        },
        addParagraph() {
          this.paragraph = true
        },
        addImage() {
          this.image = true
        },
       async getFAQThemeQuestionAnswer() {
          this.themeId = this.$route.params.themeId
          this.questionId = this.$route.params.questionId
          const {data} = await this.onboardingApi.getFAQThemeQuestionAnswer(this.themeId, this.questionId)
          this.ansverData = data
          this.answerParagraph = data.values[0]?.paragraph
          this.previewImage = data.values[0]?.imageLink
          this.ansverId = data.values[0]?.id
        },
        async getFAQThemeQuestion() {
          this.themeId = this.$route.params.themeId
          this.questionId = this.$route.params.questionId
          const {data} = await this.onboardingApi.getFAQThemeQuestion(this.themeId, this.questionId)
          this.questionData = data
          console.log(this.questionData);

        },
        deleteAnswer() {
          this.onboardingApi.deleteAnswer(this.themeId, this.questionId, this.ansverId)
        .then((response) => {
            if(response.success) {
              this.getFAQThemeQuestionAnswer()
              this.$notify({type: 'success', text: 'Ответ успешно удален'})
            } else {
              this.$notify({ type: 'error', text: `Ответ не удален. Ошибка: ${response.error.detail}` });
            }
          })
          .catch((error) => {
            console.error('Error updating slide:', error);
          });
        }
        // uploadAnsverImage () {
          
        //   console.log(this.files);
        // }
      },
      async mounted() {
        await this.getFAQThemeQuestion(),
        await this.getFAQThemeQuestionAnswer()
       
       
        
        

        // console.log(this.ansverId);

      }  
    }
</script>

<style lang="scss" scoped>
  .question__title{
    font-size: 34px;
    font-weight: 400;
  }
  .answer__title{
    font-size: 23px;
    padding: 20px 0;
    margin: 0;  
  }
  .character-count {
  color: red; /* Цвет текста для счетчика символов */
}
</style>