var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DataTable',{key:_vm.filtersHash,attrs:{"controller":"inRoute","headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.total,"sort-by":"date","sort-desc":true,"search-title":"Найти команду","search-fields":_vm.searchFields,"loading":_vm.updating},on:{"update:table-options":_vm.tableUpdated},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{on:{"click":function($event){return _vm.openCreateDialog()}}},[_vm._v("Добавить команду")])]},proxy:true},{key:"item.logo",fn:function(ref){
var item = ref.item;
return [_c('img',{staticStyle:{"width":"60px"},attrs:{"src":item.logo}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "),_c('div',{staticClass:"team-id"},[_vm._v("ID: "+_vm._s(item.teamId))])]}},{key:"item.link",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getUrl(item.teamId))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.tableRowClicked(item)}}},[_vm._v("mdi-pencil")]),(item.state === 'Disabled')?_c('v-icon',{staticClass:"mr-2",attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.enableTeam(item.teamId)}}},[_vm._v(" mdi-power ")]):_vm._e(),(item.state !== 'Disabled')?_c('v-icon',{staticClass:"mr-2",attrs:{"icon":"","color":"green"},on:{"click":function($event){return _vm.disableTeam(item.teamId)}}},[_vm._v(" mdi-power ")]):_vm._e()]}}],null,true)}),(_vm.teamEditorDialogShow)?_c('TeamEditorDialog',{attrs:{"save-func":_vm.afterSave,"object-id":_vm.teamId,"object":_vm.team},on:{"closed":function($event){_vm.teamEditorDialogShow = false}}}):_vm._e(),_c('v-dialog',{attrs:{"width":"720"},model:{value:(_vm.dialog.opened),callback:function ($$v) {_vm.$set(_vm.dialog, "opened", $$v)},expression:"dialog.opened"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(_vm._s(_vm.dialog.title))]),_c('v-card-text',{domProps:{"innerHTML":_vm._s(_vm.dialog.text)}}),_c('v-card-actions',[_c('v-btn',{on:{"click":_vm.hideError}},[_vm._v("Закрыть")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }