import marketApi from '@/api/marketApiNoMp';

const state = {
    promocodes: null,
    promocodeId: null,
    promotionSelect: null,
    totalPromotion: null,
    totalPromocode: 0,
    responseStatus: null,
    errorCode: null,
    errorDetail: null,
    responseBody: null,
};

const getters = {
  promocodes: () => state.promocodes,
  promocodeId: () => state.promocodeId,
  responseBody: () => state.responseBody,
  promotionSelect: () => state.promotionSelect,
  totalPromocode: () => state.totalPromocode,
  totalPromotion: () => state.totalPromotion,
  responseStatus: () => state.responseStatus,
  errorCode: () => state.errorCode,
  errorDetail: () => state.errorDetail,
};

const actions = {
  async getPromocodes({ commit }, { pagination, sorting, search, marketingProgramId, promotionId, state, creatorId }) {
    try {
      const response = await marketApi.getPromocodes({ pagination, sorting, search, marketingProgramId, promotionId, state, creatorId });
      commit('setResponseStatus', response.status);
      if (response.status === 200) {
        const { total, values } = response.data;
        commit('setPromocodes', values);
        commit('setTotalPromocode', total);
      } else {
        commit('setPromocodes', null);
      }
    } catch (error) {
      commit('setPromocodes', null);
      const status = error.response && error.response.status;
      commit('setResponseStatus', status || 0);
    }
  },

  async createPromocode({ commit }, data) {
    try {
      const response = await marketApi.createPromocode(data);
      commit('setResponseStatus', response.status);
      commit('setErrorCode', 0);
      commit('setErrorDetail', '');
      commit('setPromocodeId', response.status === 200 ? response.data : null);
    } catch (error) {
      commit('setPromocodeId', null);
      const status = error.response && error.response.status;
      commit('setResponseStatus', status || 0);
      commit('setErrorCode', error.response && error.response.data ? error.response.data.code || 0 : 0);
      commit('setErrorDetail', error.response && error.response.data ? error.response.data.detail || '' : '');
    }
  },

  async deletePromocode({ commit }, data) {
    try {
      const response = await marketApi.deletePromocode(data);
      commit('setResponseStatus', response.status);
      commit('setErrorCode', 0);
      commit('setErrorDetail', '');
    } catch (error) {
      const status = error.response && error.response.status;
      commit('setResponseStatus', status || 0);
      commit('setErrorCode', error.response && error.response.data ? error.response.data.code || 0 : 0);
      commit('setErrorDetail', error.response && error.response.data ? error.response.data.detail || '' : '');
    }
  },

  async deleteSelectedPromocodes({ commit }, data) {
    try {
      const response = await marketApi.deleteSelectedPromocodes(data);
      commit('setResponseStatus', response.status);
      commit('setErrorCode', 0);
      commit('setErrorDetail', '');
    } catch (error) {
      const status = error.response && error.response.status;
      commit('setResponseStatus', status || 0);
      commit('setErrorCode', error.response && error.response.data ? error.response.data.code || 0 : 0);
      commit('setErrorDetail', error.response && error.response.data ? error.response.data.detail || '' : '');
    }
  },

  async importPromocode({ commit }, data) {
    try {
      const response = await marketApi.importPromocode(data);
      commit('setResponseStatus', response.status);
      commit('setResponseBody', response.data);
      commit('setErrorCode', 0);
      commit('setErrorDetail', '');
    } catch (error) {
      const status = error.response && error.response.status;
      commit('setResponseStatus', status || 0);
      commit('setResponseBody', '');
      commit('setErrorCode', error.response && error.response.data ? error.response.data.code || 0 : 0);
      commit('setErrorDetail', error.response && error.response.data ? error.response.data.detail || '' : '');
    }
  },

  async getPromotionsSelect({ commit }, { offset, count, searchString, marketingProgramId }) {
    return  marketApi.getPromotionsSelect({ offset, count, searchString, marketingProgramId })
      .then((response) => {
        commit('setResponseStatus', response.status);
        if (response.status === 200) {
          const { values, total } = response.data;
          commit('setPromotionsSelect', values);
          commit('setTotalPromotion', total);
        } else {
          commit('setPromotionsSelect', null);
          commit('setTotalPromotion', 0);
        }
      })
      .catch((error) => {
        commit('setPromotionsSelect', null);
        commit('setTotalPromotion', 0);
        const status = error.response && error.response.status;
        commit('setResponseStatus', status || 0);
      });
    },
  };

const mutations = {
  setPromocodes(state, value) {
    state.promocodes = value;
  },
  setPromocodeId(state, value) {
    state.promocodeId = value;
  },
  setPromotionsSelect(state, value) {
    state.promotionSelect = value;
  },
  setTotalPromocode(state, value) {
    state.totalPromocode = value;
  },
  setTotalPromotion(state, value) {
    state.totalPromotion = value;
  },
  setResponseStatus(state, value) {
    state.responseStatus = value;
  },
  setErrorCode(state, value) {
    state.errorCode = value;
  },
  setErrorDetail(state, value) {
    state.errorDetail = value;
  },
  setResponseBody(state, value) {
    state.responseBody = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};