<template>
  <v-dialog
    :value="true"
    :max-width="maxWidth"
    persistent
    @keydown.esc="close"
  >
    <v-card>
      <v-card-title class="text-h6 pa-6 grey lighten-2">{{ title }}</v-card-title>
      <!-- <v-toolbar :color="color || 'primary'" dark></v-toolbar> -->
      <v-card-text>
        <slot></slot>

        <v-row v-if="error">
          <v-col>
            <form-error :error="error"/>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="justify-end pa-6 pt-0">
        <slot name="actions">
          <v-btn @click="close">{{ closeButtonText || 'Отмена' }}</v-btn>
          <v-btn v-if="saveFunc" :color="color || 'warning'" @click="save" :disabled="saving">
            <v-progress-circular
              v-if="saving"
              indeterminate
              :size="16"
              :width="2"
              class="mr-2"
              color="white"
            />
            {{ saveButtonText || 'Сохранить' }}
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'EditorDialog',

  props: {
    title: String,
    saveButtonText: String,
    closeButtonText: String,
    saveFunc: Function,
    color: String,
    maxWidth: {
      type: Number,
      default: 700,
    }
  },

  data() {
    return {
      saving: false,
      error: null,
    };
  },

  methods: {
    close() {
      this.$emit('closed');
    },

    async save() {
      this.saving = true;
      try {
        this.error = null;
        await this.saveFunc();
        this.close();
      } catch (e) {
        this.error = e;
      } finally {
        this.saving = false;
      }
    },
  },
}
</script>

<style lang="scss">
.v-dialog > .v-card > .v-card__text {
  padding: 24px !important;
}
.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 97% !important;
}
</style>