<template>
  <EditorDialog
    title="Изменить код"
    :max-width="720"
    :save-func="save"
    @closed="localClose"
  >
  <v-form class="pa-4" ref="form" lazy-validation>
    <v-row>
      <v-col cols="12">     
        <v-text-field
          label="Код"
          v-model="model.code"
          :rules="validation.code"
          hide-details="auto"
          class="mb-5"
        ></v-text-field>
      </v-col> 
    </v-row>
  </v-form>
  <v-dialog v-model="dialog.opened" width="720">
    <v-card>
      <v-card-title class="headline">{{ dialog.title }}</v-card-title>
      <v-card-text v-html="dialog.text"></v-card-text>
      <v-card-actions>
        <v-btn @click="closeDialog">Закрыть</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </EditorDialog>
</template>

<script>
import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
import editorDialogMixin from '@/global-components/dialog/mixins';
import scountApiInitializer from '@/api/scountApi';

export default {
  name: 'MarketingProgramCodeEditorDialog',
  mixins: [editorDialogMixin],

  props: {
    object: {
      type: Object,
      default: () => {
        return {
          code: undefined,
          attributes: undefined,
        }
      }
    },
    saveFunc: Function,
  },

  setup() {
    const marketingProgramStore = useMarketingProgramStore()
    return { marketingProgramStore }
  },

  computed: {
    mp() {
      if (this.marketingProgramStore.isInitialized === false)
        return null;

      return this.marketingProgramStore;
    },

    scountApi() {
      if (!this.mp)
        return null;

      return scountApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
    },
  },

  data: () => ({
    customToolbar: [
      ["bold",{ list: "ordered"}, {list: "bullet" }, "link"],
    ],
    dialog: {
      opened: false,
      title: undefined,
      text: undefined
    },
    saving: false,
    validation: {
      code: [
        value => !!value || 'Обязательное поле.',
        value => (value && value.length <= 30) || 'Не более 30 символов.',
        value => /^[a-zA-Z0-9]+$/.test(value) || 'Допустимы только латинские буквы и цифры.'
      ],
    },
  }),

  methods: {
    async save() {
      this.saving = true;
      
      const valid = await this.$refs.form.validate();

      if (!valid)
        return;

      await this.saveFunc(this.model);
      this.close();
    },

    localClose() {
      const saving = this.saving;
      this.saving = false;

      if (saving)
        return;

      this.close();
    },

    showError(title, text) {
      this.dialog.title = title;
      this.dialog.text = text;
      this.dialog.opened = true;
    },

    hideError() {
      this.dialog.title = undefined;
      this.dialog.text = undefined;
      this.dialog.opened = false;
    },

    openDialog(title, text, actionDialog, actionName) {
      this.dialog.title = title;
      this.dialog.text = text;
      this.dialog.actionDialog = actionDialog;
      this.dialog.actionName = actionName;
      this.dialog.opened = true;
    },

    closeDialog() {
      this.dialog.title = undefined;
      this.dialog.text = undefined;
      this.dialog.actionDialog = undefined;
      this.dialog.actionName = undefined;
      this.dialog.opened = false;
    },
  }
}
</script>