<template>
  <div>
    <v-icon class="mr-1">
      {{ getDiscipline(attributes.Discipline).icon }}
    </v-icon>
    <span class="ma-2">{{ getDiscipline(attributes.Discipline).text }} <u>{{attributes.Distance / 1000}} км</u> за <u>{{attributes.TimeInSeconds}} сек</u> со средней скоростью <u>{{new Intl.NumberFormat().format(attributes.AverageSpeed)}} км/ч</u> и средним темпом <u>{{new Intl.NumberFormat().format(attributes.AveragePace / 60)}} мин/км</u></span>

    <span class="ma-2">{{attributes.StartDate | formatDate}} - {{attributes.FinishDate | formatDate}}</span>

    <v-chip
      v-if="attributes.IsManual === 'True'"
      class="ma-2"
      label
      small
      color="red"
      text-color="white"
    >
      Ручная
    </v-chip>

    <v-chip
      v-if="attributes.RefereeingSystemCheckState === 'Accepted'"
      class="ma-2"
      label
      small
      color="success"
      text-color="white"
    >
      Принята
    </v-chip>

    <v-tooltip v-if="attributes.RefereeingSystemCheckState === 'Rejected'" left>
      <template v-slot:activator="{ on, attrs }">
        <span
          v-bind="attrs"
          v-on="on"
        >
          <v-chip
            class="ma-2"
            v-bind:style="{ opacity: reNewActivityLoading ? .5 : 'inherit' }"
            label
            small
            color="red"
            text-color="white"
          >
            Отклонена
          </v-chip>
        </span>
      </template>
      <span>{{getRefereeingSystemCheckStateReasonText(attributes.RefereeingSystemCheckStateReason)}}</span>
    </v-tooltip>

    <v-chip
      class="ma-2"
      label
      small
    >
      {{attributes.TrackerProvider}}
    </v-chip>

    <v-icon
      v-bind:style="{ opacity: reNewActivityLoading ? .5 : 'inherit' }"
      v-if="attributes.RefereeingSystemCheckState === 'Rejected'"
      @click="reNewActivity">mdi-autorenew</v-icon>
  </div>
</template>

<script>
import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
import sportDataApiInitializer from '@/api/sportDataApi';
export default {
  name: 'OperationInfoSportActivity',

  props: {
    operationSourceId: undefined,
    attributes: {
      type: Object,
    },
    refresh: Function,
  },

  setup() {
    const marketingProgramStore = useMarketingProgramStore()
    return { marketingProgramStore }
  },

  data: () => ({
    reNewActivityLoading: false,
  }),

  computed: {
    mp() {
      if (this.marketingProgramStore.isInitialized === false)
        return null;

      return this.marketingProgramStore;
    },

    sportDataApi() {
      if (!this.mp)
        return null;

      return sportDataApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
    },
  },

  methods: {
    getDiscipline(discipline) {
      switch (discipline) {
        case 'Running': return { text: 'Бег', icon: 'mdi-run' };
        case 'Walking': return { text: 'Ходьба', icon: 'mdi-shoe-sneaker' };
        case 'Cycling': return { text: 'Велосипед', icon: 'mdi-bicycle' };
        case 'Swimming': return { text: 'Плавание', icon: 'mdi-pool' };
        case 'Skiing': return { text: 'Лыжи', icon: 'mdi-ski' };
        case 'Quiz': return { text: 'Квиз', icon: 'mdi-head-question' };
        default: return { text: discipline };
      }
    },

    getRefereeingSystemCheckStateReasonText(reason) {
      switch (reason) {
        case 'DisciplineProhibited': return 'Дисциплина не поддерживается';
        case 'DuplicatedByPeriod': return 'Дубликат по периоду тренировки';
        case 'MinimumDistanceNotReached': return 'Не достигнута минимальная дистанция';
        case 'MaximumDistanceExceeded': return 'Превышена максимальная дистанция';
        case 'MinimumTimeNotReached': return 'Не достигнуто минимальное время';
        case 'MaximumTimeExceeded': return 'Превышено максимальное время';
        case 'MinimumAveragePaceNotReached': return 'Не достигнут минимальный средний темп';
        case 'MaximumAveragePaceExceeded': return 'Превышен максимальный средний темп';
        case 'MinimumMomentumPaceNotReached': return 'Не достигнут минимальный темп';
        case 'MaximumMomentumPaceExceeded': return 'Превышен максимальный темп';
        case 'ManualProhibited': return 'Ручные тренировки запрещены';
        case 'PauseTimeHasBeenExceeded': return 'Пауза превышает 30 минут';
        default: return reason;
      }
    },

    async reNewActivity() {
      if (this.reNewActivityLoading)
        return;
      
      this.reNewActivityLoading = true;
      const resp = await this.sportDataApi.activities(this.operationSourceId);
      if (resp.success) {
        const ths = this;
        setTimeout(() => {
          ths.reNewActivityLoading = false;
          ths.refresh();
        }, 2000);
      }
    }
  }
}
</script>
