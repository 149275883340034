<template>
  <EditorDialog
    title="Категория"
    :max-width="800"
    :save-func="save"
    @closed="localClose"
  >
  <v-form ref="form" lazy-validation>
    <v-row>
      <v-col>     
        <v-text-field
          label="Название категории"
          v-model="model.name"
          :rules="validation.name"
          hide-details="auto"
          class="mb-5"
        ></v-text-field>
        <v-text-field
          label="Номер"
          v-model="model.order"
          :rules="validation.order"
          hide-details="auto"
          class="mb-5"
        ></v-text-field>
      </v-col>  
    </v-row>
  </v-form>
  <v-dialog v-model="dialog.opened" width="720">
    <v-card>
      <v-card-title class="headline">{{ dialog.title }}</v-card-title>
      <v-card-text v-html="dialog.text">
      </v-card-text>
      <v-card-actions>
        <v-btn @click="closeDialog">Закрыть</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </EditorDialog>
</template>

<script>
import { uuid } from "vue-uuid";
import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
import editorDialogMixin from '@/global-components/dialog/mixins';
import marketApiInitializer from '@/api/marketApi';

export default {
  name: 'CategoryEditorDialog',
  mixins: [editorDialogMixin],

  props: {
    object: {
      type: Object,
      default: () => {
        return {
          name: undefined,
          order: null,
          url: undefined
        }
      }
    },
    saveFunc: Function,
  },

  setup() {
    const marketingProgramStore = useMarketingProgramStore()
    return { marketingProgramStore }
  },

  computed: {
    mp() {
      if (this.marketingProgramStore.isInitialized === false)
        return null;

      return this.marketingProgramStore;
    },

    marketApi() {
      if (!this.mp)
        return null;

      return marketApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
    },
  },

  data: () => ({
    dialog: {
      opened: false,
      title: undefined,
      text: undefined
    },
    saving: false,
    validation: {
      name: [
        value => !!value || 'Обязательное поле.',
        value => (value && value.length <= 256) || 'Не более 256 символов.',
      ]
    },
  }),

  methods: {
    async save() {
      this.saving = true;
      
      const valid = await this.$refs.form.validate();

      if (!valid)
        return;

      this.saveCategory(this.model);
    },

    localClose() {
      const saving = this.saving;
      this.saving = false;

      if (saving)
        return;

      this.close();
    },

    async saveCategory(category) {
      if (category.categoryId) {
        await this.updateCategory(category);
      } else {
        await this.createCategory(category);
      }
    },

    async createCategory(category) {
      var categoryId = uuid.v4();
      const request = {
        name: category.name,
        order: category.order
      };

      const response = await this.marketApi.createCategory(categoryId, request);
      if (response.success) {
        this.saveFunc({ success: true });
        this.close();
      } else {
        this.saveFunc({ error: response.error });
      }
    },

    async updateCategory(category) {
      const request = {
        name: category.name,
        order: category.order

      };

      const response = await this.marketApi.updateCategory(category.categoryId, request);
      if (response.success) {             
        this.saveFunc({ success: true });
        this.close();
      } else {
        this.saveFunc({ error: response.error });
      }
    },

    showError(title, text) {
      this.dialog.title = title;
      this.dialog.text = text;
      this.dialog.opened = true;
    },

    hideError() {
      this.dialog.title = undefined;
      this.dialog.text = undefined;
      this.dialog.opened = false;
    },

    openDialog(title, text, actionDialog, actionName) {
      this.dialog.title = title;
      this.dialog.text = text;
      this.dialog.actionDialog = actionDialog;
      this.dialog.actionName = actionName;
      this.dialog.opened = true;
    },

    closeDialog() {
      this.dialog.title = undefined;
      this.dialog.text = undefined;
      this.dialog.actionDialog = undefined;
      this.dialog.actionName = undefined;
      this.dialog.opened = false;
    },
  }
}
</script>