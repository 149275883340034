<template>
  <EditorDialog
    :title="'Редактирование данные участника'"
    :max-width="720"
    :save-func="() => saveFunc(model)"
    @closed="close"
  >
    <v-row>
      <v-col>
        <v-text-field
          label="Фамилия"
          v-model="model.lastName"
          :rules="validation.lastName"
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          label="Имя"
          v-model="model.firstName"
          :rules="validation.firstName"
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-select
          v-model="model.gender"
          label="Пол"
          :items="genders"
          item-text="name"
          item-value="value"
        ></v-select>
      </v-col>
    </v-row>
    
    <v-row>
      <v-col>
        <v-text-field
          label="Дата рождения"
          v-model="model.birthday"
          :rules="validation.birthday"
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          label="Email"
          v-model="model.emailAddress"
          :rules="validation.emailAddress"
          hide-details="auto"
        ></v-text-field>
      </v-col>
    </v-row>
    
    <v-row v-if="allMpTeams.length">
      <v-col>
        <v-autocomplete
          v-model="model.teamId"
          label="Команда"
          :items="allMpTeams"
          item-text="name"
          item-value="teamId"
        ></v-autocomplete>
      </v-col>
    </v-row>
  </EditorDialog>
</template>

<script>
import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
import scountApiInitializer from '@/api/scountApi';
import editorDialogMixin from '@/global-components/dialog/mixins';

export default {
  name: 'ParticipantProfileEditorDialog',
  mixins: [editorDialogMixin],

  props: {
    object: {
      type: Object,
      default: () => {
        return {
          lastName: null,
          firstName: null,
          gender: 'Male',
          birthday: null,
          emailAddress: null,
          teams: [],
        }
      }
    },
    allMpTeams: undefined,
    saveFunc: Function,
  },

  setup() {
    const marketingProgramStore = useMarketingProgramStore()
    return { marketingProgramStore }
  },

  data: () => ({
    genders: [
      {value: 'Male', name: 'Мужской'},
      {value: 'Female', name: 'Женский'},
    ],
    validation: {
      lastName: [
        value => !!value || 'Обязательное поле.',
        value => (value && value.length <= 256) || 'Не более 256 символов.',
      ],
      firstName: [
        value => !!value || 'Обязательное поле.',
        value => (value && value.length <= 256) || 'Не более 256 символов.',
      ],
      gender: [
        value => !!value || 'Обязательное поле.',
        value => (value === 'Male' || value === 'Female') || 'Не указан пол.',
      ],
      birthday: [
        value => !!value || 'Обязательное поле.',
        value => /^(19|20)\d\d-(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[012])$/i.test(value) || 'Некорректная дата.',
      ],
      emailAddress: [
        value => !!value || 'Обязательное поле.',
        value => (value && value.length <= 256) || 'Не более 256 символов.',
        // eslint-disable-next-line no-useless-escape
        value => /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(value) || 'Некорректный email.'
      ],
    }
  }),

  computed: {
    participantId() {
      return this.$route.params.participantId;
    },

    mp() {
      if (this.marketingProgramStore.isInitialized === false)
        return null;

      return this.marketingProgramStore;
    },

    scountApi() {
      if (!this.mp)
        return null;

      return scountApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
    },
  },
}
</script>