var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mt-3 mb-3"},[_c('v-col',[_c('v-text-field',{attrs:{"label":"Имя или email участника","hide-details":"auto"},model:{value:(_vm.filters.nameOrEmailAddress),callback:function ($$v) {_vm.$set(_vm.filters, "nameOrEmailAddress", $$v)},expression:"filters.nameOrEmailAddress"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Начало отчета","rules":_vm.validation.date,"hide-details":"auto","append-outer-icon":"mdi-calendar-today"},model:{value:(_vm.filters.startDate),callback:function ($$v) {_vm.$set(_vm.filters, "startDate", $$v)},expression:"filters.startDate"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Конце отчета","rules":_vm.validation.date,"hide-details":"auto","append-outer-icon":"mdi-calendar-today"},model:{value:(_vm.filters.endDate),callback:function ($$v) {_vm.$set(_vm.filters, "endDate", $$v)},expression:"filters.endDate"}})],1),_c('v-col',{staticClass:"d-flex"},[_c('div',{staticClass:"mr-auto"},[_c('v-btn',{staticClass:"mr-3",attrs:{"large":""},on:{"click":_vm.show}},[_vm._v("Показать")])],1)])],1),_c('DataTable',{key:_vm.filtersHash,attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.total,"sort-by":"date","sort-desc":true,"loading":_vm.updating},on:{"update:table-options":_vm.tableUpdated},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstName)+" "+_vm._s(item.lastName)+" ")]}},{key:"item.emailAddress",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.emailAddress ? item.emailAddress : '')+" ")]}},{key:"item.lastAccrual",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateRus")(item.lastAccrual))+" ")]}},{key:"item.organizationName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.attributes.find(function (attr) { return attr.name.value === "OrganizationName"; }) ? item.attributes.find(function (attr) { return attr.name.value === "OrganizationName"; }).value.value : '-')+" ")]}},{key:"item.tel",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.attributes.find(function (attr) { return attr.name.value === "Tel"; }) ? item.attributes.find(function (attr) { return attr.name.value === "Tel"; }).value.value : '-')+" ")]}},{key:"item.team",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.team ? item.team.teamName : '-')+" ")]}}],null,true)}),(_vm.nominationExercisesPageShow)?_c('NominationParticipantsPage',{attrs:{"nomination-id":_vm.nominationId,"save-func":_vm.createNominationExercise},on:{"closed":function($event){_vm.nominationExercisesPageShow = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }