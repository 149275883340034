<template>
  <div class="rule">
    <v-row>
      <v-col class="mt-2">
        <v-btn left large @click="openDialogRules">
          <v-icon>mdi-plus</v-icon>
          Прикрепить правила
        </v-btn>
      </v-col>
    </v-row>
    <!-- {{ rules }} -->
    <v-row>
      <v-col>
        <div class="rule__item" v-if="rules.url">
          <div class="rule__img">
            <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20.3594 16.1406V11.1406H22V16.1406H20.3594ZM3.64062 7V30.3594H27V33.6406H3.64062C2.75521 33.6406 1.97396 33.3281 1.29688 32.7031C0.671875 32.026 0.359375 31.2448 0.359375 30.3594V7H3.64062ZM12 12.8594V11.1406H13.6406V12.8594H12ZM31.1406 11.1406V8.64062H26.1406V18.6406H28.6406V15.3594H31.1406V12.8594H28.6406V11.1406H31.1406ZM24.5 16.1406V11.1406C24.5 10.4635 24.2656 9.89062 23.7969 9.42188C23.3281 8.90104 22.7292 8.64062 22 8.64062H17.8594V18.6406H22C22.7292 18.6406 23.3281 18.4062 23.7969 17.9375C24.2656 17.4167 24.5 16.8177 24.5 16.1406ZM16.1406 12.8594V11.1406C16.1406 10.4635 15.8802 9.89062 15.3594 9.42188C14.8906 8.90104 14.3177 8.64062 13.6406 8.64062H9.5V18.6406H12V15.3594H13.6406C14.3177 15.3594 14.8906 15.125 15.3594 14.6562C15.8802 14.1354 16.1406 13.5365 16.1406 12.8594ZM30.3594 0.359375C31.2448 0.359375 32 0.697917 32.625 1.375C33.3021 2 33.6406 2.75521 33.6406 3.64062V23.6406C33.6406 24.526 33.3021 25.3073 32.625 25.9844C32 26.6615 31.2448 27 30.3594 27H10.3594C9.47396 27 8.69271 26.6615 8.01562 25.9844C7.33854 25.3073 7 24.526 7 23.6406V3.64062C7 2.75521 7.33854 2 8.01562 1.375C8.69271 0.697917 9.47396 0.359375 10.3594 0.359375H30.3594Z" fill="black" fill-opacity="0.54"/>
            </svg>
          </div>
          <div class="rule__text">
            <a :href="rules.url" target="_blank" class="rule__name">{{ rules.url }}</a>
            <p class="rule__date">прикреплено {{formatDate(rules.updatedOn) }}</p>
          </div>
        </div>
      </v-col>
    </v-row>
    <!-- <v-row>
      <v-col>
        <p class="rule__old-title">
          Прошлые версии правил
        </p>
      </v-col>
    </v-row> -->
    <!-- <v-row>
      <v-col>
        <div class="rule__old-items">
          <div class="rule__item" v-for="item in oldRules()" :key="item.url">
            <div class="rule__img">
              <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.3594 16.1406V11.1406H22V16.1406H20.3594ZM3.64062 7V30.3594H27V33.6406H3.64062C2.75521 33.6406 1.97396 33.3281 1.29688 32.7031C0.671875 32.026 0.359375 31.2448 0.359375 30.3594V7H3.64062ZM12 12.8594V11.1406H13.6406V12.8594H12ZM31.1406 11.1406V8.64062H26.1406V18.6406H28.6406V15.3594H31.1406V12.8594H28.6406V11.1406H31.1406ZM24.5 16.1406V11.1406C24.5 10.4635 24.2656 9.89062 23.7969 9.42188C23.3281 8.90104 22.7292 8.64062 22 8.64062H17.8594V18.6406H22C22.7292 18.6406 23.3281 18.4062 23.7969 17.9375C24.2656 17.4167 24.5 16.8177 24.5 16.1406ZM16.1406 12.8594V11.1406C16.1406 10.4635 15.8802 9.89062 15.3594 9.42188C14.8906 8.90104 14.3177 8.64062 13.6406 8.64062H9.5V18.6406H12V15.3594H13.6406C14.3177 15.3594 14.8906 15.125 15.3594 14.6562C15.8802 14.1354 16.1406 13.5365 16.1406 12.8594ZM30.3594 0.359375C31.2448 0.359375 32 0.697917 32.625 1.375C33.3021 2 33.6406 2.75521 33.6406 3.64062V23.6406C33.6406 24.526 33.3021 25.3073 32.625 25.9844C32 26.6615 31.2448 27 30.3594 27H10.3594C9.47396 27 8.69271 26.6615 8.01562 25.9844C7.33854 25.3073 7 24.526 7 23.6406V3.64062C7 2.75521 7.33854 2 8.01562 1.375C8.69271 0.697917 9.47396 0.359375 10.3594 0.359375H30.3594Z" fill="black" fill-opacity="0.54"/>
              </svg>
            </div>
            <div class="rule__text">
              <a href="#" class="rule__name">{{ item.url }}</a>
              <p class="rule__date">прикреплено {{ item.date }} </p>
            </div>
          </div>
        </div>
      </v-col>
    </v-row> -->
    <notifications :duration="5000" position="bottom right"/>
    <v-dialog
      v-model="newRuleDialog"
      max-width="720"
    >
      <v-card>
        <v-card-title class="text-h6 grey lighten-2 py-4">
          Прикрепить правила
        </v-card-title>
        <v-form class="pa-4">
          <v-file-input
            truncate-length="25"
            append-icon="mdi-upload"
            prepend-icon=""
            label="Файл с правилами (pdf)"
            accept=".pdf"
            @change="uploadImportFile($event)"
            :clearable="false"
          ></v-file-input>
        </v-form>
        <v-card-actions class="justify-end pb-4">
          <v-btn @click="newRuleDialog = false"
          >
            Отмена
          </v-btn>
          <v-btn
            color="#FF8233"
            dark
            @click="addRule"
          >
            загрузить
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
  import onboardingApiInitializer from '@/api/onboardingApi';
  import moment from 'moment';

  export default {
     name: "RulesView",
     data: () => ({
      newRuleDialog: false,
      uploadFile: "",
      
      // rules: [
      //   {
      //     name: "положение_08.05.pdf",
      //     date: "19.05.2023 23:25"
      //   },
      //   {
      //     name: "положение_18.06.pdf",
      //     date: "19.05.2023 23:25"
      //   },
      //   {
      //     name: "положение_18.07.pdf",
      //     date: "19.05.2023 23:25"
      //   },
      //   {
      //     name: "положение_18.08.pdf",
      //     date: "19.05.2023 23:25"
      //   },
      // ]
      rules:{}
     }),
     setup() {
        const marketingProgramStore = useMarketingProgramStore()
        return { marketingProgramStore }
      },
     computed: {
      mp() {
          if (this.marketingProgramStore.isInitialized === false)
            return null;
          return this.marketingProgramStore;
        },
        onboardingApi() {
          if (!this.mp)
            return null;
          return onboardingApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
        },
      newRules() {
        return this.rules ? this.rules[0] : []
      },
     },
     methods: {
      formatDate(date) {
        return moment(date).format('DD-MM-YYYY HH:MM');
      },
      oldRules() {
        return this.rules.slice(1)
      },
      openDialogRules() {
        this.newRuleDialog = true;
      },
      uploadImportFile(event) {
        this.uploadFile = event;
      },
      addRule() {
        this.onboardingApi.addRule(this.mp.marketingProgramId, this.uploadFile).then((response) => {
          this.rules = response.data
          this.newRuleDialog = false
          this.getRule()
          this.$notify({type: 'success', text: `Правила загружены`});
        })
        .catch((error) => {
          this.$notify({type: 'error', text: `Ошибка загрузки`});
          console.error(error);
        });
      },
      getRule(){
        this.onboardingApi.getRule(this.mp.marketingProgramId).then((response) => {
          if (response.status !== 200) {
            this.onboardingApi.createRule(this.mp.marketingProgramId)
            this.onboardingApi.getRule(this.mp.marketingProgramId).then((response) => {
              this.rules = response.data
            })
            .catch((error) => {
              this.$notify({type: 'error', text: `Ошибка загрузки`});
              console.error(error);
            });
          } else this.rules = response.data
        })
        .catch((error) => {
          this.$notify({type: 'error', text: `Ошибка загрузки`});
          console.error(error);
        });
      }
    },
    mounted() {
      this.onboardingApi.createRule(this.mp.marketingProgramId)
      this.getRule();
    }
  }
</script>

<style lang="scss" scoped>
.rule {
  padding-left: 16px;
  &__item {
    display: flex;
    align-items: center;
    padding: 12px 0;
  }
  &__img {
    display: flex;
    align-items: center;
    padding-right: 24px;
  }
  &__text {

  }
  &__name {
    margin: 0;
    padding: 0;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #FF8233;
    text-decoration: none;
  }
  &__date {
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: rgba(0, 0, 0, 0.60);
  }
  &__old-title {
    font-family: Roboto;
    font-size: 23px;
    line-height: 32px;
    margin: 0;
    color: rgba(0, 0, 0, 0.87);
  }
}

</style>