<template>
  <div>
    <v-progress-linear
      v-if="!disciplines && loading"
      color="deep-purple"
      height="10"
      indeterminate
    ></v-progress-linear>

    <div v-if="disciplines">
      <v-row>
        <v-col class="cmn__h1">Дисциплины</v-col>
      </v-row>
      <div v-for="(item, i) in this.disciplines" :key="i" class="d__discipline" v-bind:class="{ d__discipline_active: item.disciplineCkeck }">
        <v-row>
          <v-col>
            <v-checkbox v-model="item.disciplineCkeck" :label="getDisplayName(i)" hide-details="true"></v-checkbox>
          </v-col>
        </v-row>
        <v-row v-if="item.disciplineCkeck">
          <v-col cols="2" class="d__discipline_key">
            Дистанция (метры)
          </v-col>
          <v-col>
            <v-text-field
              label="Минимум"
              v-model="item.distance.min"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Максимум"
              v-model="item.distance.max"
              hide-details="auto"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="item.disciplineCkeck">
          <v-col cols="2" class="d__discipline_key">
            Средний темп (мин\км)
          </v-col>
          <v-col>
            <v-text-field
              label="Минимум"
              v-model="item.averagePace.min"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Максимум"
              v-model="item.averagePace.max"
              hide-details="auto"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="item.disciplineCkeck">
          <v-col cols="2" class="d__discipline_key">
            Макс. Темп (мин\км)
          </v-col>
          <v-col>
            <v-text-field
              label="Минимум"
              v-model="item.momentumPace.min"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Максимум"
              v-model="item.momentumPace.max"
              hide-details="auto"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="item.disciplineCkeck">
          <v-col cols="2" class="d__discipline_key">
            Время (секунды)
          </v-col>
          <v-col>
            <v-text-field
              label="Минимум"
              v-model="item.timeInSeconds.min"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Максимум"
              v-model="item.timeInSeconds.max"
              hide-details="auto"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-checkbox v-model="item.manualCkeck" label="Принимать ручные тренировки" hide-details="true"></v-checkbox>
          </v-col>
        </v-row>
      </div>
      <v-row>
        <v-col>
          <v-btn @click="save" :disabled="loading">Сохранить</v-btn>
          <br>
          <span class="sps__spana">Измененные параметры будут применяться только для новых тренировок.</span>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
import scountApiInitializer from '@/api/scountApi';
import sportDataApiInitializer from '@/api/sportDataApi';

export default {
  name: 'SportSettingsView',

  setup() {
    const marketingProgramStore = useMarketingProgramStore()
    return { marketingProgramStore }
  },

  data: () => ({
    sportDataApplicationId: undefined,
    loading: false,
    disciplines: null,
  }),

  computed: {
    mp() {
      if (this.marketingProgramStore.isInitialized === false)
        return null;

      return this.marketingProgramStore;
    },

    scountApi() {
      if (!this.mp)
        return null;

      return scountApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
    },

    sportDataApi() {
      if (!this.mp)
        return null;

      return sportDataApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
    },
  },

  methods: {
    async getDisciplines() {
      const responseMp = await this.scountApi.getMarketingProgram(this.mp.marketingProgramId);
      this.sportDataApplicationId = responseMp.data.parameters.SportDataApplicationId;

      const response = await this.sportDataApi.getApplicationsRestrictions(this.sportDataApplicationId);
      this.disciplines = JSON.parse(JSON.stringify(response.data.disciplines));
      for (var i in this.disciplines) {
        const item = this.disciplines[i];
        item.disciplineCkeck = item.discipline === 'Allowed';
        item.manualCkeck = item.manual === 'Allowed';
      }
    },

    async save() {
      this.loading = true;

      const disciplines = JSON.parse(JSON.stringify(this.disciplines));
      for (var i in disciplines) {
        const item = disciplines[i];
        item.discipline = item.disciplineCkeck ? 'Allowed' : 'Forbidden';
        item.manual = item.manualCkeck ? 'Allowed' : 'Forbidden';
      }

      await this.sportDataApi.patchApplicationsRestrictions(this.sportDataApplicationId, { disciplines: disciplines });
      await this.getDisciplines();

      setTimeout(() => { this.loading = false; }, 300);      
    },

    getDisplayName(v) {
      switch (v) {
        case 'Skiing': return 'Лыжи';
        case 'Cycling': return 'Велосипед';
        case 'Running': return 'Бег';
        case 'Walking': return 'Ходьба';
        case 'Swimming': return 'Плавание';
        default: undefined;
      }
    }
  },

  async mounted() {
    await this.getDisciplines();
  },
}
</script>

<style lang="scss" scoped>
  @import '../../assets/global';
</style>

<style lang="scss">
.sps__spana {
  line-height: 42px;
  opacity: .6;
}
.d__discipline {
  background: rgba(1, 1, 1, 0.02);
  border: 1px solid rgba(1, 1, 1, 0.12);
  border-radius: 4px;
  padding: 0 16px 16px;
  margin: 10px 0;
}
.d__discipline_active {
  background: #F6FFED;
}
.d__discipline_key {
  line-height: 56px;
}
</style>
