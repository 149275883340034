import {initializeApi} from "@/api/base";
function marketingProgramSteps(marketingProgramId) {
  const api = initializeApi("/api/scount-steps", {'X-MarketingProgram-Id': marketingProgramId});

  async function getProgramsReward() {
    const response = await api
      .get(`api/v1/marketing-programs`)
      .then(response => {
        return {status: response.status, data: response.data};
      });
      return response;
  }
  async function changeProgramsReward(model) {
    const response = await api
      .patch('/api/v1/marketing-programs', model)
      .then(response => {
        return {success: true, status: response.status, data: response.data};
      })
      .catch((error) => {
        return {success: false, error};
      });

    return response;
  }
  return {
    getProgramsReward,
    changeProgramsReward
  };
}
export default {
  marketingProgramSteps,
};
