<template>
  <div>
    <v-row>
      <v-col class="exercies__h2">
        &nbsp;
      </v-col>
      <v-col class="d-flex">
        <div class="ml-auto">
          <v-btn @click="nominationExercisesPageShow = true" :disabled="!isEdit">
            <v-icon left>mdi-plus-circle</v-icon>
            Прикрепить задания
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <DataTable
      :key="filtersHash"
      :headers="headers"
      :items="items"
      :server-items-length="total"
      sort-by="date"
      :sort-desc="true"
      :search-fields="searchFields"
      :loading="updating"
      @update:table-options="tableUpdated"
    >
      <template v-slot:[`item.title`]="{item}">
        {{ item.title }}
      </template>
      <template v-slot:[`item.actions`]="{item}">
        <v-icon @click="deleteItem(item)" :disabled="!isEdit">mdi-delete</v-icon>
      </template>
    </DataTable>

    <NominationExercisesPage
      v-if="nominationExercisesPageShow"
      :nomination-id="nominationId"
      :save-func="createNominationExercise"
      @closed="nominationExercisesPageShow = false"
    />
  </div>
</template>

<script>
import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
import ratingsApiInitializer from '@/api/ratingsApi';
import NominationExercisesPage from './NominationExercisesPage.vue';

export default {
  name: 'NominationExercisesView',
  components: { NominationExercisesPage },

  props: {
    nominationId: undefined,
    isEdit: Boolean,
  },

  setup() {
    const marketingProgramStore = useMarketingProgramStore()
    return { marketingProgramStore }
  },

  data: () => ({
    filtersHash: Date.now(),
    headers: [
      { value: 'title', text: 'Название', align: 'start', sortable: false },
      { value: 'actions', text: 'Действия', align: 'center', width: 160, sortable: false },
    ],
    items: [],
    total: 0,
    searchFields: [
      { key: 'title', label: 'Название' },
    ],
    updating: false,
    nominationExercisesPageShow: false,
  }),

  computed: {
    exerciseTemplateId() {
      return this.$route.params.exerciseTemplateId;
    },

    mp() {
      if (this.marketingProgramStore.isInitialized === false)
        return null;

      return this.marketingProgramStore;
    },

    ratingsApi() {
      if (!this.mp)
        return null;

      return ratingsApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
    },
  },

  methods: {
    async tableUpdated(pagination, sorting, search) {
      await this.getNominationExercises(pagination, sorting, search);
    },

    async getNominationExercises(pagination, sorting, search) {
      if (!this.ratingsApi) return;

      this.updating = true;

      try {
        const response = await this.ratingsApi.getNominationExercises({ nominationId: this.nominationId, pagination, sorting, search });

        this.total = response.data.total;
        this.items = response.data.values;
      } finally {
        this.updating = false;
      }
    },

    async createNominationExercise(model) {
      const request = {
        nominationsIds: [
          this.nominationId
        ],
        exercisesIds: model
      };
      await this.ratingsApi.assignExercisesToNominations(request);
      this.filtersHash = Date.now();
    },

    async deleteItem(nominationExercise) {
      this.updating = true;

      try {
        const response = await this.ratingsApi.deleteNominationExercise(nominationExercise.nominationExerciseId);
        if (response.status === 200) {
          this.filtersHash = Date.now();
          this.updating = false;
        } else {
          const e = response.error;
          const errorText = !e
            ? 'Произошла ошибка'
            : `Код статуса ответа: ${e.status}`
              + `<br>Код: ${e.code}`
              + `<br>Ошибка: ${e.title}`
              + `<br>Подробности: ${e.detail}`;

          this.openDialog('Ошибка при удалении номинации', errorText); 
          this.updating = false;
        }
      } catch {
        this.updating = false;
      }
    },

    goToExerciseTemplateMp(exerciseTemplateMpId) {
      this.$router.push(`/exerciseTemplates/${this.exerciseTemplateId}/mp/${exerciseTemplateMpId}`);
    }
  },
}
</script>