var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.pageLoading)?_c('div',{staticClass:"loading"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_vm._e(),_c('div',{staticClass:"px-4"},[_c('v-autocomplete',{attrs:{"label":"Фильтр по маркетинговой программе","items":_vm.marketingPrograms2,"item-text":"title","item-value":"marketingProgramId"},on:{"change":_vm.handleMarketingProgramChange},model:{value:(_vm.marketingProgramId),callback:function ($$v) {_vm.marketingProgramId=$$v},expression:"marketingProgramId"}})],1),_c('DataTable',{attrs:{"controller":"inRoute","headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.total,"search-title":"Найти поощрение","search-fields":_vm.searchFields,"sort-by":"createdOn","sort-desc":true,"loading":_vm.updating},on:{"update:table-options":_vm.updateTable},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{staticClass:"ml-3",on:{"click":_vm.createPromotion}},[_vm._v("Добавить поощрения")])]},proxy:true},{key:"item.images",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center py-2"},[(item.images.mobile)?_c('img',{attrs:{"height":"50","width":"50","src":item.images.mobile}}):_c('img',{attrs:{"height":"50","width":"50","src":"https://cdn1.ozone.ru/s3/multimedia-x/6391435521.jpg"}})])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"promotion-id"},[_vm._v("ID: "+_vm._s(item.promotionId))])]}},{key:"item.expiresOn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.expiresOn))+" ")]}},{key:"item.createdOn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdOn))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"icon":""},on:{"click":function($event){return _vm.editPromotion(item)}}},on),[_vm._v(" mdi-pencil-outline ")])]}}],null,true)},[_c('span',[_vm._v("Редактировать")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"icon":""},on:{"click":function($event){return _vm.copyPromotion(item)}}},on),[_vm._v(" mdi-content-copy ")])]}}],null,true)},[_c('span',[_vm._v("Копировать")])]),(item.state === 'Disabled')?_c('v-icon',{staticClass:"mr-2",attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.enabledPromotion(item.promotionId)}}},[_vm._v(" mdi-power ")]):_vm._e(),(item.state !== 'Disabled')?_c('v-icon',{staticClass:"mr-2",attrs:{"icon":"","color":"green"},on:{"click":function($event){return _vm.disabledPromotion(item.promotionId)}}},[_vm._v(" mdi-power ")]):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }