<template>
  <div>
    <div class="market-section p-8">
      <v-expansion-panels class="mb-6" v-model="panels" multiple>
        <v-expansion-panel
          v-for="(item, index) in items"
          :key="item.id"
          class="mb-2"
        >
          <v-expansion-panel-header>
            <div class="market-section__header">
              <div class="market-section__header-left">
                <p class="market-section__header-title">{{ item.name }}</p>
                <span class="market-section__header-id">ID:{{ item.id }}</span>
              </div>
              <div class="market-section__header-right">
                <div class="market-section__header-img mr-4">
                  <img src="img/empty.png" alt="">
                </div>
                <v-icon @click.stop="editItem(item)" class="pa-2">mdi-pencil</v-icon>
                <v-icon @click.stop="viewItem(item)" class="pa-2">mdi-eye</v-icon>
                <v-icon @click.stop="dialogDeleteOpen(item.id)" class="mr-4 pa-2">mdi-delete</v-icon>
                <v-icon class="pa-2">mdi-arrow-up</v-icon>
                <v-icon class="mr-4 pa-2">mdi-arrow-down</v-icon>
                <v-icon class="pa-2" @click.stop="addItem(item, index)" color="#FF8233">mdi-plus-box</v-icon>

              </div>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-expansion-panels class="children">
              <v-expansion-panel
                v-for="item in item.children" :key="item.id" :readonly="item.children1 ? item.children1.length < 1 : true"
              >
                <v-expansion-panel-header :class="{ 'none': item.children1 ? item.children1.length < 1 : null }">
                  <div class="market-section__header">
                    <div class="market-section__header-left">
                      <p class="market-section__header-title">{{ item.name }}</p>
                      <span class="market-section__header-id">ID:{{ item.id }}</span>
                    </div>
                    <div class="market-section__header-right">
                      <div class="market-section__header-img mr-4">
                        <img src="img/empty.png" alt="">
                      </div>
                      <v-icon @click.stop="editItem(item)" class="pa-2">mdi-pencil</v-icon>
                      <v-icon @click.stop="viewItem(item)" class="pa-2">mdi-eye</v-icon>
                      <v-icon @click.stop="deleteItem(item.id)" class="mr-4 pa-2">mdi-delete</v-icon>
                      <v-icon class="pa-2">mdi-arrow-up</v-icon>
                      <v-icon class="mr-4 pa-2">mdi-arrow-down</v-icon>
                      <v-icon class="pa-2" @click.stop="addItemChild(item)" color="#FF8233">mdi-plus-box</v-icon>
                    </div>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-expansion-panels class="children1" >
                    <v-expansion-panel
                      v-for="item in item.children1"
                      :key="item.id"
                    >
                      <v-expansion-panel-header>
                        <div class="market-section__header">
                          <div class="market-section__header-left">
                            <p class="market-section__header-title">{{ item.name }}</p>
                            <span class="market-section__header-id">ID:{{ item.id }}</span>
                          </div>
                          <div class="market-section__header-right">
                            <v-icon @click.stop="editItem(item)" class="pa-2">mdi-pencil</v-icon>
                            <v-icon @click.stop="viewItem(item)" class="pa-2">mdi-eye</v-icon>
                            <v-icon @click.stop="deleteItem(item.id)" class="mr-4 pa-2">mdi-delete</v-icon>
                            <v-icon class="pa-2">mdi-arrow-up</v-icon>
                            <v-icon class="mr-4 pa-2">mdi-arrow-down</v-icon>
                            <v-icon class="pa-2" @click.stop="addItem" color="#FF8233">mdi-plus-box</v-icon>
                          </div>
                        </div>
                      </v-expansion-panel-header>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-row>
        <v-col>
          <v-btn large @click="dialogSectionOpen">
            <v-icon left>mdi-plus</v-icon>
              Добавить раздел
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-dialog
      v-model="dialogSection"
      max-width="720"
    >
    <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Добавить раздел
        </v-card-title>
        <v-form
          v-model="valid"
          class="pa-6 pb-0"
          ref="form"
          lazy-validation
        >
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Название"
                dense
              />
            </v-col>
            <v-col cols="6">
              <upload-images
                @changeFile="changeDesktopImage"
                @fileInputClick="clickImage"
                :imageSelected="imageDesktopSelected"
                :deleteButton="imageDesktopSelected ? true : false"
                width="241"
                height="128"
                title="Иллюстрация(необязательно)"
                accept=".jpg, .png, .jpeg"
                imageSize="1920x500"
              />
            </v-col>
            <v-col cols="6">
              <upload-images
                @changeFile="changeMobileImage"
                @fileInputClick="clickImage"
                :imageSelected="imageMobileSelected"
                :deleteButton="imageMobileSelected ? true : false"
                width="241"
                height="128"
                title="Иллюстрация(необязательно)"
                accept=".jpg, .png, .jpeg"
              />
            </v-col>
          </v-row>
        </v-form>
        <v-card-actions class="pa-6">
          <v-spacer></v-spacer>
          <v-btn @click="dialogSection = false">Отмена</v-btn>
          <v-btn
            color="#FF8233"
            :dark="valid"
            :disabled="!valid"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogDelete"
      max-width="500"
    >
      <v-card>
        <v-card-title class="text-h6">
          Удалить раздел?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="dialogDelete = false"
          >
            Отменить
          </v-btn>

          <v-btn
            color="#FF8233"
            dark
            @click="deleteItem"
            class="ml-4"
          >
            Удалить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import uploadImages from '@/components/uploadImages.vue';
import { uuid } from "vue-uuid";

  export default {
    name: 'marketSections',
    components: { uploadImages },
    data: () => ({
      dialogSection: false,
      dialogDelete: false,
      valid: true,
      imageDesktopSelected: null,
      imageDesktopFile: null,
      imageMobileSelected: null,
      imageMobileFile: null,
      panels: [],
      items: [
        {
          id: 'ec8e1d4c-ae0c-49c8-b27b-228d3c149ead',
          name: 'Тренировочный процесс для циклических видов спорта',
          children: [
            {
              id: 'vc8e1d4c-ae0c-49c8-b27b-228d3c149ead',
              name: 'Бегунам',
              children1: []
            },
            {
              id: 'fd8e1d4c-ae0c-49c8-b27b-228d3c149ead',
              name: 'Пловцам',
              children1: [
                {
                  id: 'mc8e1d4c-ae0c-49c8-b27b-228d3c149ea3',
                  name: 'Спортивное питание и витамины'
                },
                {
                  id: 'fc5e1d4c-ae0c-49c8-b27b-228d3c149ea7',
                  name: 'Одежда для тренировок'
                },
                {
                  id: 'fc871d4c-ae0c-49c8-b27b-228d3c149ea5',
                  name: 'Обувь для тренировок'
                },
                {
                  id: 'fj8e1d4c-ae0c-49c8-b27b-228d3c149ea4',
                  name: 'Спортивная электроника'
                },
              ]
            },
            {
              id: 'vc8e1d4c-ae4c-49c8-b27b-228d3c149ead',
              name: 'Лыжникам',
              children1: []
            },
            {
              id: 'vc8e1d4c-ae4c-49c8-b27b-248d3c149ead',
              name: 'Триатлонистам',
              children1: []
            },
          ]
        },
        {
          id: 'fc8e1d4c-ae0c-49c8-b27b-228d3c149ead',
          name: 'Тренировочный процесс для циклических видов спорта',
          children: [
            {
              id: 'fc8e1d4c-ae0c-49c8-b27b-228d3c149ead',
              name: 'Тренировочный процесс для циклических видов спорта',
              children1: [
                {
                  id: 'fc8e1d4c-ae0c-49c8-b27b-228d3c149ea2',
                  name: 'Тренировочный процесс для циклических видов спорта'
                }
              ]
            }
          ]
        }
      ]
    }),

    methods: {
      dialogSectionOpen() {
        this.dialogSection = true
      },
      dialogDeleteOpen () {
        this.dialogDelete = true
      },
      clickImage(fileUploadId) {
        document.getElementById(fileUploadId).click();
      },
      changeDesktopImage(imageFile, file) {
        if(imageFile) {
          this.imageDesktopSelected = imageFile
          this.imageDesktopFile = file
        }
      },
      changeMobileImage(imageFile, file) {
        if(imageFile) {
          this.imageMobileSelected = imageFile
          this.imageMobileFile = file
        }
      },
      editItem (item) {
        console.log(item);
      },
      viewItem (item) {
        console.log(item);
      },
      deleteItem (item) {
        console.log(item);
      },
      addItem (item, i) {
        const child = {
          id: uuid.v4(),
          name: 'Название',
          children1: []
        }
        item.children.push(child);
        this.panels = [i]
        console.log(i);
      },
      addItemChild (item) {
        console.log('addItemChild', item);
        const child = {
          id: uuid.v4(),
          name: 'Название',
        }
        item.children1.push(child);
        console.log(item);
      }
    }
  }
</script>

<style lang="scss">

.v-expansion-panels.children .v-expansion-panel {
  background-color: rgba(248, 248, 248, 1) !important;
  &::before {
    box-shadow: none !important;
  }
}
.v-expansion-panels.children1 .v-expansion-panel {
  background-color: rgba(255, 255, 255, 1) !important;
  &::before {
    box-shadow: none !important;
  }
  .v-expansion-panel-header__icon {
    display: none
  }
  .v-expansion-panel-header {
    padding-left: 0 !important;
  }
}
.v-expansion-panel-header {
  position: relative;
  &.none {
    padding-left: 0;
    .v-expansion-panel-header__icon {
      display: none
    }
  }
}
.v-expansion-panel-content__wrap {
  padding: 0;
  padding-left: 20px;
  padding-bottom: 14px;
}
.v-expansion-panel {
  background-color: rgba(240, 240, 240, 1) !important;
}
.v-application--is-ltr .v-expansion-panel-header__icon {
  width: 24px;
  height: 24px;
  position: absolute;
  left: 20px;
  top: 24px;
}
.v-application p {
  margin-bottom: 0;
}
.market-section {
  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 24px;
    &-title {
      font-size: 16px;
      line-height: 24px;
      margin: 0;
    }
    &-id {
      font-size: 14px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.38);
    }
    &-left {
    }
    &-right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    &-img {
      width: 57px;
      height: 32px;
      img {
        max-width: 100%;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

</style>