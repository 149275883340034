<template>
  <div>
    <router-link :to="{ name: 'Exercise', params: { exerciseId: sourceId }}">
      {{attributes.ExerciseTitle}}
    </router-link>

    <v-chip
      class="ma-2"
      label
      small
    >
      {{attributes.Value}}
    </v-chip>
  </div>
</template>

<script>
export default {
  name: 'OperationInfoParticipantExperience',

  props: {
    sourceId: String,
    attributes: {
      type: Object,
    },
  },
}
</script>
