<template>
  <div class="ambassadors">
    <v-row>
      <v-col class="mt-2">
        <v-btn left large @click="openDialogAmbassadors">
          <v-icon left>mdi-image-plus</v-icon>
          добавить амбассадора
        </v-btn>
      </v-col>
    </v-row>
    <DataTable
      controller="inRoute"
      :headers="headers"
      :items="items"
      :server-items-length="total"
      sort-by="order"
      :sort-desc="true"
      :loading="updating"
      @update:table-options="tableUpdated"
    >
    <template v-slot:[`item.mobileImageUrl`]="{item}">
      <div class="d-flex align-center py-2">
        <img height="50" width="50" v-if="item.mobileImageUrl" :src="item.mobileImageUrl" />
        <img height="50" width="50" v-else src="https://cdn1.ozone.ru/s3/multimedia-x/6391435521.jpg"/>
      </div>
    </template>
    <template v-slot:[`item.fullName`]="{item}">
      <p class="name trancate">{{ item.fullName }}</p>
    </template>
    <template v-slot:[`item.content`]="{item}">
      <p class="content trancate">{{ item.content }}</p>
    </template>
    <template v-slot:[`item.description`]="{item}">
      <p class="description trancate" v-html="item.description"></p>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon class="mr-2" @click="openDialogAmbassadors(item)">mdi-pencil</v-icon>
      <v-icon class="mr-2" @click="confirmDialogOpen(item)" >mdi-trash-can-outline</v-icon>
    </template>
  </DataTable>
  <v-dialog
      v-model="ambassadorDialog"
      max-width="720"
    >
      <v-card >
        <v-card-title class="text-h6 mb-8 pa-6 grey lighten-2">
          {{ ambassadorId ? 'Редактировать амбассадора' : 'Добавить амбассадора' }}
        </v-card-title>
        <v-form
          ref="form"
          class="px-6"
          v-model="valid"
          validation
        >
          <v-row>
            <v-col cols="6">
              <v-text-field
                label="Имя и Фамилия"
                dense
                v-model="formData.fullName"
                :rules="validation.fullName"
                required
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Номер в слайдере"
                dense
                v-model="formData.order"
                :rules="validation.order"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Должность"
                dense
                v-model="formData.content"
                :rules="validation.content"
              />
            </v-col>
          </v-row>
          <div :class="showMessage || showMessage1  ? 'active' : ''">
            <v-row>
              <v-col>
                <p class="label" :class="descriptionLength1 ? 'active' : ''">Цитата</p>
              </v-col>
            </v-row>
            <vue-editor ref="editor" :editorToolbar="customToolbar" v-model="formData.description" @input="descriptionLength"/>
            <p v-if="showMessage" class="description__error">Обязательное поле.</p>
            <p v-if="showMessage1" class="description__error">Максимальное количесто символов 400</p>
          </div>
          <v-row class="mt-6">
            <v-col cols="6">  
              <v-file-input
                v-model="selectedFileDesktop"
                :rules="!ambassadorId ? validation.image : []"
                accept="image/jpg"
                label="Photo desktop (JPG, 800х800)"
                append-outer-icon ="mdi-paperclip"
                prepend-icon=""
                @change="handleFileChangeDesktop"
                @click:clear="fileDesktopClear"
                :clearable="!ambassadorId"
              />
            </v-col>
            <v-col cols="6">
              <v-file-input
                v-model="selectedFileMobile"
                :rules="!ambassadorId ? validation.image : []"
                accept="image/jpg"
                label="Photo mobile (JPG, 600x400)"
                append-outer-icon ="mdi-paperclip"
                prepend-icon=""
                @change="handleFileChangeMobile"
                @click:clear="fileMobileClear"
                :clearable="!ambassadorId"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col  class="pt-0">
              <div class="image image__desktop"> 
                <img
                  v-if="imageUrlDesktop"
                  :src="imageUrlDesktop"
                  :key="imageUrlDesktop"
                >
              </div>
            </v-col>
            <v-col class="pt-0">
              <div class="image image__mobile">
                <img
                  v-if="imageUrlMobile"
                  :src="imageUrlMobile"
                  :key="imageUrlMobile"
                >
              </div>
            </v-col>
          </v-row>
        <v-card-actions class="pt-8 pa-6">
          <v-spacer></v-spacer>
          <v-btn
            @click="closeAmbassadorDialog"
          >
            Отмена
          </v-btn>
          <v-btn
            color="warning"
            @click="saveAmbassador"
            :disabled ="!valid || !descriptionLength1 || lengthDescrition > limitDscription"
            :loading="isLoading"
          >
          Сохранить
          </v-btn>
        </v-card-actions>
        </v-form>
      </v-card>
  </v-dialog>
  <v-dialog
    v-model="confirmDialog"
    max-width="500"
  >
    <v-card>
      <v-card-title class="text-h6">
        Вы уверены что хотите удалить амбассадора {{fullName}}?
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="confirmDialog = false"
        >
          Отменить
        </v-btn>

        <v-btn
          color="red"
          dark
          @click="deleteAmbassadorItem"
          class="ml-4"
        >
          Удалить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <notifications :duration="5000" position="bottom right"/>
  </div>
</template>

<script>
  import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
  import onboardingApiInitializer from '@/api/onboardingApi';
  import { VueEditor } from "vue2-editor";
  import { uuid } from "vue-uuid";
  export default {
    name: 'AmbassadorsView',
    components: {
      VueEditor,
    },
    setup() {
        const marketingProgramStore = useMarketingProgramStore()
        return { marketingProgramStore }
      },
     computed: {
      mp() {
          if (this.marketingProgramStore.isInitialized === false)
            return null;
          return this.marketingProgramStore;
        },
        onboardingApi() {
          if (!this.mp)
            return null;
          return onboardingApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
        },
        descriptionLength1() {
          return this.lengthDescrition > 0 ? true : false;
        }

     },
     data: () => ({ 
      isLoading: false,
      confirmDialog: false,
      ambassadorId: null,     
      imageUrlDesktop: null,
      selectedFileDesktop: null,
      imageUrlMobile: null,
      selectedFileMobile: null,
      ambassadorDialog: false,
      updating: false,
      items: [],
      total: 0,
      pagination: {
        offset: 0,
        count: 10,
      },
      formData: {
        fullName: '',
        order: null,
        content: null,
        description: '',
        desktopImageUrl: null,
        mobileImageUrl: null,
      },
      limitDscription: 400,
      lengthDescrition: 0,
      valid: true,
      validation: {        
        fullName: [
          value => !!value || 'Обязательное поле.',
        ],
        order: [
          value => !!value || 'Обязательное поле.',
          value => !value || /^\d+(\.\d{1,2})?$/i.test(value) || 'Значение должно быть числом не меньше 0',
        ],
        content: [
          value => !!value || 'Обязательное поле.',
          value => (value && value.length <= 450) || 'Не более 450 символов.',
        ],
        description: [
          value => !!value || 'Обязательное поле.',
          value => (value && value.length <= 450) || 'Не более 450 символов.',
        ],
        image: [
          value => !!value || 'Обязательное поле.',
        ],
      },
      showMessage: false,
      showMessage1: false,
      fullName: null,
      customToolbar: [
        ["bold",{ list: "ordered"}, {list: "bullet" }, "link",],
      ],
      headers: [
        { value: 'mobileImageUrl', text: 'Изображени', align: 'start', sortable: false, width: '113px'},
        { value: 'fullName', text: 'Имя', align: 'start', sortable: true },
        { value: 'content', text: 'Должность', align: 'start', sortable: true },
        { value: 'description', text: 'Цитата', align: 'start', sortable: true },
        { value: 'order', text: 'Номер', align: 'center', sortable: true, width: '100px' },
        { value: 'actions', text: 'Действия', align: 'center', sortable: false, width: '100px'  },
      ],
     }),
     methods: {
      descriptionLength() {
        const quill = this.$refs.editor.quill
        this.lengthDescrition = quill.getLength()
        this.showMessage = this.formData.description.trim() === '';
        this.showMessage1 = this.lengthDescrition > this.limitDscription;
        if (this.lengthDescrition > this.limitDscription) {
          quill.deleteText(this.limitDscription, this.lengthDescrition);
        }
      },
      async openDialogAmbassadors(item){
        if (item?.ambassadorId) {
          this.ambassadorId = item.ambassadorId;
          this.formData.fullName = item.fullName;
          this.formData.order = item.order;
          this.formData.content = item.content;
          this.formData.description = item.description;
          // this.selectedFileDesktop = item.desktopImageUrl;
          // this.selectedFileMobile = item.mobileImageUrl;
          // const timestamp = new Date().getTime();
          this.imageUrlDesktop = item.desktopImageUrl;
          this.imageUrlMobile = item.mobileImageUrl;

        } else {
          if(this.$refs.form) {
            await this.$nextTick();
            this.$refs.form.resetValidation()
            }
          this.ambassadorId = null;
          this.formData.fullName = null;
          this.formData.order = null;
          this.formData.content = null;
          this.formData.description = ' ';
          this.selectedFileDesktop = null;
          this.selectedFileMobile = null;
          this.imageUrlDesktop = null;
          this.imageUrlMobile = null;
        } 
        this.ambassadorDialog = true;
      },
      closeAmbassadorDialog() {
        this.ambassadorId = null;
        this.ambassadorDialog = false;
      },
      confirmDialogOpen(item) {
        this.confirmDialog = true; 
        this.fullName = item.fullName;
        this.ambassadorId = item.ambassadorId;
      },
      async deleteAmbassadorItem() {
        this.onboardingApi.deleteAmbassador(this.ambassadorId)
        .then((response) => {
          if(response.success) {
            this.getAmbassadorsItems(this.pagination);
            this.resetForm();
            this.$notify({ type: 'success', text: `Амбассадор ${this.fullName} успешно удален` });
          } else this.$notify({ type: 'error', text: 'Амбассадор не удален' });
        })
        this.confirmDialog = false; 
      },
      handleFileChangeDesktop(event) {
      const file = event;
      this.selectedFileDesktop = file;
      if (this.selectedFileDesktop) {
        this.imageUrlDesktop = URL.createObjectURL(this.selectedFileDesktop);
      }
    },
      handleFileChangeMobile(event) {
        const file = event;
        this.selectedFileMobile = file;
        if (this.selectedFileMobile) {
          this.imageUrlMobile = URL.createObjectURL(this.selectedFileMobile);
        }
      },
      fileDesktopClear() {
        // if (!this.ambassadorId) {
          this.selectedFileDesktop = null;
          this.imageUrlDesktop = null
        // }
      },
      fileMobileClear() {
        this.selectedFileMobile = null;
        this.imageUrlMobile = null
      },
      saveAmbassador() {
        if (this.ambassadorId) {
          this.updateAmbassador();
        } else {
          this.createAmbassador();
        }
      },
      resetForm() {
        if(this.$refs.form) {
          this.$refs.form.reset()
        }
      },
      async updateAmbassador() {
        this.isLoading = true;
        const model = {
          fullName: this.formData.fullName,
          order: this.formData.order,
          content: this.formData.content,
          description: this.formData.description,

        }
        if (this.selectedFileDesktop) {
          model.desktopFile = this.selectedFileDesktop;
        } else {
          model.desktopFile = '';
        }
        if (this.selectedFileMobile) {
          model.mobileFile = this.selectedFileMobile;
        } else {
          model.mobileFile = '';
        }
        this.onboardingApi.updateAmbassador(this.ambassadorId, model)
        .then((response) => {
          if(response.status === 204) {
            this.isLoading = false;
            this.resetForm();
            this.ambassadorDialog = false;
            this.$notify({ type: 'success', text: `Амбассадор ${model.fullName} успешно обновлен` });
            this.getAmbassadorsItems(this.pagination)
          } else {
              this.$notify({ type: 'error', text: `Амбассадор не обновлен` });
              this.isLoading = false;
            }
          })
          .catch((error) => {
            console.error('Error creating slide:', error);
            this.$notify({ type: 'error', text: `Амбассадор не обновлен. Ошибка: ${error.title}` });
            this.isLoading = false;
          });
      },
      async createAmbassador() {
        const valid = this.$refs.form.validate()
        if(valid) {
          this.isLoading = true;
        }
        const ambassadorId = uuid.v4();
        const model = {
          ambassadorId: ambassadorId,
          fullName: this.formData.fullName,
          order: this.formData.order,
          content: this.formData.content,
          description: this.formData.description,
          desktopFile: this.selectedFileDesktop, 
          mobileFile: this.selectedFileMobile
        }
        await this.onboardingApi.createAmbassador(model)
        .then((response) => {
          if(response.status === 201) {
            this.isLoading = false;
            this.resetForm();
            this.ambassadorDialog = false;
            this.$notify({ type: 'success', text: 'Амбассадор успешно создан' });
            this.getAmbassadorsItems(this.pagination)
          } else {
              this.$notify({ type: 'error', text: `Амбассадор не создан. Ошибка: ${response.error.detail}` });
              this.isLoading = false;
            }
          })
          .catch((error) => {
            console.error('Error creating slide:', error);
            this.isLoading = false;
          });
        },
      async tableUpdated(pagination, sorting, search) {
        await this.getAmbassadorsItems(pagination, sorting, search);
      },
      async getAmbassadorsItems(pagination, sorting, search) {
        if (!this.onboardingApi) return;
        this.updating = true;
        try {
          const response = await this.onboardingApi.getAmbassadors(
            pagination,
            sorting,
            search,
          );
          const {values, total} = response.data;
          this.total = total;
          this.items = values;
        } finally {
          this.updating = false;
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .trancate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
  }
  .name {
    max-width: 170px;
  }
  .content {
    max-width: 285px;
  }
  .description {
    max-width: 211px;
    ::v-deep  p{
      margin: 0;
    }
    &__error {
      color: #ff5252;
      font-size: 12px;
    }
  }
  .label {
    margin: 0;
    padding: 4px 0 2px;
    color: rgba(0, 0, 0, 0.6);
    &.active {
      font-size: 12px;
    }
  }
  .image {
    img {
      height: 100px;
      width: 100%;
      object-fit: cover;
    }
    &__desktop {
      width: 100px;
    }
    &__mobile {
      width: 147px;
    }
  } 
  ::v-deep .quillWrapper > .ql-container {
    border-color: rgba(0, 0, 0, 0.42);

  }
  ::v-deep .ql-toolbar.ql-snow {
    border-color: rgba(0, 0, 0, 0.42);
  }
  ::v-deep .ql-tooltip.ql-editing {
    left: 0 !important;
  }
  .active {
    .label {
      color: #ff5252;
    }
    ::v-deep .quillWrapper > .ql-container {
    border-color: #ff5252;
    }
    ::v-deep .ql-toolbar.ql-snow {
      border-color: #ff5252;

    }
    ::v-deep .ql-snow .ql-stroke{
        stroke: #ff5252;
      }
    ::v-deep .ql-fill{
        fill: #ff5252;
      }
  }
</style>