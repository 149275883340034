<template>
  <div>
    <v-row class="mt-3 mb-3">
      <v-col>
        <v-text-field
            label="Имя или email участника"
            v-model="filters.nameOrEmailAddress"
            hide-details="auto"
          ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
            label="Начало отчета"
            v-model="filters.startDate"
            :rules="validation.date"
            hide-details="auto"
            append-outer-icon="mdi-calendar-today"
          ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
            label="Конце отчета"
            v-model="filters.endDate"
            :rules="validation.date"
            hide-details="auto"
            append-outer-icon="mdi-calendar-today"
          ></v-text-field>
      </v-col>
      <v-col class="d-flex">
        <div class="mr-auto">
          <v-btn large @click="show" class="mr-3">Показать</v-btn>
          <!-- <v-btn large @click="getNominationParticipantsCsv" class="mr-3">
            <v-icon left>mdi-download</v-icon>
            CSV
          </v-btn> -->
        </div>
      </v-col>
    </v-row>

    <DataTable
      :key="filtersHash"
      :headers="headers"
      :items="items"
      :server-items-length="total"
      sort-by="date"
      :sort-desc="true"
      :loading="updating"
      @update:table-options="tableUpdated"
    >
      <template v-slot:[`item.name`]="{item}">
        {{ item.firstName }}
        {{ item.lastName }}
      </template>
      <template v-slot:[`item.emailAddress`]="{item}">
        {{ item.emailAddress ? item.emailAddress : '' }}
      </template>
      <template v-slot:[`item.lastAccrual`]="{item}">
        {{ item.lastAccrual | formatDateRus }}
      </template>
      <template v-slot:[`item.organizationName`]="{item}">
        {{ item.attributes.find(attr => attr.name.value === "OrganizationName") ? item.attributes.find(attr => attr.name.value === "OrganizationName").value.value : '-' }}
      </template>
      <template v-slot:[`item.tel`]="{item}">
        {{ item.attributes.find(attr => attr.name.value === "Tel") ? item.attributes.find(attr => attr.name.value === "Tel").value.value : '-' }}
      </template>
      <template v-slot:[`item.team`]="{item}">
        {{ item.team ? item.team.teamName : '-' }}
      </template>
    </DataTable>

    <NominationParticipantsPage
      v-if="nominationExercisesPageShow"
      :nomination-id="nominationId"
      :save-func="createNominationExercise"
      @closed="nominationExercisesPageShow = false"
    />
  </div>
</template>

<script>
import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
import ratingsApiInitializer from '@/api/ratingsApi';
import NominationParticipantsPage from './NominationParticipantsPage.vue';
import Moment from 'moment'

export default {
  name: 'NominationParticipantsView',
  components: { NominationParticipantsPage },

  props: {
    nominationId: undefined,
    isEdit: Boolean,
  },

  setup() {
    const marketingProgramStore = useMarketingProgramStore()
    return { marketingProgramStore }
  },

  data: () => ({
    filtersHash: Date.now(),
    headers: [
      { value: 'name', text: 'Имя', align: 'start', sortable: false },
      { value: 'emailAddress', text: 'Email', align: 'start', sortable: false },
      { value: 'points', text: 'Очков', align: 'end', width: 160, sortable: false },
      { value: 'position', text: 'Место', align: 'end', width: 160, sortable: false },
      { value: 'organizationName', text: 'Место работы', align: 'end', width: 200, sortable: false },
      { value: 'tel', text: 'Телефон', align: 'end', width: 200, sortable: false },
      { value: 'team', text: 'Команда', align: 'end', width: 200, sortable: false },
      { value: 'lastAccrual', text: 'Последнее начисление', align: 'end', width: 160, sortable: false },
    ],
    validation: {
      date: [
        value => !value || /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[012])\.(19|20)\d\d$/i.test(value) || 'Некорректная дата. Формат ДД.ММ.ГГГГ',
      ],
    },
    items: [],
    total: 0,
    filters: {
      nameOrEmailAddress: undefined,
      startDate: undefined,
      endDate: undefined
    },
    updating: false,
    nominationExercisesPageShow: false,
  }),

  computed: {
    exerciseTemplateId() {
      return this.$route.params.exerciseTemplateId;
    },

    mp() {
      if (this.marketingProgramStore.isInitialized === false)
        return null;

      return this.marketingProgramStore;
    },

    ratingsApi() {
      if (!this.mp)
        return null;

      return ratingsApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
    },
  },

  methods: {
    show() {
      this.filtersHash = Date.now();
    },

    async tableUpdated(pagination, sorting, search) {
      await this.getNominationParticipants(pagination, sorting, search);
    },

    async getNominationParticipants(pagination, sorting, search) {
      if (!this.ratingsApi) return;

      this.updating = true;

      try {
        const request = {
          nominationId: this.nominationId,
          nameOrEmailAddress: this.filters.nameOrEmailAddress,
          startDate: this.filters.startDate ? Moment(this.filters.startDate, 'DD.MM.YYYY').toDate() : null,
          endDate: this.filters.endDate ? Moment(this.filters.endDate, 'DD.MM.YYYY').toDate() : null,
          pagination, sorting, search
        };

        const response = await this.ratingsApi.getNominationParticipants(request);

        this.total = response.data.total;
        this.items = response.data.values;
      } finally {
        this.updating = false;
      }
    },

    async getNominationParticipantsCsv() {
      const request = {
          nominationId: this.nominationId,
          nameOrEmailAddress: this.filters.nameOrEmailAddress,
          startDate: this.filters.startDate ? Moment(this.filters.startDate, 'DD.MM.YYYY').toDate() : null,
          endDate: this.filters.endDate ? Moment(this.filters.endDate, 'DD.MM.YYYY').toDate() : null
        };

      const response = await this.ratingsApi.getNominationParticipantsCsv(request);

      const fileUrl = window.URL.createObjectURL(response.data);

      const anchor = document.createElement("a");
      anchor.download = decodeURI(response.request.getResponseHeader("X-Data-FileName")).replace(/\+/g, " ");
      anchor.href = fileUrl;
      anchor.target = "_blank";
      anchor.click();

      anchor.remove();
    }
  },
}
</script>