<template>
  <EditorDialog
    title="Работа"
    :max-width="800"
    :save-func="save"
    @closed="localClose"
  >
    <v-form ref="formTask" lazy-validation>
      <v-row>
        <v-col>
          <v-select
            v-model="model.type"
            label="Тип"
            item-text="name"
            item-value="key"
            :items="exerciseTypes"
            :disabled="!!objectId"
            hide-details="auto"
          ></v-select>
        </v-col>
        <v-col>
          <v-text-field
            label="Порядок"
            v-model="model.order"
            :rules="validation.order"
            hide-details="auto"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row class="mb-3">
        <v-col>
          <v-text-field
            label="Название работы"
            v-model="model.title"
            :rules="validation.title"
            hide-details="auto"
          ></v-text-field>
        </v-col>
      </v-row>

      <div v-if="model.type === 'SportActivity'">
        <v-row>
          <v-col>
            <v-text-field
              :label="labelReward"
              v-model="model.reward"
              :rules="validation.reward"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Максимальное вознаграждение"
              v-model="model.maxReward"
              :rules="validation.maxReward"
              hide-details="auto"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-select
              v-model="model.settings.Discipline"
              label="Дисциплина"
              :items="exerciseDisciplines"
              item-text="name"
              item-value="type"
              :disabled="!!objectId"
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              label="Мин дистанция"
              v-model="model.settings.MinDistance"
              :rules="validation.settingsMinDistance"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Макс дистанция"
              v-model="model.settings.MaxDistance"
              :rules="validation.settingsMaxDistance"
              hide-details="auto"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              label="Мин темп"
              v-model="model.settings.MinPace"
              :rules="validation.settingsMinPace"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Макс темп"
              v-model="model.settings.MaxPace"
              :rules="validation.settingsMaxPace"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Мин время (сек)"
              v-model="model.settings.MinTimeInSeconds"
              :rules="validation.settingsMinTimeInSecondsRunles(model.settings.MaxTimeInSeconds)"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Макс время (сек)"
              v-model="model.settings.MaxTimeInSeconds"
              :rules="validation.settingsMaxTimeInSecondsRunles(model.settings.MinTimeInSeconds)"
              hide-details="auto"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>

      <div v-if="model.type === 'GeoLocation'">
        <v-row>
          <v-col>
            <v-text-field
              label="Вознаграждение за работу"
              v-model="model.reward"
              :rules="validation.reward"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Радиус приема чекина, м"
              v-model="model.settings.Radius"
              :rules="validation.radius"
              hide-details="auto"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="mt-6">
          <v-col>
            <h4>МЕСТА ДЛЯ ЧЕКИНА</h4>
          </v-col>
        </v-row>

        <v-row v-for="item in model.settings.Locations"
          :key="item.id">
          <v-col>
            <v-text-field
              label="Название места"
              v-model="item.Name"
              :rules="validation.geoLocationField3Key"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Координаты"
              v-model="item.value"
              :rules="validation.geoLocationField3Value"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="1">
            <v-icon @click="deleteGeoLocationPoint(item)">mdi-delete</v-icon>
          </v-col>
        </v-row>

        <v-row class="mt-4">
          <v-col>
            <v-btn @click="addGeoLocationPoint()" class="mr-3"><v-icon>mdi-plus-circle</v-icon> ДОБАВИТЬ МЕСТО</v-btn>
            <v-btn @click="importGeoLocation()"><v-icon>mdi-download</v-icon> ИМПОРТИРОВАТЬ</v-btn>
          </v-col>
        </v-row>
      </div>

      <div v-if="model.type === 'Quiz'">
        <v-row>
          <v-col>
            <v-text-field
              label="Номер квиза"
              v-model="model.settings.QuizId"
              :rules="validation.quizeId"
              hide-details="auto"
          ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              label="Баллов за правильный ответ"
              v-model="model.reward"
              :rules="validation.reward"
              hide-details="auto"
          ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Количество вопросов"
              v-model="model.settings.QuizQuestionsQty"
              :rules="validation.quizeQuestionsQty"
              hide-details="auto"
          ></v-text-field>
          </v-col>
        </v-row>
      </div>

      <div v-if="model.type === 'PartnerLink'">
        <v-row>
          <v-col>
            <v-text-field
              label="Ссылка на сайт\страницу партнера"
              v-model="model.settings.PartnerLink"
              :rules="validation.wbLinkPartner"
              hide-details="auto"
          ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              label="Ссылка на поощрение в маркете (необязательно)"
              v-model="model.settings.PromocodeLink"
              :rules="validation.wbLinkPromocode"
              hide-details="auto"
          ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              label="Вознаграждение за работу"
              v-model="model.reward"
              :rules="validation.reward"
              hide-details="auto"
          ></v-text-field>
          </v-col>
        </v-row>
      </div>

      <div v-if="model.type === 'UploadImage'">
        <v-row>
          <v-col>
            <v-text-field
              label="Что прислать"
              v-model="fbWhats"
              hide-details="auto"
              :disabled="true"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              label="Вознаграждение за работу"
              v-model="model.reward"
              :rules="validation.reward"
              hide-details="auto"
          ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Время на «проверку», часов"
              v-model="model.settings.CheckTime"
              :rules="validation.checkTime"
              hide-details="auto"
          ></v-text-field>
          </v-col>
        </v-row>
      </div>
      <div v-if="model.type === 'Registration'">
        <v-row>
          <v-col>
            <v-text-field
              label="Ссылка на страницу регистрации"
              v-model="model.settings.link"
              :rules="validation.link"
              hide-details="auto"
          ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              label="ID стартов для регистрации (RaceID)"
              v-model="model.settings.RaceId"
              :rules="validation.raceId"
              hide-details="auto"
          ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              label="Вознаграждение за работу"
              v-model="model.reward"
              :rules="validation.reward"
              hide-details="auto"
          ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Максимальное вознаграждение"
              v-model="model.maxReward"
              :rules="validation.settingsMinPace(model.reward, model.maxReward)"
              hide-details="auto"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
      <div v-if="model.type === 'Participation'">
        <v-row>
          <v-col>
            <v-text-field
              label="ID выбранной дистанции старта"
              v-model="model.settings.RaceId"
              :rules="validation.raceId"
              hide-details="auto"
          ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              label="Минимальная дистанция (м)"
              v-model="model.settings.MinDistance"
              :rules="validation.settingsMinDistance"
              hide-details="auto"
          ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              label="Максимальное время (сек)"
              v-model="model.settings.MinTimeInSeconds"
              :rules="validation.settingsMinTimeInSecondsRunles(model.settings.MaxTimeInSeconds)"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              label="Вознаграждение за работу"
              v-model="model.reward"
              :rules="validation.settingsMinPace1"
              hide-details="auto"
          ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              label="Максимальное вознаграждение"
              v-model="model.maxReward"
              :rules="validation.settingsMinPace(model.reward, model.maxReward)"
              hide-details="auto"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
      <div v-if="model.type === 'Invite'">
        <v-row>
          <v-col cols="6">
            <v-text-field
              label="Для закрытых МП: реферальная ссылка на команду или домен"
              v-model="model.settings.Link"
              :rules="validation.link"
              hide-details="auto"
            ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Минимальное количество активаций реф.ссылки"
                v-model="model.settings.RegistrationCount"
                :rules="validation.raceId"
                hide-details="auto"
            ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Вознаграждение за 1 активацию"
                v-model="model.reward"
                :rules="validation.settingsMinPace1"
                hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              label="Максимальное вознаграждение"
              v-model="model.maxReward"
              :rules="validation.settingsMinPace(model.reward, model.maxReward)"
              hide-details="auto"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
    </v-form>
  </EditorDialog>
</template>

<script>
import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
import editorDialogMixin from '@/global-components/dialog/mixins';
import { uuid } from "vue-uuid";

export default {
  name: 'ExerciseTemplateTaskEditorDialog',
  mixins: [editorDialogMixin],

  props: {
    object: {
      type: Object,
      default: () => {
        return {
          title: null,
          type: 'SportActivity',
          order: 1,
          reward: null,
          maxReward: null,
          geoLocationField1: null,
          geoLocationField2: null,
          geoLocationField3: [{ id: uuid.v4(), name: null, value: null }],
          settings: {
            Discipline: "Running",
            MinDistance: "10000",
            MaxDistance: "50000",
            MinPace: "510",
            MaxPace: "180",
            MinTimeInSeconds: null,
            MaxTimeInSeconds: null,
            Radius: null,
            Locations: [{ Name: null, value: null, Latitude: 0, Longitude: 0 }]
          },
        }
      }
    },
    exerciseType: undefined,
    saveFunc: Function,
  },

  setup() {
    const marketingProgramStore = useMarketingProgramStore()
    return { marketingProgramStore }
  },

  data: () => ({
    saving: false,
    valid: false,
    exerciseDisciplines: [
      {type: 'Running', name: 'Бег'},
      {type: 'Walking', name: 'Ходьба'},
      {type: 'Cycling', name: 'Велосипед'},
      {type: 'Swimming', name: 'Плавание'},
      {type: 'Skiing', name: 'Лыжи'},
    ],
    validation: {
      title: [
        value => !!value || 'Обязательное поле.',
        value => (value && value.length <= 256) || 'Не более 256 символов.',
      ],
      type: [
        value => !!value || 'Обязательное поле.',
        value => (value && value.length <= 16) || 'Не более 16 символов.',
      ],
      order: [
        value => !!value || 'Обязательное поле.',
        value => (value && Number.isInteger(value * 1)) || 'Требуется целое число.',
        value => (value && value > 0 && value < 11) || 'Требуется целое число от 1 до 10.',
      ],
      reward: [
        value => !!value || 'Обязательное поле.',
        value => (value && !isNaN(parseFloat(value))) || 'Требуется число.',
        value => (value && value > 0) || 'Требуется число больше 0.',
      ],
      maxReward: [
        value => !!value || 'Обязательное поле.',
        value => (value && !isNaN(parseFloat(value))) || 'Требуется число.',
        value => (value && value > 0) || 'Требуется число больше 0.',
      ],
      radius: [
        value => !!value || 'Обязательное поле.',
        value => !value || /^\d+?$/.test(value) || 'Значение должно быть целым числом.',
      ],
      geoLocationField3Key: [
        value => !!value || 'Обязательное поле.',
        value => (value && value.length <= 256) || 'Не более 256 символов.',
      ],
      geoLocationField3Value: [
        value => !!value || 'Обязательное поле.',
        value => !value || /^-?\d{1,3}\.\d{1,6}, -?\d{1,3}\.\d{1,6}$/.test(value) || 'Формат: 55.715262, 37.595075.'
      ],
      settingsDiscipline: [
        value => !!value || 'Обязательное поле.',
      ],
      settingsMinDistance: [
        value => !!value || 'Обязательное поле.',
        value => (value && !isNaN(parseInt(value))) || 'Требуется целое число.',
      ],
      settingsMaxDistance: [
        value => !!value || 'Обязательное поле.',
        value => (value && !isNaN(parseInt(value))) || 'Требуется целое число.',
      ],
      settingsMinPace1: [
        value => !!value || 'Обязательное поле.',
        value => (value && !isNaN(parseInt(value))) || 'Требуется целое число.',
      ],
      settingsMaxPace: [
        value => !!value || 'Обязательное поле.',
        value => (value && !isNaN(parseInt(value))) || 'Требуется целое число.',
      ],
      quizeId: [
        value => !!value || 'Обязательное поле.',
        value => (value && !isNaN(parseInt(value))) || 'Требуется целое число.',
      ],
      quizeQuestionsQty: [
        value => !!value || 'Обязательное поле.',
        value => (value && !isNaN(parseInt(value))) || 'Требуется целое число.',
      ],
      wbLinkPartner: [
        value => !!value || 'Обязательное поле.',
      ],
      checkTime: [
        value => !!value || 'Обязательное поле.',
        value => (value && !isNaN(parseInt(value))) || 'Требуется целое число.',
      ],
      fbWhat: [
        value => !!value || 'Обязательное поле.',
      ],
      link: [
        value => !!value || 'Обязательное поле.',
        value => !value || /https?:\/\//.test(value) || 'Формат: http:// или https://.',
      ],
      raceId: [
        // value => !!value || 'Обязательное поле.',
      ],
      settingsMinTimeInSecondsRunles(maxValue) {
        return [  
          value => !!value || 'Обязательное поле.',
          v => !v || /^\d*?$/.test(v) || 'Значение должно отсутсвовать или быть целым числом.',
          v => !v || !maxValue || parseInt(v, 10) <= parseInt(maxValue) || 'Минимальное время не может быть больше максимального'
        ];
      },
      settingsMaxTimeInSecondsRunles(minValue) {
        return [  
          v => !v || /^\d*?$/.test(v) || 'Значение должно отсутсвовать или быть целым числом.',
          v => !v || !minValue || parseInt(v, 10) >= parseInt(minValue) || 'Максимальное время не может быть меньше минимального'
        ];
      },
      // settingsMaxPace(minValue, maxValue) {
      //   return [  
      //     value => !!value || 'Обязательное поле.',
      //     v => !v || /^\d*?$/.test(v) || 'Значение должно отсутсвовать или быть целым числом.',
      //     v => !v || !minValue || parseInt(v, 10) >= parseInt(maxValue) || 'Максимальное время не может быть меньше минимального'
      //   ];
      // },
      settingsMinPace(minValue, maxValue) {
        return [  
          value => !!value || 'Обязательное поле.',
          v => !v || /^\d*?$/.test(v) || 'Значение должно быть целым числом.',
          v => !v || !maxValue || parseInt(v, 10) >= parseInt(minValue) || 'Максимальное вознаграждение не может быть меньше минимального'
        ];
      },
    },
  }),

  computed: {
    labelReward: function() {
      if (this.model && this.model.settings) {
        const d = this.model.settings.Discipline
        if (d === 'Swimming') return 'Вознаграждение за 100м';
        else return "Вознаграждение за км";
      }
      return "Вознаграждение";
    },
    exerciseTypes: function() {
      const r = [
        {
          key: 'SportActivity',
          name: 'Спортивная активность'
        },
        {
          key: 'GeoLocation',
          name: 'Геопривязка'
        },
        {
          key: 'Quiz',
          name: 'Квиз',
          disabled: this.exerciseType !== 'Disposable'
        },
        {
          key: 'PartnerLink',
          name: 'Партнерская ссылка'
        },
        {
          key: 'UploadImage',
          name: 'Загрузка картинки'
        },
        {
          key: 'Registration',
          name: 'Регистрация на забег'
        },
        {
          key: 'Participation',
          name: 'Участие в собыии'
        },
        {
          key: 'Invite',
          name: 'Пригласить друга'
        }
      ];

      return r;
    }
  },

  methods: {
    addGeoLocationPoint() {
      this.model.settings.Locations.push({ id: uuid.v4(), Name: null, value: null });
    },

    deleteGeoLocationPoint(item) {
      this.model.settings.Locations.splice(this.model.settings.Locations.indexOf(item), 1);
    },

    importGeoLocation() {
      // TODO:
      console.log('Import Geo Activity');
    },

    async save() {
      this.saving = true;
      this.valid = await this.$refs.formTask.validate();

      if (!this.valid)
        return;

      this.saveFunc(this.model);
    },

    localClose() {
      const saving = this.saving;
      this.saving = false;

      if (saving && !this.valid)
        return;

      this.close();
    },
  },
}
</script>