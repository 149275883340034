import axios from "axios";
import { HttpError } from "../utils/errors";
import Cookies from 'js-cookie'







export function initializeApi(baseUrl, headers = {}) {
    const api = axios.create({
        responseType: "json",
        baseURL: baseUrl,
        headers: {
            "Content-Type": "application/json",
            ...headers
        }
    });

    api.interceptors.request.use(config => {
        const token = Cookies.get('token'); // Получение токена из куки
        if (token) {
        config.headers.Authorization = `bearer ${token}`;
        }
        return config;
    }, error => {
        return Promise.reject(error);
    });

    function getHttpError(error) {
        let { config: {url, method}, response: { status, data } } = error;

        if (status === 413) {
            return new HttpError(
                status,
                'request_entity_too_large',
                'Слишком большой запрос',
                'Слишком большое тело запроса',
                method,
                baseUrl + url
            );
        }

        if (!data) {
            return new HttpError(
                status,
                'internal_server_error',
                'Internal server error',
                `Сервер ответил кодом ошибки "${status}" без описания ошибки.`,
                method,
                baseUrl + url,
                null,
                null
            );
        }

        if (status === 400) {
            return new HttpError(
                status,
                data.code || 'bad_request',
                'Некорректный запрос',
                data.title,
                method,
                baseUrl + url,
                data,
                data.traceId
            );
        }

        return new HttpError(
            status,
            data.code,
            data.title || 'Неизвестная ошибка',
            data.detail || `Сервер ответил кодом ошибки "${status}" без описания ошибки.`,
            method,
            baseUrl + url,
            data,
            data.traceId
        );
    }

    api.interceptors.response.use(
        (response) => {
            // if (response.status === 201 && response.headers.location) {
            //     return { $location: response.headers.location, ...(response.data || {}) };
            // }
            return response;
        },
        async (error) => {
            if (axios.isCancel(error)) {
                return Promise.reject(error);
            }

            return Promise.reject(getHttpError(error));
        },
    );

    return api;
}

export default {
    initializeApi,
};