<template>
  <div>
    {{attributes.TeamName}}
  </div>
</template>

<script>
export default {
  name: 'OperationTeamParticipant',

  props: {
    sourceId: String,
    attributes: Object,
  },

  computed: {
    operationTypeText() {
      if (this.attributes.OperationType === 'Refill') {
        switch (this.attributes.OperationSource) {
          case 'Exercise': return 'Задание';
          case 'ExtendedQuestionnaire': return 'Вознаграждение за анкету';
          case 'Registration': return 'Вознаграждение за регистрацию';
          default: return this.attributes.OperationSource;
        }
      }

      if (this.attributes.OperationType === 'Withdrawal') {
        return 'Вывод';
      }

      return '';
    },
  },

  methods: {
    getOperationTypeColor(operationType) {
      if (operationType === 'Refill')
        return ['#93de64', 'black'];

      if (operationType === 'Withdrawal')
        return ['#fa4646', 'white'];
    },
  }
}
</script>
