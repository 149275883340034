import {initializeApi} from "@/api/base";

function withMarketingProgram(marketingProgramId) {
  const api = initializeApi("/api/content", {'X-MarketingProgram-Id': marketingProgramId});
  async function createBanner(bannerData) {
    const form = new FormData();
    for (const key of Object.keys(bannerData)) {
      form.append(key, bannerData[key]);
    }
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };
    return api.post('/api/v1/banners', form, config);
  }
  async function updateBanner(bannerId, bannerData) {
    const form = new FormData();
    for (const [key, value] of Object.entries(bannerData)) {
      form.append(key, value);
    }
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };
    return api.patch(`/api/v1/banners/${bannerId}`, form, config);
  }

  async function getBanners(pagination, sorting, search) {
    const response = await api
      .get(`/api/v1/banners`, {
        params: {
          searchString: search,
          ...pagination,
          ...sorting,
        }
      })
      .then(response => {
        return {status: response.status, data: response.data};
      });
      return response;
  }

  async function getBanner(bannerId) {
    const response = await api
      .get(`/api/v1/banners/${bannerId}`)
      .then(response => {
        return {status: response.status, data: response.data};
      });
    return response;
  }
  async function deleteBanner(bannerId) {
    const response = await api
      .delete(`/api/v1/banners/${bannerId}`)
      .then(response => {
        return {status: response.status, data: response.data};
      });
    return response;
  }
  
  async function getFAQThemes(pagination, sorting, search) {
    const response = await api
      .get(`/api/v1/faq/themes`, { 
        params: {
          searchString: search,
          ...pagination,
          ...sorting,
        }
      })
      .then(response => {
        return {status: response.status, data: response.data};
      });
      return response;
  }

  async function getTheme(themeId) {
    const response = await api
      .get(`/api/v1/faq/themes/${themeId}`)
      .then(response => {
        return {status: response.status, data: response.data};
      });
  
    return response;
  }
  async function createRule(marketingProgramRuleId) {
    const response = await api
      .post(`/api/v1/rules`, { marketingProgramRuleId })
      .then(response => {
        return {status: response.status, data: response.data};
      });
  
    return response;
  }
  async function getRule(ruleId) {
    const response = await api
      .get(`/api/v1/rules/${ruleId}`)
      .then(response => {
        return {status: response.status, data: response.data};
      });
  
    return response;
  }
  async function addRule(ruleId, data) {
    const formData = new FormData();
    formData.append("File", data, data.name);
    const response = await api
      .patch(`/api/v1/rules/${ruleId}/files`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }})
      .then(response => {
        return {status: response.status, data: response.data};

      });
  
    return response;
  }

  async function getFAQThemeQuestion(themeId, themeQuestionId) {
    const response = await api
      .get(`/api/v1/faq/themes/${themeId}/questions/${themeQuestionId}`)
      .then(response => {
        return {status: response.status, data: response.data};
      });
  
    return response;
  }
  async function getFAQThemeQuestionAnswer(themeId, themeQuestionId) {
    const response = await api
      .get(`/api/v1/faq/themes/${themeId}/questions/${themeQuestionId}/answers`)
      .then(response => {
        return {status: response.status, data: response.data};
      });
  
    return response;
  }

  async function getFAQThemeQuestions(pagination, sorting, search, themeId) {
    const response = await api
      .get(`/api/v1/faq/themes/${themeId}/questions`, { 
        params: {
          searchString: search,
          ...pagination,
          ...sorting,
        }
      })
      .then(response => {
        console.log(response.data);
        return {status: response.status, data: response.data};
      });
      return response;
  }

  async function createFAQTheme(model) {
    const response = await api
      .post(`/api/v1/faq/themes`, {...model})
      .then(response => {
        return {success: true, status: response.status, data: response.data};
      })
      .catch((error) => {
        return {success: false, error};
      });

    return response;
  }

  async function createFAQThemeQuestion(model, themeId) {
    const response = await api
      .post(`/api/v1/faq/themes/${themeId}/questions`, {...model})
      .then(response => {
        return {success: true, status: response.status, data: response.data};
      })
      .catch((error) => {
        return {success: false, error};
      });

    return response;
  }
  async function createFAQThemeQuestionAnswer(themeId, themeQuestionId, model) {
    const response = await api
      .post(`/api/v1/faq/themes/${themeId}/questions/${themeQuestionId}/answers`, {...model})
      .then(response => {
        return {success: true, status: response.status, data: response.data};
      })
      .catch((error) => {
        return {success: false, error};
      });

    return response;
  }

  async function uploadAnswerImage(themeId, themeQuestionId, answerId, file) {
    const formData = new FormData();
    formData.append("file", file, file.name.toLowerCase());

    const response = await api
        .patch(`/api/v1/faq/themes/${themeId}/questions/${themeQuestionId}/answers/${answerId}/image`, formData)
        .then(response => {
            return { success: true, status: response.status, data: response.data };
        })
        .catch((error) => {
          return {success: false, error};
        });

    return response;
  }

  async function updateFAQThemeQuestion(themeId, themeQuestionId, model) {
    const response = await api
      .patch(`/api/v1/faq/themes/${themeId}/questions/${themeQuestionId}`, model)
      .then(response => {
        return {success: true, status: response.status, data: response.data};
      })
      .catch((error) => {
        return {success: false, error};
      });

    return response;
  }
  async function updateFAQThemeQuestionAnswer(themeId, themeQuestionId, answerId, model) {
    const response = await api
      .patch(`/api/v1/faq/themes/${themeId}/questions/${themeQuestionId}/answers/${answerId}`, model)
      .then(response => {
        return {success: true, status: response.status, data: response.data};
      })
      .catch((error) => {
        return {success: false, error};
      });

    return response;
  }

  async function deleteFAQThemeQuestion(themeId, themeQuestionId) {
    const response = await api
      .delete(`/api/v1/faq/themes/${themeId}/questions/${themeQuestionId}`)
      .then(response => {
        return {success: true, status: response.status, data: response.data};
      })
      .catch((error) => {
        return {success: false, error};
      });

    return response;
  }

  async function deleteAnswer(themeId, themeQuestionId, answerId) {
    const response = await api
      .delete(`/api/v1/faq/themes/${themeId}/questions/${themeQuestionId}/answers/${answerId}`)
      .then(response => {
        return {success: true, status: response.status, data: response.data};
      })
      .catch((error) => {
        return {success: false, error};
      });

    return response;
  }

  async function deleteFAQTheme(themeId) {
    const response = await api
      .delete(`/api/v1/faq/themes/${themeId}`)
      .then(response => {
        return {success: true, status: response.status, data: response.data};
      })
      .catch((error) => {
        return {success: false, error};
      });

    return response;
  } 

  async function updateFAQTheme(themeId, model) {
    const response = await api
      .patch(`/api/v1/faq/themes/${themeId}`, model)
      .then(response => {
        return {success: true, status: response.status, data: response.data};
      })
      .catch((error) => {
        return {success: false, error};
      });

    return response;
  }

  async function getOnboardingItems(pagination, sorting, search) {
    return api.get(`/api/v1/onboarding`, {
    params: {
      searchString: search,
      ...pagination,
      ...sorting,
    }
    })
  }

  async function deleteOnboarding(onboardingItemId) {
    const response = await api
      .delete(`/api/v1/onboarding/${onboardingItemId}`)
      .then(response => {
        return {success: true, status: response.status, data: response.data};
      })
      .catch((error) => {
        return {success: false, error};
      });

    return response;
  } 

  async function createOnboarding(model) {
    const response = await api
      .post(`/api/v1/onboarding`, {...model})
      .then(response => {
        return {success: true, status: response.status, data: response.data};
      })
      .catch((error) => {
        return {success: false, error};
      });

    return response;
  }

  async function updateOnboarding(onboardingItemId, model) {
    const response = await api
      .patch(`/api/v1/onboarding/${onboardingItemId}`, model)
      .then(response => {
        return {success: true, status: response.status, data: response.data};
      })
      .catch((error) => {
        return {success: false, error};
      });

    return response;
  }

  async function getAmbassadors(pagination, sorting, search) {
    const response = await api
      .get(`/api/v1/ambassadors`, { 
        params: {
          searchString: search,
          ...pagination,
          ...sorting,
        }
      })
      .then(response => {
        return {status: response.status, data: response.data};
      });
      return response;
  }

  async function getAmbassador(ambassadorId) {
    return api.get(`/api/v1/ambassadors/${ambassadorId}`, {
    })
  }

  async function createAmbassador(application) {
    const form = new FormData();
    const keys = Object.keys(application);
    for (let i = 0; i < keys.length; i += 1) {
      form.append(keys[i], application[keys[i]]);
    }
    return api.post('/api/v1/ambassadors', form,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  }
  async function updateAmbassador(ambassadorId, application) {
    const form = new FormData();
    const keys = Object.keys(application);
    for (let i = 0; i < keys.length; i += 1) {
      form.append(keys[i], application[keys[i]]);
    }
    return api.patch(`/api/v1/ambassadors/${ambassadorId}`, form,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  }

  async function deleteAmbassador(ambassadorId) {
    const response = await api
      .delete(`/api/v1/ambassadors/${ambassadorId}`)
      .then(response => {
        return {success: true, status: response.status, data: response.data};
      })
      .catch((error) => {
        return {success: false, error};
      });

    return response;
  }
  return {
    getOnboardingItems,
    deleteOnboarding,
    createOnboarding,
    updateOnboarding,
    getFAQThemes,
    createFAQTheme,
    deleteFAQTheme,
    updateFAQTheme,
    getFAQThemeQuestions,
    getTheme,
    createFAQThemeQuestion,
    updateFAQThemeQuestion,
    deleteFAQThemeQuestion,
    getFAQThemeQuestion,
    getFAQThemeQuestionAnswer,
    createFAQThemeQuestionAnswer,
    uploadAnswerImage,
    updateFAQThemeQuestionAnswer,
    deleteAnswer,
    createRule,
    getRule,
    addRule,
    getAmbassadors,
    getAmbassador,
    createAmbassador,
    updateAmbassador,
    deleteAmbassador,
    createBanner,
    getBanners,
    getBanner,
    updateBanner,
    deleteBanner,
  };
}

export default {
  withMarketingProgram,
};
