<template>
  <v-navigation-drawer app
    v-model="drawer"
    :mini-variant.sync="mini"
    permanent
    width="268"
  >
  <template v-slot:prepend>
    <MarketingProgramSelector/>
    <v-list-item class="px-2">
      <v-list-item-avatar color="#ccc">
        <v-icon >
        mdi-account
        </v-icon>   
      </v-list-item-avatar>
      <v-list-item-content>
          <v-list-item-title >{{ user }}</v-list-item-title>
          <v-list-item-subtitle v-text="roles" />
        </v-list-item-content>
      <!-- <v-btn
        icon
        @click.stop="mini = !mini"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn> -->
    </v-list-item>
  </template>
  <v-divider></v-divider>

    <v-list class="px-0">
      <v-list-item-group
          color="#FF8233"
        >
      <v-list-item
        v-for="item in items"
        :key="item.title"
        link
        @click="goTo(item.path)"
        :class="{ active: $route.path === item.path }"
        class="hoverable-item"
        :exact-active-class="'active'"
      >
        <v-list-item-icon>
          <v-icon size="24" class="icon__sidebar" v-text="item.icon"/>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title v-text="item.title" />
        </v-list-item-content>
      </v-list-item>
      </v-list-item-group>
      <v-divider></v-divider>
      <v-list-group
        v-for="(item, i) in itemsList"
        :key="i"
        :value="false"
        no-action
        :prepend-icon="item.icon"
        color="#FF8233"
        :class="{ active: isActive(item) }"
        >
        
          <template v-slot:activator>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </template>
          <v-list-item
            v-for="item in item.children.filter(x => itemsListClildrenFilter(item.alias, x))"
            :key="item.title"
            link
            @click="goTo(item.path)"
            color="#FF8233"
            :class="{ active: $route.path === item.path }"
            :exact-active-class="'active'"
            hover
          >
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item>
        </v-list-group>
    </v-list>
    <template v-slot:append>
      <v-divider></v-divider>
        <!-- <v-list-item
        link
        >
          <v-list-item-content>
            <v-list-item-title>
              <span>{{ user }}</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        <v-list-item
        link
        @click="logout()"
        >
          <v-list-item-icon>
            <v-icon>icon-logout</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              <span>Выйти</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
    </template>
  </v-navigation-drawer>
</template>

<script>
import nav from './_nav';
import MarketingProgramSelector from './MarketingProgramSelector.vue';
import { useKeycloakStore } from '@/stores/keycloakStore';

export default {
  name: 'AppSidebar',
  components: {MarketingProgramSelector},

  setup() {
    const keycloakStore = useKeycloakStore()
    return { keycloakStore }
  },

  data () {
    return {
      drawer: true,
      items: nav.items.filter(this.itemsFilter),
      itemsList: nav.itemsList.filter(this.itemsListFilter),
      mini: false,
      selectedItem: 0,
      profile: undefined
    }
  },

  computed: {
    kl() {
      return this.keycloakStore;
    },
    itemsList2() {
      return this.itemsList;
    },
    user() {
      return this.kl.userInfo_ ? this.kl.userInfo_.name ||  this.kl.userInfo_.preferred_username : 'Пользователь'
    },
    roles() {
      let roles = [];
      if (this.kl.userInfo_.realm_access.roles) {
        roles = this.kl.userInfo_.realm_access.roles
        if (roles.includes('admin')) {
          return 'Администратор'
        }
        if (roles.includes('operisdir')) {
          return 'Оператор ИС (директор проектного офиса)'
        }
        if (roles.includes('orgmp')) {
          return 'Организатор МП'
        }
        if (roles.includes('capclub')) {
          return 'Капитна клуба'
        }
        if (roles.includes('oroperisofficegmp')) {
          return 'Оператор ИС (проектный офис)'
        }
        if (roles.includes('operissupport')) {
          return 'Оператор ИС (поддержка)'
        }
        if (roles.includes('supplieractives')) {
          return 'Поставщик активностей'
        }
        if (roles.includes('supplierpromotion')) {
          return 'Поставщик поощрений'
        }
      }
      return 'Пользователь'
    }
  },

  methods: {
    itemsFilter(x) {
      if (this.keycloakStore.isSupplPromo) {
        return false;
      }

      if (this.keycloakStore.isCapClub) {
        return x.alias !== 'participants'
          && x.alias !== 'logging';
      }

      return true;
    },

    itemsListFilter(x) {
      if (this.keycloakStore.isOrgMp) {
        return x.alias !== 'analitics';
      }

      if (this.keycloakStore.isSupplPromo) {
        return x.alias !== 'settings';
      }

      if (this.keycloakStore.isCapClub) {
        return x.alias !== 'catalogs';
      }

      return true;
    },

    itemsListClildrenFilter(parentAlias, x) {
      if (this.keycloakStore.isSupplPromo) {
        return (parentAlias === 'analitics' && (x.alias === 'analitics-sell' || x.alias === 'analitics-promotions')) || 
          (parentAlias === 'catalogs' && (x.alias === 'promotions' || x.alias === 'promocodes' || x.alias === 'partners'));
      }

      if (this.keycloakStore.isCapClub) {
        return (parentAlias === 'settings' && 
          (
            x.alias === 'goal' ||
            x.alias === 'banners' || 
            x.alias === 'calendar' || 
            x.alias === 'onboarding' || 
            x.alias === 'ambassadors' || 
            x.alias === 'communities'
          ))
          || parentAlias === 'analitics';
      }

      return true;
    },

    goTo(path) {
      if (this.$route.path !== path)
        this.$router.push(path);
    },
    logout() {
    if (this.kl.keycloak) this.kl.keycloak.logout({ redirectUri: window.location.origin });
    },
    isActive(item) {
    return item.children.some(subItem => subItem.path === this.$route.path);
  },
  }
}
</script>
<style lang="scss">
.v-list-group {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.v-list-item{
  margin-bottom: 0 !important;
}
.v-list-item.active {
  background-color: #FFF8F0;
  color: #FF8233 !important;
}
.v-list-group.active > .v-list-item > .v-list-item__title, .v-list-group.active > .v-list-item i {
  color: #FF8233 !important;
}
.v-list-group.active > .v-list-item {
  background-color: #FFF8F0;
}
.v-list-item.active i {
  color: #FF8233 !important;
}
.v-list-item__icon{
  margin: 12px 0;
}
.v-list-item--dense .v-list-item__title, .v-list-item--dense .v-list-item__subtitle, .v-list--dense .v-list-item .v-list-item__title, .v-list--dense .v-list-item .v-list-item__subtitle{
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;  
}
.icon__sidebar {
  font-size: 24px !important;
  width: 24px !important;
  height: 24px !important;
  text-align: center;
  max-width: 100%;
}
.v-navigation-drawer {
  /* полоса прокрутки (скроллбар) */
  ::-webkit-scrollbar {
      width: 6px; /* ширина для вертикального скролла */
      height: 8px; /* высота для горизонтального скролла */
      background-color: #fff;
  }

  /* ползунок скроллбара */
  ::-webkit-scrollbar-thumb {
      background-color: #FFF8F0;
      border-radius: 9em;
  }

  ::-webkit-scrollbar-thumb:hover {
  }
}

</style>