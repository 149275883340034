var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mb-6"},[_c('h3',{staticClass:"cmn__h1"},[_vm._v("Аналитика за период")]),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Начало отчетного периода","rules":_vm.validation.date,"hide-details":"auto"},model:{value:(_vm.analiticsParams.startDate),callback:function ($$v) {_vm.$set(_vm.analiticsParams, "startDate", $$v)},expression:"analiticsParams.startDate"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Конец отчетного периода","rules":_vm.validation.date,"hide-details":"auto"},model:{value:(_vm.analiticsParams.endDate),callback:function ($$v) {_vm.$set(_vm.analiticsParams, "endDate", $$v)},expression:"analiticsParams.endDate"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"ml-3",on:{"click":_vm.analiticsShow}},[_vm._v("Показать")]),_c('v-btn',{staticClass:"ml-3",on:{"click":_vm.analiticsCsv}},[_vm._v("Скачать в CSV")])],1)],1),_c('v-row',[_c('v-col',[(_vm.analiticsTableShow)?_c('DataTable',{key:_vm.analiticsCommonFiltersHash,attrs:{"headers":_vm.analiticsCommonHeaders,"items":_vm.analiticsCommonItems,"loading":_vm.analiticsCommonUpdating,"hide-default-footer":true},on:{"update:table-options":_vm.analiticsCommonTableUpdated},scopedSlots:_vm._u([{key:"item.count",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.count)+" "),(item.countAdded)?_c('span',{staticClass:"count-added"},[_vm._v("("+_vm._s(item.countAdded > 0 ? ("+" + (item.countAdded)) : item.countAdded)+")")]):_vm._e()]}}],null,true)}):_vm._e()],1),_c('v-col',[(_vm.analiticsTableShow)?_c('DataTable',{key:_vm.analiticsFiltersHash,attrs:{"headers":_vm.analiticsHeaders,"items":_vm.analiticsItems,"server-items-length":_vm.analiticsTotal,"loading":_vm.analiticsUpdating},on:{"update:table-options":_vm.analiticsTableUpdated},scopedSlots:_vm._u([{key:"item.count",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.count)+" "),_c('span',{staticClass:"count-added"},[_vm._v("("+_vm._s(item.countAdded > 0 ? ("+" + (item.countAdded)) : item.countAdded)+")")])]}}],null,true)}):_vm._e()],1)],1)],1),_c('div',{staticClass:"mb-6"},[_c('h3',{staticClass:"cmn__h1"},[_vm._v("Новые участники")]),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Начало отчетного периода","rules":_vm.validation.date,"hide-details":"auto"},model:{value:(_vm.newParticipantsParams.startDate),callback:function ($$v) {_vm.$set(_vm.newParticipantsParams, "startDate", $$v)},expression:"newParticipantsParams.startDate"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Конец отчетного периода","rules":_vm.validation.date,"hide-details":"auto"},model:{value:(_vm.newParticipantsParams.endDate),callback:function ($$v) {_vm.$set(_vm.newParticipantsParams, "endDate", $$v)},expression:"newParticipantsParams.endDate"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"ml-3",on:{"click":_vm.newParticipantsShow}},[_vm._v("Показать")]),_c('v-btn',{staticClass:"ml-3",on:{"click":_vm.newParticipantsCsv}},[_vm._v("Скачать в CSV")])],1)],1),_c('v-row',[_c('v-col',[(_vm.newParticipantsTableShow)?_c('DataTable',{key:_vm.newParticipantsFiltersHash,attrs:{"headers":_vm.newParticipantsHeaders,"items":_vm.newParticipantsItems,"server-items-length":_vm.newParticipantsTotal,"loading":_vm.newParticipantsUpdating},on:{"update:table-options":_vm.newParticipantsTableUpdated},scopedSlots:_vm._u([{key:"item.registration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTimeRus")(item.registration))+" ")]}},{key:"item.birthday",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateRus")(item.birthday))+" ")]}},{key:"item.gender",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.gender === 'Male' ? 'муж.' : item.gender === 'Female' ? 'жен.' : '')+" ")]}},{key:"item.team",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.team ? item.team : '')+" ")]}}],null,true)}):_vm._e()],1)],1)],1),_c('div',{staticClass:"mb-6"},[_c('h3',{staticClass:"cmn__h1"},[_vm._v("Активное ядро аудитории")]),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Начало отчетного периода","rules":_vm.validation.date,"hide-details":"auto"},model:{value:(_vm.activeCoreParams.startDate),callback:function ($$v) {_vm.$set(_vm.activeCoreParams, "startDate", $$v)},expression:"activeCoreParams.startDate"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Конец отчетного периода","rules":_vm.validation.date,"hide-details":"auto"},model:{value:(_vm.activeCoreParams.endDate),callback:function ($$v) {_vm.$set(_vm.activeCoreParams, "endDate", $$v)},expression:"activeCoreParams.endDate"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"ml-3",on:{"click":_vm.activeCoreShow}},[_vm._v("Показать")]),_c('v-btn',{staticClass:"ml-3",on:{"click":_vm.activeCoreCsv}},[_vm._v("Скачать в CSV")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[(_vm.activeCoreTableShow)?_c('DataTable',{key:_vm.activeCoreFiltersHash,attrs:{"headers":_vm.activeCoreHeaders,"items":_vm.activeCoreItems,"loading":_vm.activeCoreUpdating,"hide-default-footer":true},on:{"update:table-options":_vm.activeCoreTableUpdated},scopedSlots:_vm._u([{key:"item.count",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.count)+" "),(item.countAdded)?_c('span',{staticClass:"count-added"},[_vm._v("("+_vm._s(item.countAdded > 0 ? ("+" + (item.countAdded)) : item.countAdded)+")")]):_vm._e()]}}],null,true)}):_vm._e()],1)],1)],1),_c('div',{staticClass:"mb-6"},[_c('h3',{staticClass:"cmn__h1"},[_vm._v("Аналитика по тренировкам")]),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Начало отчетного периода","rules":_vm.validation.date,"hide-details":"auto"},model:{value:(_vm.ativityAnaliticsParams.startDate),callback:function ($$v) {_vm.$set(_vm.ativityAnaliticsParams, "startDate", $$v)},expression:"ativityAnaliticsParams.startDate"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Конец отчетного периода","rules":_vm.validation.date,"hide-details":"auto"},model:{value:(_vm.ativityAnaliticsParams.endDate),callback:function ($$v) {_vm.$set(_vm.ativityAnaliticsParams, "endDate", $$v)},expression:"ativityAnaliticsParams.endDate"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"ml-3",on:{"click":_vm.ativityAnaliticsShow}},[_vm._v("Показать")]),_c('v-btn',{staticClass:"ml-3",on:{"click":_vm.ativityAnaliticsCsv}},[_vm._v("Скачать в CSV")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[(_vm.ativityAnaliticsTableShow)?_c('DataTable',{key:_vm.ativityAnaliticsFiltersHash,attrs:{"headers":_vm.ativityAnaliticsExerciseHeaders,"items":_vm.ativityAnaliticsExerciseItems,"loading":_vm.ativityAnaliticsUpdating,"hide-default-footer":true},on:{"update:table-options":_vm.ativityAnaliticsTableUpdated},scopedSlots:_vm._u([{key:"item.count",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.count)+" "),(item.countAdded)?_c('span',{staticClass:"count-added"},[_vm._v("("+_vm._s(item.countAdded > 0 ? ("+" + (item.countAdded)) : item.countAdded)+")")]):_vm._e()]}}],null,true)}):_vm._e()],1),_c('v-col',{attrs:{"cols":"6"}},[(_vm.ativityAnaliticsTableShow)?_c('DataTable',{attrs:{"headers":_vm.ativityAnaliticsActivityHeaders,"items":_vm.ativityAnaliticsActivityItems,"loading":_vm.ativityAnaliticsUpdating,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.count",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.count)+" "),(item.countAdded)?_c('span',{staticClass:"count-added"},[_vm._v("("+_vm._s(item.countAdded > 0 ? ("+" + (item.countAdded)) : item.countAdded)+")")]):_vm._e()]}}],null,true)}):_vm._e()],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }