import {initializeApi} from "@/api/base";

function withMarketingProgram(marketingProgramId) {
  const api = initializeApi("/api/operationshistory", {'X-MarketingProgram-Id': marketingProgramId});

  async function getOperations(participantId, pagination, sorting, filters) {
    const response = await api
      .get(`/api/v1/operations`, {
        params: {
          participantId,
          ...pagination,
          ...sorting,
          ...filters,
        }
      })
      .then(response => {
        return {status: response.status, data: response.data};
      });

    return response;
  }

  return {
    getOperations,
  };
}

export default {
  withMarketingProgram,
};
