<template>

  <div v-if="exerciseTemplate">

    <v-form ref="form" lazy-validation>
      <v-row>
        <v-col class="exercies__h1">
          {{exerciseTemplate.title ? exerciseTemplate.title : 'Новый шаблон задания'}}
        </v-col>
        <v-col class="d-flex">
          <div class="ml-auto">
            <v-btn v-if="!isEdit" @click="editExerciseTemplate" class="mr-2">Изменить</v-btn>
            <v-btn v-else @click="saveExerciseTemplate" class="mr-2">Сохранить</v-btn>
            <v-btn v-if="exerciseTemplateId" @click="openDeleteConfirm"><v-icon>mdi-delete</v-icon></v-btn>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col>     
          <v-text-field
            label="Название задания"
            v-model="exerciseTemplate.title"
            :rules="validation.title"
            hide-details="auto"
            class="mb-5"
            :disabled="!isEdit"
          ></v-text-field>

          <v-text-field
            label="Краткое описание"
            v-model="exerciseTemplate.shortDescription"
            :rules="validation.shortDescription"
            hide-details="auto"
            class="mb-5"
            :disabled="!isEdit"
          ></v-text-field>
          <v-label class="v-la">Описание</v-label>
          <vue-editor
            v-model="exerciseTemplate.description"
            :editorToolbar="customToolbar"
            :class="{disabled:!isEdit}"
          />
          <!-- <v-textarea
            label="Полное описание"
            v-model="exerciseTemplate.description"
            :rules="validation.description"
            hide-details="auto"
            class="mb-5"
            :disabled="!isEdit"
          ></v-textarea> -->
          <v-row>
            <v-col>
              <v-text-field
                label="Приоритет"
                v-model="exerciseTemplate.priority"
                :rules="validation.priority"
                hide-details="auto"
                :disabled="!isEdit"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="Сложность"
                v-model="exerciseTemplate.complexity"
                :rules="validation.complexity"
                hide-details="auto"
                :disabled="!isEdit"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-text-field
                label="Часов на выполнение(необязательно)"
                v-model="exerciseTemplate.timeLimit"
                :rules="validation.timeLimit"
                hide-details="auto"
                :disabled="!isEdit"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-select
                v-model="exerciseTemplate.type"
                :rules="validation.type"
                label="Тип задания"
                :items="exerciseTypes"
                item-text="name"
                item-value="type"
                :disabled="!!exerciseTemplateId || !isEdit"
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <ImageSelector
                :preview-url="exerciseTemplate.images ? exerciseTemplate.images.mobile : null"
                :read-only="!isEdit"
                :save-image-func="changeMobileImage"
                accept=".jpg"
                title="Изображение карточки (608x280)"
                :rules="exerciseTemplate.images && exerciseTemplate.images.mobile ? undefined : validation.image"
                :subTitle="mobileImageSubTitle"
                @show-preview="showImagePreview('mobile')"
              ></ImageSelector>
            </v-col>
            <v-col>
              <ImageSelector
                :preview-url="exerciseTemplate.images ? exerciseTemplate.images.desktop : null"
                :read-only="!isEdit"
                :save-image-func="changeDesktopImage"
                accept=".jpg"
                title="Подробное изображение (1181x545)"
                :rules="exerciseTemplate.images && exerciseTemplate.images.desktop ? undefined : validation.image"
                :subTitle="desktopImageSubTitle"
                @show-preview="showImagePreview('desktop')"
              ></ImageSelector>
            </v-col>
          </v-row>

          <ExerciseTemplateTasksView v-if="this.exerciseTemplateId" :is-edit="isEdit" :exercise-type="exerciseTemplate.type" />

        </v-col>
        <v-col>
          &nbsp;
        </v-col>
      </v-row>
    </v-form>
    
    <v-dialog v-model="dialog.opened" width="720">
      <v-card>
        <v-card-title class="headline">{{ dialog.title }}</v-card-title>
        <v-card-text v-html="dialog.text">
        </v-card-text>
        <v-card-actions>
          <v-btn @click="closeDialog">Закрыть</v-btn>
          <v-btn v-if="dialog.actionDialog" @click="dialog.actionDialog" color="primary">{{ dialog.actionName }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { uuid } from "vue-uuid";
import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
import scountApiInitializer from '@/api/scountApi';
import ExerciseTemplateTasksView from './ExerciseTemplateTasksView.vue';
import { VueEditor } from "vue2-editor";

export default {
  name: 'ExerciseTemplateView',
  components: {ExerciseTemplateTasksView, VueEditor
  },
  setup() {
    const marketingProgramStore = useMarketingProgramStore()
    return { marketingProgramStore }
  },

  data: () => ({
    customToolbar: [
      ["bold",{ list: "ordered"}, {list: "bullet" }, "link"],
    ],
    exerciseTemplate: null,
    exerciseTypes: [
      {type: 'Disposable', name: 'Невозобновляемое', hint: 'После выполнения можно взять руками'},
      {type: 'Reusable', name: 'Возобновляемое', hint: 'После выполнения будет назначено автоматически'},
      {type: 'Regular', name: 'Регулярное', hint: 'Выполняется регулярно'},
    ],
    isEdit: false,
    loading: false,
    dialog: {
      opened: false,
      title: undefined,
      text: undefined,
      actionDialog: undefined,
      actionName: undefined
    },
    validation: {
      title: [
        value => !!value || 'Обязательное поле.',
        value => (value && value.length <= 256) || 'Не более 256 символов.',
      ],
      shortDescription: [
        value => !!value || 'Обязательное поле.',
      ],
      description: [
        value => !!value || 'Обязательное поле.',
      ],
      priority: [
        value => !!value || 'Обязательное поле.',
        value => /^\d+(\.\d{1,2})?$/.test(value) || 'Значение должно быть числом не меньше 0',
      ],
      complexity: [
        value => !!value || 'Обязательное поле.',
        value => /^\d+(\.\d{1,2})?$/.test(value) || 'Значение должно быть числом не меньше 0',
      ],
      image: [
        value => !!value || 'Обязательное поле.'
      ],
      type: [
        value => !!value || 'Обязательное поле.'
      ],
      timeLimit: [
        value => value === null || value === undefined || value === '' || (/^\d+(\.\d{1,2})?$/.test(value) && value >= 1) || 'Значение должно быть числом не меньше 1',
      ],
    },
    desktopImageSubTitle: null,
    mobileImageSubTitle: null,
  }),

  computed: {
    exerciseTemplateId() {
      return this.$route.params.exerciseTemplateId;
    },

    mp() {
      if (this.marketingProgramStore.isInitialized === false)
        return null;

      return this.marketingProgramStore;
    },

    scountApi() {
      if (!this.mp)
        return null;

      return scountApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
    },
  },

  methods: {
    async getExerciseTemplate() {
      const response = await this.scountApi.getExerciseTemplate(this.exerciseTemplateId);
      this.exerciseTemplate = response.data;

      this.isEdit = false;
    },

    editExerciseTemplate() {
      this.isEdit = true;
    },

    async saveExerciseTemplate() {
      this.saving = true;
      
      const valid = await this.$refs.form.validate();

      if (!valid)
        return;

      if (this.exerciseTemplateId) {
        this.changeExerciseTemplate();
        this.getExerciseTemplate();
      } else {
        this.createExerciseTemplate();
      }

      this.isEdit = false;
    },

    async createExerciseTemplate() {
      var exerciseTemplateId = uuid.v4();
      const request = {
        title: this.exerciseTemplate.title,
        shortDescription: this.exerciseTemplate.shortDescription,
        description: this.exerciseTemplate.description,
        type: this.exerciseTemplate.type,
        priority: this.exerciseTemplate.priority,
        complexity: this.exerciseTemplate.complexity,
        isAutomaticAssign: true,
        timeLimit: this.exerciseTemplate.timeLimit || null

      };

      const response = await this.scountApi.createExerciseTemplate(exerciseTemplateId, request);

      if (response.success) {
        if (this.desktopImageFile) {
          const destResp = await this.scountApi.uploadExerciseTemplateDesktopImage(exerciseTemplateId, this.desktopImageFile);
          if (destResp.success) {
            if (this.mobileImageFile) {
              const mobileResp = await this.scountApi.uploadExerciseTemplateMobileImage(exerciseTemplateId, this.mobileImageFile);
              if (!mobileResp.success) {
                this.showError('Ошибка при загрузке изображения mobile', this.getUploadAfterSaveText(mobileResp));
              }
            }
          } else {
            this.showError('Ошибка при загрузке изображения desktop', this.getUploadAfterSaveText(destResp));
          }
        }
        this.goToExerciseTemplate(exerciseTemplateId);
      } else {
        const e = response.error;
        const errorText = !e
          ? 'Произошла ошибка'
          : `Код статуса ответа: ${e.status}`
            + `<br>Код: ${e.code}`
            + `<br>Ошибка: ${e.title}`
            + `<br>Подробности: ${e.detail}`;

        this.openDialog('Ошибка при создании шаблона', errorText);
      }
    },

    async changeExerciseTemplate() {
      const request = {
        title: this.exerciseTemplate.title,
        shortDescription: this.exerciseTemplate.shortDescription,
        description: this.exerciseTemplate.description,
        type: this.exerciseTemplate.type,
        priority: this.exerciseTemplate.priority,
        complexity: this.exerciseTemplate.complexity,
        isAutomaticAssign: true,
        timeLimit: this.exerciseTemplate.timeLimit || null
      };

      const response = await this.scountApi.changeExerciseTemplate(this.exerciseTemplateId, request);

      if (response.success) {
        if (this.desktopImageFile) {
          const destResp = await this.scountApi.uploadExerciseTemplateDesktopImage(this.exerciseTemplateId, this.desktopImageFile);
          if (destResp.success) {
            if (this.mobileImageFile) {
              const mobileResp = await this.scountApi.uploadExerciseTemplateMobileImage(this.exerciseTemplateId, this.mobileImageFile);
              if (!mobileResp.success) {
                this.showError('Ошибка при загрузке изображения mobile', this.getUploadAfterSaveText(mobileResp));
              }
            }
          } else {
            this.showError('Ошибка при загрузке изображения desktop', this.getUploadAfterSaveText(destResp));
          }
        }
      } else {
        const e = response.error;
        const errorText = !e
          ? 'Произошла ошибка'
          : `Код статуса ответа: ${e.status}`
            + `<br>Код: ${e.code}`
            + `<br>Ошибка: ${e.title}`
            + `<br>Подробности: ${e.detail}`;

        this.openDialog('Ошибка при создании шаблона', errorText);
      }
    },

    getUploadAfterSaveText(resp) {
        const e = resp.error;
        if (!e) return 'Произошла ошибка';
        if (e.status === 413) return 'Фото не было загружено. Превышен максимальный размер изображения';
        if (e.status === 400 && e.responseData && e.responseData.errors && e.responseData.errors.File) {
          const errFile = e.responseData.errors.File;
          const max = errFile.find(x => x.startsWith('Maximum '));
          if (max)
            return 'Фото не было загружено. Превышен максимальный размер изображения.';
        }

        return e.detail;
    },

    showError(title, text) {
      this.dialog.title = title;
      this.dialog.text = text;
      this.dialog.opened = true;
    },

    hideError() {
      this.dialog.title = undefined;
      this.dialog.text = undefined;
      this.dialog.opened = false;
    },

    async goToExerciseTemplates() {
      this.$router.push('/exercise-templates');
    },

    async goToExerciseTemplate(exerciseTemplateId) {
      this.$router.push(`/exercise-template/${exerciseTemplateId}`);
    },

    async deleteItem() {
      this.loading = true;

      try {
        const response = await this.scountApi.deleteExerciseTemplate(this.exerciseTemplateId);
        if (response.status === 204) {
          this.goToExerciseTemplates();
        } else {
          const e = response.error;
          const errorText = !e
            ? 'Произошла ошибка'
            : `Код статуса ответа: ${e.status}`
              + `<br>Код: ${e.code}`
              + `<br>Ошибка: ${e.title}`
              + `<br>Подробности: ${e.detail}`;

          this.openDialog('Ошибка при удалении шаблона задания', errorText); 
          this.loading = false;
        }
      } catch (ex) {
        this.openDialog('Ошибка при удалении шаблона задания', 'Произошла ощибка.');
        this.loading = false;
      }
    },

    changeDesktopImage(file) {
      this.desktopImageFile = file;
      this.desktopImageSubTitle = `${file.name} (${file.size / 1024}KB)`;
    },

    changeMobileImage(file) {
      this.mobileImageFile = file;
      this.mobileImageSubTitle = `${file.name} (${file.size / 1024}KB)`;
    },

    showImagePreview(type) {
      this.showPreview = true;
      this.showPreviewType = type;
    },

    async changeExercise(model) {
      await this.scountApi.changeExerciseTemplate(this.exerciseTemplateId, model);

      if (model.desktopImageFile) {
        await this.scountApi.uploadDesktopImage(this.exerciseTemplateId, model.desktopImageFile);
      }

      if (model.mobileImageFile) {
        await this.scountApi.uploadMobileImage(this.exerciseTemplateId, model.mobileImageFile);
      }

      await this.getExerciseTemplate();
    },

    getComplexityClass(complexity) {
      if (complexity < 1.4)
        return '__low';
      if (complexity < 1.9)
        return '__medium';
      return '__high';
    },

    openDeleteConfirm() {
      this.openDialog('Подтверждение удаления',
        'Вы уверены, что хотите удалить?',
        () => { 
          this.deleteItem();
          this.closeDialog();
        },
        'Удалить')
    },

    openDialog(title, text, actionDialog, actionName) {
      this.dialog.title = title;
      this.dialog.text = text;
      this.dialog.actionDialog = actionDialog;
      this.dialog.actionName = actionName;
      this.dialog.opened = true;
    },

    closeDialog() {
      this.dialog.title = undefined;
      this.dialog.text = undefined;
      this.dialog.actionDialog = undefined;
      this.dialog.actionName = undefined;
      this.dialog.opened = false;
    },
  },

  async mounted() {
    this.loading = true;
    try {

      if (this.exerciseTemplateId)
        await this.getExerciseTemplate();
      else {
        this.exerciseTemplate = { };
        this.isEdit = true;
      }

    } finally {
      this.loading = false;
    }
  },
}
</script>

<style lang="scss">
.complexity {
  display: inline-block;
  margin-left: 8px;
  padding: 0 4px;
  border-radius: 4px;
  &.__low {
    background-color: #93de64;
  }
  &.__medium {
    background-color: #ffd666;
  }
  &.__high {
    background-color: #fa4646;
    color: white;
  }
}
</style>

<style lang="scss" scoped>
  @import '../../assets/global';
</style>
