<template>
  <EditorDialog
    title="Партнер"
    :max-width="800"
    :save-func="save"
    @closed="localClose"
  >
  <v-form ref="form" lazy-validation>
    <v-row>
      <v-col>     
        <v-text-field
          label="Название партнера"
          v-model="model.name"
          :rules="validation.name"
          hide-details="auto"
          class="mb-5"
        ></v-text-field>
      </v-col>  
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          label="Ссылка на страницу о партнёре"
          v-model="model.url"
          :rules="validation.url"
          hide-details="auto"
          class="mb-5"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="model.partnerId">
      <v-col>
        <ImageSelector
          :preview-url="model.logos ? model.logos.mobile : null"
          :read-only="false"
          :save-image-func="changeMobileImage"
          accept="image/*"
          title="Изображение карточки (466x466)"
          @show-preview="showImagePreview('mobile')"
        ></ImageSelector>
      </v-col>
      <v-col>
        <ImageSelector
          :preview-url="model.logos ? model.logos.desktop : null"
          :read-only="false"
          :save-image-func="changeDesktopImage"
          accept="image/*"
          title="Подробное изображение (192x192)"
          @show-preview="showImagePreview('desktop')"
        ></ImageSelector>
      </v-col>
    </v-row>
  </v-form>
  <v-dialog v-model="dialog.opened" width="720">
    <v-card>
      <v-card-title class="headline">{{ dialog.title }}</v-card-title>
      <v-card-text v-html="dialog.text">
      </v-card-text>
      <v-card-actions>
        <v-btn @click="closeDialog">Закрыть</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </EditorDialog>
</template>

<script>
import { uuid } from "vue-uuid";
import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
import { useKeycloakStore } from '@/stores/keycloakStore';
import editorDialogMixin from '@/global-components/dialog/mixins';
import marketApiInitializer from '@/api/marketApi';

export default {
  name: 'PartnerEditorDialog',
  mixins: [editorDialogMixin],

  props: {
    object: {
      type: Object,
      default: () => {
        return {
          name: undefined,
          url: undefined
        }
      }
    },
    saveFunc: Function,
  },

  setup() {
    const marketingProgramStore = useMarketingProgramStore()
    const keycloakStore = useKeycloakStore()
    return { marketingProgramStore, keycloakStore }
  },

  computed: {
    mp() {
      if (this.marketingProgramStore.isInitialized === false)
        return null;

      return this.marketingProgramStore;
    },

    marketApi() {
      if (!this.mp)
        return null;

      return marketApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
    },

    kl() {
      return this.keycloakStore;
    },
  },

  data: () => ({
    dialog: {
      opened: false,
      title: undefined,
      text: undefined
    },
    saving: false,
    validation: {
      name: [
        value => !!value || 'Обязательное поле.',
        value => (value && value.length <= 256) || 'Не более 256 символов.',
      ],
      url: [
        value => !!value || 'Обязательное поле.',
        value => (value && value.length <= 256) || 'Не более 256 символов.',
      ]
    },
  }),

  methods: {
    async save() {
      this.saving = true;
      
      const valid = await this.$refs.form.validate();

      if (!valid)
        return;

      this.savePartner(this.model);
    },

    localClose() {
      const saving = this.saving;
      this.saving = false;

      if (saving)
        return;

      this.close();
    },

    async savePartner(partner) {
      if (partner.partnerId) {
        await this.updatePartner(partner);
      } else {
        await this.createPartner(partner);
      }
    },

    async createPartner(partner) {
      var partnerId = uuid.v4();
      const request = {
        name: partner.name,
        url: partner.url,
        creatorId: this.kl.userId
      };

      const response = await this.marketApi.createPartner(partnerId, request);
      if (response.success) {
        this.saveFunc({ success: true });
        this.close();
      } else {
        this.saveFunc({ error: response.error });
      }
    },

    async updatePartner(partner) {
      const request = {
        name: partner.name,
        url: partner.url,
      };

      const response = await this.marketApi.updatePartner(partner.partnerId, request);
      if (response.success) {
        if (partner.desktopImageFile) {
          const destResp = await this.marketApi.uploadPartnerLogoDesktop(partner.partnerId, partner.desktopImageFile);
          if (destResp.success) {
            if (partner.mobileImageFile) {
              const mobileResp = await this.marketApi.uploadPartnerLogoMobile(partner.partnerId, partner.mobileImageFile);
              if (mobileResp.success) {                
                this.saveFunc({ success: true });
                this.close();
              } else {
                this.showError('Ошибка при загрузке изображения mobile', this.getUploadAfterSaveText(mobileResp));
              }
            }
          } else {
            this.showError('Ошибка при загрузке изображения desktop', this.getUploadAfterSaveText(destResp));
          }
        }
      } else {
        this.saveFunc({ error: response.error });
      }
    },

    changeDesktopImage(file) {
      this.model.desktopImageFile = file;
    },

    changeMobileImage(file) {
      this.model.mobileImageFile = file;
    },

    getUploadAfterSaveText(resp) {
        const e = resp.error;
        if (!e) return 'Произошла ошибка';
        if (e.status === 413) return 'Фото не было загружено. Превышен максимальный размер изображения';
        if (e.status === 400 && e.responseData && e.responseData.errors && e.responseData.errors.File) {
          const errFile = e.responseData.errors.File;
          const max = errFile.find(x => x.startsWith('Maximum '));
          if (max)
            return 'Фото не было загружено. Превышен максимальный размер изображения.';
        }

        return e.detail;
    },

    showError(title, text) {
      this.dialog.title = title;
      this.dialog.text = text;
      this.dialog.opened = true;
    },

    hideError() {
      this.dialog.title = undefined;
      this.dialog.text = undefined;
      this.dialog.opened = false;
    },

    openDialog(title, text, actionDialog, actionName) {
      this.dialog.title = title;
      this.dialog.text = text;
      this.dialog.actionDialog = actionDialog;
      this.dialog.actionName = actionName;
      this.dialog.opened = true;
    },

    closeDialog() {
      this.dialog.title = undefined;
      this.dialog.text = undefined;
      this.dialog.actionDialog = undefined;
      this.dialog.actionName = undefined;
      this.dialog.opened = false;
    },
  }
}
</script>