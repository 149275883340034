<template>
  <div class="exercies__hr">
    <v-row>
      <v-col class="exercies__h2">
        Участники
      </v-col>
      <v-col class="d-flex">
        <div class="ml-auto">
          <v-btn @click="teamParticipantsPageShow = true" :disabled="!isEdit">Добавить участника</v-btn>
        </div>
      </v-col>
    </v-row>

    <DataTable
      :key="filtersHash"
      :headers="headers"
      :items="items"
      :server-items-length="total"
      sort-by="date"
      :sort-desc="true"
      :search-fields="searchFields"
      :loading="updating"
      @update:table-options="tableUpdated"
    >
      <template v-slot:[`item.title`]="{item}">
        {{ item.title }}
      </template>
      <template v-slot:[`item.actions`]="{item}">
        <v-icon @click="deleteItem(item.participantId)" :disabled="!isEdit">mdi-delete</v-icon>
      </template>
    </DataTable>

    <TeamParticipantsPageDialog
      v-if="teamParticipantsPageShow"
      :participant-id="teamId"
      :save-func="saveTeamParticipant"
      @closed="teamParticipantsPageShow = false"
    />
  </div>
</template>

<script>
import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
import TeamParticipantsPageDialog from './TeamParticipantsPageDialog.vue';
import scountApiInitializer from '@/api/scountApi';

export default {
  name: 'TeamParticipantsView',
  components: { TeamParticipantsPageDialog },

  props: {
    teamId: undefined,
    isEdit: Boolean,
  },

  setup() {
    const marketingProgramStore = useMarketingProgramStore()
    return { marketingProgramStore }
  },

  data: () => ({
    filtersHash: Date.now(),
    headers: [
      { value: 'emailAddress', text: 'Email', align: 'start', sortable: false },
      { value: 'lastName', text: 'LastName', align: 'start', sortable: false },
      { value: 'firstName', text: 'FirstName', align: 'start', sortable: false },
      { value: 'actions', text: 'Действия', align: 'center', width: 160, sortable: false },
    ],
    items: [],
    total: 0,
    searchFields: [
      { key: 'title', label: 'Название' },
    ],
    updating: false,
    teamParticipantsPageShow: false,
  }),

  computed: {
    exerciseTemplateId() {
      return this.$route.params.exerciseTemplateId;
    },

    mp() {
      if (this.marketingProgramStore.isInitialized === false)
        return null;

      return this.marketingProgramStore;
    },

    scountApi() {
      if (!this.mp)
        return null;

      return scountApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
    },
  },

  methods: {
    async tableUpdated(pagination, sorting, search) {
      await this.getTeamParticipants(pagination, sorting, search);
    },

    async getTeamParticipants(pagination, sorting, search) {
      if (!this.scountApi) return;

      this.updating = true;

      try {
        const response = await this.scountApi.getTeamParticipants(pagination, sorting, search, this.teamId);

        this.total = response.data.total;
        this.items = response.data.values;
      } finally {
        this.updating = false;
      }
    },

    async createTeamParticipant(model) {
      const request = {
        nominationsIds: [
          this.nominationId
        ],
        exercisesIds: model
      };
      await this.scountApi.assignExercisesToNominations(request);
      this.filtersHash = Date.now();
    },

    async deleteItem(participantId) {
      this.updating = true;

      try {

        const request = {
          answers: {
            TeamId: ""
          }
        };

        const responseQuestionnaire = await this.scountApi.editParticipantQuestionnaire(participantId, request);

        if (responseQuestionnaire.status === 204) {
          const responseDelete = await this.scountApi.deleteTeamParticipant(this.teamId, participantId);
          if (responseDelete.status === 204) { 
            setTimeout(() => {
              this.filtersHash = Date.now();
            }, 1000);
          } else {
            const e = responseDelete.error;
            const errorText = !e
              ? 'Произошла ошибка'
              : `Код статуса ответа: ${e.status}`
                + `<br>Код: ${e.code}`
                + `<br>Ошибка: ${e.title}`
                + `<br>Подробности: ${e.detail}`;

            this.openDialog('Ошибка при удалении номинации', errorText); 
            this.updating = false;
          }
        } else {
          const e = responseQuestionnaire.error;
          const errorText = !e
            ? 'Произошла ошибка'
            : `Код статуса ответа: ${e.status}`
              + `<br>Код: ${e.code}`
              + `<br>Ошибка: ${e.title}`
              + `<br>Подробности: ${e.detail}`;

          this.openDialog('Ошибка при удалении номинации', errorText); 
          this.updating = false;
        }
      } catch {
        this.updating = false;
      }
    },

    async saveTeamParticipant(model) {
      this.updating = true;

      try {
        for (let i = 0; i < model.length; i++) {
          const request = {
            answers: {
              TeamId: this.teamId
            }
          };

          const responseQuestionnaire = await this.scountApi.editParticipantQuestionnaire(model[i], request);

          if (responseQuestionnaire.success) {
            setTimeout(() => {
              this.filtersHash = Date.now();
            }, 1000);
          } else {
            const e = responseQuestionnaire.error;
            const errorText = !e
              ? 'Произошла ошибка'
              : `Код статуса ответа: ${e.status}`
                + `<br>Код: ${e.code}`
                + `<br>Ошибка: ${e.title}`
                + `<br>Подробности: ${e.detail}`;

            this.openDialog('Ошибка при добавлении задания', errorText); 
            this.updating = false;
          }
        }
      } catch {
        this.updating = false;
      }
    }
  },
}
</script>