function HttpError(status, code, title, detail, requestMethod, requestUrl, responseData, traceId) {
    this.name = "HttpError";

    this.status = status;
    this.code = code;
    this.title = title;
    this.detail = detail;
    this.requestMethod = requestMethod.toUpperCase();
    this.requestUrl = requestUrl;
    this.responseData = responseData;
    this.traceId = traceId;
}
HttpError.prototype = Object.create(Error.prototype);

function MainWithStackTrace(message, status) {
    this.name = "MainWithStackTrace";

    this.message = message;
    this.status = status;

    if (Error.captureStackTrace) {
        Error.captureStackTrace(this, MainWithStackTrace);
    } else {
        this.stack = (new Error()).stack;
    }
}
MainWithStackTrace.prototype = Object.create(Error.prototype);

export {
    HttpError,
    MainWithStackTrace,
};
