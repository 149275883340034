<template>
  <div>
    {{attributes.NominationTitle}}
    &nbsp;|&nbsp;
    <span style="color: #49ae4d">
      Зачислено очков: {{attributes.Points}}
    </span>
  </div>
</template>

<script>
export default {
  name: 'OperationInfoNominationParticipant',

  props: {
    attributes: {
      type: Object,
    },
  },
}
</script>
