<template>
  <div>
    {{getAchievementText(attributes.AchievementType)}}
  </div>
</template>

<script>
export default {
  name: 'OperationInfoAchievement',

  props: {
    attributes: {
      type: Object,
    },
  },

  methods: {
    getAchievementText(achievementType) {
      switch (achievementType) {
        case 'Welcome': return 'Первый подход';
        case 'TrackerConnected': return 'Измеритель';
        case 'FirstRunning': return 'Первая тренировка (бег)';
        case 'FirstWalking': return 'Первая тренировка (ходьба)';
        case 'FirstSwimming': return 'Первая тренировка (плавание)';
        case 'FirstSkiing': return 'Первая тренировка (лыжи)';
        case 'FirstCycling': return 'Первая тренировка (велосипед)';
        case 'First100Balance': return 'Накопил 100 баллов';
        case 'First1000Balance': return 'Накопил 1000 баллов';
        case 'FirstMarketplaceBuy': return 'Первая покупка';
        case 'Run25Kilometers': return '25 км (бег)';
        case 'Run50Kilometers': return '50 км (бег)';
        case 'Run100Kilometers': return '100 км (бег)';
        case 'Run300Kilometers': return '300 км (бег)';
        case 'Run500Kilometers': return '500 км (бег)';
        case 'Run42KilometersAtATime': return '42 за раз (бег)';
        case 'Run100KilometersAtATime': return '100 за раз (бег)';
        case 'Walked25Kilometers': return '25 км (ходьба)';
        case 'Walked50Kilometers': return '50 км (ходьба)';
        case 'Walked100Kilometers': return '100 км (ходьба)';
        case 'Walked300Kilometers': return '300 км (ходьба)';
        case 'Walked500Kilometers': return '500 км (ходьба)';
        case 'Walked42KilometersAtATime': return '42 за раз (ходьба)';
        case 'Walked100KilometersAtATime': return '100 за раз (ходьба)';
        case 'Swimming10Kilometers': return '10 км (плавание)';
        case 'Swimming20Kilometers': return '20 км (плавание)';
        case 'Swimming50Kilometers': return '50 км (плавание)';
        case 'Swimming5KilometersAtATime': return '5 за раз (плавание)';
        case 'Swimming10KilometersAtATime': return '10 за раз (плавание)';
        case 'Skiing50Kilometers': return '50 км (лыжи)';
        case 'Skiing100Kilometers': return '100 км (лыжи)';
        case 'Skiing300Kilometers': return '300 км (лыжи)';
        case 'Skiing50KilometersAtATime': return '50 за раз (лыжи)';
        case 'Cycled50Kilometers': return '50 км (велосипед)';
        case 'Cycled100Kilometers': return '100 км (велосипед)';
        case 'Cycled500Kilometers': return '500 км (велосипед)';
        case 'Cycled100KilometersAtATime': return '100 за раз (велосипед)';
      }
    },
  },
}
</script>
