export function getTableOptionsFromRouteQuery(query) {
  let tableOptions = {};

  const cleanedQuery = Object.keys(query)
      .filter(key => query[key] !== "")
      .reduce((obj, key) => {
        obj[key] = query[key];
        return obj;
      }, {});

  if (cleanedQuery.page !== undefined) {
    tableOptions.page = parseInt(cleanedQuery.page);
  }

  if (cleanedQuery.itemsPerPage !== undefined) {
    tableOptions.itemsPerPage = parseInt(cleanedQuery.itemsPerPage);
  }

  if (cleanedQuery.sortBy !== undefined) {
    tableOptions.sortBy = cleanedQuery.sortBy;
  }

  if (cleanedQuery.sortDesc !== undefined) {
    tableOptions.sortDesc = cleanedQuery.sortDesc == 'true';
  }

  if (cleanedQuery.search !== undefined) {
    tableOptions.search = cleanedQuery.search;
  }

  const filters = Object.keys(cleanedQuery)
      .filter(key => ['page', 'itemsPerPage', 'sortBy', 'sortDesc', 'search'].includes(key) === false)
      .reduce((obj, key) => {
        obj[key] = cleanedQuery[key];
        return obj;
      }, {});
  tableOptions.filters = filters;

  return tableOptions;
}

function fieldsEquals(value1, value2) {
  function comparer(first, second) {
      if (first < second)
          return -1;
      if (first > second)
          return 1;
      return 0;
  }

  const dict1 = Object.keys(value1).map(key => [key, value1[key]]).sort(comparer);
  const dict2 = Object.keys(value2).map(key => [key, value2[key]]).sort(comparer);

  return JSON.stringify(dict1) == JSON.stringify(dict2);
}

export function queriesEquals(left, right) {
  return fieldsEquals(left, right);
}

export function updateRouter($router, $route, query) {
  const newQuery = { ...$route.query, ...query };

  const needUpdate = !queriesEquals($route.query, newQuery);

  if (needUpdate) {
    $router.push({ query: newQuery});
  }

  return needUpdate;
}