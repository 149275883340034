<template>
  <div v-if="participant">
    <v-row>
      <v-col>
        <h1 class="px-4">Участник: {{participantInfo.fio}}</h1>
        <div style="float:left;" class="px-4">ID:</div>
        <div style="float:left;">{{participantId}}</div>
      </v-col>
      <v-col class="buttons">
        <div class="buttons-block">
          <button class="buttons__edit" v-if="!kl.isOrgMp" @click="participantProfileDialogShow = true"><v-icon>mdi-pencil</v-icon>Редактирование</button>
          <button class="buttons__disable" v-if="!kl.isOrgMp" @click="participantBlock"><v-icon :color="participant.state === 'Active' ? '#666' : '#b00'">mdi-diameter-variant</v-icon></button>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-list two-line>
          <v-list-item>
            <v-list-item-avatar v-if="avatarUrl">
              <v-img :src="avatarUrl"></v-img> 
            </v-list-item-avatar>
            <v-list-item-icon  v-else>
              <v-icon color="indigo">mdi-passport</v-icon>       
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Фамилия и имя</v-list-item-title>
              <v-list-item-subtitle>{{participantInfo.fio}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col>
        <v-list two-line>
          <v-list-item>
            <v-list-item-icon>
              <v-icon :color="participantInfo.gender === 'Male' ? 'indigo' : 'purple'">
                mdi-face-{{participantInfo.gender === 'Male' ? 'man' : 'woman'}}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Пол</v-list-item-title>
              <v-list-item-subtitle>{{participantInfo.gender === 'Male' ? 'Мужчина' : 'Женщина'}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col>
        <v-list two-line>
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="indigo">mdi-cake</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Дата рождения</v-list-item-title>
              <v-list-item-subtitle>
                {{participantInfo.birthday | formatDate('DD.MM.YYYY')}} | {{participantInfo.age}}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col>
        <v-list two-line>
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="indigo">mdi-at</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Email</v-list-item-title>
              <v-list-item-subtitle>{{profile.emailAddress}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col>
        <v-list two-line>
          <v-list-item v-if="participantInfo.teamName">
            <v-list-item-icon>
              <v-icon color="indigo">mdi-account-group</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Команда</v-list-item-title>
              <v-list-item-subtitle>{{participantInfo.teamName}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <h1 class="px-4">Достижения участника</h1>
      </v-col>
      <v-col class="buttons">
        <div class="buttons-block">
          <button class="buttons__edit" v-if="!kl.isOrgMp" @click="participantBalanceDialogShow = true"><v-icon>mdi-pencil</v-icon>Редактирование</button>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-list two-line>          
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="indigo">mdi-calendar</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Баллы</v-list-item-title>
              <v-list-item-subtitle>{{participant.balance.amount}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col>
        <v-list two-line>   
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="indigo">mdi-calendar</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Уровень, XP</v-list-item-title>
              <v-list-item-subtitle>{{participant.experience.level}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col>
        <v-list two-line>   
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="indigo">mdi-calendar</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Вклад в общую цель</v-list-item-title>
              <v-list-item-subtitle>{{participant.goalContributionTotalDistance}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col><v-list two-line>   
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="indigo">mdi-calendar</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Получено ачивок</v-list-item-title>
              <v-list-item-subtitle>{{participant.achievements.length}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>

    <ParticipantOperationsHistoryList :participant-id="participantId" :participant-state="participant.state" />

    <ParticipantProfileEditorDialog
      v-if="participantProfileDialogShow"
      :object="profile"
      :allMpTeams="allMpTeams"
      :save-func="saveParticipantProfile"
      @closed="participantProfileDialogShow = false"
    />

    <ParticipantBalanceEditorDialog
      v-if="participantBalanceDialogShow"
      :object="balance"
      :save-func="saveParticipantBalance"
      @closed="participantBalanceDialogShow = false"
    />
    
    <v-dialog v-model="dialog.opened" width="720">
      <v-card>
        <v-card-title class="headline">{{ dialog.title }}</v-card-title>
        <v-card-text v-html="dialog.text">
        </v-card-text>
        <v-card-actions>
          <v-btn @click="hideError">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-overlay :value="loading"></v-overlay>
  </div>
</template>

<script>
import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
import { useKeycloakStore } from '@/stores/keycloakStore';
import scountApiInitializer from '@/api/scountApi';
import ParticipantOperationsHistoryList from './ParticipantOperationsHistoryList.vue';
import ParticipantProfileEditorDialog from './ParticipantProfileEditorDialog.vue';
import ParticipantBalanceEditorDialog from './ParticipantBalanceEditorDialog.vue';

export default {
  name: 'ParticipantView',
  components: { ParticipantOperationsHistoryList, ParticipantProfileEditorDialog, ParticipantBalanceEditorDialog },

  setup() {
    const marketingProgramStore = useMarketingProgramStore()
    const keycloakStore = useKeycloakStore()
    return { marketingProgramStore, keycloakStore }
  },

  data: () => ({
    participant: null,
    dialog: {
      opened: false,
      title: undefined,
      text: undefined
    },
    profile: {
      fio: undefined,
      gender: undefined,
      birthday: undefined,
      emailAddress: undefined,
      teamId: undefined,
    },
    allMpTeams: [],
    balance: { 
      amount: undefined
    },
    loading: false,
    participantProfileDialogShow: false,
    participantBalanceDialogShow: false,
  }),

  computed: {
    participantId() {
      return this.$route.params.participantId;
    },

    mp() {
      if (this.marketingProgramStore.isInitialized === false)
        return null;

      return this.marketingProgramStore;
    },

    kl() {
      return this.keycloakStore;
    },

    scountApi() {
      if (!this.mp)
        return null;

      return scountApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
    },

    participantInfo() {
      if (!this.participant)
        return null;

      var emailAddress = this.getAnswersValue('EmailAddress');
      var firstName = this.getAnswersValue('FirstName');
      var lastName = this.getAnswersValue('LastName');

      var gender = this.getAnswersValue('Gender');
      var birthday = this.getAnswersValue('Birthday');

      const teamId = this.getAnswersValue('TeamId');

      var today = new Date();
      var birthDate = new Date(birthday);
      var years = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          years--;
      }

      const endOfAge = (() => { 
        if (years < 0)
          return "";

        const cm10 = years % 10;
        const cm100 = years % 100;

        if (cm100 >= 10 && cm100 <= 20)
            return "лет";

        if (cm10 == 1)
            return "год";

        if (cm10 > 1 && cm10 < 5)
            return "года";

        return "лет";
       })();

      const age = `${years} ${endOfAge}`;

      var avatarUrl = null;
      if (!this.participant.avatar)
        avatarUrl = null;
      else
      {
        avatarUrl = this.participant.avatar.type === 'custom'
          ? this.participant.avatar.url
          : `/img/avatars/avatar-step${parseInt(this.participant.avatar.type) < 10 ? '0' : ''}${this.participant.avatar.type}.svg`;
      }

      let team = teamId ? this.allMpTeams.find(x => x.teamId === teamId) : "-";

      return {
        birthday,
        age,
        gender,
        avatarUrl,
        fio: `${firstName} ${lastName}`,
        emailAddress,
        teamName: team ? team.name : "-",
      };
    },

    avatarUrl() {
      if (!this.participant || !this.participant.avatar)
        return null;

      return this.participant.avatar.type === 'custom'
        ? this.participant.avatar.url
        : `/img/avatars/avatar-step${parseInt(this.participant.avatar.type) < 10 ? '0' : ''}${this.participant.avatar.type}.svg`;
    }
  },

  methods: {
    async getParticipant() {
      const response = await this.scountApi.getParticipant(this.participantId);
      this.participant = response.data;
            
      const lastName = this.getAnswersValue('LastName');
      const firstName = this.getAnswersValue('FirstName');

      this.profile.lastName = lastName;
      this.profile.firstName = firstName;
      this.profile.gender = this.getAnswersValue('Gender');
      this.profile.birthday = this.getAnswersValue('Birthday');
      this.profile.emailAddress = this.getAnswersValue('EmailAddress');
      this.profile.teamId = this.getAnswersValue('TeamId');

      this.allMpTeams = await this.getAllTeams();

      this.balance.amount = this.participant.balance.amount;
    },

    async saveParticipantProfile(model) {
      this.loading = true;
      
      try {
        const request = {
          answers: {
            LastName: model.lastName,
            FirstName: model.firstName,
            Gender: model.gender,
            Birthday: model.birthday,
            EmailAddress: model.emailAddress,
            TeamId: model.teamId
          }
        };

        const resp = await this.scountApi.editParticipantQuestionnaire(this.participantId, request);
        if (resp.success)
          await this.getParticipant();
        else
          this.showError('Ошибка при замене адреса e-mail', this.getAfterSaveText(resp.error));
      } finally {
        this.loading = false;
      }
    },

    showError(title, text) {
      this.dialog.title = title;
      this.dialog.text = text;
      this.dialog.opened = true;
    },

    hideError() {
      this.dialog.title = undefined;
      this.dialog.text = undefined;
      this.dialog.opened = false;
    },

    getAfterSaveText(error) {
        const e = error;
        if (!e) return 'Произошла ошибка';
        if (e.status === 422 && e.detail && e.detail.startsWith('The user with email '))
          return 'Пользователь с указанным e-mail уже есть.';
        return e.detail;
    },

    async saveParticipantBalance(model) {
      this.loading = true;

      try {
        await this.scountApi.editParticipantBalance(this.participantId, model);
        await this.getParticipant();
      } finally {
        this.loading = false;
      }
    },
    
    async getAllTeams() {
      const total = await this.scountApi.getTeams();
      if (total.data.total >= 5) {
        const response = await this.scountApi.getTeams(1, null, null, total.data.total);
        return response.data.values;
      } else {
      const response = await this.scountApi.getTeams(1, null, null, 5);
        return response.data.values;
      }
    },

    async participantBlock() {
      this.loading = true;

      try {
        if (this.participant.state === 'Active')
          await this.scountApi.blockParticipant(this.participantId);
        else
          await this.scountApi.unblockParticipant(this.participantId);
        await this.getParticipant();
      } finally {
        this.loading = false;
      }
    },

    getAnswersValue(key) {
      const answer = this.participant.questionnaire.answers.find(a => a.key === key);
      return answer ? answer.value : undefined;
    },
  },

  async mounted() {
    this.loading = true;
    try {
      await this.getParticipant();
    } finally {
      this.loading = false;
    }
  },
}
</script>

<style lang="scss" scoped>
  @import '../../assets/global';
</style>