<template>
  <div>
    <router-link :to="{ name: 'Exercise', params: { exerciseId: sourceId }}">
      {{attributes.ExerciseTitle}}
    </router-link>

    <v-chip
      class="ma-2"
      label
      small
      :color="getOperationTypeColor(attributes.OperationType)[0]"
      :text-color="getOperationTypeColor(attributes.OperationType)[1]"
    >
      {{attributes.Amount}}
    </v-chip>
  </div>
</template>

<script>
export default {
  name: 'OperationInfoBalanceOperationRefill',

  props: {
    sourceId: String,
    attributes: Object,
  },

  computed: {
    operationTypeText() {
      if (this.attributes.OperationType === 'Refill') {
        switch (this.attributes.OperationSource) {
          case 'Exercise': return 'Задание';
          case 'ExtendedQuestionnaire': return 'Вознаграждение за анкету';
          case 'Registration': return 'Вознаграждение за регистрацию';
          default: return this.attributes.OperationSource;
        }
      }

      if (this.attributes.OperationType === 'Withdrawal') {
        return 'Вывод';
      }

      return '';
    },
  },

  methods: {
    getOperationTypeColor(operationType) {
      if (operationType === 'Refill')
        return ['#93de64', 'black'];

      if (operationType === 'Withdrawal')
        return ['#fa4646', 'white'];
    },
  }
}
</script>
