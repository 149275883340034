<template>
  <span>
    <span :id="`input_${elementId}`">{{ content }}</span>

    <v-tooltip v-model="showTooltip" left>
      <template v-slot:activator="{ props }">
        <v-btn
          icon
          v-bind="props"
          small
        >
          <v-icon
            class="ml-1"
            small
            @click="copy()"
          >mdi-content-copy</v-icon>
        </v-btn>
      </template>
      <span>Скопировано</span>
    </v-tooltip>
  </span>
</template>

<script>
import { uuid } from 'vue-uuid';

export default {
  name: 'CCopy',

  props: {
    content: {
      type: String,
    },
  },

  data () {
    return {
      elementId: uuid.v4(),
      showTooltip: false,
    };
  },

  methods: {
    // checkPermissions() {
    //   navigator.permissions
    //     .query({ name: "write-on-clipboard" })
    //     .then((result) => {
    //       console.log('permissions result', result)
    //       if (result.state == "granted" || result.state == "prompt") {
    //         alert("Write access granted!");
    //       } else {
    //         alert("Grant not found!");
    //       }
    //     });
    // },

    async copy() {
      try {
        await navigator.clipboard.writeText(this.content);
        this.showTooltip = true;

        var self = this;
        setTimeout(function() {
          self.showTooltip = false;
        }, 2000);
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    },
  },
}
</script>