<template>
  <div class="wrapper">
    <v-row>
      <v-col cols="5">
        <v-text-field
          label="Начало отчетного периода"
          v-model="loggingDate.startDate"
          :rules="validation.date"
          hide-details="auto"
          append-outer-icon="mdi-calendar-blank"
          placeholder="ДД.ММ.ГГГГ"
        />
      </v-col>
      <v-col cols="5">
        <v-text-field
          label="Конец отчетного периода"
          v-model="loggingDate.endDate"
          :rules="validation.date"
          hide-details="auto"
          append-outer-icon="mdi-calendar-blank"
          placeholder="ДД.ММ.ГГГГ"    
        />
      </v-col>
      <v-col cols="2">
        <v-btn
          width="100%"
          large
          @click="getOnboardingItems"
        >Показать</v-btn>
      </v-col>
      <v-col cols="12">
        <v-text-field
          label="Найти запись (ФИО, email, объект, id, действие)"
          hide-details="auto"
          v-model="searchString"
        />
      </v-col>
    </v-row>
    <div class="mt-7">
      <v-tabs
        color="#FF8233"
      >
      <v-tabs-slider color="#FF8233"></v-tabs-slider>
        <v-tab
          v-for="(tab, index) in loggingTabs "
          :key="index"
          @click="handleTabClick(tab)"
          >
          {{ tab.name }}
        </v-tab>
      </v-tabs>
    </div>
    <div class="mt-4">
      <DataTable
        controller="inRoute"
        :key="newParticipantsFiltersHash"
        :headers="loggingTableHeader"
        :items="loggingTableItems"
        :server-items-length="loggingTableTotal"
        :loading="loggingUpdating"
        @update:table-options="tableUpdated"
      >
        <template v-slot:[`item.actions`]="{item}">
          <v-icon
            @click="openDialog(item)"
            color="primary"
            class="mr-2"
          >
          mdi-alert-circle
          </v-icon>
        </template>
        <template v-slot:[`item.date`]="{item}">
          {{ formatDate(item.date) }}
        </template>
        <template v-slot:[`item.authorFio`]="{item}">
          <p class="author__title">{{ item.authorFio }}</p>
          <p class="author__subtitle">{{ item.authorEmail }}</p>
        </template>
        <template v-slot:[`item.objectName`]="{item}">
          <p class="author__title color">{{ item.objectName }}</p>
          <p class="author__subtitle">{{ item.objectId }}</p>
        </template>
        <template v-slot:[`item.details`]="{item}">
          <v-icon>mdi-autorenew</v-icon>
          Изменено: {{ detailsName(item) }}
        </template>
      </DataTable>
    </div>
    <v-dialog
      v-model="dialogLogging"
      max-width="720"
    >
      <v-card>
        <div class="pa-6 grey lighten-2" >
          <div class="dialog__title">{{ authorFio }} | {{ authorEmail }}</div>
          <div class="dialog__subtitle">id: {{ objectId }} | Изменение {{ formatDate(objectDate) }}</div>
        </div>
        <v-card-content>
          <div class="dialog__content pa-6">
            <div class="dialog__row" v-for="(item, index) in details" :key="index">
              <div class="dialog__column-block before">
                <div class="dialog__column-title">{{ item.Name }}</div>
                <div class="dialog__column-text">{{ item.PrevValue ? item.PrevValue : '-' }}</div>
              </div>
              <div class="dialog__column-block after">
                <div class="dialog__column-title">{{ item.Name }}</div>
                <div class="dialog__column-text">{{ item.NewValue ? item.NewValue : '-' }}</div>
              </div>
            </div>
          </div>
        </v-card-content>
        <v-card-actions class="pa-6 d-flex justify-end">
          <v-btn
            text
            @click="dialogLogging = false"
          >
            Закрыть
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
import scountApiInitializer from '@/api/scountApi';
import Moment from 'moment'
  export default {
    data: () => ({
      validation: {
        date: [
          value => !value || /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[012])\.(19|20)\d\d$/i.test(value) || 'Некорректная дата. Формат ДД.ММ.ГГГГ',
        ],
      },
      loggingTableHeader: [
        { value: 'date', text: 'Дата/время', align: 'start', sortable: false },
        { value: 'authorFio', text: 'Автор', align: 'start', sortable: false },
        { value: 'objectName', text: 'Объект', align: 'start', sortable: false },
        { value: 'details', text: 'Действие', align: 'start', sortable: false },
        { value: 'actions', text: 'Подробности', align: 'right', sortable: false },
      ],
      loggingTabs: [
        {index: 0, name: 'Участники', type: 'Participant'},
        {index: 1, name: 'Задания', type: 'Exercise'},
        {index: 2, name: 'Номинации', type: 'Nomination'},
        {index: 3, name: 'Команды', type: 'Team'},
        {index: 4, name: 'Поощрения', type: 'Promotion'},
        {index: 5, name: 'Настройки', type: 'Settings'},
        {index: 6, name: 'Каталоги', type: 'Catalog'},
      ],
      loggingTableItems: [],
      newParticipantsFiltersHash: Date.now(),
      loggingTableTotal: 0,
      loggingUpdating: false,
      dialogLogging: false,
      authorFio: '',
      authorEmail: '',
      objectType: 'Participant',
      objectId: null,
      objectDate: null,
      details: [],
      loggingDate: {
        startDate: undefined,
        endDate: undefined,
      },
      searchString: '',
      pagination: {
        page: 1,
        itemsPerPage: 10,
      }
    }),
    setup() {
      const marketingProgramStore = useMarketingProgramStore()
      return { marketingProgramStore }
    },
    computed: {
      mp() {
        if (this.marketingProgramStore.isInitialized === false)
          return null;
        return this.marketingProgramStore;
      },
      scountApi() {
        if (!this.mp)
          return null;
        return scountApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
      },
    },
    methods: {
      detailsName(item) {
        return item.details ? item.details[0].Name : "";
      },
      formatDate(date) {
        return Moment(date).format('DD.MM.YYYY HH:mm');
      },
      openDialog(item) {
        this.details = item.details;
        this.objectDate = item.date;
        this.objectId = item.objectId;
        this.authorFio = item.authorFio;
        this.authorEmail = item.authorEmail;
        this.dialogLogging = true
      },
      async tableUpdated(pagination, sorting) {
          await this.getOnboardingItems(pagination, sorting);
      },
      async getOnboardingItems(pagination, sorting) {
        if (!this.scountApi) return;
          this.loggingUpdating = true;
        try {
          const response = await this.scountApi.getLogging(
            pagination,
            sorting,
            this.searchString,
            this.loggingDate.startDate ? Moment(this.loggingDate.startDate, 'DD.MM.YYYY').toDate() : null,
            this.loggingDate.endDate ? Moment(this.loggingDate.endDate, 'DD.MM.YYYY').toDate() : null,
            this.objectType
          );
          const {values, total} = response.data;
          this.loggingTableTotal = total;
          this.loggingTableItems = values;
        } finally {
          this.loggingUpdating = false;
        }
      },
      async handleTabClick(item, sorting) {
        this.loggingTableItems = [];
        this.loggingDate.startDate = null;
        this.loggingDate.endDate = null;
        this.searchString = '';
        this.objectType = item.type;
        await this.tableUpdated(this.pagination, sorting, this.searchString, this.objectType);

      },
    }
  }
</script>

<style lang="scss" scoped>
  .author {
    &__title {
      font-size: 12px;
      font-weight: 700;
      line-height: 15px;
      letter-spacing: 0.25px;
      margin-bottom: 0;
      &.color {
        color: #FF8233;
      }
    }
    &__subtitle {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
      margin-bottom: 0;
    }
  }
  .wrapper {
    padding: 0 12px;
  }
  .dialog {
    &__title {
      font-size: 20px;
      line-height: 32px;
      letter-spacing: 0.25px;
    }
    &__subtitle {
      font-size: 12px;
      line-height: 32px;
      letter-spacing: 0.25px;
    }
    &__row{
      display: flex;
      justify-content: space-between;
      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
    &__column {
      &-block {
        flex: 0 0 50%;
        &.before {
          color: #ED1C24;
          background-image: url(../../assets/icon/arrow-r.svg);
          background-position: 98% 50%;
          padding-right: 50px;
        }
        &.after {
          color: #51C41A;
          padding-left: 20px;
        }
      }
      &-title {
        color: rgba(0, 0, 0, 0.6);
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.4px;
      }
      &-text {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
      }
    }
  }
</style>