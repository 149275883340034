<template>
  <div>
    <v-row class="px-4">
      <v-col cols="6">
        <div class="goal__wrapper">
          <h3 class="goal__title">Тренировочная цель</h3>
          <!-- <v-checkbox 
            v-model="trenningData.activity"
            label="Активна"
          /> -->
          <div class="goal__border mt-4" v-for="(goal, index) in goals" :key="index">
            <!-- <v-row>
              <v-col class="d-flex justify-end" v-if="goals.length > 1">
                <v-icon>mdi-delete-outline</v-icon>
              </v-col>
              <p>{{ goal.id }}</p>
            </v-row> -->
            <v-row class="mt-0">
              <v-col>
                <v-text-field
                v-model="goal.necessaryDistance"
                :rules="validation.necessaryDistance"
                label="Цель в километрах"
                />
              </v-col>
            </v-row>
            <v-text-field
              v-model="goal.title"
              :rules="validation.title"
              label="Название цели (например: Добежать до луны)"
            />
            <p class="label__editor">Описание цели</p>
            <vue-editor v-model="goal.description" :rules="validation.description" :editorToolbar="customToolbar"/>
            <v-alert
              border="top"
              colored-border
              type="info"
              color="rgb(77, 65, 246)"
              elevation="2"
              class="mt-4"
            >
            Если Вам необходимо отразить в окне "Название цели" или "Описание цели" цифру указанную в окне "Цель в километрах" используйте команду {{totalKm}}, не указывая цифры.
            </v-alert>
            <v-row>
              <v-col class="mt-4">
                <v-btn large class="mr-4" @click="saveGoal(goal, goal.id)">
                  <v-icon left>mdi-content-save-outline</v-icon>
                  сохранить
                </v-btn>
                <v-btn large @click="deleteGoalItem(goal.id)">
                  <v-icon left>mdi-delete-outline</v-icon>
                  удалить
                </v-btn>
              </v-col>
            </v-row>
          </div>
          <v-row>
            <v-col>
              <v-btn large class="mt-8" @click="addGoal" v-if="addButton">
                <v-icon left>mdi-plus</v-icon>
                добавить цель
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-col>

      <v-col cols="6">
        <div class="goal__wrapper">
          <h3 class="goal__title">Благотворительная цель</h3>
          <!-- <v-checkbox 
            v-model="charityData.activity"
            label="Активна"
          /> -->
          <div class="goal__border mt-4">
            <!-- <v-row>
              <v-col cols="12" class="d-flex justify-end">
                <v-icon>mdi-delete-outline</v-icon>
              </v-col>
            </v-row> -->
            <v-row class="mt-0">
              <v-col cols="6">
                <v-text-field
                  v-model="charityData.necessaryCost"
                  label="Цель в рублях"
                  :rules="validation.necessaryCost"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="charityData.costPerKilometer"
                  label="Коэффициент (рублей за км)"
                  :rules="validation.costPerKilometer"
                />
              </v-col>
          </v-row>
          <v-text-field
            v-model="charityData.title"
            label="Название цели (например: помочь школе 564)"
          />
          <p class="label__editor">Правила</p>
          <vue-editor v-model="charityData.costDescription" :editorToolbar="customToolbar"/>
          <v-row class="mt-1">
            <v-col>
              <v-btn large @click="editGoalGroup">
                <v-icon left>mdi-content-save-outline</v-icon>
                сохранить
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <!-- <v-row>
          <v-col>
            <v-btn large class="mt-8">
              <v-icon left>mdi-plus</v-icon>
              добавить цель
            </v-btn>
          </v-col>
        </v-row> -->
      </div>
      </v-col>
    </v-row> 
    <notifications :duration="5000" position="bottom right"/>
  </div>
</template>

<script>
  import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
  import scountApiInitializer from '@/api/scountApi';
  import { VueEditor } from "vue2-editor";
  import { uuid } from "vue-uuid";

  export default {
    name: "GoalView",
    components: {
      VueEditor
    },
    data: () => ({
      totalKm: ' {{totalKm}}',
      goals: [
        {
        // activity: true,
        id: '',
        title: '',
        necessaryDistance: null,
        name: '',
        description: '',
      }
    ],
    charityData: {
      // activity: true,
      costPerKilometer: null,
      necessaryCost: null,
      title: '',
      costDescription: '',
    },
    customToolbar: [
      ["bold",{ list: "ordered"}, {list: "bullet" }, "link"],
    ],
    validation: {        
      title: [
        value => !!value || 'Обязательное поле.',
      ],
      description: [
        value => !!value || 'Обязательное поле.',
      ],
      necessaryDistance: [
        value => /^\d+(\.\d{1,2})?$/.test(value) || 'Значение должно быть числом не меньше 0',
        value => !!value || 'Обязательное поле.',
      ],
      necessaryCost: [
        value => /^\d+(\.\d{1,2})?$/.test(value) || 'Значение должно быть числом не меньше 0',
      ],
      costPerKilometer: [
        value => /^\d+(\.\d{1,2})?$/.test(value) || 'Значение должно быть числом не меньше 0',
      ],
    },
    idGoal: '',
    id: '',
    newGoal: {},
    addButton: true,
    }),
    computed: {
      mp() {
        if (this.marketingProgramStore.isInitialized === false)
          return null;
        return this.marketingProgramStore;
      },
      scountApi() {
        if (!this.mp)
          return null;
        return scountApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
      },
    },
    methods: {
      async updateGoals() {
        await this.scountApi.getGoalGroup()
        .then((response) => {
          this.goals = response.data.goals
          this.$notify({type: 'success', text: `Данные успешно обновлены`});
        })
        .catch((error) => {
          this.$notify({type: 'error', text: `Ошибка обновления данных`});
          console.error('Ошибка:', error);
        });
      },
      async getGoalGroup() {
        await this.scountApi.getGoalGroup()
        .then((response) => {
          this.goals = response.data.goals
          this.charityData.title = response.data.costTitle
          this.charityData.costPerKilometer = response.data.costPerKilometer
          this.charityData.costDescription = response.data.costDescription
          this.charityData.necessaryCost = response.data.necessaryCost
          this.idGoal = response.data.id
          this.$notify({type: 'success', text: `Цели успешно загружены`});
        })
        .catch((error) => {
          this.$notify({type: 'error', text: `Ошибка загрузки целей`});
          console.error('Ошибка:', error);
        });
      },
      // async createGoalGroup() {
      //   const goalGroupId = uuid.v4();
      //   const marketingProgramId = this.mp.marketingProgramId;
      //   const model = {
      //     goalGroupId: goalGroupId,
      //     marketingProgramId: marketingProgramId,
      //     title: this.goals.name,
      //     description: this.goals.description
      //   }
      //   await this.scountApi.createGoalGroup(model)
      //   .then(() => {
      //     this.getGoalGroup();
      //     this.$notify({type: 'success', text: `Цель успешно создана`});
      //   })
      //   .catch((error) => {
      //     this.$notify({type: 'error', text: `Ошибка: ${error}`});
      //     console.error('Ошибка:', error);
      //   });
      // },
      async editGoalGroup() {
        const model = {
          costPerKilometer: this.charityData.costPerKilometer,
          title: this.charityData.title,
          description: this.charityData.costDescription,
          necessaryCost: this.charityData.necessaryCost
        }
        await this.scountApi.editGoalGroup(this.idGoal, model)
        .then((response) => {
          this.updateGoals();
          this.$notify({type: 'success', text: `Цель успешно изменена`});
          console.log(response)
        })
        .catch((error) => {
          this.$notify({type: 'error', text: `Ошибка: ${error}`});
          console.error('Ошибка:', error);
        });
      },
      addGoal() {
        this.newGoal = {
          title: '',
          necessaryDistance: null,
          necessaryDistanceInKilometers: null,
          description: '',
        };
        this.goals.push(this.newGoal);
        this.addButton = false
      },
      async addGoalSave() {
        const goalId = uuid.v4();
        const model = {
          ...this.newGoal,
          goalId: goalId,
          necessaryDistanceInKilometers: parseFloat(this.newGoal.necessaryDistance),
          necessaryDistance: parseFloat(this.newGoal.necessaryDistance)
        }
        await this.scountApi.addGoal(this.idGoal, model)
        .then((response) => {
          this.updateGoals();
          this.addButton = true
          this.$notify({type: 'success', text: `Цель успешно создана`});
          console.log(response)
        })
        .catch((error) => {
          this.$notify({type: 'error', text: `Ошибка: ${error}`});
          console.error('Ошибка:', error);
        });
      },
      async editGoal(goal, goalId) {
        const model = {
          necessaryDistanceInKilometers: goal.necessaryDistance,
          title: goal.title,
          description: goal.description
        }
        await this.scountApi.editGoal(this.idGoal, goalId, model)
        .then((response) => {
          this.updateGoals();
          this.$notify({type: 'success', text: `Цель успешно изменена`});
          console.log(response)
        })
        .catch((error) => {
          this.$notify({type: 'error', text: `Ошибка: ${error}`});
          console.error('Ошибка:', error);
        });
      },

      saveGoal(goal, goalId) {
        if (goalId){
        this.editGoal(goal, goalId)
        } else {
          this.addGoalSave()
        }
      },
      
      async deleteGoalItem(goalId) {
        await this.scountApi.deleteGoal(this.idGoal, goalId)
        .then((response) => {
          this.updateGoals();
          this.$notify({type: 'success', text: `Цель успешно удалена`});
          console.log(response)
        })
        .catch((error) => {
          this.$notify({type: 'error', text: `Ошибка: ${error}`});
          console.error('Ошибка:', error);
        });
      }
    },
    setup() {
      const marketingProgramStore = useMarketingProgramStore()
      return { marketingProgramStore }
    },
    mounted() {
      this.getGoalGroup()
    }
  }
</script>

<style lang="scss" scoped>
  .goal__title {
    font-size: 23px;
    font-weight: 400;
    line-height: 32px
  }
  .goal__wrapper {
    border-radius: 8px;
    border: 2px solid rgba(1, 1, 1, 0.45);
    padding: 24px;
  }
  .goal__border {
    border-radius: 4px;
    border: 1px solid rgba(1, 1, 1, 0.25);
    padding: 24px;
  }
  .label__editor {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.6);
    margin: 8px 0;
  }
</style>