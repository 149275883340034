<template>
  <div class="exercies__hr">
    <v-row>
      <v-col class="exercies__h2">
        Работы в задании
      </v-col>
      <v-col class="d-flex">
        <div class="ml-auto">
        <v-btn @click="openCreateTaskDialog()" :disabled="!isEdit">Добавить работы</v-btn>
        </div>
      </v-col>
    </v-row>

    <DataTable
      controller="inRoute"
      :key="filtersHash"
      :headers="headers"
      :items="items"
      :server-items-length="total"
      sort-by="date"
      :sort-desc="true"
      :search-fields="searchFields"
      :loading="updating"
      @update:table-options="tableUpdated"
    >
      <template v-slot:[`item.title`]="{item}">
        {{ item.title }}
      </template>
      <template v-slot:[`item.order`]="{item}">
        {{ item.order }}
      </template>
      <template v-slot:[`item.reward`]="{item}">
        {{ item.reward }}
      </template>
      <template v-slot:[`item.settings`]="{item}">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
            >
              <v-icon class="mr-2">
                {{ getDiscipline(item.settings.Discipline).icon }}
              </v-icon>
            </span>
          </template>
          <span>Дисциплина: {{ getDiscipline(item.settings.Discipline).text }}</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.createdOn`]="{item}">
        {{ item.createdOn | formatDate }}
      </template>
      <template v-slot:[`item.actions`]="{item}">
        <v-icon class="mr-2" @click="openUpdateTaskDialog(item)" :disabled="!isEdit">mdi-pencil</v-icon>
        <v-icon @click="deleteItem(item)" :disabled="!isEdit">mdi-delete</v-icon>
      </template>
    </DataTable>

    <ExerciseTemplateTaskEditorDialog
      v-if="exerciseTemplateTaskEditorDialogShow"
      :save-func="saveExerciseTemplateTask"
      :object-id="taskId"
      :object="task"
      :exercise-type="exerciseType"
      @closed="exerciseTemplateTaskEditorDialogShow = false"
    />
  </div>
</template>

<script>
import { uuid } from "vue-uuid";
import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
import scountApiInitializer from '@/api/scountApi';
import ExerciseTemplateTaskEditorDialog from './ExerciseTemplateTaskEditorDialog.vue';

export default {
  name: 'ExerciseTemplateTasksView',
  components: { ExerciseTemplateTaskEditorDialog },

  props: {
    exerciseType: undefined,
    isEdit: Boolean,
  },

  setup() {
    const marketingProgramStore = useMarketingProgramStore()
    return { marketingProgramStore }
  },

  data: () => ({
    headers: [
      { value: 'title', text: 'Название', align: 'start', sortable: false },
      { value: 'order', text: 'Порядок', align: 'start', sortable: false },
      { value: 'reward', text: 'Баллы', align: 'start', sortable: false },
      { value: 'maxReward', text: 'Баллы (max)', align: 'start', sortable: false },
      { value: 'settings', text: 'Настройки', align: 'start', sortable: false },
      { value: 'createdOn', text: 'Создан', align: 'end', sortable: false },
      { value: 'actions', text: 'Действия', align: 'center', width: '150px', sortable: false },
    ],
    filtersHash: Date.now(),
    items: [],
    total: 0,
    searchFields: [
      { key: 'title', label: 'Название' },
    ],
    updating: false,
    exerciseTemplateTaskEditorDialogShow: false,
    taskId: undefined,
    task: undefined
  }),

  computed: {
    exerciseTemplateId() {
      return this.$route.params.exerciseTemplateId;
    },

    mp() {
      if (this.marketingProgramStore.isInitialized === false)
        return null;

      return this.marketingProgramStore;
    },

    scountApi() {
      if (!this.mp)
        return null;

      return scountApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
    },
  },

  methods: {
    async tableUpdated() {
      await this.getExerciseTemplateTasks();
    },

    async getExerciseTemplateTasks() {
      if (!this.scountApi) return;

      this.updating = true;

      try {
        const response = await this.scountApi.getExerciseTemplateTasks(this.exerciseTemplateId);

        this.total = response.data.length;
        this.items = response.data;
      } finally {
        this.updating = false;
      }
    },

    async saveExerciseTemplateTask(model) {
      if (this.taskId) {
        this.updateExerciseTemplateTask(model);
      } else {
        this.createExerciseTemplateTask(model);
      }
    },

    async createExerciseTemplateTask(model) {
      var exerciseTemplateTaskId = uuid.v4();

      let request;
      switch (model.type) {
        case 'SportActivity':
          request = {
            type: model.type,
            title: model.title,
            order: model.order,
            reward: model.reward,
            maxReward: model.maxReward,
            settings: {
              MaxPace: model.settings.MaxPace, 
              MinPace: model.settings.MinPace, 
              Discipline: model.settings.Discipline, 
              MaxDistance: model.settings.MaxDistance, 
              MinDistance: model.settings.MinDistance,
              MaxTimeInSeconds: model.settings.MaxTimeInSeconds
            }
          };
          break;
        case 'GeoLocation':
          model.settings.Locations.forEach(el => {
            const m = /^(-?\d{1,3}\.\d{1,6}), (-?\d{1,3}\.\d{1,6})$/.exec(el.value);
            console.log(m);
            el.Latitude = m[1];
            el.Longitude = m[2];
            delete el.value;
          });
          request = {
            type: model.type,
            title: model.title,
            order: model.order,
            reward: model.reward,
            maxReward: model.reward,
            settings: {
              Discipline: 'GeoLocation',
              Radius: model.settings.Radius,
              Locations: JSON.stringify(model.settings.Locations)
            }
          };
          break;
        case 'Quiz':
          request = {
            type: model.type,
            title: model.title,
            order: model.order,
            reward: model.reward,
            maxReward: model.reward,
            settings: {
              Discipline: 'Quiz',
              QuizId: model.settings.QuizId,
              QuizQuestionsQty: model.settings.QuizQuestionsQty
            }
          };
          break;
        case 'PartnerLink':
          request = {
            type: model.type,
            title: model.title,
            order: model.order,
            reward: model.reward,
            maxReward: model.reward,
            settings: {
              Discipline: 'PartnerLink',
              PartnerLink: model.settings.PartnerLink,
              PromocodeLink: model.settings.PromocodeLink
            }
          };
          break;
        case 'UploadImage':
          request = {
            type: model.type,
            title: model.title,
            order: model.order,
            reward: model.reward,
            maxReward: model.reward,
            settings: {
              Discipline: 'UploadImage',
              What: 'File',
              CheckTime: model.settings.CheckTime
            }
          };
          break;
        case 'Registration':
          request = {
            type: model.type,
            title: model.title,
            order: model.order,
            reward: +model.reward,
            maxReward: model.maxReward,
            settings: {
              Link: model.settings.link,
              Disciplines: JSON.stringify(model.settings.Disciplines),
              Events: [
                {
                  EventId: model.settings.Events[0].EventId,
                  Races: model.settings.Events[0].Races
                }
              ]
            }
          };
          break;
        case 'Participation':
          request = {
            type: model.type,
            title: model.title,
            order: model.order,
            reward: model.reward,
            maxReward: model.maxReward,
            settings: {
              RaceId: model.settings.RaceId,
              MinDistance: model.settings.MinDistance,
              MaxTimeInSeconds: model.settings.MaxTimeInSeconds,
              Discipline: model.type,
            }
          };
          break;
        case 'Invite':
          request = {
            type: model.type,
            title: model.title,
            order: model.order,
            reward: model.reward,
            maxReward: model.maxReward,
            settings: {
              RegistrationCount: model.settings.RegistrationCount,
              Link: model.settings.Link,
              Discipline: model.type,
            }
          };
          break;
      }

      await this.scountApi.createExerciseTemplateTask(this.exerciseTemplateId, exerciseTemplateTaskId, request);
      this.goToExerciseTemplate(this.exerciseTemplateId);
    },

    async updateExerciseTemplateTask(model) {
      if (model.type === 'GeoLocation') {
        model.settings.Locations.forEach(el => {
          const m = /^(\d{1,3}\.\d{1,6}), (\d{1,3}\.\d{1,6})$/.exec(el.value);
          el.Latitude = m[1];
          el.Longitude = m[2];
        });
        model.settings.Locations = JSON.stringify(model.settings.Locations);
      }

      if (model.type !== 'SportActivity') {
        model.maxReward = model.reward;
      }

      await this.scountApi.changeExerciseTemplateTask(this.exerciseTemplateId, this.taskId, model);
      this.goToExerciseTemplate(this.exerciseTemplateId);
    },

    openCreateTaskDialog() {
      this.taskId = undefined;
      this.task = undefined;
      this.exerciseTemplateTaskEditorDialogShow = true;
    },

    openUpdateTaskDialog(exerciseTemplateTask) {
      this.taskId = exerciseTemplateTask.exerciseTemplateTaskId;
      this.task = exerciseTemplateTask;

      if (exerciseTemplateTask.type === 'GeoLocation') {
        exerciseTemplateTask.settings.Locations = JSON.parse(exerciseTemplateTask.settings.Locations);
        exerciseTemplateTask.settings.Locations.forEach(el => {
          el.value = `${el.Latitude}, ${el.Longitude}`;
        });
      }

      this.exerciseTemplateTaskEditorDialogShow = true;
    },

    async deleteItem(exerciseTemplateTask) {
      this.updating = true;

      try {
        const response = await this.scountApi.deleteExerciseTemplateTask(this.exerciseTemplateId, exerciseTemplateTask.exerciseTemplateTaskId);
        if (response.status === 204) {
          this.filtersHash = Date.now();
          this.updating = false;
        } else {
          const e = response.error;
          const errorText = !e
            ? 'Произошла ошибка'
            : `Код статуса ответа: ${e.status}`
              + `<br>Код: ${e.code}`
              + `<br>Ошибка: ${e.title}`
              + `<br>Подробности: ${e.detail}`;

          this.openDialog('Ошибка при добавлении задания', errorText); 
          this.updating = false;
        }
      } catch {
        this.updating = false;
      }
    },

    goToExerciseTemplate() {
      this.$router.push(`/exercise-template/${this.exerciseTemplateId}`);
    },

    getDiscipline(discipline) {
      switch (discipline) {
        case 'Running': return { text: 'Бег', icon: 'mdi-run' };
        case 'Walking': return { text: 'Ходьба', icon: 'mdi-shoe-sneaker' };
        case 'Cycling': return { text: 'Велосипед', icon: 'mdi-bicycle' };
        case 'Swimming': return { text: 'Плавание', icon: 'mdi-pool' };
        case 'Skiing': return { text: 'Лыжи', icon: 'mdi-ski' };
        case 'Quiz': return { text: 'Квиз', icon: 'mdi-head-question' };
        default: return { text: 'undefined' };
      }
    }
  },
}
</script>
