import {initializeApi} from "@/api/base";

function withMarketingProgram(marketingProgramId) {
  const api = initializeApi("/api/sportdata-admin", {'X-MarketingProgram-Id': marketingProgramId});

  // todo: debug host
  // const debugHost = 'http://localhost:5005';

  async function activities(activityId) {
    const response = await api
      .put(`/api/v1/activities/${activityId}`)
      .then(response => {
        return {success: true, status: response.status, data: response.data};
      })
      .catch((error) => {
        return {success: false, error};
      });
  
    return response;
  }

  async function addParticipantTraining(trackerId, model) {
    const response = await api
      .post(`/api/v1/trackers/${trackerId}/activities`, model)
      .then(response => {
        return {success: true, status: response.status, data: response.data};
      })
      .catch((error) => {
        return {success: false, error};
      });
  
    return response;
  }

  async function getActiveParticipantTrackerId(participantId) {
    const response = await api
      .get(`/api/v1/trackers/get-active-tracker-id?externalUserId=${participantId}`)
      .then(response => {
        return {success: true, status: response.status, data: response.data};
      })
      .catch((error) => {
        return {success: false, error};
      });
  
    return response;
  }

  async function getApplicationsRestrictions(applicationId) {
    const response = await api
      .get(`/api/v1/applications/${applicationId}/restrictions`)
      .then(response => {
        return {success: true, status: response.status, data: response.data};
      })
      .catch((error) => {
        return {success: false, error};
      });
  
    return response;
  }

  async function patchApplicationsRestrictions(applicationId, model) {
    const response = await api
      .patch(`/api/v1/applications/${applicationId}/restrictions`, model)
      .then(response => {
        return {success: true, status: response.status, data: response.data};
      })
      .catch((error) => {
        return {success: false, error};
      });
  
    return response;
  }

  return {
    activities,
    addParticipantTraining,
    getActiveParticipantTrackerId,
    getApplicationsRestrictions,
    patchApplicationsRestrictions
  };
}

export default {
  withMarketingProgram,
};
