<template>
  <div>
    <DataTable
      :key="filtersHash"
      controller="inRoute"
      :headers="headers"
      :items="items"
      :server-items-length="total"
      sort-by="date"
      :sort-desc="true"
      search-title="Найти категорию"
      :search-fields="searchFields"
      :loading="updating"
      @update:table-options="tableUpdated"
    >
      <template #actions>
        <v-btn @click="openCreateDialog()">Добавить категорию</v-btn>
      </template>

      <template v-slot:[`item.name`]="{item}">
        {{ item.name }}
        <div class="category-id">ID: {{ item.categoryId }}</div>
      </template>
      <template v-slot:[`item.created`]="{item}">
        {{ item.createdOn | formatDateTimeRus }}
      </template>
      <template v-slot:[`item.actions`]="{item}">
        <v-icon class="mr-2" @click="openEditDialog(item)">mdi-pencil</v-icon>
      </template>
    </DataTable>

    <CategoryEditorDialog
      v-if="categoryEditorDialogShow"
      :save-func="afterSave"
      :object-id="categoryId"
      :object="category"
      @closed="categoryEditorDialogShow = false"
    />
    
    <v-dialog v-model="dialog.opened" width="720">
      <v-card>
        <v-card-title class="headline">{{ dialog.title }}</v-card-title>
        <v-card-text v-html="dialog.text">
        </v-card-text>
        <v-card-actions>
          <v-btn @click="hideError">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
import CategoryEditorDialog from './CategoryEditorDialog.vue';
import marketApiInitializer from '@/api/marketApi';

export default {
  name: 'CategoriesView',
  components: { CategoryEditorDialog },

  setup() {
    const marketingProgramStore = useMarketingProgramStore()
    return { marketingProgramStore }
  },

  computed: {
    mp() {
      if (this.marketingProgramStore.isInitialized === false)
        return null;

      return this.marketingProgramStore;
    },

    marketApi() {
      if (!this.mp)
        return null;

      return marketApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
    },
  },

  data: () => ({
    dialog: {
      opened: false,
      title: undefined,
      text: undefined
    },
    filtersHash: Date.now(),
    headers: [
      { value: 'name', text: 'Название', align: 'start', sortable: true },
      { value: 'order', text: 'Номер', align: 'start', sortable: true },
      { value: 'created', text: 'Создан', align: 'start', width: 160, sortable: true },
      { value: 'actions', text: 'Действия', align: 'center', width: 120, sortable: false },
    ],
    items: [],
    total: 0,
    searchFields: [
      { key: 'name', label: 'Наименование' },
    ],
    updating: false,
    categoryId: undefined,
    category: undefined,
    categoryEditorDialogShow: false,
    categoryFromTempalteDialogShow: false,
  }),

  methods: {
    async tableUpdated(pagination, sorting, search) {
      await this.getCategories(pagination, sorting, search);
    },

    async getCategories(pagination, sorting, search) {
      if (!this.marketApi) return;

      this.updating = true;

      try {
        const response = await this.marketApi.getCategories(
          pagination,
          sorting,
          search,
        );

        const {values, total} = response.data;

        this.total = total;
        this.items = values;
      } finally {
        this.updating = false;
      }
    },

    afterSave(model) {
      if (model.success)
        this.filtersHash = Date.now();
      else
        this.showError('Ошибка при создании', this.getAfterSaveText(model.error));
    },

    getAfterSaveText(error) {
        const e = error;
        if (!e) return 'Произошла ошибка';
        if (e.code === 409) return '';
        return `Уже есть категория с таким названием`;
    },

    openCreateDialog() {
      this.categoryId = undefined;
      this.category = undefined;
      this.categoryEditorDialogShow = true;
    },

    openEditDialog(category) {
      this.categoryId = category.categoryId;
      this.category = category;
      this.categoryEditorDialogShow = true;
    },

    showError(title, text) {
      this.dialog.title = title;
      this.dialog.text = text;
      this.dialog.opened = true;
    },

    hideError() {
      this.dialog.title = undefined;
      this.dialog.text = undefined;
      this.dialog.opened = false;
    },

    goToCategories() {
      this.$router.push('/categories');
    }
  },
}
</script>

<style scoped>
.category-id {
  color: #999;
  font-size: 80%;
}
</style>