<template>
  <div>
    <DataTable
      controller="inRoute"
      :headers="headers"
      :items="items"
      :server-items-length="total"
      sort-by="date"
      :sort-desc="true"
      search-title="Найти номинацию"
      :search-fields="searchFields"
      :loading="updating"
      @update:table-options="tableUpdated"
      @click:row="tableRowClicked"
    >
      <template #actions>
        <v-btn large @click="goToCreateNomination" class="ml-3">
          <v-icon left>mdi-plus-circle</v-icon>
            Создать номинацию
        </v-btn>
        <v-btn large @click="nominationFromTempalteDialogShow = true" class="ml-3">Добавить из каталога</v-btn>
      </template>

      <template v-slot:[`item.title`]="{item}">
        {{ item.title }}
        <div class="nomination-id">ID: {{ item.nominationId }}</div>
      </template>
      <template v-slot:[`item.startDate`]="{item}">
        {{ item.startDate | formatDate }}
      </template>
      <template v-slot:[`item.finishDate`]="{item}">
        {{ item.finishDate | formatDate }}
      </template>
      <template v-slot:[`item.settings`]="{item}">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="#2196F3"
              v-bind="attrs"
              v-on="on">mdi-information</v-icon>
          </template>
          <v-list>
            <v-list-item dense>
              <v-list-item-title>Пол: {{ nominationGender(item) }}</v-list-item-title>
            </v-list-item>
            <v-list-item dense>
              <v-list-item-title>Параметры: {{ item.settings.calculationSettings.coefficients }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </DataTable>

    <NominationFromTempalteDialog
      v-if="nominationFromTempalteDialogShow"
      :save-func="createNominationFromTemplate"
      @closed="nominationFromTempalteDialogShow = false"
    />
    <v-dialog v-model="dialog.opened" width="720">
      <v-card>
        <v-card-title class="headline">{{ dialog.title }}</v-card-title>
        <v-card-text v-html="dialog.text">
        </v-card-text>
        <v-card-actions>
          <v-btn @click="closeDialog">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { uuid } from "vue-uuid";
import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
import ratingsApiInitializer from '@/api/ratingsApi';
import NominationFromTempalteDialog from './NominationFromTempalteDialog.vue';

export default {
  name: 'NominationsView',
  components: { NominationFromTempalteDialog },

  setup() {
    const marketingProgramStore = useMarketingProgramStore()
    return { marketingProgramStore }
  },

  data: () => ({
    dialog: {
      opened: false,
      title: undefined,
      text: undefined
    },
    headers: [
      { value: 'title', text: 'Название', align: 'start', sortable: true },
      { value: 'startDate', text: 'Начало', align: 'end', sortable: true },
      { value: 'finishDate', text: 'Окончание', align: 'end', sortable: true },
      { value: 'settings', text: 'Параметры', align: 'end', sortable: false },
    ],
    items: [],
    total: 0,
    searchFields: [
      { key: 'title', label: 'Название' },
    ],
    updating: false,
    nominationEditorDialogShow: false,
    nominationFromTempalteDialogShow: false,
  }),

  computed: {
    mp() {
      if (this.marketingProgramStore.isInitialized === false)
        return null;

      return this.marketingProgramStore;
    },

    ratingsApi() {
      if (!this.mp)
        return null;

      return ratingsApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
    },
  },

  methods: {
    nominationGender(item) {
      return item.settings?.participantFilter ? item.settings?.participantFilter.Gender : null
    },
    async tableUpdated(pagination, sorting, search) {
      await this.getNominations(pagination, sorting, search);
    },

    async getNominations(pagination, sorting, search) {
      if (!this.ratingsApi) return;

      this.updating = true;

      try {
        const response = await this.ratingsApi.getNominations(
          pagination,
          sorting,
          search,
        );

        const {values, total} = response.data;

        this.total = total;
        this.items = values;
      } finally {
        this.updating = false;
      }
    },

    tableRowClicked(nomination) {
      this.goToNomination(nomination.nominationId);
    },

    goToNominations() {
      this.$router.push('/nominations');
    },

    goToNomination(nominationId) {
      this.$router.push(`/nomination/${nominationId}`);
    },

    goToCreateNomination() {
      this.$router.push(`/nomination/`);
    },

    async createNominationFromTemplate(model) {
      for (let i = 0; i < model.length; i++) {
        const nominationId = uuid.v4();
        const request = {
          nominationTemplateId: model[i],
          nominationId: nominationId,
        };
        await this.ratingsApi.createNominationFromTemplate(nominationId, request);
      }
      this.goToNominations();
    },

    async inactivateNomination(nomination) {
      this.updating = true;

      try {
        const response = await this.ratingsApi.inactivateNomination(nomination.nominationId);
        if (response.status === 204) {
          this.filtersHash = Date.now();
          this.updating = false;
        } else {
          const e = response.error;
          const errorText = !e
            ? 'Произошла ошибка'
            : `Код статуса ответа: ${e.status}`
              + `<br>Код: ${e.code}`
              + `<br>Ошибка: ${e.title}`
              + `<br>Подробности: ${e.detail}`;

          this.openDialog('Ошибка при удалении номинации', errorText); 
          this.updating = false;
        }
      } catch {
        this.updating = false;
      }
    },

    openDialog(title, text) {
      this.dialog.title = title;
      this.dialog.text = text;
      this.dialog.opened = true;
    },

    closeDialog() {
      this.dialog.opened = false;
    },

    getType(type) {
      switch (type) {
        case 'Disposable': return { text: 'Одноразовое', icon: 'mdi-autorenew-off', color: '' };
        case 'Reusable': return { text: 'Многоразовое', icon: 'mdi-autorenew', color: 'success' };
        default: return { text: 'undefined' };
      }
    }
  },
}
</script>

<style scoped>
.nomination-id {
  color: #999;
  font-size: 80%;
}
</style>