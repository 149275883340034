<template>
  <div>
    <DataTable
      controller="inRoute"
      :headers="headers"
      :items="items"
      :server-items-length="total"
      sort-by="date"
      :sort-desc="true"
      :search-fields="searchFields"
      :loading="updating"
      @update:table-options="tableUpdated"
      @click:row="tableRowClicked"
    >
      <template v-slot:[`item.name`]="{item}">
        {{ item.lastName }} {{ item.firstName }}
      </template>
      <template v-slot:[`item.emailAddress`]="{item}">
        {{ item.emailAddress }}
      </template>
      <template v-slot:[`item.createdOn`]="{item}">
        {{ item.createdOn | formatDate }}
      </template>
    </DataTable>
  </div>
</template>

<script>
import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
import scountApiInitializer from '@/api/scountApi';

export default {
  name: 'ParticipantsList',
  components: { },

  setup() {
    const marketingProgramStore = useMarketingProgramStore()
    return { marketingProgramStore }
  },

  data: () => ({
    headers: [
      { value: 'name', text: 'Имя', align: 'start', sortable: false },
      { value: 'emailAddress', text: 'Email', align: 'start', sortable: false },
      { value: 'createdOn', text: 'Создан', align: 'end', sortable: true },
    ],
    items: [],
    total: 0,
    searchFields: [
      { key: 'lastName', label: 'Фамилия' },
      { key: 'firstName', label: 'Имя' },
      { key: 'emailAddress', label: 'Email' },
    ],
    updating: false,
  }),

  computed: {
    mp() {
      if (this.marketingProgramStore.isInitialized === false)
        return null;

      return this.marketingProgramStore;
    },

    scountApi() {
      if (!this.mp)
        return null;

      return scountApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
    },
  },

  methods: {
    async tableUpdated(pagination, sorting, search) {
      await this.getParticipants(pagination, sorting, search);
    },

    async getParticipants(pagination, sorting, search) {
      if (!this.scountApi) return;

      this.updating = true;

      try {
        const response = await this.scountApi.getParticipants(
          pagination,
          sorting,
          search,
        );

        const {values, total} = response.data;

        this.total = total;
        this.items = values;
      } finally {
        this.updating = false;
      }
    },

    tableRowClicked(participant) {
      this.goToParticipant(participant.participantId);
    },

    goToParticipant(participantId) {
      this.$router.push(`/participants/${participantId}`);
    },
  },
}
</script>
