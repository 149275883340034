<template>
    <div>
      <div class="mb-6">
        <h2 class="cmn__h1 mb-5">Активное ядро аудитории</h2>
        <v-row>
          <v-col>
            <v-text-field
              label="Начало отчетного периода"
              v-model="activeCoreParams.startDate"
              :rules="validation.date"
              hide-details="auto"
              append-outer-icon="mdi-calendar-blank"
              placeholder="ДД.ММ.ГГГГ"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Конец отчетного периода"
              v-model="activeCoreParams.endDate"
              :rules="validation.date"
              hide-details="auto"
              append-outer-icon="mdi-calendar-blank"
              placeholder="ДД.ММ.ГГГГ"
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="d-flex align-end">
            <v-btn large @click="activeCoreShow" class="ml-3">Показать</v-btn>
            <v-btn large @click="activeCoreCsv" class="ml-3">
                <v-icon left>
                    mdi-content-save
                </v-icon>
                Скачать в CSV</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <DataTable v-if="activeCoreTableShow"
              :key="activeCoreFiltersHash"
              :headers="activeCoreHeaders"
              :items="activeCoreItems"
              :loading="activeCoreUpdating"
              :hide-default-footer="true"
              @update:table-options="activeCoreTableUpdated"
            >
              <template v-slot:[`item.count`]="{item}">
                {{ item.count }}
                <span class="count-added" v-if="item.countAdded">({{ item.countAdded > 0 ? `+${item.countAdded}` : item.countAdded }})</span>
              </template>
            </DataTable>
          </v-col>
        </v-row>
      </div>
    </div>
  </template>
  
  <script>
  import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
  import scountApiInitializer from '@/api/scountApi';
  import ratingsApiInitializer from '@/api/ratingsApi';
  import Moment from 'moment'
  
  export default {
    name: 'AnaliticsView',
  
    setup() {
      const marketingProgramStore = useMarketingProgramStore()
      return { marketingProgramStore }
    },
  
    data: () => ({
      validation: {
        date: [
          value => !value || /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[012])\.(19|20)\d\d$/i.test(value) || 'Некорректная дата. Формат ДД.ММ.ГГГГ',
        ],
      },
  
      analiticsCommonHeaders: [
        { value: 'name', text: 'Общая статистика', align: 'start', sortable: false },
        { value: 'count', text: '', align: 'start', sortable: false },
      ],
      analiticsCommonUpdating: false,
      analiticsCommonItems: undefined,
      analiticsCommonFiltersHash: Date.now(),
  
      analiticsHeaders: [
        { value: 'nominationName', text: 'Статистика по наминациям', align: 'start', sortable: false },
        { value: 'count', text: 'Всего участников', align: 'start', sortable: false },
      ],
      analiticsTotal: 0,
      analiticsUpdating: false,
      analiticsParams: {
        startDate: undefined,
        endDate: undefined,
      },
      analiticsItems: undefined,
      analiticsFiltersHash: Date.now(),
      analiticsTableShow: false,
  
      newParticipantsHeaders: [
        { value: 'lastName', text: 'Фамилия', align: 'start', sortable: false },
        { value: 'firstName', text: 'Имя', align: 'start', sortable: false },
        { value: 'emailAddress', text: 'Email', align: 'start', sortable: false },
        { value: 'registration', text: 'Регистрация', align: 'start', sortable: false },
        { value: 'birthday', text: 'Д.рождения', align: 'start', sortable: false },
        { value: 'gender', text: 'Пол', align: 'start', sortable: false },
        { value: 'organization', text: 'Организация', align: 'start', sortable: false },
        { value: 'homeAddress', text: 'Адрес', align: 'start', sortable: false },
        { value: 'unionMemberTicket', text: 'Номер', align: 'start', sortable: false },
        { value: 'team', text: 'Команда', align: 'start', sortable: false },
      ],
      newParticipantsTotal: 0,
      newParticipantsUpdating: false,
      newParticipantsParams: {
        startDate: undefined,
        endDate: undefined,
      },
      newParticipantsItems: undefined,
      newParticipantsFiltersHash: Date.now(),
      newParticipantsTableShow: false,
      
      activeCoreHeaders: [
        { value: 'name', text: 'Ядро аудитории', align: 'start', sortable: false },
        { value: 'count', text: '', align: 'start', sortable: false },
      ],
      activeCoreTotal: 0,
      activeCoreUpdating: false,
      activeCoreParams: {
        startDate: undefined,
        endDate: undefined,
      },
      activeCoreItems: undefined,
      activeCoreFiltersHash: Date.now(),
      activeCoreTableShow: false,
      
      ativityAnaliticsExerciseHeaders: [
        { value: 'name', text: 'Выполнено заданий', align: 'start', sortable: false },
        { value: 'count', text: '', align: 'start', sortable: false },
      ],
      ativityAnaliticsActivityHeaders: [
        { value: 'name', text: 'Принято тренировок', align: 'start', sortable: false },
        { value: 'count', text: '', align: 'start', sortable: false },
      ],
      ativityAnaliticsTotal: 0,
      ativityAnaliticsUpdating: false,
      ativityAnaliticsParams: {
        startDate: undefined,
        endDate: undefined,
      },
      ativityAnaliticsItems: undefined,
      ativityAnaliticsExerciseItems: undefined,
      ativityAnaliticsActivityItems: undefined,
      ativityAnaliticsFiltersHash: Date.now(),
      ativityAnaliticsTableShow: false,
    }),
  
    computed: {
      mp() {
        if (this.marketingProgramStore.isInitialized === false)
          return null;
  
        return this.marketingProgramStore;
      },
  
      scountApi() {
        if (!this.mp)
          return null;
  
        return scountApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
      },
  
      ratingsApi() {
        if (!this.mp)
          return null;
  
        return ratingsApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
      },
    },
  
    methods: {
      async analiticsShow() {
        this.analiticsTableShow = true;
        this.analiticsFiltersHash = Date.now();
        this.analiticsCommonFiltersHash = Date.now();
      },
  
      async analiticsCommonTableUpdated(pagination, sorting, search) {
        if (!this.scountApi) return;
  
        this.analiticsCommonUpdating = true;
  
        try {
          const response = await this.scountApi.getAnalitics(
            pagination,
            sorting,
            search,
            this.analiticsParams.startDate ? Moment(this.analiticsParams.startDate, 'DD.MM.YYYY').toDate() : null,
            this.analiticsParams.endDate ? Moment(this.analiticsParams.endDate, 'DD.MM.YYYY').toDate() : null,
          );
  
          const d = response.data.common;
  
          this.analiticsCommonItems = [
            { name: 'Начисленные баллы', count: d.gainedPoints.count },
            { name: 'Остаток по баллам', count: d.pointBalance.count, countAdded: d.pointBalance.countAdded },
            { name: 'Сколько человек подключили трекер', count: d.countTrackerConnected.count },
            { name: 'Сколько человек тренируется (фоновые+задания)', count: d.countTrainingsParticipants.count },
            { name: 'Сколько человек берут только задания', count: d.countOnlyExercises.count },
            { name: 'Количество женщин-участников', count: d.countFemale.count, countAdded: d.countFemale.countAdded },
            { name: 'Количество мужчин-участников', count: d.countMale.count, countAdded: d.countMale.countAdded },
          ];
        } finally {
          this.analiticsCommonUpdating = false;
        }
      },
  
      async analiticsTableUpdated(pagination, sorting, search) {
        await this.getAnalitics(pagination, sorting, search);
      },
  
      async getAnalitics(pagination, sorting, search) {
        if (!this.ratingsApi) return;
  
        this.analiticsUpdating = true;
  
        try {
          const response = await this.ratingsApi.getAnaliticsByNominations(
            pagination,
            sorting,
            search,
            this.analiticsParams.startDate ? Moment(this.analiticsParams.startDate, 'DD.MM.YYYY').toDate() : null,
            this.analiticsParams.endDate ? Moment(this.analiticsParams.endDate, 'DD.MM.YYYY').toDate() : null,
          );
  
          const {values, total} = response.data;
  
          this.analiticsTotal = total;
          this.analiticsItems = values;
        } finally {
          this.analiticsUpdating = false;
        }
      },
  
      async analiticsCsv() {
        this.analiticsCsvPart1();
        this.analiticsCsvPart2();
      },
  
      async analiticsCsvPart1() {
        const response = await this.scountApi.getAnaliticsCsv(
          this.analiticsParams.startDate ? Moment(this.analiticsParams.startDate, 'DD.MM.YYYY').toDate() : null,
          this.analiticsParams.endDate ? Moment(this.analiticsParams.endDate, 'DD.MM.YYYY').toDate() : null
        );
  
        const fileUrl = window.URL.createObjectURL(response.data);
  
        const anchor = document.createElement("a");
        anchor.download = decodeURI(response.request.getResponseHeader("X-Data-FileName")).replace(/\+/g, " ");
        anchor.href = fileUrl;
        anchor.target = "_blank";
        anchor.click();
  
        anchor.remove();
      },
  
      async analiticsCsvPart2() {
        const response = await this.ratingsApi.getAnaliticsByNominationsCsv(
          this.analiticsParams.startDate ? Moment(this.analiticsParams.startDate, 'DD.MM.YYYY').toDate() : null,
          this.analiticsParams.endDate ? Moment(this.analiticsParams.endDate, 'DD.MM.YYYY').toDate() : null
        );
  
        const fileUrl = window.URL.createObjectURL(response.data);
  
        const anchor = document.createElement("a");
        anchor.download = decodeURI(response.request.getResponseHeader("X-Data-FileName")).replace(/\+/g, " ");
        anchor.href = fileUrl;
        anchor.target = "_blank";
        anchor.click();
  
        anchor.remove();
      },
  
      newParticipantsShow() {
        this.newParticipantsTableShow = true;
        this.newParticipantsFiltersHash = Date.now();
      },
  
      async newParticipantsTableUpdated(pagination, sorting, search) {
        await this.getNewParticipants(pagination, sorting, search);
      },
  
      async getNewParticipants(pagination, sorting, search) {
        if (!this.scountApi) return;
  
        this.newParticipantsUpdating = true;
  
        try {
          const response = await this.scountApi.getNewParticipants(
            pagination,
            sorting,
            search,
            this.newParticipantsParams.startDate ? Moment(this.newParticipantsParams.startDate, 'DD.MM.YYYY').toDate() : null,
            this.newParticipantsParams.endDate ? Moment(this.newParticipantsParams.endDate, 'DD.MM.YYYY').toDate() : null,
          );
  
          const {values, total} = response.data;
  
          this.newParticipantsTotal = total;
          this.newParticipantsItems = values;
        } finally {
          this.newParticipantsUpdating = false;
        }
      },
  
      async newParticipantsCsv() {
        const response = await this.scountApi.getNewParticipantsCsv(
          this.newParticipantsParams.startDate ? Moment(this.newParticipantsParams.startDate, 'DD.MM.YYYY').toDate() : null,
          this.newParticipantsParams.endDate ? Moment(this.newParticipantsParams.endDate, 'DD.MM.YYYY').toDate() : null
        );
  
        const fileUrl = window.URL.createObjectURL(response.data);
  
        const anchor = document.createElement("a");
        anchor.download = decodeURI(response.request.getResponseHeader("X-Data-FileName")).replace(/\+/g, " ");
        anchor.href = fileUrl;
        anchor.target = "_blank";
        anchor.click();
  
        anchor.remove();
      },
      
  
      activeCoreShow() {
        this.activeCoreTableShow = true;
        this.activeCoreFiltersHash = Date.now();
      },
  
      async activeCoreTableUpdated(pagination, sorting, search) {
        await this.getActiveCore(pagination, sorting, search);
      },
  
      async getActiveCore(pagination, sorting, search) {
        if (!this.scountApi) return;
  
        this.activeCoreUpdating = true;
  
        try {
          const response = await this.scountApi.getActiveCore(
            pagination,
            sorting,
            search,
            this.activeCoreParams.startDate ? Moment(this.activeCoreParams.startDate, 'DD.MM.YYYY').toDate() : null,
            this.activeCoreParams.endDate ? Moment(this.activeCoreParams.endDate, 'DD.MM.YYYY').toDate() : null,
          );
  
          const d = response.data.common;
  
          this.activeCoreItems = [
            { name: 'Выполнили задания', count: d.completedTasks.count },
            { name: 'Выполнили фоновые и покупали', count: d.completedBackAndBuy.count },
            { name: 'Начислено баллов', count: d.pointsAccrued.count },
            { name: 'Потрачено баллов', count: d.pointsSpent.count },
            { name: 'Покупок', count: d.purchases.count },
          ];
        } finally {
          this.activeCoreUpdating = false;
        }
      },
  
      async activeCoreCsv() {
        const response = await this.scountApi.getActiveCoreCsv(
          this.activeCoreParams.startDate ? Moment(this.activeCoreParams.startDate, 'DD.MM.YYYY').toDate() : null,
          this.activeCoreParams.endDate ? Moment(this.activeCoreParams.endDate, 'DD.MM.YYYY').toDate() : null
        );
  
        const fileUrl = window.URL.createObjectURL(response.data);
  
        const anchor = document.createElement("a");
        anchor.download = decodeURI(response.request.getResponseHeader("X-Data-FileName")).replace(/\+/g, " ");
        anchor.href = fileUrl;
        anchor.target = "_blank";
        anchor.click();
  
        anchor.remove();
      },
      
  
      ativityAnaliticsShow() {
        this.ativityAnaliticsTableShow = true;
        this.ativityAnaliticsFiltersHash = Date.now();
      },
  
      async ativityAnaliticsTableUpdated(pagination, sorting, search) {
        await this.getAtivityAnalitics(pagination, sorting, search);
      },
  
      async getAtivityAnalitics(pagination, sorting, search) {
        if (!this.scountApi) return;
  
        this.ativityAnaliticsUpdating = true;
  
        try {
          const response = await this.scountApi.getAtivityAnalitics(
            pagination,
            sorting,
            search,
            this.ativityAnaliticsParams.startDate ? Moment(this.ativityAnaliticsParams.startDate, 'DD.MM.YYYY').toDate() : null,
            this.ativityAnaliticsParams.endDate ? Moment(this.ativityAnaliticsParams.endDate, 'DD.MM.YYYY').toDate() : null,
          );
  
          const d = response.data.common;
  
          this.ativityAnaliticsExerciseItems = [
            { name: 'Тренировочных', count: d.exerciseSportActivity.count },
            { name: 'Тестов', count: d.exerciseQuiz.count },
            { name: 'Составных', count: d.exerciseComposite.count },
            { name: 'Прочих заданий', count: d.exerciseOther.count },
            { name: 'Итого', count: d.exerciseTotal.count },
          ];
  
          this.ativityAnaliticsActivityItems = [
            { name: 'По ходьбе', count: d.activityWalking.count },
            { name: 'По бегу', count: d.activityRunning.count },
            { name: 'По плаванию', count: d.activitySwimming.count },
            { name: 'По вело', count: d.activityCycling.count },
            { name: 'По лыжам', count: d.activitySkiing.count },
          ];
        } finally {
          this.ativityAnaliticsUpdating = false;
        }
      },
  
      async ativityAnaliticsCsv() {
        const response = await this.scountApi.getAtivityAnaliticsCsv(
          this.ativityAnaliticsParams.startDate ? Moment(this.ativityAnaliticsParams.startDate, 'DD.MM.YYYY').toDate() : null,
          this.ativityAnaliticsParams.endDate ? Moment(this.ativityAnaliticsParams.endDate, 'DD.MM.YYYY').toDate() : null
        );
  
        const fileUrl = window.URL.createObjectURL(response.data);
  
        const anchor = document.createElement("a");
        anchor.download = decodeURI(response.request.getResponseHeader("X-Data-FileName")).replace(/\+/g, " ");
        anchor.href = fileUrl;
        anchor.target = "_blank";
        anchor.click();
  
        anchor.remove();
      },
    }
  }
  </script>