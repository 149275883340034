<template>
  <div>
    <router-link :to="{ name: 'Exercise', params: { exerciseId: attributes.ExerciseId }}">
      {{attributes.ExerciseTitle}}
    </router-link>

    <v-chip
      class="ma-2"
      label
      small
      :color="getComplexityColor(attributes.ExerciseComplexity)[0]"
      :text-color="getComplexityColor(attributes.ExerciseComplexity)[1]"
    >
      x{{attributes.ExerciseComplexity}}
    </v-chip>

    <v-tooltip v-if="attributes.ExerciseIsAutomaticAssign" left>
      <template v-slot:activator="{ on, attrs }">
        <span
          v-bind="attrs"
          v-on="on"
        >
          <v-icon class="mr-2" :color="(/^[tT]rue$/).test(attributes.ExerciseIsAutomaticAssign) ? 'success' : ''">
            mdi-refresh-auto
          </v-icon>
        </span>
      </template>
      <span>Авто</span>
    </v-tooltip>
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <span
          v-bind="attrs"
          v-on="on"
        >
          <v-icon class="mr-2" :color="getExerciseType(attributes.ExerciseType).color">
            {{ getExerciseType(attributes.ExerciseType).icon }}
          </v-icon>
        </span>
      </template>
      <span>{{ getExerciseType(attributes.ExerciseType).text }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'OperationInfoParticipantExerciseCreated',

  props: {
    attributes: {
      type: Object,
    },
  },

  methods: {
    getComplexityColor(complexity) {
      if (complexity < 1.3)
        return ['#93de64', 'black'];

      if (complexity < 1.9)
        return ['#ffd666', 'black'];

      return ['#fa4646', 'white'];
    },

    getExerciseType(exerciseType) {
      switch (exerciseType) {
        case 'Disposable': return { text: 'Одноразовое', icon: 'mdi-autorenew-off', color: '' };
        case 'Reusable': return { text: 'Многоразовое', icon: 'mdi-autorenew', color: 'success' };
        case 'Regular': return { text: 'Регулярное', icon: 'mdi-regex', color: 'blue' };
        default: return { text: 'undefined' };
      }
    },
  }
}
</script>
