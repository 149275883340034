var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('h1',{staticClass:"px-4"},[_vm._v("Активность участника")])]),_c('v-col',{staticClass:"buttons"},[(!_vm.kl.isOrgMp)?_c('div',{staticClass:"buttons-block"},[(_vm.activeParticipantTrackerId && _vm.participantState === 'Active')?_c('button',{staticClass:"buttons__edit",on:{"click":function($event){_vm.participantTrainingDialogShow = true}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v("Тренировка")],1):_vm._e(),(!_vm.activeParticipantTrackerId || _vm.participantState !== 'Active')?_c('button',{staticClass:"buttons__edit",attrs:{"disabled":""}},[_vm._v("Трекер не подключен")]):_vm._e(),_c('button',{staticClass:"buttons__edit",on:{"click":function($event){_vm.participantExercisesDialogShow = true}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v("Задание")],1)]):_vm._e()])],1),_c('DataTable',{key:_vm.filtersHash,attrs:{"controller":"inRoute","headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.total,"sort-by":"operationDate","sort-desc":true,"loading":_vm.updating},on:{"update:table-options":_vm.tableUpdated,"click:row":_vm.tableRowClicked},scopedSlots:_vm._u([{key:"filters",fn:function(){return _vm._l((_vm.filterTypeValues),function(type){return _c('v-chip',{key:("filter-type-" + (type.value)),staticClass:"ma-2",attrs:{"label":"","small":"","color":_vm.filters.operationTypes.includes(type.value) ? 'primary' : ''},on:{"click":function($event){return _vm.toggleTypeFilter(type.value)}}},[_vm._v(" "+_vm._s(type.text)+" ")])})},proxy:true},{key:"item.operationType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getOperationTypeText(item))+" ")]}},{key:"item.operationInfo",fn:function(ref){
var item = ref.item;
return [_c(("OperationInfo" + (_vm.getOperationInfoComponentName(item))),{tag:"component",attrs:{"operationSourceId":item.operationSourceId,"sourceId":item.sourceId,"attributes":item.attributes,"refresh":_vm.refreshData}})]}},{key:"item.operationDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTimeRus")(item.operationDate))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!_vm.kl.isOrgMp && item.operationType === 'ParticipantExercise' && item.attributes.StateOperation === 'Created' && item.attributes.WasRejected !== 'True')?_c('v-icon',{style:({ opacity: _vm.cancelExerciseLoading ? .5 : 'inherit' }),attrs:{"hint":"Отменить"},on:{"click":function($event){return _vm.cancelExercise(item)}}},[_vm._v("mdi-close-box")]):_vm._e(),_vm._v("   ")]}}],null,true)}),(_vm.participantTrainingDialogShow)?_c('ParticipantTrainingEditorDialog',{attrs:{"tracker-id":_vm.activeParticipantTrackerId,"save-func":_vm.saveParticipantTraining},on:{"closed":function($event){_vm.participantTrainingDialogShow = false}}}):_vm._e(),(_vm.participantExercisesDialogShow)?_c('ParticipantExercisesEditorDialog',{attrs:{"participant-id":_vm.participantId,"save-func":_vm.saveParticipantExercises},on:{"closed":function($event){_vm.participantExercisesDialogShow = false}}}):_vm._e(),_c('v-dialog',{attrs:{"width":"720"},model:{value:(_vm.dialog.opened),callback:function ($$v) {_vm.$set(_vm.dialog, "opened", $$v)},expression:"dialog.opened"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(_vm._s(_vm.dialog.title))]),_c('v-card-text',{domProps:{"innerHTML":_vm._s(_vm.dialog.text)}}),_c('v-card-actions',[_c('v-btn',{on:{"click":_vm.closeDialog}},[_vm._v("Закрыть")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }