<template>

  <div v-if="nominationTemplate">
    <v-form ref="form" lazy-validation>
      <v-row>
        <v-col class="nominations__h1">
          {{nominationTemplate.title ? nominationTemplate.title : 'Новый шаблон номинации'}}
        </v-col>
        <v-col class="d-flex">
          <div class="ml-auto">
            <v-btn v-if="!isEdit" @click="editNominationTemplate" class="mr-2">Изменить</v-btn>
            <v-btn v-else @click="saveNominationTemplate" class="mr-2">Сохранить</v-btn>
            <v-btn v-if="nominationTemplateId" @click="openDeleteConfirm" class="ml-3"><v-icon>mdi-delete</v-icon></v-btn>
          </div>
        </v-col>
      </v-row>

      <br />

      <v-text-field
        label="Название номинации"
        v-model="nominationTemplate.title"
        :rules="validation.title"
        hide-details="auto"
        class="mb-5"
        :disabled="!isEdit"
      ></v-text-field>

      <v-text-field
        label="Правила"
        v-model="nominationTemplate.rules"
        :rules="validation.rules"
        hide-details="auto"
        class="mb-5"
        :disabled="!isEdit"
      ></v-text-field>

      <v-text-field
        label="Награды"
        v-model="nominationTemplate.awards"
        :rules="validation.awards"
        hide-details="auto"
        class="mb-5"
        :disabled="!isEdit"
      ></v-text-field>

      <v-row>
        <v-col>
          &nbsp;
        </v-col>
        <v-col>
          &nbsp;
        </v-col>
        <v-col>
          <v-select
            label="Пол"
            v-model="nominationTemplate.settings.participantFilter.Gender"
            :items="genders"
            item-text="name"
            item-value="value"
            :disabled="!isEdit"
          ></v-select>
        </v-col>
        <v-col>
          <v-select
            label="Возрастная группа"
            v-model="nominationTemplate.settings.participantFilter.Age"
            :items="ages"
            item-text="name"
            item-value="value"
            :disabled="!isEdit"
          ></v-select>
        </v-col>
      </v-row>
      
      <v-row>
          <v-col>
            <v-select
              v-model="coefficientsFor"
              :items="coefficientsForItems"
              item-text="name"
              item-value="value"
              :disabled="!isEdit"
            ></v-select>
            <v-row class="mb-5" v-if="coefficientsFor === 'SportActivity'">
              <v-col>
                <v-text-field
                  label="Бег"
                  v-model="nominationTemplate.settings.calculationSettings.coefficients.Running"
                  :rules="validation.running"
                  hide-details="auto"
                  :disabled="!isEdit"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Ходьба"
                  v-model="nominationTemplate.settings.calculationSettings.coefficients.Walking"
                  :rules="validation.walking"
                  hide-details="auto"
                  :disabled="!isEdit"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Велосипед"
                  v-model="nominationTemplate.settings.calculationSettings.coefficients.Cycling"
                  :rules="validation.cycling"
                  hide-details="auto"
                  :disabled="!isEdit"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Плавание"
                  v-model="nominationTemplate.settings.calculationSettings.coefficients.Swimming"
                  :rules="validation.swimming"
                  hide-details="auto"
                  :disabled="!isEdit"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Лыжи"
                  v-model="nominationTemplate.settings.calculationSettings.coefficients.Skiing"
                  :rules="validation.skiing"
                  hide-details="auto"
                  :disabled="!isEdit"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mb-5" v-if="coefficientsFor === 'Quiz'">
              <v-col cols="4">
                <v-text-field
                  label="Очков за правильный ответ"
                  v-model="nominationTemplate.settings.calculationSettings.coefficients.Quiz"
                  :rules="validation.quiz"
                  hide-details="auto"
                  :disabled="!isEdit"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mb-5" v-if="coefficientsFor === 'Wellbeing'">
              <v-col cols="4">
                <v-text-field
                  label="Очков за выполнение"
                  v-model="nominationTemplate.settings.calculationSettings.coefficients.Wellbeing"
                  :rules="validation.wellbeing"
                  hide-details="auto"
                  :disabled="!isEdit"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mb-5" v-if="coefficientsFor === 'GeoLocation'">
              <v-col cols="4">
                <v-text-field
                  label="Очков за выполнение"
                  v-model="nominationTemplate.settings.calculationSettings.coefficients.GeoLocation"
                  :rules="validation.geoLocation"
                  hide-details="auto"
                  :disabled="!isEdit"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>          
        </v-row>

      <v-row v-if="true">
        <v-col>
          <ImageSelector
            :preview-url="nominationTemplate.images ? nominationTemplate.images.mobile : null"
            :read-only="!isEdit"
            :save-image-func="changeMobileImage"
            accept=".png"
            title="ПК (192x192)"
            :subTitle="mobileImageSubTitle"
            @show-preview="showImagePreview('mobile')"
          ></ImageSelector>
        </v-col>
        <v-col>
          <ImageSelector
            :preview-url="nominationTemplate.images ? nominationTemplate.images.desktop : null"
            :read-only="!isEdit"
            :save-image-func="changeDesktopImage"
            accept=".png"
            title="Мобильный (128x128)"
            :subTitle="desktopImageSubTitle"
            @show-preview="showImagePreview('desktop')"
          ></ImageSelector>
        </v-col>
      </v-row>
    </v-form>

    <v-dialog v-model="dialog.opened" width="720">
      <v-card>
        <v-card-title class="headline">{{ dialog.title }}</v-card-title>
        <v-card-text v-html="dialog.text">
        </v-card-text>
        <v-card-actions>
          <v-btn @click="closeDialog">Закрыть</v-btn>
          <v-btn v-if="dialog.actionDialog" @click="dialog.actionDialog" color="primary">{{ dialog.actionName }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { uuid } from "vue-uuid";
import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
import ratingsApiInitializer from '@/api/ratingsApi';

export default {
  name: 'NominationTemplateView',

  setup() {
    const marketingProgramStore = useMarketingProgramStore()
    return { marketingProgramStore }
  },

  data: () => ({
    nominationTemplate: undefined,
    desktopImageFile: null,
    desktopImageSubTitle: null,
    mobileImageFile: null,
    mobileImageSubTitle: null,
    loading: false,
    isEdit: false,
    dialog: {
      opened: false,
      title: undefined,
      text: undefined,
      actionDialog: undefined,
      actionName: undefined
    },
    coefficientsFor: 'SportActivity',
    coefficientsForItems: [
      {value: 'SportActivity', name: 'Для спортивных заданий'},
      {value: 'Quiz', name: 'Для квизов'},
      {value: 'Wellbeing', name: 'Для wellbeing заданий'},
      {value: 'GeoLocation', name: 'Для заданий с геопривязкой'},
    ],
    genders: [
      {value: null, name: 'Любой'},
      {value: 'Male', name: 'Мужской'},
      {value: 'Female', name: 'Женский'},
    ],
    ages: [
      {value: null, name: 'Любая'},
      {value: '14-17', name: '14-17'},
      {value: '18-34', name: '18-34'},
      {value: '35-39', name: '35-39'},
      {value: '35-44', name: '35-44'},
      {value: '40-44', name: '40-44'},
      {value: '45-49', name: '45-49'},
      {value: '45-54', name: '45-54'},
      {value: '50-54', name: '50-54'},
      {value: '55-59', name: '55-59'},
      {value: '55-64', name: '55-64'},
      {value: '60-64', name: '60-64'},
      {value: '65-69', name: '65-69'},
      {value: '65+', name: '65+'},
      {value: '70+', name: '70+'},
    ],
    validation: {
      title: [
        value => !!value || 'Обязательное поле.',
        value => (value && value.length <= 64) || 'Не более 64 символов.',
      ],
      rules: [
        value => !!value || 'Обязательное поле.',
        value => (value && value.length <= 512) || 'Не более 512 символов.',
      ],
      awards: [
        value => !!value || 'Обязательное поле.',
        value => (value && value.length <= 512) || 'Не более 512 символов.',
      ],
      running: [
        value => !value || /^\d+(\.\d{1,2})?$/i.test(value) || 'Значение должно отсутствовать или быть числом не меньше 0',
      ],
      walking: [
        value => !value || /^\d+(\.\d{1,2})?$/i.test(value) || 'Значение должно отсутствовать или быть числом не меньше 0',
      ],
      cycling: [
        value => !value || /^\d+(\.\d{1,2})?$/i.test(value) || 'Значение должно отсутствовать или быть числом не меньше 0',
      ],
      swimming: [
        value => !value || /^\d+(\.\d{1,2})?$/i.test(value) || 'Значение должно отсутствовать или быть числом не меньше 0',
      ],
      skiing: [
        value => !value || /^\d+(\.\d{1,2})?$/i.test(value) || 'Значение должно отсутствовать или быть числом не меньше 0',
      ],
      quiz: [
        value => !value || /^\d+(\.\d{1,2})?$/i.test(value) || 'Значение должно отсутствовать или быть числом не меньше 0',
      ]
    },
  }),

  computed: {
    nominationTemplateId() {
      return this.$route.params.nominationTemplateId;
    },

    mp() {
      if (this.marketingProgramStore.isInitialized === false)
        return null;

      return this.marketingProgramStore;
    },

    ratingsApi() {
      if (!this.mp)
        return null;

      return ratingsApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
    },
  },

  methods: {
    async getNominationTemplate() {
      const response = await this.ratingsApi.getNominationTemplate(this.nominationTemplateId);
      this.nominationTemplate = response.data;

      if (!this.nominationTemplate.settings.participantFilter.Age)
        this.nominationTemplate.settings.participantFilter.Age = null;

      if (!this.nominationTemplate.settings.participantFilter.Gender)
        this.nominationTemplate.settings.participantFilter.Gender = null;

      this.isEdit = false;
    },

    editNominationTemplate() {
      this.isEdit = true;
    },

    async saveNominationTemplate() {
      const valid = await this.$refs.form.validate();

      if (!valid)
        return;

      if (!this.nominationTemplate.settings.participantFilter.Age)
        this.nominationTemplate.settings.participantFilter.Age = undefined;

      if (!this.nominationTemplate.settings.participantFilter.Gender)
        this.nominationTemplate.settings.participantFilter.Gender = undefined;
              
      if (this.nominationTemplateId) {
        await this.changeNominationTemplate();
        await this.getNominationTemplate();
      } else {
        await this.createNominationTemplate();
      }      

      this.isEdit = false;
    },

    async createNominationTemplate() {
      var nominationTemplateId = uuid.v4();

      const request = {
        title: this.nominationTemplate.title,
        rules: this.nominationTemplate.rules,
        awards: this.nominationTemplate.awards,
        participantFilter: this.nominationTemplate.settings.participantFilter,
        coefficients: this.nominationTemplate.settings.calculationSettings.coefficients
      };

      await this.ratingsApi.createNominationTemplate(nominationTemplateId, request);

      if (this.desktopImageFile) {
        const destResp = await this.ratingsApi.uploadNominationTemplateDesktopImage(nominationTemplateId, this.desktopImageFile);
        if (destResp.success) {
          if (this.mobileImageFile) {
            const mobileResp = await this.ratingsApi.uploadNominationTemplateMobileImage(nominationTemplateId, this.mobileImageFile);
            if (!mobileResp.success) {
              this.showError('Ошибка при загрузке изображения mobile', this.getUploadAfterSaveText(mobileResp));
            }
          }
        } else {
          this.showError('Ошибка при загрузке изображения desktop', this.getUploadAfterSaveText(destResp));
        }
      }

      this.goToNominationTemplate(nominationTemplateId);
    },

    getUploadAfterSaveText(resp) {
        const e = resp.error;
        if (!e) return 'Произошла ошибка';
        if (e.status === 413) return 'Фото не было загружено. Превышен максимальный размер изображения';
        if (e.status === 400 && e.responseData && e.responseData.errors && e.responseData.errors.File) {
          const errFile = e.responseData.errors.File;
          const max = errFile.find(x => x.startsWith('Maximum '));
          if (max)
            return 'Фото не было загружено. Превышен максимальный размер изображения.';
        }

        return e.detail;
    },

    showError(title, text) {
      this.dialog.title = title;
      this.dialog.text = text;
      this.dialog.opened = true;
    },

    hideError() {
      this.dialog.title = undefined;
      this.dialog.text = undefined;
      this.dialog.opened = false;
    },

    async changeNominationTemplate() {
      const request = {
        title: this.nominationTemplate.title,
        rules: this.nominationTemplate.rules,
        awards: this.nominationTemplate.awards,
        participantFilter: this.nominationTemplate.settings.participantFilter,
        coefficients: this.nominationTemplate.settings.calculationSettings.coefficients
      };

      await this.ratingsApi.changeNominationTemplate(this.nominationTemplateId, request);      

      if (this.desktopImageFile) {
        const destResp = await this.ratingsApi.uploadNominationTemplateDesktopImage(this.nominationTemplateId, this.desktopImageFile);
        if (destResp.success) {
          if (this.mobileImageFile) {
            const mobileResp = await this.ratingsApi.uploadNominationTemplateMobileImage(this.nominationTemplateId, this.mobileImageFile);
            if (!mobileResp.success) {
              this.showError('Ошибка при загрузке изображения mobile', this.getUploadAfterSaveText(mobileResp));
            }
          }
        } else {
          this.showError('Ошибка при загрузке изображения desktop', this.getUploadAfterSaveText(destResp));
        }
      }
    },

    async deleteNominationTemplate() {
      this.loading = true;

      try {
        const response = await this.ratingsApi.deleteNominationTemplate(this.nominationTemplateId);
        if (response.status === 204) {
          this.goToNominationTemplates();
        } else {
          const e = response.error;
          const errorText = !e
            ? 'Произошла ошибка'
            : `Код статуса ответа: ${e.status}`
              + `<br>Код: ${e.code}`
              + `<br>Ошибка: ${e.title}`
              + `<br>Подробности: ${e.detail}`;

          this.openDialog('Ошибка при удалении шаблона', errorText); 
          this.loading = false;
        }
      } catch (ex) {
        this.openDialog('Ошибка при удалении шаблона.', 'Произошла ошибка.');
        this.loading = false;
      }
    },

    goToNominationTemplates() {
      this.$router.push(`/nomination-templates`);
    },

    goToNominationTemplate(nominationId) {
      this.$router.push(`/nomination-template/${nominationId}`);
    },

    changeDesktopImage(file) {
      this.desktopImageFile = file;
      this.desktopImageSubTitle = `${file.name} (${file.size / 1024}KB)`;
    },

    changeMobileImage(file) {
      this.mobileImageFile = file;
      this.mobileImageSubTitle = `${file.name} (${file.size / 1024}KB)`;
    },

    showImagePreview(type) {
      this.showPreview = true;
      this.showPreviewType = type;
    },

    openDeleteConfirm() {
      this.openDialog('Подтверждение удаления',
        'Вы уверены, что хотите удалить?',
        () => { 
          this.deleteNominationTemplate();
          this.closeDialog();
        },
        'Удалить')
    },

    openDialog(title, text, actionDialog, actionName) {
      this.dialog.title = title;
      this.dialog.text = text;
      this.dialog.actionDialog = actionDialog;
      this.dialog.actionName = actionName;
      this.dialog.opened = true;
    },

    closeDialog() {
      this.dialog.title = undefined;
      this.dialog.text = undefined;
      this.dialog.actionDialog = undefined;
      this.dialog.actionName = undefined;
      this.dialog.opened = false;
    },
  },

  async mounted() {
    this.loading = true;
    try {

      if (this.nominationTemplateId)
        await this.getNominationTemplate();
      else {
        this.nominationTemplate = {
          title: undefined,
          rules: undefined,
          awards: undefined,
          settings: {
            participantFilter: {
              Gender: undefined,
              Age: undefined
            },
            calculationSettings: {
              coefficients: {
                Running: undefined,
                Walking: undefined,
                Cycling: undefined,
                Swimming: undefined,
                Skiing: undefined,
                Quiz: undefined
              }
            }
          }
        };

        this.isEdit = true;
      }
    } finally {
      this.loading = false;
    }
  },
}
</script>

<style lang="scss">
.complexity {
  display: inline-block;
  margin-left: 8px;
  padding: 0 4px;
  border-radius: 4px;
  &.__low {
    background-color: #93de64;
  }
  &.__medium {
    background-color: #ffd666;
  }
  &.__high {
    background-color: #fa4646;
    color: white;
  }
}
</style>

<style lang="scss" scoped>
  @import '../../assets/global';
</style>
