<template>
  <div class="mt-6">
    <DataTable
      controller="inRoute"
      :headers="headers"
      :items="items"
      :server-items-length="total"
      sort-by="order"
      :sort-desc="false"
      search-title="Найти по названию"
      :search-fields="searchFields"
      :loading="updating"
      @update:table-options="tableUpdated"
      @click:row = "clickThemeRow"
    >
      <template #actions>
        <v-btn large class="mr-4" @click="themeDialogOpen">
          <v-icon left>mdi-plus-circle</v-icon>
          Создать тему</v-btn>
        <!-- <v-btn large>прикрепить FAQ к МП</v-btn> -->
      </template>
      <template v-slot:[`item.actions`]="{item}">
        <v-icon class="mr-2" @click.stop = "editThemeDialog(item)">mdi-pencil</v-icon>
        <v-icon class="mr-2" @click.stop = "confirmDialogOpen(item)">mdi-trash-can-outline</v-icon>
      </template>
    </DataTable>
    <v-dialog
      v-model="themeDialog"
      max-width="720"
    >
      <v-card >
        <v-card-title class="modal__title text-h6 mb-8">
         {{ themeItemId ? 'Редактировать тему' : 'Создать тему' }}
        </v-card-title>
        <v-form 
          class="px-6"
          lazy-validation
        >
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="themeData.title"
                :rules="validation.title"
                label="Название темы"
                dense
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="themeData.order"
                :rules="validation.order"
                label="Приоритет"
                dense
              />
            </v-col>
          </v-row>
        </v-form>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn
            @click="themeDialog = false"
          >
            Отмена
          </v-btn>
          <v-btn
            color="warning"
            @click="saveSlideDialog"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="confirmDialog"
      max-width="500"
    >
      <v-card>
        <v-card-title class="text-h6">
          Вы уверены что хотите удалить тему?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="confirmDialog = false"
          >
            Отменить
          </v-btn>

          <v-btn
            color="red"
            dark
            @click="deleteThemeItem"
          >
            Удалить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <notifications :duration="5000" position="bottom right"/>
  </div>
</template>

<script>
import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
import onboardingApiInitializer from '@/api/onboardingApi';
import { uuid } from "vue-uuid";

export default {
  name: 'FAQView',
  components: {  },
  setup() {
        const marketingProgramStore = useMarketingProgramStore()
        return { marketingProgramStore }
      },
      computed: {
        mp() {
          if (this.marketingProgramStore.isInitialized === false)
            return null;
          return this.marketingProgramStore;
        },
        onboardingApi() {
          if (!this.mp)
            return null;
          return onboardingApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
        },
      },

  data: () => ({
    headers: [
      { value: 'title', text: 'Название темы', align: 'left', sortable: true },
      { value: 'questionsCount', text: 'Вопросов', align: 'center', width: 160, sortable: true },
      { value: 'order', text: 'Приоритет', align: 'center', width: 160, sortable: true },
      { value: 'actions', text: 'Действия', align: 'center', width: 120, sortable: false },
    ],
    validation: {        
      title: [
        value => !!value || 'Обязательное поле.',
      ],
      order: [
        value => !!value || 'Обязательное поле.',
        value => !value || /^\d+(\.\d{1,2})?$/i.test(value) || 'Значение должно быть числом не меньше 0',
      ],
    },
    items: [],
    total: 0,
    searchFields: [
      { key: 'title', label: 'Название' },
    ],
    themeData: {
      title: "",
      order: 0,
    },
    themeItemId: null,
    updating: false,
    themeDialog: false,
    confirmDialog: false,
  }),

  methods: {
    async tableUpdated(pagination, sorting, search) {
      await this.getFAQItems(pagination, sorting, search);
    },

    async getFAQItems(pagination, sorting, search) {
      if (!this.onboardingApi) return;

      this.updating = true;

      try {
        const response = await this.onboardingApi.getFAQThemes(
          pagination,
          sorting,
          search,
        );

        const {values, total} = response.data;

        this.total = total;
        this.items = values;
      } finally {
        this.updating = false;
      }
    },
    clickThemeRow (e) {
      console.log(e);
      const themeId = e.id
      this.$router.push({ name: 'FAQItemView', params: { themeId: themeId}});
    },
    confirmDialogOpen (item) {
      this.confirmDialog = true
      this.themeItemId = item.id;
    },
    deleteThemeItem () {
      console.log(this.themeItemId);
      this.confirmDialog = false;
        this.onboardingApi.deleteFAQTheme(this.themeItemId)
        .then((response) => {
            if(response.success) {
              this.$notify({type: 'success', text: 'Слайд успешно удален'})
              this.getFAQItems();
            } else {
              this.$notify({ type: 'error', text: `Слайд не удален. Ошибка: ${response.error.detail}` });
            }
          })
          .catch((error) => {
            console.error('Error updating slide:', error);
          });
    },
    themeDialogOpen() {
      this.themeDialog = true
      this.themeItemId = null
      this.themeData = {}
    },
    editThemeDialog (item) {
      this.themeDialog = true;
      this.themeData.title = item.title;
      this.themeData.order = item.order;
      this.themeItemId = item.id;
    },
    saveSlideDialog () {
      if (this.themeItemId) {
        this.updateTheme();
      } else {
        this.createTheme();
      }
    },
    createTheme() {
      var teamId = uuid.v4();
        const model = {
          title: this.themeData.title,
          order: +this.themeData.order,
          faqThemeId: teamId
        };
        this.onboardingApi
          .createFAQTheme(model)
          .then((response) => {
            if(response.success) {
              this.themeData = {};
              this.themeDialog = false;
              this.$notify({ type: 'success', text: 'Слайд успешно создан' });
              this.getFAQItems();
            } else {
                this.$notify({ type: 'error', text: `Слайд не создан. Ошибка: ${response.error.detail}` });
              }
          })
          .catch((error) => {
            console.error('Error creating slide:', error);
          });
    },
    updateTheme() {
      const model = {
        title: this.themeData.title,
        order: +this.themeData.order,
      };
      this.onboardingApi
        .updateFAQTheme(this.themeItemId, model)
        .then((response) => {
          if(response.success) {
            this.themeData = {};
            this.themeDialog = false;
            this.$notify({ type: 'success', text: 'Слайд успешно обновлен' });
            this.getFAQItems();
          } else {
            this.$notify({ type: 'error', text: `Слайд не обновлен. Ошибка: ${response.error.detail}` });
          }
        })
        .catch((error) => {
          console.error('Error updating slide:', error);
        });
    },
  },
}
</script>

<style lang="scss" scoped>

</style>