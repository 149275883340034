<template>
  <div>
    <router-link :to="{ name: 'Exercise', params: { exerciseId: attributes.ExerciseId }}">
      {{attributes.ExerciseTitle}}
    </router-link>

    <v-chip
      class="ma-2"
      label
      small
      :color="getComplexityColor(attributes.ExerciseComplexity)[0]"
      :text-color="getComplexityColor(attributes.ExerciseComplexity)[1]"
    >
      x{{attributes.ExerciseComplexity}}
    </v-chip>
  </div>
</template>

<script>
export default {
  name: 'OperationInfoParticipantExerciseRejected',

  props: {
    attributes: {
      type: Object,
    },
  },

  methods: {
    getComplexityColor(complexity) {
      if (complexity < 1.3)
        return ['#93de64', 'black'];

      if (complexity < 1.9)
        return ['#ffd666', 'black'];

      return ['#fa4646', 'white'];
    },
  }
}
</script>
