import {initializeApi} from "@/api/base";

function withMarketingProgram(marketingProgramId) {
  const api = initializeApi("/api/ratings", {'X-MarketingProgram-Id': marketingProgramId});

  // todo: debug host
  // const debugHost = 'http://localhost:5006';

  async function getNominations(pagination, sorting, search) {
    const response = await api
      .get(`/api/v1/nominations`, {
        params: {
          searchString: search,
          ...pagination,
          ...sorting,
        }
      })
      .then(response => {
        return {status: response.status, data: response.data};
      });

    return response;
  }

  async function getNomination(nominationId) {
    const response = await api
      .get(`/api/v1/nominations/${nominationId}`)
      .then(response => {
        return {status: response.status, data: response.data};
      });
  
    return response;
  }

  async function createNomination(nominationId, model) {
    const response = await api
      .post(`/api/v1/nominations`, {nominationId, ...model})
      .then(response => {
        return {status: response.status, data: response.data};
      });

    return response;
  }

  async function changeNomination(nominationId, model) {
    const response = await api
      .patch(`/api/v1/nominations/${nominationId}`, model)
      .then(response => {
        return {status: response.status, data: response.data};
      });

    return response;
  }

  async function changeNominationSettings(nominationId, model) {
    const response = await api
      .patch(`/api/v1/nominations/${nominationId}/settings`, model)
      .then(response => {
        return {status: response.status, data: response.data};
      });

    return response;
  }

  async function uploadNominationDesktopImage(nominationId, file) {
    const formData = new FormData();
    formData.append("file", file, file.name.toLowerCase());

    const response = await api
        .patch(`/api/v1/nominations/${nominationId}/images/desktop`, formData)
        .then(response => {
            return { success: true, status: response.status, data: response.data };
        })
        .catch((error) => {
          return {success: false, error};
        });

    return response;
  }

  async function uploadNominationMobileImage(nominationId, file) {
    const formData = new FormData();
    formData.append("file", file, file.name.toLowerCase());

    const response = await api
        .patch(`/api/v1/nominations/${nominationId}/images/mobile`, formData)
        .then(response => {
            return { success: true, status: response.status, data: response.data };
        })
        .catch((error) => {
          return {success: false, error};
        });

    return response;
  }

  async function activateNomination(nominationId) {
    const response = await api
      .put(`/api/v1/nominations/${nominationId}/activate`)
      .then(response => {
        return {status: response.status, data: response.data};
      });

    return response;
  }

  async function inactivateNomination(nominationId) {
    const response = await api
      .put(`/api/v1/nominations/${nominationId}/inactivate`)
      .then(response => {
        return {status: response.status, data: response.data};
      });

    return response;
  }

  async function createNominationFromTemplate(nominationId, model) {
    const response = await api
      .post(`/api/v1/nominations/from-template`, {nominationId, ...model})
      .then(response => {
        return {status: response.status, data: response.data};
      });

    return response;
  }

  async function getNominationTemplates(pagination, sorting, search) {
    const response = await api
      .get(`/api/v1/nominationtemplates`, {
        params: {
          searchString: search,
          ...pagination,
          ...sorting,
        }
      })
      .then(response => {
        return {status: response.status, data: response.data};
      });

    return response;
  }

  async function getNominationTemplate(nominationTemplateId) {
    const response = await api
      .get(`/api/v1/nominationtemplates/${nominationTemplateId}`)
      .then(response => {
        return {status: response.status, data: response.data};
      });
  
    return response;
  }

  async function createNominationTemplate(nominationTemplateId, model) {
    const response = await api
      .post(`/api/v1/nominationtemplates`, {nominationTemplateId, ...model})
      .then(response => {
        return {status: response.status, data: response.data};
      });

    return response;
  }

  async function changeNominationTemplate(nominationTemplateId, model) {
    const response = await api
      .patch(`/api/v1/nominationtemplates/${nominationTemplateId}`, model)
      .then(response => {
        return {status: response.status, data: response.data};
      });

    return response;
  }

  async function deleteNominationTemplate(nominationTemplateId) {
    const response = await api
      .delete(`/api/v1/nominationtemplates/${nominationTemplateId}`)
      .then(response => {
        return {status: response.status, data: response.data};
      });

    return response;
  }

  async function uploadNominationTemplateDesktopImage(nominationTemplateId, file) {
    const formData = new FormData();
    formData.append("file", file, file.name.toLowerCase());

    const response = await api
        .patch(`/api/v1/nominationtemplates/${nominationTemplateId}/images/desktop`, formData)
        .then(response => {
            return { success: true, status: response.status, data: response.data };
        })
        .catch((error) => {
          return {success: false, error};
        });

    return response;
  }

  async function uploadNominationTemplateMobileImage(nominationTemplateId, file) {
    const formData = new FormData();
    formData.append("file", file, file.name.toLowerCase());

    const response = await api
        .patch(`/api/v1/nominationtemplates/${nominationTemplateId}/images/mobile`, formData)
        .then(response => {
            return { success: true, status: response.status, data: response.data };
        })
        .catch((error) => {
          return {success: false, error};
        });

    return response;
  }

  async function getNominationExercises(model) {
    const response = await api
      .get(`/api/v1/nominations-exercises/${model.nominationId}/exercises`, {
        params: {
          searchString: model.search,
          ...model.pagination,
          ...model.sorting,
        }
      })
      .then(response => {
        return {status: response.status, data: response.data};
      });

    return response;
  }

  async function getNominationParticipants(model) {
    const response = await api
      .get(`/api/v1/nominations-participants/${model.nominationId}`, {
        params: {
          nameOrEmailAddress: model.nameOrEmailAddress,
          startDate: model.startDate,
          endDate: model.endDate,
          ...model.pagination,
          ...model.sorting,
        }
      })
      .then(response => {
        return {status: response.status, data: response.data};
      });

    return response;
  }

  async function getNominationParticipantsCsv(model) {
    const response = await api
      .get(`/api/v1/nominations-participants/${model.nominationId}/csv`, {
        responseType: 'blob',
        params: {
          nameOrEmailAddress: model.nameOrEmailAddress,
          startDate: model.startDate,
          endDate: model.endDate,
          ...model.pagination,
          ...model.sorting,
        }
      })
      .then(response => {
        return {status: response.status, data: response.data, request: response.request};
      });

    return response;
  }

  async function getAvailableExercises(nominationId, pagination, sorting, search, state) {
    const response = await api
      .get(`/api/v1/nominations-exercises/${nominationId}/available-exercises`, {
        params: {
          state: state,
          searchString: search,
          ...pagination,
          ...sorting,
        }
      })
      .then(response => {
        return {status: response.status, data: response.data};
      });

    return response;
  }

  async function assignExercisesToNominations(model) {
    const response = await api
      .post(`/api/v1/nominations-exercises`, {...model})
      .then(response => {
        return {status: response.status, data: response.data};
      });

    return response;
  }

  async function deleteNominationExercise(nominationExerciseId) {
    const response = await api
      .delete(`/api/v1/nominations-exercises/${nominationExerciseId}`)
      .then(response => {
        return {status: response.status, data: response.data};
      });

    return response;
  }

  async function getAnaliticsByNominations(pagination, sorting, search, dateFrom, dateTo) {
    const response = await api
      .get(`/api/v1/analitics`, {
        params: {
          searchString: search,
          ...pagination,
          ...sorting,
          dateFrom: dateFrom,
          dateTo: dateTo,
        }
      })
      .then(response => {
        return {status: response.status, data: response.data};
      });

    return response;
  }

  async function getAnaliticsByNominationsCsv(dateFrom, dateTo) {
    const response = await api
      .get(`/api/v1/analitics/csv`, {
        responseType: 'blob',
        params: {
          dateFrom: dateFrom,
          dateTo: dateTo,
        }
      })
      .then(response => {
        return {status: response.status, data: response.data, request: response.request};
      });

    return response;
  }

  return {
    getNominations,
    getNomination,
    createNomination,
    changeNomination,
    changeNominationSettings,
    uploadNominationDesktopImage,
    uploadNominationMobileImage,
    activateNomination,
    inactivateNomination,
    createNominationFromTemplate,
    getNominationTemplates,
    getNominationTemplate,
    createNominationTemplate,
    changeNominationTemplate,
    deleteNominationTemplate,
    uploadNominationTemplateDesktopImage,
    uploadNominationTemplateMobileImage,
    getNominationExercises,
    getNominationParticipants,
    getNominationParticipantsCsv,
    getAvailableExercises,
    assignExercisesToNominations,
    deleteNominationExercise,
    getAnaliticsByNominations,
    getAnaliticsByNominationsCsv,
  };
}

export default {
  withMarketingProgram,
};
