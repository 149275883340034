import { defineStore } from 'pinia'

export const useKeycloakStore = defineStore('keycloakStore', {

  state: () => ({
    keycloak_: null,
    profile_: null,
    userInfo_: null,
  }),

  getters: {
    keycloak: (state) => state.keycloak_,
    profile: (state) => state.profile_,
    userId: (state) => state.profile_ ? state.profile_.id : '',
    username: (state) => state.profile_ ? state.profile_.username : '',
    groups: (state) => state.userInfo_ ? state.userInfo_.groups : [],
    roles: (state) => state.userInfo_ && state.userInfo_.realm_access ? state.userInfo_.realm_access.roles : [],
    isOrgMp: (state) => (state.userInfo_ && state.userInfo_.realm_access ? state.userInfo_.realm_access.roles : []).indexOf('orgmp') > -1,
    isCapClub: (state) => (state.userInfo_ && state.userInfo_.realm_access ? state.userInfo_.realm_access.roles : []).indexOf('capclub') > -1,
    isSupplPromo: (state) => (state.userInfo_ && state.userInfo_.realm_access ? state.userInfo_.realm_access.roles : []).indexOf('supplierpromotion') > -1,
    isAnyMp: (state) => {
      const roles = state.userInfo_ && state.userInfo_.realm_access ? state.userInfo_.realm_access.roles : [];
      const isOrgMp = roles.indexOf('orgmp') > -1;
      const isCapClub = roles.indexOf('capclub') > -1;
      const isSupplPromo = roles.indexOf('supplierpromotion') > -1;
      return isOrgMp || isCapClub || isSupplPromo;
    },
    isSimpleMp: (state) => {
      const roles = state.userInfo_ && state.userInfo_.realm_access ? state.userInfo_.realm_access.roles : [];
      const isOrgMp = roles.indexOf('orgmp') > -1;
      const isCapClub = roles.indexOf('capclub') > -1;
      const isSupplPromo = roles.indexOf('supplierpromotion') > -1;
      if (!isOrgMp && !isCapClub && !isSupplPromo)
        return;

      const groups = state.userInfo_ ? state.userInfo_.groups : [];
      return groups && groups.length === 1;
    },
    isOrgMpOneMp: (state) => {
      const isOrgMp = (state.userInfo_ && state.userInfo_.realm_access ? state.userInfo_.realm_access.roles : []).indexOf('orgmp') > -1;
      if (!isOrgMp) return false;

      const isCapClub = (state.userInfo_ && state.userInfo_.realm_access ? state.userInfo_.realm_access.roles : []).indexOf('capclub') > -1;
      if (!isCapClub) return false;

      const groups = state.userInfo_ ? state.userInfo_.groups : [];
      return groups && groups.length === 1;
    },
    filterByCreatorId: (state) => {
      const roles = state.userInfo_ && state.userInfo_.realm_access ? state.userInfo_.realm_access.roles : [];
      const isSupplPromo = roles.indexOf('supplierpromotion') > -1;
      return isSupplPromo && state.profile_ ? state.profile_.id : '';
    }
  },

  actions: {
    async setKeycloak(keycloak) {
      this.keycloak_ = keycloak;
    },

    async setProfile(profile) {
      this.profile_ = profile;
    },

    async setUserInfo(userInfo) {
      this.userInfo_ = userInfo;
    },
  },

})