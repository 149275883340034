var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"article__h1 mb-6 pl-2"},[_vm._v("Кнопка подписки на группу в ВК")]),_c('v-form',{ref:"form",staticClass:"mb-6",attrs:{"lazy-validation":""}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"ID группы в ВК","rules":_vm.validation.externalId,"hide-details":"auto"},model:{value:(_vm.externalId),callback:function ($$v) {_vm.externalId=$$v},expression:"externalId"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{on:{"click":_vm.saveId}},[_vm._v("Сохранить")])],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"article__h2"},[_c('span',{staticClass:"pl-2"},[_vm._v("Статьи")])]),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"disabled":_vm.newArticleAdded},on:{"click":_vm.addArticle}},[_vm._v("Добавить статью")])],1)],1),_c('DataTable',{key:_vm.filtersHash,attrs:{"controller":"inRoute","headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.total,"loading":_vm.updating},on:{"update:table-options":_vm.tableUpdated},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"label":"Заголовок","rules":_vm.validation.articleTitle,"hide-details":"auto"},model:{value:(item.title),callback:function ($$v) {_vm.$set(item, "title", $$v)},expression:"item.title"}})]}},{key:"item.link",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"label":"Ссылка на статью","rules":_vm.validation.articleLink,"hide-details":"auto"},model:{value:(item.link),callback:function ($$v) {_vm.$set(item, "link", $$v)},expression:"item.link"}})]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"label":"Тип","items":_vm.types,"item-text":"name","item-value":"value","disabled":true},model:{value:(item.type),callback:function ($$v) {_vm.$set(item, "type", $$v)},expression:"item.type"}})]}},{key:"item.number",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"label":"№","hide-details":"auto"},model:{value:(item.order),callback:function ($$v) {_vm.$set(item, "order", $$v)},expression:"item.order"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex mt-auto mt-2"},[_c('v-radio-group',{model:{value:(item.isMain),callback:function ($$v) {_vm.$set(item, "isMain", $$v)},expression:"item.isMain"}},[_c('v-radio',{staticClass:"mr-4",attrs:{"label":"Топ","value":true,"readonly":true,"disabled":!item.articleId},on:{"click":function($event){return _vm.setMainClick(item)}}})],1),_c('v-btn',{staticClass:"mr-3",attrs:{"minWidth":"40","width":"40","disabled":item.state === 'Active' && !!item.articleId},on:{"click":function($event){return _vm.saveArticle(item)}}},[_c('v-icon',[_vm._v("mdi-floppy")])],1),_c('v-btn',{staticClass:"mr-4",attrs:{"minWidth":"40","width":"40","disabled":!item.articleId},on:{"click":function($event){return _vm.activateArticle(item)}}},[(item.state !== 'Active')?_c('v-icon',[_vm._v("mdi-play-circle")]):_vm._e(),(item.state === 'Active')?_c('v-icon',[_vm._v("mdi-eye-off")]):_vm._e()],1),_c('v-icon',{staticClass:"jsp-cp",attrs:{"disabled":!item.articleId},on:{"click":function($event){return _vm.deleteArticle(item)}}},[_vm._v("mdi-delete")])],1),(item.state === 'Active' && !!item.articleId)?_c('div',{staticClass:"mb-2"},[_c('v-icon',[_vm._v("mdi-alert-circle")]),_vm._v(" Для изменения статьи необходимо ее деактивировать. ")],1):_vm._e()]}}],null,true)}),_c('v-dialog',{attrs:{"width":"720"},model:{value:(_vm.dialog.opened),callback:function ($$v) {_vm.$set(_vm.dialog, "opened", $$v)},expression:"dialog.opened"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(_vm._s(_vm.dialog.title))]),_c('v-card-text',{domProps:{"innerHTML":_vm._s(_vm.dialog.text)}}),_c('v-card-actions',[_c('v-btn',{on:{"click":_vm.hideError}},[_vm._v("Закрыть")])],1)],1)],1),_c('v-overlay',{attrs:{"value":_vm.loading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }