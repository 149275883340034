<template>
  <div>
    {{attributes.ProductName}}

    <v-chip
      class="ma-2"
      label
      small
      color="red"
      text-color="white"
    >
      {{attributes.Amount}}
    </v-chip>

    <v-chip
      class="ma-2"
      label
      small
    >
      Промокод: {{attributes.PromocodeCode}}
    </v-chip>
  </div>
</template>

<script>
export default {
  name: 'OperationInfoPurchase',

  props: {
    attributes: {
      type: Object,
    },
  },
}
</script>
