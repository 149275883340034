<template>
  <div>
    <DataTable
      :key="filtersHash"
      controller="inRoute"
      :headers="headers"
      :items="items"
      :server-items-length="total"
      sort-by="date"
      :sort-desc="true"
      :loading="updating"
      @click:row="tableRowClicked"
      @update:table-options="tableUpdated"
    >
      <template #actions>
        <v-btn @click="openCreateDialog()" :disabled="items.length > 0">Добавить сообщество</v-btn>
      </template>

      <template v-slot:[`item.externalId`]="{item}">
        VK ID: {{ item.externalId }}
      </template>
    </DataTable>

    <CommunityEditorDialog
      v-if="communityEditorDialogShow"
      :save-func="afterSave"
      :object-id="communityId"
      :object="community"
      @closed="communityEditorDialogShow = false"
    />
    
    <v-dialog v-model="dialog.opened" width="720">
      <v-card>
        <v-card-title class="headline">{{ dialog.title }}</v-card-title>
        <v-card-text v-html="dialog.text">
        </v-card-text>
        <v-card-actions>
          <v-btn @click="hideError">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
import CommunityEditorDialog from './CommunityEditorDialog.vue';
import communityApiInitializer from '@/api/communityApi';

export default {
  name: 'CommunitiesView',
  components: { CommunityEditorDialog },

  setup() {
    const marketingProgramStore = useMarketingProgramStore()
    return { marketingProgramStore }
  },

  computed: {
    mp() {
      if (this.marketingProgramStore.isInitialized === false)
        return null;

      return this.marketingProgramStore;
    },

    communityApi() {
      if (!this.mp)
        return null;

      return communityApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
    },
  },

  data: () => ({
    dialog: {
      opened: false,
      title: undefined,
      text: undefined
    },
    filtersHash: Date.now(),
    headers: [
      { value: 'externalId', text: 'Название', align: 'start', sortable: true },
    ],
    items: [],
    total: 0,
    searchFields: [
      { key: 'name', label: 'Наименование' },
    ],
    updating: false,
    communityId: undefined,
    community: undefined,
    communityEditorDialogShow: false,
  }),

  methods: {
    async tableUpdated(pagination, sorting, search) {
      await this.getCommunities(pagination, sorting, search);
    },
    
    tableRowClicked(community) {
      this.goToCommunity(community.communityId)
    },

    async getCommunities(pagination, sorting, search) {
      if (!this.communityApi) return;

      this.updating = true;

      try {
        const response = await this.communityApi.getCommunities(
          pagination,
          sorting,
          search,
        );

        const {values, total} = response.data;

        this.total = total;
        this.items = values;
      } finally {
        this.updating = false;
      }
    },

    afterSave(model) {
      if (model.success)
        this.filtersHash = Date.now();
      else
        this.showError('Ошибка при создании сообщества', this.getAfterSaveText(model.error));
    },

    getAfterSaveText(error) {
        const e = error;
        if (!e) return 'Произошла ошибка';
        if (e.status === 409 && e.detail && e.detail.startsWith('The community with Marketing Program ID'))
          return 'Для текущей МП уже создано сообщество. Второе создать нельзя.';
        return e.detail;
    },

    openCreateDialog() {
      this.communityId = undefined;
      this.community = undefined;
      this.communityEditorDialogShow = true;
    },

    openEditDialog(community) {
      this.communityId = community.communityId;
      this.community = community;
      this.communityEditorDialogShow = true;
    },

    showError(title, text) {
      this.dialog.title = title;
      this.dialog.text = text;
      this.dialog.opened = true;
    },

    hideError() {
      this.dialog.title = undefined;
      this.dialog.text = undefined;
      this.dialog.opened = false;
    },

    goToCommunities() {
      this.$router.push('/communities');
    },

    goToCommunity(communityId) {
      this.$router.push(`/communities/${communityId}`);
    }
  },
}
</script>

<style scoped>
.community-id {
  color: #999;
  font-size: 80%;
}
</style>