import {initializeApi} from "@/api/base";

function withMarketingProgram(marketingProgramId) {
  const api = initializeApi("/api/community", {'X-MarketingProgram-Id': marketingProgramId});

  // todo: debug host
  // const debugHost = 'http://localhost:5006';

  async function getCommunities(pagination, sorting) {
    const response = await api
      .get(`/api/v1/communities`, {
        params: {
          ...pagination,
          ...sorting,
        }
      })
      .then(response => {
        return {status: response.status, data: response.data};
      });

    return response;
  }

  async function getCommunity(communityId) {
    const response = await api
      .get(`/api/v1/communities/${communityId}`)
      .then(response => {
        return {success: true, status: response.status, data: response.data};
      })
      .catch((error) => {
        return {success: false, error};
      });

    return response;
  }

  async function createCommunity(communityId, model) {
    const response = await api
      .post(`/api/v1/communities`, {communityId, ...model, marketingProgramId})
      .then(response => {
        return {success: true, status: response.status, data: response.data};
      })
      .catch((error) => {
        return {success: false, error};
      });

    return response;
  }

  async function updateCommunity(communityId, model) {
    const response = await api
      .put(`/api/v1/communities/${communityId}`, model)
      .then(response => {
        return {success: true, status: response.status, data: response.data};
      })
      .catch((error) => {
        return {success: false, error};
      });

    return response;
  }

  async function getArticles(communityId, pagination, sorting) {
    const response = await api
      .get(`/api/v1/communities/${communityId}/articles`, {
        params: {
          ...pagination,
          ...sorting,
        }
      })
      .then(response => {
        return {success: true, status: response.status, data: response.data};
      })
      .catch((error) => {
        return {success: false, error};
      });

    return response;
  }

  async function createArticle(communityId, model) {
    const response = await api
      .post(`/api/v1/communities/${communityId}/articles`, {communityId, ...model, marketingProgramId})
      .then(response => {
        return {success: true, status: response.status, data: response.data};
      })
      .catch((error) => {
        return {success: false, error};
      });

    return response;
  }

  async function updateArticle(communityId, articleId, model) {
    const response = await api
      .patch(`/api/v1/communities/${communityId}/articles/${articleId}`, model)
      .then(response => {
        return {success: true, status: response.status, data: response.data};
      })
      .catch((error) => {
        return {success: false, error};
      });

    return response;
  }

  async function deleteArticle(communityId, articleId) {
    const response = await api
      .delete(`/api/v1/communities/${communityId}/articles/${articleId}`)
      .then(response => {
        return {success: true, status: response.status, data: response.data};
      })
      .catch((error) => {
        return {success: false, error};
      });

    return response;
  }

  async function activeArticle(communityId, articleId) {
    const response = await api
      .post(`/api/v1/communities/${communityId}/articles/${articleId}/active`)
      .then(response => {
        return {success: true, status: response.status, data: response.data};
      })
      .catch((error) => {
        return {success: false, error};
      });

    return response;
  }

  async function inactiveArticle(communityId, articleId) {
    const response = await api
      .post(`/api/v1/communities/${communityId}/articles/${articleId}/inactive`)
      .then(response => {
        return {success: true, status: response.status, data: response.data};
      })
      .catch((error) => {
        return {success: false, error};
      });

    return response;
  }

  async function setMainArticle(communityId, articleId) {
    const response = await api
      .post(`/api/v1/communities/${communityId}/articles/${articleId}/main`)
      .then(response => {
        return {success: true, status: response.status, data: response.data};
      })
      .catch((error) => {
        return {success: false, error};
      });

    return response;
  }

  return {
    getCommunities,
    getCommunity,
    createCommunity,
    updateCommunity,
    getArticles,
    createArticle,
    updateArticle,
    deleteArticle,
    activeArticle,
    inactiveArticle,
    setMainArticle,
  };
}

export default {
  withMarketingProgram,
};
