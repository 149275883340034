import marketApi from '@/api/marketApiNoMp';

const state = {
  marketingPrograms: null,
  marketingProgramPromotion: null,
  marketingProgramPromotions: null,
  promotions: null,
  promotion: null,
  promotionId: null,
  partners: null,
  categories: null,
  total: 0,
  totalCategory: 0,
  totalPartner: 0,
  responseStatus: null,
  errorCode: null,
  errorDetail: null,
};

const getters = {
  marketingPrograms: () => state.marketingPrograms,
  marketingProgramPromotion: () => state.marketingProgramPromotion,
  marketingProgramPromotions: () => state.marketingProgramPromotions,
  promotions: () => state.promotions,
  promotion: () => state.promotion,
  promotionId: () => state.promotionId,
  partners: () => state.partners,
  categories: () => state.categories,
  total: () => state.total,
  totalCategory: () => state.totalCategory,
  totalPartner: () => state.totalPartner,
  responseStatus: () => state.responseStatus,
  errorCode: () => state.errorCode,
  errorDetail: () => state.errorDetail,
};

const actions = {
  async getPromotions({ commit }, { pagination, sorting, search, marketingProgramId, creatorId }) {
    try {
      const response = await marketApi.getPromotions({ pagination, sorting, search, marketingProgramId, creatorId });
      commit('setResponseStatus', response.status);
      if (response.status === 200) {
        const { total } = response.data;
        commit('setPromotions', response.data);
        commit('setTotal', total);
      } else {
        commit('setPromotions', null);
      }
    } catch (error) {
      commit('setPromotions', null);
      const status = error.response && error.response.status;
      commit('setResponseStatus', status || 0);
    }
  },

  async getPromotion({ commit }, promotionId) {
    return await marketApi.getPromotion({ promotionId })
      .then((response) => {
        console.log('response', response);
        
        commit('setResponseStatus', response.status);
        if (response.status === 200) {
          commit('setPromotion', response.data);
        } else {
          commit('setPromotion', null);
        }
      })
      .catch((error) => {
        commit('setPromotion', null);
        const status = error.response && error.response.status;
        commit('setResponseStatus', status || 0);
      });
  },

  async disablePromotion({ commit }, data) {
    try {
      const response = await marketApi.disablePromotion(data);
      commit('setResponseStatus', response.status);
      commit('setErrorCode', 0);
      commit('setErrorDetail', '');
    } catch (error) {
      const status = error.response && error.response.status;
      commit('setResponseStatus', status || 0);
      commit('setErrorCode', error.response && error.response.data ? error.response.data.code || 0 : 0);
      commit('setErrorDetail', error.response && error.response.data ? error.response.data.detail || '' : '');
    }
  },

  async enablePromotion({ commit }, data) {
    try {
      const response = await marketApi.enablePromotion(data);
      commit('setResponseStatus', response.status);
      commit('setErrorCode', 0);
      commit('setErrorDetail', '');
    } catch (error) {
      const status = error.response && error.response.status;
      commit('setResponseStatus', status || 0);
      commit('setErrorCode', error.response && error.response.data ? error.response.data.code || 0 : 0);
      commit('setErrorDetail', error.response && error.response.data ? error.response.data.detail || '' : '');
    }
  },

  async disableMarketingProgramPromotion({ commit }, data) {
    try {
      const response = await marketApi.disableMarketingProgramPromotion(data);
      commit('setResponseStatus', response.status);
      commit('setErrorCode', 0);
      commit('setErrorDetail', '');
    } catch (error) {
      const status = error.response && error.response.status;
      commit('setResponseStatus', status || 0);
      commit('setErrorCode', error.response && error.response.data ? error.response.data.code || 0 : 0);
      commit('setErrorDetail', error.response && error.response.data ? error.response.data.detail || '' : '');
    }
  },

  async enableMarketingProgramPromotion({ commit }, data) {
    try {
      const response = await marketApi.enableMarketingProgramPromotion(data);
      commit('setResponseStatus', response.status);
      commit('setErrorCode', 0);
      commit('setErrorDetail', '');
    } catch (error) {
      const status = error.response && error.response.status;
      commit('setResponseStatus', status || 0);
      commit('setErrorCode', error.response && error.response.data ? error.response.data.code || 0 : 0);
      commit('setErrorDetail', error.response && error.response.data ? error.response.data.detail || '' : '');
    }
  },

  async getPartners({ commit }, {pagination, sorting, search, creatorId}) {
    try {
      const response = await marketApi.getPartners({ pagination, sorting, search, creatorId });
      commit('setResponseStatus', response.status);
      if (response.status === 200) {
        const { values, total } = response.data;
        commit('setPartners', values);
        commit('setTotalPartners', total);

      } else {
        commit('setPartners', null);
      }
    } catch (error) {
      commit('setPartners', null);
      const status = error.response && error.response.status;
      commit('setResponseStatus', status || 0);
    }
  },

  async getCategories({ commit }, {pagination, sorting, search}) {
    try {
      const response = await marketApi.getCategories({ pagination, sorting, search });
      const { values, total } = response.data;
      commit('setResponseStatus', response.status);
      if (response.status === 200) {
        commit('setCategories', values);
        commit('setTotalCategory', total);
      } else {
        commit('setCategories', null);
      }
    } catch (error) {
      commit('setCategories', null);
      const status = error.response && error.response.status;
      commit('setResponseStatus', status || 0);
    }
  },

  async getMarketingPrograms({ commit }) {
    try {
      const response = await marketApi.getMarketingPrograms();
      commit('setResponseStatus', response.status);
      if (response.status === 200) {
        commit('setMarketingPrograms', response.data.values);
      } else {
        commit('setMarketingPrograms', null);
      }
    } catch (error) {
      commit('setMarketingPrograms', null);
      const status = error.response && error.response.status;
      commit('setResponseStatus', status || 0);
    }
  },

  async getMarketingProgramPromotion({ commit }, marketingProgramPromotionId) {
    try {
      const response = await marketApi.getMarketingProgramPromotion({ marketingProgramPromotionId });
      commit('setResponseStatus', response.status);
      if (response.status === 200) {
        commit('setMarketingProgramPromotion', response.data);
      } else {
        commit('setMarketingProgramPromotion', null);
      }
    } catch (error) {
      commit('setMarketingProgramPromotion', null);
      const status = error.response && error.response.status;
      commit('setResponseStatus', status || 0);
    }
  },

  async getMarketingProgramPromotions({ commit }, {pagination, sorting, search, promotionId }) {
    try {
      const response = await marketApi.getMarketingProgramPromotions({ pagination, sorting, search, promotionId });
      commit('setResponseStatus', response.status);
      if (response.status === 200) {
        const { values, total } = response.data;
        commit('setMarketingProgramPromotions', values);
        commit('setTotal', total);
      } else {
        commit('setMarketingProgramPromotions', null);
      }
    } catch (error) {
      commit('setMarketingProgramPromotions', null);
      const status = error.response && error.response.status;
      commit('setResponseStatus', status || 0);
    }
  },

  async createMarketingProgramPromotion({ commit }, data) {
    try {
      const response = await marketApi.createMarketingProgramPromotion(data);
      commit('setResponseStatus', response.status);
      commit('setErrorCode', 0);
      commit('setErrorDetail', '');
    } catch (error) {
      const status = error.response && error.response.status;
      commit('setResponseStatus', status || 0);
      commit('setErrorCode', error.response && error.response.data ? error.response.data.code || 0 : 0);
      commit('setErrorDetail', error.response && error.response.data ? error.response.data.detail || '' : '');
    }
  },

  async updateMarketingProgramPromotion({ commit }, data) {
    try {
      const response = await marketApi.updateMarketingProgramPromotion(data);
      commit('setResponseStatus', response.status);
      commit('setErrorCode', 0);
      commit('setErrorDetail', '');
    } catch (error) {
      const status = error.response && error.response.status;
      commit('setResponseStatus', status || 0);
      commit('setErrorCode', error.response && error.response.data ? error.response.data.code || 0 : 0);
      commit('setErrorDetail', error.response && error.response.data ? error.response.data.detail || '' : '');
    }
  },

  async createPromotion({ commit }, data) {
    try {
      const response = await marketApi.createPromotion(data);
      commit('setResponseStatus', response.status);
      commit('setErrorCode', 0);
      commit('setErrorDetail', '');
      commit('setPromotionId', response.status === 200 ? response.data : null);
      return response;
    } catch (error) {
      commit('setPromotionId', null);
      const status = error.response && error.response.status;
      commit('setResponseStatus', status || 0);
      commit('setErrorCode', error.response && error.response.data ? error.response.data.code || 0 : 0);
      commit('setErrorDetail', error.response && error.response.data ? error.response.data.detail || '' : '');
    }
  },

  async updatePromotion({ commit }, data) {
    try {
      const response = await marketApi.updatePromotion(data);
      commit('setResponseStatus', response.status);
      commit('setErrorCode', 0);
      commit('setErrorDetail', '');
      commit('setPromotionId', response.status === 200 ? response.data : null);
    } catch (error) {
      commit('setPromotionId', null);
      const status = error.response && error.response.status;
      commit('setResponseStatus', status || 0);
      commit('setErrorCode', error.response && error.response.data ? error.response.data.code || 0 : 0);
      commit('setErrorDetail', error.response && error.response.data ? error.response.data.detail || '' : '');
    }
  },

  async copyPromotion({ commit }, data) {
    try {
      const response = await marketApi.copyPromotion(data);
      commit('setResponseStatus', response.status);
      commit('setErrorCode', 0);
      commit('setErrorDetail', '');
      commit('setPromotionId', response.status === 200 ? response.data : null);
    } catch (error) {
      commit('setPromotionId', null);
      const status = error.response && error.response.status;
      commit('setResponseStatus', status || 0);
      commit('setErrorCode', error.response && error.response.data ? error.response.data.code || 0 : 0);
      commit('setErrorDetail', error.response && error.response.data ? error.response.data.detail || '' : '');
    }
  },

  async uploadPromotionDesktopImage({ commit }, data) {
    try {
      const response = await marketApi.uploadPromotionDesktopImage(data);
      commit('setResponseStatus', response.status);
      commit('setErrorCode', 0);
      commit('setErrorDetail', '');
    } catch (error) {
      const status = error.response && error.response.status;
      commit('setResponseStatus', status || 0);
      commit('setErrorCode', error.response && error.response.data ? error.response.data.code || 0 : 0);
      commit('setErrorDetail', error.response && error.response.data ? error.response.data.detail || '' : '');
    }
  },
  
  async uploadPromotionMobileImage({ commit }, data) {
    try {
      const response = await marketApi.uploadPromotionMobileImage(data);
      commit('setResponseStatus', response.status);
      commit('setErrorCode', 0);
      commit('setErrorDetail', '');
    } catch (error) {
      const status = error.response && error.response.status;
      commit('setResponseStatus', status || 0);
      commit('setErrorCode', error.response && error.response.data ? error.response.data.code || 0 : 0);
      commit('setErrorDetail', error.response && error.response.data ? error.response.data.detail || '' : '');
    }
  },
};

const mutations = {
  setMarketingPrograms(state, value) {
    state.marketingPrograms = value;
  },
  setMarketingProgramPromotion(state, value) {
    state.marketingProgramPromotion = value;
  },
  setMarketingProgramPromotions(state, value) {
    state.marketingProgramPromotions = value;
  },
  setPromotions(state, value) {
    state.promotions = value;
  },
  setPromotion(state, value) {
    state.promotion = value;
  },
  setPromotionId(state, value) {
    state.promotionId = value;
  },
  setPartners(state, value) {
    state.partners = value;
  },
  setCategories(state, value) {
    state.categories = value;
  },
  setTotal(state, value) {
    state.total = value;
  },
  setTotalCategory(state, value) {
    state.totalCategory = value;
  },
  setTotalPartners(state, value) {
    state.totalPartner = value;
  },
  setResponseStatus(state, value) {
    state.responseStatus = value;
  },
  setErrorCode(state, value) {
    state.errorCode = value;
  },
  setErrorDetail(state, value) {
    state.errorDetail = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
