<template>
  <div>
    <v-row>
      <v-col>
        <v-text-field
          v-if="searchFields"
          v-model="searchInput"
          :label="searchTitle"
          class="mx-4"
          :hint="`Поиск по данным: ${searchHint}`"
        ></v-text-field>
      </v-col>
      <v-col v-if="$slots.filters" cols="auto">
        <slot name="filters"></slot>
      </v-col>
      <v-col v-if="$slots.actions" cols="auto">
        <slot name="actions"></slot>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="items"
      :server-items-length="serverItemsLength"
      :footer-props="footerProps"
      :multi-sort="false"
      :must-sort="true"
      :options.sync="options.table"
      :hide-default-header="hideDefaultHeader"
      :hide-default-footer="hideDefaultFooter"
      :loading="loading"
      :loading-text="loadingText"
      class="elevation-0"
      :item-class="itemClass"
      @click:row="handleClick"
      v-model="selectedItems"
      :show-select="showSelect"
      :single-select="singleSelect"
      :item-key="itemKey"
      @input="onInput"
      no-data-text="Нет данных"
    >
      <template v-for="(colName) in rawColumnNames" v-slot:[`item.${colName}`]="{item}">
        <slot :name="`item.${colName}`" :item="item">{{ String(item[colName]) }}</slot>
      </template>

      <template slot="body.append">
        <tr v-if="hasTotal">
          <th v-for="(colName) in rawColumnNames" :key="`item.${colName}.total`" :class="`text-${headers.find(h => h.value === colName).align || 'start'}`">
            <slot :name="`item.${colName}.total`" :total="sumField(colName)"></slot>
          </th>
        </tr>
        <slot :name="`body.append`"></slot>
      </template>
      
      <template v-if="$slots.footer" slot="footer">
        <slot name="footer"></slot>
      </template>

    </v-data-table>
  </div>
</template>

<script>
import { debounce } from 'vue-debounce';
import { getTableOptionsFromRouteQuery, updateRouter } from './inRouteController';

export default {
  name: 'DataTable',

  props: {
    controller: {
      type: String,
      default: 'inMemory',
    },
    headers: Array,
    items: Array,
    serverItemsLength: Number,
    page: {
      type: Number,
      default: 1
    },
    itemsPerPage: {
      type: Number,
      default: 10
    },
    sortBy: String,
    sortDesc: Boolean,
    searchTitle: {
      type: String,
      default: () => { return 'Поиск' },
    },
    searchFields: Array,
    filters: {
      type: Object,
      default: () => { return {} },
    },
    footerProps: {
      type: Object,
      default: () => {
        return {
          'items-per-page-options': [5, 10, 20, 50, 100],
          'items-per-page-text': 'Записей на странице:',
          'show-current-page': true,
          'show-first-last-page': true,
        };
      },
    },
    hideDefaultHeader: Boolean,
    hideDefaultFooter: Boolean,
    loading: Boolean,
    loadingText: {
      type: String,
      default: 'Данные загружаются...',
    },
    itemClass: Function,
    // Selected items
    showSelect: Boolean,
    singleSelect: Boolean,
    itemKey: String,
  },

  data () {
    return {
      options: {
        table: this.initTableOptions(),
        search: this.$route.search,
        filters: {},
      },
      searchInput: this.$route.search,
      timerId: null,
      selectedItems: [],
    };
  },

  computed: {
    generatedColumnNames () {
      return Object.keys(this.passedItems[0] || {}).filter(el => el.charAt(0) !== '_')
    },

    rawColumnNames () {
      if (this.headers) {
        return this.headers.map(el => el.value || el)
      }
      return this.generatedColumnNames
    },

    pagination() {
      let {itemsPerPage, page} = this.options.table;

      return {
        offset: itemsPerPage * (page - 1),
        count: itemsPerPage,
      };
    },

    sorting() {
      let {sortBy, sortDesc} = this.options.table;

      return {
        sortBy: sortBy.length > 0 ? sortBy[0] : undefined,
        descending: sortDesc.length > 0 ? sortDesc[0] : undefined,
      };
    },

    search() {
      return this.options.search ? this.options.search.toLocaleLowerCase() : null;
    },

    searchHint() {
      return this.searchFields.reduce((previous, current, index) => `${previous}${index > 0 ? ', ' : ''}${current.label}`, '');
    },

    hasTotal() {
      return Object.keys(this.$scopedSlots).find(s => s.endsWith('.total')) !== undefined;
    },
  },

  methods: {
    initTableOptions () {
      let tableOptions = {};
      if (this.controller === 'inRoute') {
        tableOptions = getTableOptionsFromRouteQuery(this.$route.query);
      }
      const initialTableOptions = {
        page: this.$props.page,
        itemsPerPage: this.$props.itemsPerPage,
        sortBy: [this.$props.sortBy],
        sortDesc: [this.$props.sortDesc],
      };
      return Object.assign(initialTableOptions, {
        page: tableOptions.page !== undefined ? tableOptions.page : initialTableOptions.page,
        itemsPerPage: tableOptions.itemsPerPage !== undefined ? tableOptions.itemsPerPage : initialTableOptions.itemsPerPage,
        sortBy: tableOptions.sortBy !== undefined ? [tableOptions.sortBy] : initialTableOptions.sortBy,
        sortDesc: tableOptions.sortDesc !== undefined ? [tableOptions.sortDesc] : initialTableOptions.sortDesc,
      });
    },

    updateOptionsStore(changes) {
      if (this.controller === 'inRoute') {
        updateRouter(this.$router, this.$route, changes);
      }
    },

    getFilterFunction(filterName) {
      const filterValuesFuncName = `filter_get_values_${filterName}`;
      if (typeof this.$parent[filterValuesFuncName] !== 'function') {
        throw new Error(`Function '${filterValuesFuncName}' must be provided.`);
      }
      return this.$parent[filterValuesFuncName];
    },

    handleClick(item, e) {
      this.$emit('click:row', item, e);
    },

    sumField(key) {
      return this.items.reduce((a, b) => a + (b[key] || 0), 0)
    },

    emitUpdate() {
      if (this.timerId) {
        clearTimeout(this.timerId);
        this.timerId = null;
      }

      const self = this;
      this.timerId = setTimeout(function() {
        self.$emit('update:table-options', self.pagination, self.sorting, self.search, self.options.filters);
      }, 100);
    },

    onInput (selectedItems) {
      this.$emit('input', selectedItems)
    },
  },

  watch: {
    options: {
      deep: true,
      immediate: true,
      handler () {
        this.emitUpdate();
      },
    },

    'options.table': {
      immediate: true,
      handler (value) {
        this.updateOptionsStore({
          page: value.page.toString(),
          itemsPerPage: value.itemsPerPage.toString(),
          sortBy: value.sortBy[0],
          sortDesc: value.sortDesc[0].toString(),
        });
      },
    },

    'options.search': {
      immediate: false,
      handler (value) {
        this.updateOptionsStore({search: value, page: 1});
      },
    },

    'options.filters': {
      immediate: true,
      handler (value) {
        this.updateOptionsStore(value);
      },
    },

    searchInput: debounce(function (value) {
      this.options = Object.assign(this.options, {search: value});
    }, '300ms'),

    filters: {
      immediate: true,
      handler (value) {
        this.options = Object.assign(this.options, {filters: value});
      },
    },

    $route: {
      immediate: true,
      handler (newRoute, oldRoute) {
        if (this.controller !== 'inRoute') return;

        if (Object.keys(newRoute.query).length === 0 && oldRoute) {
          this.$router.push({ query: oldRoute.query});
          return;
        }

        const optionsFromQuery = getTableOptionsFromRouteQuery(newRoute.query);

        this.options.table = Object.assign(this.options.table, {
          page: optionsFromQuery.page,
          itemsPerPage: optionsFromQuery.itemsPerPage,
          sortBy: [optionsFromQuery.sortBy],
          sortDesc: [optionsFromQuery.sortDesc],
        });

        this.options = Object.assign(this.options, {
          search: optionsFromQuery.search,
          filters: optionsFromQuery.filters,
        });
        this.searchInput = optionsFromQuery.search;
      }
    },
  },
}
</script>

<style lang="scss">
.search-input-description {
  margin-bottom: 8px;
  font-size: 0.8rem;
  color: #999;
}
.data-table-filters {
  -moz-transition: height .5s;
  -ms-transition: height .5s;
  -o-transition: height .5s;
  -webkit-transition: height .5s;
  transition: height .5s;
}
.table-footer{
  background: rgba(0,0,0,0.1);
}
</style>