<template>
  <EditorDialog
    :title="'Добавить тренировку участнику'"
    :max-width="720"
    :save-func="save"
    @closed="localClose"
  >
    <v-form
      ref="form"
      lazy-validation>
      <v-row>
        <v-col>
          <v-select
            v-model="model.discipline"
            label="Дисциплина"
            :items="disciplines"
            item-text="name"
            item-value="value"
            :rules="validation.discipline"
          ></v-select>
        </v-col>
        <v-col>
          <v-text-field
            label="Дистанция (м)"
            v-model="model.distance"
            :rules="validation.distance"
            hide-details="auto"
          ></v-text-field>
        </v-col>
      </v-row>  
      <v-row>
        <v-col>
          <v-text-field
            label="Дата и время начала"
            v-model="model.startDate"
            :rules="validation.startDate"
            hide-details="auto"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            label="Время (сек)"
            v-model="model.timeInSeconds"
            :rules="validation.timeInSeconds"
            hide-details="auto"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
  </EditorDialog>
</template>

<script>
import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
import editorDialogMixin from '@/global-components/dialog/mixins';
import sportDataApiInitializer from '@/api/sportDataApi';

export default {
  name: 'ParticipantTrainingEditorDialog',
  mixins: [editorDialogMixin],

  props: {
    trackerId: undefined,
    object: {
      type: Object,
      default: () => {
        return {
          trackerId: undefined,
          discipline: undefined,
          startDate: undefined,
          distance: undefined,
          timeInSeconds: undefined,
        }
      }
    },
    saveFunc: Function,
  },

  setup() {
    const marketingProgramStore = useMarketingProgramStore()
    return { marketingProgramStore }
  },

  data: () => ({
    saving: false,
    loading: false,
    disciplines: [
      {value: 'Running', name: 'Бег'},
      {value: 'Swimming', name: 'Плавание'},
      {value: 'Walking', name: 'Ходьба'},
      {value: 'Skiing', name: 'Лыжи'},
      {value: 'Cycling', name: 'Велосипед'}
    ],
    validation: {
      discipline: [
        value => !!value || 'Обязательное поле.',
      ],
      startDate: [
        value => !!value || 'Обязательное поле.',
        value => /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[012])\.(19|20)\d\d (0[0-9]|1[0-9]|2[0-4]):\d\d$/i.test(value) || 'Некорректная дата. Формат ДД.ММ.ГГГГ ЧЧ:ММ',
      ],
      distance: [
        value => !!value || 'Обязательное поле.',
        value => /\D/i.test(value) === false || 'Значение должно быть целым числом не меньше 0',
      ],
      timeInSeconds: [
        value => !!value || 'Обязательное поле.',
        value => /\D/i.test(value) === false || 'Значение должно быть целым числом не меньше 0',
      ]
    },
    trackerIdIsEmpty: true
  }),
  computed: {
    participantId() {
      return this.$route.params.participantId;
    },

    mp() {
      if (this.marketingProgramStore.isInitialized === false)
        return null;

      return this.marketingProgramStore;
    },

    sportDataApi() {
      if (!this.mp)
        return null;

      return sportDataApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
    },
  },
  methods: {
    async save() {
      this.saving = true;
      
      this.valid = await this.$refs.form.validate();

      if (this.valid)
        this.saveFunc(this.model);
    },
    localClose() {
      const saving = this.saving;
      this.saving = false;

      if (saving && !this.valid)
        return;

      this.close();
    }
  },

  async mounted() {
    this.loading = true;
    try {
      this.model.trackerId = this.trackerId;
    } finally {
      this.loading = false;
    }
  },
}
</script>