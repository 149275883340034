import Vue from "vue";
import Vuex from "vuex";
import promotions from "./modules/promotionsModule";
import promocodes from "./modules/promocodesModule";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    promotions,
    promocodes
  },
});

export default store;