<template>
  <div>
    <div v-if="pageLoading" class="loading">
      <v-progress-circular
        indeterminate
        color="primary"
      />
    </div>
    <div class="px-4">
      <v-autocomplete
      label="Фильтр по маркетинговой программе"
      :items="marketingPrograms2"
      item-text="title"
      item-value="marketingProgramId"
      v-model="marketingProgramId"
      @change="handleMarketingProgramChange"
    />
    </div>
    <DataTable
      controller="inRoute"
      :headers="headers"
      :items="items"
      :server-items-length="total"
      search-title="Найти поощрение"
      :search-fields="searchFields"
      sort-by="createdOn"
      :sort-desc="true"
      :loading="updating"
      @update:table-options="updateTable"
      >
      <template #actions>
        <v-btn @click="createPromotion" class="ml-3">Добавить поощрения</v-btn>
      </template>
      <template v-slot:[`item.images`]="{item}">
        <div class="d-flex align-center py-2">
          <img height="50" width="50" v-if="item.images.mobile" :src="item.images.mobile" />
          <img height="50" width="50" v-else src="https://cdn1.ozone.ru/s3/multimedia-x/6391435521.jpg"/>
        </div>
      </template>
      <template v-slot:[`item.name`]="{item}">
        <div>{{ item.name }}</div>
        <div class="promotion-id">ID: {{ item.promotionId }}</div>
      </template>
      <template v-slot:[`item.expiresOn`]="{item}">
        {{ formatDate(item.expiresOn) }}
      </template>
      <template v-slot:[`item.createdOn`]="{item}">
        {{ formatDate(item.createdOn) }}
      </template>
      <template v-slot:[`item.actions`]="{item}">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              class="mr-2"
              icon
              v-on="on"
              @click="editPromotion(item)"
              >
              mdi-pencil-outline
            </v-icon>
          </template>
          <span>Редактировать</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              class="mr-2"
              icon
              v-on="on"
              @click="copyPromotion(item)"
            >
              mdi-content-copy
            </v-icon>
          </template>
          <span>Копировать</span>
        </v-tooltip>
        <v-icon
          v-if="item.state === 'Disabled'"
          class="mr-2"
          icon
          color="red"
          @click="enabledPromotion(item.promotionId)"
          >
          mdi-power
        </v-icon>

        <v-icon
          v-if="item.state !== 'Disabled'"
          class="mr-2"
          icon
          color="green"
          @click="disabledPromotion(item.promotionId)"
          >
          mdi-power
        </v-icon>
      </template>
    </DataTable>
  </div>
</template>

<script>
  import marketApiInitializer from '@/api/marketApiNoMp';
  import { mapGetters, mapActions } from "vuex";
  import moment from 'moment';
  import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
  import { useKeycloakStore } from '@/stores/keycloakStore';
  import { uuid } from "vue-uuid";
  export default {

    setup() {
      const marketingProgramStore = useMarketingProgramStore()
      const keycloakStore = useKeycloakStore()
      return { marketingProgramStore, keycloakStore }
    },

    data: () => ({
      headers: [
        { value: 'images', text: '', align: 'start', sortable: false },
        { value: 'name', text: 'Поощрение', align: 'start', sortable: true },
        { value: 'expiresOn', text: 'Срок действия', align: 'end', sortable: true },
        { value: 'createdOn', text: 'Дата создания', align: 'end', sortable: true },
        { value: 'actions', text: 'Действия', align: 'end', sortable: false },
      ],
      items: [],
      pageLoading: false,
      updating: false,
      searchFields: [
      { key: 'name', label: 'Поощрение' },
      ],
      pagination: {
        page: 1,
        itemsPerPage: 10,
      },
      sorting: {
        sortBy: 'expiresOn',
        sortDesc: true,
      },
      search: '',
      marketingProgramId: '',
    }),
    methods: {
      ...mapActions("promotions", ["getPromotions", "getMarketingPrograms", 'disablePromotion', 'enablePromotion']),
      updateTable(pagination, sorting, search, marketingProgramId) {
        this.pagination = pagination;
        this.sorting = sorting;
        this.search = this.$route.query.search;
        this.updating = true;
        const requestParams = { pagination, sorting, search };
        if (marketingProgramId !== "") {
          requestParams.marketingProgramId = marketingProgramId;
        }
        const p = { pagination, sorting, search, marketingProgramId: this.marketingProgramId, creatorId: this.kl.filterByCreatorId };
        this.getPromotions(p)
        .then(() => {
          this.items = this.promotions.values;
          this.updating = false;
        });
        this.$forceUpdate();
      },
      handleMarketingProgramChange() {
        this.updateTable(this.pagination, this.sorting, this.search, this.marketingProgramId);
      },
    async disabledPromotion(promotionId) {
      await this.disablePromotion({ promotionId:promotionId });
      this.updateTable(this.pagination, this.sorting, this.search);
    },

    async enabledPromotion(promotionId) {
      await this.enablePromotion({ promotionId: promotionId });
      this.updateTable(this.pagination, this.sorting, this.search);
    },
    editPromotion(item) {
      const promotionId = item.promotionId
      this.$router.push({ name: 'PromotionEdit', params: { promotionId: promotionId}});
    },
    copyPromotion(item) {
      const newPromotionId = uuid.v4();
      const promotionId = item.promotionId;
      console.log(newPromotionId);
      this.pageLoading = true;
      this.marketApi.copyPromotion({ promotionId: promotionId, newPromotionId: newPromotionId })
      .then(response => {
        console.log('Запрос успешно выполнен1', response);
        this.$router.push({
        name: 'PromotionEdit',
        params: {  promotionId: newPromotionId },
      });
      this.pageLoading = false;
      })
      .catch(error => {
        console.error('Ошибка при выполнении запроса', error);
        this.pageLoading = false;
      });
      // this.updateTable(this.pagination, this.sorting, this.search);
    },
    createPromotion() {
      this.$router.push({ name: 'PromotionEdit' });
    },
    formatDate(date) {
      return date ? moment(date).format('DD.MM.YYYY') : '';
    }
  },
  computed: {
    ...mapGetters("promotions", ["promotions", "total", "marketingPrograms"]),
    mp() {
      if (this.marketingProgramStore.isInitialized === false)
        return null;

      return this.marketingProgramStore;
    },
    marketApi() {
      return marketApiInitializer
    },
    marketingPrograms2() {
      if (this.kl.isAnyMp) {
        return this.mp
          ? [
            { marketingProgramId: '', title: 'Все маркетинговые программы' },
            { marketingProgramId: this.mp.marketingProgramId, title: this.mp.title }
          ] : [];
      } else {
        return [{ marketingProgramId: '', title: 'Все маркетинговые программы' }].concat(this.marketingPrograms);
      }
    },
    kl() {
      return this.keycloakStore;
    },
  },
  mounted(){
    if (this.kl.isAnyMp)
        this.marketingProgramId = this.mp.marketingProgramId;
      else
        this.getMarketingPrograms();
    // this.updateTable(this.pagination, this.sorting, this.search, this.marketingProgramId);
  }
}
</script>

<style lang="scss" scoped>
.promotion-id {
  color: #999;
  font-size: 80%;
}
</style>