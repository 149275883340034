<template v-if="kl.profile">
  <div>
    <v-dialog
    :value="dialogMp"
    width="500"
    persistent
  >
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Маркетинговая программа
      </v-card-title>

      <v-card-text>
        <v-list>
          <v-list-item-group :value="marketingProgramStore.marketingProgramId" @change="changeMarketingProgram">
            <v-list-item class="px-2"
              v-for="mp in klMarketingPrograms"
              :key="mp.id"
              :value="mp.id"
            >
              <v-list-item-avatar>
                <v-img :src="getMpLogo(mp.id)"></v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-text="mp.title"></v-list-item-title>
                <v-list-item-subtitle v-text="mp.site"></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
          <v-list-item class="px-2" @click="click">
            <v-list-item-avatar class="d-flex">
              <v-icon color="#FF8233">mdi-plus-circle</v-icon>
            </v-list-item-avatar>
            <v-list-item-title>Создать новую МП</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
  <v-dialog
    :value="dialogMpCreate"
    width="720"
    persistent>
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Создание новой МП
      </v-card-title>
      <v-form
        v-model="valid"
        class="pa-6 pb-0"
        ref="form"
        lazy-validation
      >
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="newMarketingProgramId"
              label="ID Программы"
              readonly
              hide-details
              class="noborder"
            />
          </v-col>
          <!-- <v-col cols="6">
            <v-text-field
              v-model="organizerId"
              label="ID Организатора"
              readonly
              hide-details
              class="noborder"
            />
          </v-col> -->
          <v-col cols="6">
            <v-autocomplete
            label="Организатор"
            :items="organizers"
            item-text="title"
            item-value="organizerId"
            no-data-text="Ничего не найдено"
            clearable
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="name"
              :rules="validation.required"
              label="Название МП"
              hide-details
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="address"
              :rules="validation.required"
              label="Адрес МП"
              hide-details
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="code"
              :rules="validation.required"
              label="Код программы"
              hide-details
            />
          </v-col>
          <v-col cols="12">
            <v-checkbox
              v-model="closed"
              label="Закрытая МП"
              hide-details
            />
        </v-col>
        </v-row>
      </v-form>
      <v-card-actions class="pa-6">
        <v-spacer></v-spacer>
        <v-btn @click="closeDialog">Отмена</v-btn>
        <v-btn
          color="#FF8233"
          :dark="valid"
          @click="createMP"
          :disabled="!valid"
        >
          Создать
        </v-btn>
      </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
import { useKeycloakStore } from '@/stores/keycloakStore';
import scountApi from '@/api/scountApi';
import { uuid } from "vue-uuid";

export default {
  name: 'MarketingProgramSelectorDialog',

  setup() {
    const marketingProgramStore = useMarketingProgramStore()
    const keycloakStore = useKeycloakStore()
    return { marketingProgramStore, keycloakStore }
  },

  computed: {
    kl() {
      return this.keycloakStore;
    },

    klMarketingPrograms() {
      if (this.kl.isAnyMp) {
        const groups = this.kl.groups;
        if (!groups) return [];
        const getMpIdByGroup = this.getMpIdByGroup;
        const mpIds = groups.map(x => getMpIdByGroup(x));
        return this.marketingPrograms.filter(x => mpIds.indexOf(x.id) > -1);
      } else {
        return this.marketingPrograms;
      }
    }
  },

  data: () => ({
    organizers: null,
    valid: true,
    organizerId: null,
    newMarketingProgramId: null,
    name: null,
    address: null,
    code: null,
    closed: false,
    dialogMp: true,
    dialogMpCreate: false,
    validation: {
      required: [
        value => !!value || 'Обязательное поле.',
      ],
    },
    marketingPrograms: [
      {
        id: 'a6a2d18f-940d-4237-b99e-ec2d81281689',
        title: 'Лига Подмосковья',
        site: 'runningmr.ru',
        icon: 'mdi-inbox'
      },
      {
        id: '8381a34e-c84a-4dd4-a636-fdc2f3cbab5b',
        title: 'ВСК',
        site: 'vskgo.russiarunning.com',
        icon: 'mdi-inbox'
      },
      {
        id: '5696b784-5be8-4f76-8e5c-df084275c27d',
        title: 'МГО',
        site: 'mgo.russiarunning.com',
        icon: 'mdi-inbox'
      },
      {
        id: 'bec0c390-4d39-44fb-86d7-fe65c3b89883',
        title: 'ЛДПР',
        site: 'mneposilam.russiarunning.com',
        icon: 'mdi-inbox'
      },
      {
        id: 'f94c9680-ad60-4636-b9ed-f32ae5282622',
        title: 'Шире шаг',
        site: 'shag.russiarunning.com',
        icon: 'mdi-inbox'
      },
      {
        id: '9b5fe464-2c55-4466-bea0-abe9d5d6cfc1',
        title: 'ЗОЖ Трансформация',
        site: 'зожтрансформация.рф',
        icon: 'mdi-inbox'
      },
      {
        id: 'f3a88565-1657-4779-ae47-b3cc0287fc13',
        title: 'Фарм Чемпионат',
        site: 'фармчемпионат.рф',
        icon: 'mdi-inbox'
      },
      {
        id: 'af0f9923-f448-413c-ba01-e09cebcb95f6',
        title: 'Дело в отношении!',
        site: 'auchan.scount.pro',
        icon: 'mdi-inbox'
      },
      {
        id: '37f15c6d-308f-4fb8-bc45-50a1634439b7',
        title: 'SuperLiga78',
        site: 'superliga78.scount.pro',
        icon: 'mdi-inbox'
      },
      {
        id: 'e4f19024-2e86-42ed-9f5d-be99c0621d71',
        title: 'ЗОЖ ЦИКЛон',
        site: 'clubs50.scount.pro',
        icon: 'mdi-inbox'
      },
      {
        id: '3deeebdd-b363-4ce4-9f79-de3713a52ff5',
        title: 'Делай как я',
        site: 'minsport.scount.pro',
        icon: 'mdi-inbox'
      },
      {
        id: '42cce763-abc3-490e-a461-1568d354951d',
        title: 'Спорт Экспо',
        site: 'expo.scount.pro',
        icon: 'mdi-inbox'
      },
      {
        id: 'dfeaffb5-56a3-4135-8345-3625d24c6b4c',
        title: 'Russia Running Экспо',
        site: 'rrexpo.scount.pro',
        icon: 'mdi-inbox'
      },
      {
        id: '4d008edc-17d1-4669-8bd8-10221c98586a',
        title: 'Стань лучшей версией себя!',
        site: 'severstal.scount.pro',
        icon: 'mdi-inbox'
      },
    ],
  }),

  methods: {
    async click() {
      this.newMarketingProgramId = uuid.v4();
      this.organizerId = uuid.v4();
      this.dialogMp = false
      this.dialogMpCreate = true
      const response = await scountApi.getOrganizers()
      if(response.status === 200) {
        const {data} = response;
        this.organizers = data.values;
        console.log(this.organizers);
      }
    },
    closeDialog() {
      this.dialogMpCreate = false,
      this.dialogMp = true
      this.$refs.form.reset()
    },
    async createMP() {
      const valid = await this.$refs.form.validate();

      if (!valid) {
        return;
      }
      console.log('Создать маркетинговую программу');
    },
    getMpIdByGroup(klGroup) {
      switch (klGroup) {
        case '/MP-Slp': return 'a6a2d18f-940d-4237-b99e-ec2d81281689';
        case '/MP-Vsk': return  '8381a34e-c84a-4dd4-a636-fdc2f3cbab5b';
        case '/MP-Mgo': return  '5696b784-5be8-4f76-8e5c-df084275c27d';
        case '/MP-Ldpr': return  'bec0c390-4d39-44fb-86d7-fe65c3b89883';
        case '/MP-Shag': return  'f94c9680-ad60-4636-b9ed-f32ae5282622';
        case '/MP-ZoshTransform': return  '9b5fe464-2c55-4466-bea0-abe9d5d6cfc1';
        case '/MP-Farm': return  'f3a88565-1657-4779-ae47-b3cc0287fc13';
        case '/MP-Auchan': return  'af0f9923-f448-413c-ba01-e09cebcb95f6';
        case '/MP-Sl78': return  '37f15c6d-308f-4fb8-bc45-50a1634439b7';
        case '/MP-ZoshCiclon': return  'e4f19024-2e86-42ed-9f5d-be99c0621d71';
        case '/MP-MinSport': return  '3deeebdd-b363-4ce4-9f79-de3713a52ff5';
        case '/MP-SportExpo': return  '42cce763-abc3-490e-a461-1568d354951d';
        case '/MP-RussiaRunningExpo': return  'dfeaffb5-56a3-4135-8345-3625d24c6b4c';
        case '/MP-Severstal': return  '4d008edc-17d1-4669-8bd8-10221c98586a';
        default: return null;
      }
    },

    getMpLogo(marketingProgramId) {
      switch (marketingProgramId) {
        case 'a6a2d18f-940d-4237-b99e-ec2d81281689': return 'https://runningmr.ru/themes/RunningMR/favicon/apple-touch-icon.png';
        case '8381a34e-c84a-4dd4-a636-fdc2f3cbab5b': return 'https://vskgo.russiarunning.com/themes/VSKGO/favicon/favicon.ico';
        case '5696b784-5be8-4f76-8e5c-df084275c27d': return 'https://mgoprof.russiarunning.com/themes/MGO/favicon/apple-touch-icon.png';
        case 'bec0c390-4d39-44fb-86d7-fe65c3b89883': return 'https://mneposilam.russiarunning.com/themes/LDPR/favicon/apple-touch-icon.png';
        case 'f94c9680-ad60-4636-b9ed-f32ae5282622': return 'https://mneposilam.russiarunning.com/themes/LDPR/favicon/apple-touch-icon.png';
        case '9b5fe464-2c55-4466-bea0-abe9d5d6cfc1': return 'https://зожтрансформация.рф/themes/transformation/favicon/apple-touch-icon.png';
        case 'f3a88565-1657-4779-ae47-b3cc0287fc13': return 'https://фармчемпионат.рф/themes/transformation/favicon/apple-touch-icon.png';
        case 'af0f9923-f448-413c-ba01-e09cebcb95f6': return 'https://auchan.scount.pro/themes/transformation/favicon/apple-touch-icon.png';
        case '37f15c6d-308f-4fb8-bc45-50a1634439b7': return 'https://superliga78.scount.pro/themes/transformation/favicon/apple-touch-icon.png';
        case 'e4f19024-2e86-42ed-9f5d-be99c0621d71': return 'https://clubs50.scount.pro/themes/transformation/favicon/apple-touch-icon.png';
        case '3deeebdd-b363-4ce4-9f79-de3713a52ff5': return 'https://minsport.scount.pro/themes/transformation/favicon/apple-touch-icon.png';
        case '42cce763-abc3-490e-a461-1568d354951d': return 'https://minsport.scount.pro/themes/transformation/favicon/apple-touch-icon.png';
        case 'dfeaffb5-56a3-4135-8345-3625d24c6b4c': return 'https://rrexpo.scount.pro/themes/rrexpo/favicon/apple-touch-icon.png';
        case '4d008edc-17d1-4669-8bd8-10221c98586a': return 'https://severstal.scount.pro/themes/severstal/favicon/apple-touch-icon.png';
        default: return null;
      }
    },

    changeMarketingProgram(id) {
      this.marketingProgramStore.setMarketingProgramId(id);
    },
  }
}
</script>