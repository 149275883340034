<template>
  <div class="home">
  </div>
</template>

<script>
import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
// import scountApi from '@/api/scountApi';

// import { storeToRefs } from 'pinia'
// const { user } = storeToRefs(useAppStore())

export default {
  name: 'DashboardView',
  setup() {
    const marketingProgramStore = useMarketingProgramStore()
    return { marketingProgramStore }
  },
}
</script>
