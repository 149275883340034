export default {
  props: {
    objectId: String,
  },

  data: function () {
    return {
      model: null,
    }
  },

  methods: {
    close() {
      this.$emit('closed');
    },
  },

  created() {
    if (this.$props.object)
      this.model = {...this.$props.object};
  },
};