<template>
  <div>
    <!-- <v-progress-linear
      v-if="!nomination"
      color="#4D41F6"
      height="4"
      indeterminate
    ></v-progress-linear> -->
    <v-col
        cols="12"
        v-if="!nomination"
      >
      <v-skeleton-loader
        type="date-picker-options, list-item, card, heading, table"
      ></v-skeleton-loader>
    </v-col>

    <div v-if="nomination">
      <v-form ref="form" lazy-validation>
        <v-row>
          <v-col cols="8">
            <div class="main__title">
              <v-card-text class="nominations__h1 text-truncate">
                {{nomination.title ? nomination.title : 'Новая номинация'}}
              </v-card-text>
              <div v-if="nomination.title" class="main__subtitle" :class="{'active': nominationActive}">
                <p>id: {{ nominationId }} |</p>
                <span>{{ nominationActiveTitle }}</span>
              </div>
            </div>

          </v-col>
          <v-col cols="4" class="d-flex">
            <div class="ml-auto">
              <v-btn
                v-if="!isEditInformation"
                @click="editNominationInformation"
                class="mr-2"
                large
              >
              <v-icon left>mdi-pencil</v-icon>
              редактировать
              </v-btn>
              <v-btn v-else @click="saveNominationInformation" class="mr-2" large>
                <v-icon left>mdi-content-save</v-icon>
                Сохранить
              </v-btn>
              <v-btn class="mr-2" large>
                <v-icon>mdi-delete</v-icon>
              </v-btn>
              <v-btn
                v-if="nomination.state === 'Inactive' || nomination.state === 'Calculating'"
                :disabled="nomination.state === 'Calculating'"
                @click="activateNomination"
                large
              >
                <v-icon >mdi-eye</v-icon>
              </v-btn>
              <v-btn
                v-if="nomination.state === 'Active'"
                @click="inactivateNomination"
                large
              >
              <v-icon >mdi-eye-off</v-icon>

              </v-btn>
            </div>
          </v-col>
        </v-row>

        <br />

        <v-text-field
          label="Название номинации"
          v-model="nomination.title"
          :rules="validation.title"
          hide-details="auto"
          class="mb-5"
          :disabled="!isEditInformation"
        ></v-text-field>

        <!-- <v-text-field
          label="Правила"
          v-model="nomination.rules"
          :rules="validation.rules"
          hide-details="auto"
          class="mb-5"
          :disabled="!isEditInformation"
        ></v-text-field> -->
        <div :class="showMessage || showMessage1  ? 'active' : ''">
          <v-row>
            <v-col>
              <p class="label" :class="descriptionLength1 ? 'active' : ''">Описание</p>
            </v-col>
          </v-row>
          <vue-editor
            ref="editor"
            :class="{disabled:!isEditInformation}"
            v-model="nomination.rules"
            :editorToolbar="customToolbar"
            :disabled="!isEditInformation"
            @input="descriptionLength"
          />
          <p v-if="showMessage" class="description__error">Обязательное поле.</p>
          <p v-if="showMessage1" class="description__error">Максимальное количесто символов {{limitDscription}}</p>
        </div>

        <v-text-field
          label="Награды"
          v-model="nomination.awards"
          :rules="validation.awards"
          hide-details="auto"
          class="mb-5"
          :disabled="!isEditInformation"
        ></v-text-field>
        <v-checkbox
          label="Внутрикомандная номинация"
          v-model="nomination.teamNomination"
          hide-details="auto"
          :disabled="!isEditInformation"
        ></v-checkbox>

        <br>

        <h3>Параметры номинации</h3>

        <v-row>
          <v-col>
            <v-alert
              v-if="nomination.state === 'Active'"
              colored-border
              type="info"
              elevation="0"
            >
              <small>При изменении этих параметров произойдет пересчет номинации. Для изменения необходимо деактивировать номинацию.</small>
            </v-alert>

            <div
              v-if="nomination.state === 'Calculating'"
            >
              <v-progress-circular
                indeterminate
                :size="16"
                :width="2"
                class="mr-2"
              />
              <small>Номинация пересчитывается. Для изменения номинации дождитесь окончания процесса.</small>
            </div>
          </v-col>
          <v-col class="d-flex" cols="auto">
            <div class="ml-auto">
              <v-btn
                v-if="!isEditSettings"
                @click="editNominationSettings"
                :disabled="nomination.state === 'Active' || nomination.state === 'Calculating'"
                class="mr-2"
              >
                Изменить
              </v-btn>
              
              <v-btn v-else @click="saveNominationSettings" :disabled="!nominationId" class="mr-2">Сохранить</v-btn>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              label="Дата начала"
              v-model="nomination.startDate"
              :rules="validation.startDate"
              hide-details="auto"
              :disabled="!isEditSettings"
              append-icon="mdi-calendar-today"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Дата окончания"
              v-model="nomination.finishDate"
              :rules="validation.finishDate"
              hide-details="auto"
              :disabled="!isEditSettings"
              append-icon="mdi-calendar-today"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-select
              label="Пол"
              v-model="nomination.settings.participantFilter.Gender"
              :items="genders"
              item-text="name"
              item-value="value"
              :disabled="!isEditSettings"
            ></v-select>
          </v-col>
          <v-col>
            <v-select
              label="Возрастная группа"
              v-model="nomination.settings.participantFilter.Age"
              :items="ages"
              item-text="name"
              item-value="value"
              :disabled="!isEditSettings"
            ></v-select>
          </v-col>
          <v-col>
            <v-select
              label="Участники объединения"
              v-model="isUnionMember"
              :items="unionMemberStates"
              item-text="name"
              item-value="value"
              :disabled="!isEditSettings"
            ></v-select>
          </v-col>
          <v-col>
            <v-autocomplete
              label="Команда"
              v-model="nomination.settings.participantFilter.TeamId"
              :items="[{name:'Все',value:null},...teams]"
              item-text="name"
              item-value="value"
              search-input.sync="searchTeams"
              :disabled="!isEditSettings"
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-row class="mb-5">
          <v-col cols="3">
            <v-text-field
              label="Бег"
              v-model="nomination.settings.calculationSettings.coefficients.Running"
              :rules="validation.running"
              hide-details="auto"
              :disabled="!isEditSettings"
              prepend-icon="mdi-run"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              label="Ходьба"
              v-model="nomination.settings.calculationSettings.coefficients.Walking"
              :rules="validation.walking"
              hide-details="auto"
              :disabled="!isEditSettings"
              prepend-icon="mdi-walk"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              label="Велосипед"
              v-model="nomination.settings.calculationSettings.coefficients.Cycling"
              :rules="validation.cycling"
              hide-details="auto"
              :disabled="!isEditSettings"
              prepend-icon="mdi-bike"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              label="Лыжи"
              v-model="nomination.settings.calculationSettings.coefficients.Skiing"
              :rules="validation.skiing"
              hide-details="auto"
              :disabled="!isEditSettings"
              prepend-icon="mdi-ski"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              label="Плавание"
              v-model="nomination.settings.calculationSettings.coefficients.Swimming"
              :rules="validation.swimming"
              hide-details="auto"
              :disabled="!isEditSettings"
              prepend-icon="mdi-swim"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              label="За правильный ответ (квиз)"
              v-model="nomination.settings.calculationSettings.coefficients.Quiz"
              :rules="validation.quiz"
              hide-details="auto"
              :disabled="!isEditSettings"
              prepend-icon="mdi-head-cog"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              label="Прикрепление файла"
              v-model="nomination.settings.calculationSettings.coefficients.UploadImage"
              :rules="validation.wellbeing"
              hide-details="auto"
              :disabled="!isEditSettings"
              prepend-icon="mdi-spa"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              label="Геопривязка"
              v-model="nomination.settings.calculationSettings.coefficients.GeoLocation"
              :rules="validation.geoLocation"
              hide-details="auto"
              :disabled="!isEditSettings"
              prepend-icon="mdi-map-marker-circle"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              label="Переход по ссылке"
              v-model="nomination.settings.calculationSettings.coefficients.PartnerLink"
              :rules="validation.link"
              hide-details="auto"
              :disabled="!isEditSettings"
              prepend-icon="mdi-link"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              label="Приглашение друга"
              v-model="nomination.settings.calculationSettings.coefficients.Invite"
              :rules="validation.link"
              hide-details="auto"
              :disabled="!isEditSettings"
              prepend-icon="mdi-account-multiple-plus"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              label="Регистрация на событие"
              v-model="nomination.settings.calculationSettings.coefficients.Registration"
              :rules="validation.link"
              hide-details="auto"
              :disabled="!isEditSettings"
              prepend-icon="mdi-ticket-confirmation"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              label="Участие в событии"
              v-model="nomination.settings.calculationSettings.coefficients.Participating"
              :rules="validation.link"
              hide-details="auto"
              :disabled="!isEditSettings"
              prepend-icon="mdi-trophy-variant"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="true">
          <v-col>
            <ImageSelector
              :preview-url="nomination.images ? nomination.images.mobile : null"
              :read-only="!isEditInformation"
              :save-image-func="changeMobileImage"
              accept=".png"
              title="ПК (192x192)"
              :subTitle="mobileImageSubTitle"
              @show-preview="showImagePreview('mobile')"
            ></ImageSelector>
          </v-col>
          <v-col>
            <ImageSelector
              :preview-url="nomination.images ? nomination.images.desktop : null"
              :read-only="!isEditInformation"
              :save-image-func="changeDesktopImage"
              accept=".png"
              title="Мобильный (128x128)"
              :subTitle="desktopImageSubTitle"
              @show-preview="showImagePreview('desktop')"
            ></ImageSelector>
          </v-col>
        </v-row>
      </v-form>

      <div class="nominations__participant-filter">
        <v-row class="nominations__participant-filter__h2">
          <v-col>
            Настройки фильтров участников (Participant Filter)
            <div v-if="participantFilterChanged" class="nominations__participant-filter__hint"> * изменения в фильтрах участников произойдут после сохранения номинации</div>
          </v-col>
          <v-col class="text-right">
            <v-btn @click="addParticipantFilterKeyDialogShow = true" :disabled="!isEditSettings">Добавить фильтр</v-btn>
          </v-col>
        </v-row>
        <div v-for="(value, key) in nomination.settings.participantFilter" :key="key">
          <v-row v-if="!isParticipantFilterSystemKey(key)">
            <v-col>
              <v-text-field
                :label="key"
                v-model="nomination.settings.participantFilter[key]"
                hide-details="auto"
                class="mb-5"
                :disabled="!isEditSettings"
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="d-flex">
              <v-icon @click="deleteParticipantFilterItem(key)" :disabled="!isEditSettings">mdi-delete</v-icon>
            </v-col>
          </v-row>
        </div>
        <div v-if="Object.keys(nomination.settings.participantFilter).filter(x => !isParticipantFilterSystemKey(x)).length === 0" 
          class="nominations__participant-filter__nodata">
          Нет ни одного фильтра
        </div>
      </div>

      <ul class="js-tabs js-nomination-tabs">
        <li v-bind:class="{ active: tabsMode === 'exercises' }" @click="setTabMode('exercises')">ЗАДАНИЯ</li>
        <li v-bind:class="{ active: tabsMode === 'participants' }" @click="setTabMode('participants')">УЧАСТНИКИ</li>
        <li v-bind:class="{ active: tabsMode === 'teams' }" @click="setTabMode('teams')">КОМАНДЫ</li>
      </ul>

      <NominationExercisesView v-if="nominationId && tabsMode === 'exercises'" :nominationId="nominationId" :is-edit="isEditSettings" />
      <NominationParticipantsView v-if="nominationId && tabsMode === 'participants'" :nominationId="nominationId" :is-edit="isEditSettings" />
      <NominationTeamsView v-if="nominationId && tabsMode === 'teams'" :nominationId="nominationId" :is-edit="isEditSettings" />

      <AddParticipantFilterKeyDialog
        v-if="addParticipantFilterKeyDialogShow"
        :participantFilter="nomination.settings.participantFilter"
        :isSystemKey="isParticipantFilterSystemKey"
        :save-func="addParticipantFilter"
        @closed="addParticipantFilterKeyDialogShow = false" />
    </div>
    <v-dialog v-model="dialog.opened" width="720">
      <v-card>
        <v-card-title class="headline">{{ dialog.title }}</v-card-title>
        <v-card-text v-html="dialog.text">
        </v-card-text>
        <v-card-actions>
          <v-btn @click="closeDialog">Закрыть</v-btn>
          <v-btn v-if="dialog.actionDialog" @click="dialog.actionDialog" color="primary">{{ dialog.actionName }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from 'vue';
import { uuid } from "vue-uuid";
import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
import scountApiInitializer from '@/api/scountApi';
import ratingsApiInitializer from '@/api/ratingsApi';
import NominationExercisesView from './NominationExercisesView.vue';
import NominationParticipantsView from './NominationParticipantsView.vue';
import NominationTeamsView from './NominationTeamsView.vue';
import AddParticipantFilterKeyDialog from './AddParticipantFilterKeyDialog.vue';
import Moment from 'moment'
import { VueEditor } from "vue2-editor";


export default {
  name: 'NominationView',
  components: { NominationExercisesView, NominationParticipantsView, NominationTeamsView, AddParticipantFilterKeyDialog, VueEditor },

  setup() {
    const marketingProgramStore = useMarketingProgramStore()
    return { marketingProgramStore }
  },

  data: () => ({
    showMessage: false,
    showMessage1: false,
    limitDscription: 512,
    lengthDescrition: 0,
    customToolbar: [
      ["bold",{ list: "ordered"}, {list: "bullet" }, "link"],
    ],
    dialog: {
      opened: false,
      title: undefined,
      text: undefined,
      actionDialog: undefined,
      actionName: undefined
    },
    tabsMode: 'exercises',
    nomination: null,
    desktopImageFile: null,
    desktopImageSubTitle: null,
    mobileImageFile: null,
    mobileImageSubTitle: null,
    loading: false,
    isEditInformation: false,
    isEditSettings: false,
    nominationEditorDialogShow: false,
    addParticipantFilterKeyDialogShow: false,
    isUnionMember: null,
    participantFilterChanged: false,
    validation: {
      activityType: [
        value => !!value || 'Обязательное поле.',
      ],
      title: [
        value => !!value || 'Обязательное поле.',
        value => (value && value.length <= 64) || 'Не более 64 символов.',
      ],
      rules: [
        value => !!value || 'Обязательное поле.',
        value => (value && value.length <= 512) || 'Не более 512 символов.',
      ],
      awards: [
        value => !!value || 'Обязательное поле.',
        value => (value && value.length <= 512) || 'Не более 512 символов.',
      ],
      startDate: [
        value => !!value || 'Обязательное поле.',
        value => /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[012])\.(19|20)\d\d (0[0-9]|1[0-9]|2[0-4]):\d\d$/i.test(value) || 'Некорректная дата. Формат ДД.ММ.ГГГГ ЧЧ:ММ',
      ],
      finishDate: [
        value => !!value || 'Обязательное поле.',
        value => /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[012])\.(19|20)\d\d (0[0-9]|1[0-9]|2[0-4]):\d\d$/i.test(value) || 'Некорректная дата. Формат ДД.ММ.ГГГГ ЧЧ:ММ',
      ],
      running: [
        value => !value || /^\d+(\.\d{1,2})?$/i.test(value) || 'Значение должно отсутствовать или быть числом не меньше 0',
      ],
      walking: [
        value => !value || /^\d+(\.\d{1,2})?$/i.test(value) || 'Значение должно отсутствовать или быть числом не меньше 0',
      ],
      cycling: [
        value => !value || /^\d+(\.\d{1,2})?$/i.test(value) || 'Значение должно отсутствовать или быть числом не меньше 0',
      ],
      swimming: [
        value => !value || /^\d+(\.\d{1,2})?$/i.test(value) || 'Значение должно отсутствовать или быть числом не меньше 0',
      ],
      skiing: [
        value => !value || /^\d+(\.\d{1,2})?$/i.test(value) || 'Значение должно отсутствовать или быть числом не меньше 0',
      ],
      quiz: [
        value => !value || /^\d+(\.\d{1,2})?$/i.test(value) || 'Значение должно отсутствовать или быть числом не меньше 0',
      ],
      link: [
        value => !value || /^\d+(\.\d{1,2})?$/i.test(value) || 'Значение должно отсутствовать или быть числом не меньше 0',
      ]
    },
    activityTypeItems: [
      {value: 'SportActivities', name: 'Для спортивных заданий'},
      {value: 'Quizes', name: 'Для квизов'},
      {value: 'Partners', name: 'Для wellbeing заданий'},
      {value: 'GeoLocation', name: 'Для заданий с геопривязкой'},
      {value: 'Multinaminations', name: 'Для Мультиноминаций'},
    ],
    genders: [
      {value: null, name: 'Любой'},
      {value: 'Male', name: 'Мужской'},
      {value: 'Female', name: 'Женский'},
    ],
    ages: [
      {value: null, name: 'Любая'},
      {value: '14-17', name: '14-17'},
      {value: '18-34', name: '18-34'},
      {value: '35-39', name: '35-39'},
      {value: '35-44', name: '35-44'},
      {value: '40-44', name: '40-44'},
      {value: '45-49', name: '45-49'},
      {value: '45-54', name: '45-54'},
      {value: '50-54', name: '50-54'},
      {value: '55-59', name: '55-59'},
      {value: '55-64', name: '55-64'},
      {value: '60-64', name: '60-64'},
      {value: '65-69', name: '65-69'},
      {value: '65+', name: '65+'},
      {value: '70+', name: '70+'},
    ],
    teams: [
      {value: null, name: 'Все'},
    ],
    unionMemberStates: [
      {value: null, name: 'Не выбрано'},
      {value: true, name: 'Участник'},
      {value: false, name: 'Не участник'},
    ],
    updateNominationTimerId: null,
  }),

  computed: {
    descriptionLength1() {
      return this.lengthDescrition > 0 ? true : false;
    },
    nominationId() {
      return this.$route.params.nominationId;
    },

    nominationActiveTitle() {
      return this.nomination.state === 'Inactive' ? 'не опубликовано' : 'опубликовано'
    },
    nominationActive() {
      return this.nomination.state === 'Inactive' ? false : true
    },

    mp() {
      if (this.marketingProgramStore.isInitialized === false)
        return null;

      return this.marketingProgramStore;
    },

    scountApi() {
      if (!this.mp)
        return null;

      return scountApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
    },

    ratingsApi() {
      if (!this.mp)
        return null;

      return ratingsApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
    },
  },

  watch: {
    nomination: {
      immediate: false,
      handler (value) {
        if (!value) return;
        if (value.state === 'Calculating') {
          if (this.updateNominationTimerId) {
            clearTimeout(this.updateNominationTimerId);
            this.updateNominationTimerId = null;
          }

          const self = this;
          this.updateNominationTimerId = setTimeout(async function() {
            await self.getNomination();
          }, 3000);
        } else if (this.updateNominationTimerId) {
          clearTimeout(this.updateNominationTimerId);
          this.updateNominationTimerId = null;
        }
      },
    },
  },

  methods: {
    descriptionLength() {
      const quill = this.$refs.editor.quill
      this.lengthDescrition = quill.getLength()
      this.showMessage = this.nomination.rules.trim() === '';
      this.showMessage1 = this.lengthDescrition > this.limitDscription;
      if (this.lengthDescrition > this.limitDscription) {
        quill.deleteText(this.limitDscription, this.lengthDescrition);
      }
    },
    setTabMode(mode) {
      this.tabsMode = mode;
    },

    async searchTeams(value) {
      const response = await this.scountApi.getTeams(null, null, value, 1000);
      this.teams = [];
      response.data.values.forEach(x => {
        this.teams.push({ value: x.teamId, name: x.name });
      });
    },

    async getNomination() {
      const response = await this.ratingsApi.getNomination(this.nominationId);
      this.nomination = response.data;

      if (this.nomination.startDate)
        this.nomination.startDate = Moment.utc(this.nomination.startDate).local().format('DD.MM.YYYY HH:mm');
      if (this.nomination.finishDate)
        this.nomination.finishDate = Moment.utc(this.nomination.finishDate).local().format('DD.MM.YYYY HH:mm');

      if (!this.nomination.settings.participantFilter.Age)
        this.nomination.settings.participantFilter.Age = null;

      if (!this.nomination.settings.participantFilter.Gender)
        this.nomination.settings.participantFilter.Gender = null;

      if (!this.nomination.settings.participantFilter.TeamId)
        this.nomination.settings.participantFilter.TeamId = null;

      this.isUnionMember = this.nomination.settings.participantFilter.IsUnionMember 
        ? `${this.nomination.settings.participantFilter.IsUnionMember}` === 'true'
        : null;

      this.isEditInformation = false;
      this.isEditSettings = false;
      this.participantFilterChanged = false;
    },

    editNominationInformation() {
      this.isEditInformation = true;
    },

    editNominationSettings() {
      this.isEditSettings = true;
    },

    async saveNominationInformation() {
      const valid = await this.$refs.form.validate();
      this.showMessage = true;

      if (!valid) {
        return;
      }

      if (this.nominationId) {
        await this.changeNominationInformation();
        await this.getNomination();
      } else {
        var nominationId = await this.createNomination();
        this.goToNomination(nominationId);
      }

      this.isEditInformation = false;
    },

    async saveNominationSettings() {
      const valid = await this.$refs.form.validate();

      if (!valid)
        return;

      if (!this.nomination.settings.participantFilter.Age)
        this.nomination.settings.participantFilter.Age = undefined;

      if (!this.nomination.settings.participantFilter.Gender)
        this.nomination.settings.participantFilter.Gender = undefined;

      if (!this.nomination.settings.participantFilter.TeamId)
        delete this.nomination.settings.participantFilter.TeamId;

      if (this.isUnionMember === null)
        delete this.nomination.settings.participantFilter.IsUnionMember;
      else
        this.nomination.settings.participantFilter.IsUnionMember = `${this.isUnionMember}`;
      const coefficients = this.nomination.settings.calculationSettings.coefficients;
      coefficients.Swimming = coefficients.Swimming === undefined || coefficients.Swimming === '' ? undefined : `${coefficients.Swimming}`;
      coefficients.Running = coefficients.Running === undefined || coefficients.Running === '' ? undefined : `${coefficients.Running}`;
      coefficients.Walking = coefficients.Walking === undefined || coefficients.Walking === '' ? undefined : `${coefficients.Walking}`;
      coefficients.Cycling = coefficients.Cycling === undefined || coefficients.Cycling === '' ? undefined : `${coefficients.Cycling}`;
      coefficients.Skiing = coefficients.Skiing === undefined || coefficients.Skiing === '' ? undefined : `${coefficients.Skiing}`;
      coefficients.Quiz = coefficients.Quiz === undefined || coefficients.Quiz === '' ? undefined : `${coefficients.Quiz}`;
      coefficients.PartnerLink = coefficients.PartnerLink === undefined || coefficients.PartnerLink === '' ? undefined : `${coefficients.PartnerLink}`;
      coefficients.Invite = coefficients.Invite === undefined || coefficients.Invite === '' ? undefined : `${coefficients.Invite}`;
      coefficients.Registration = coefficients.Registration === undefined || coefficients.Registration === '' ? undefined : `${coefficients.Registration}`;
      coefficients.Participating = coefficients.Participating === undefined || coefficients.Participating === '' ? undefined : `${coefficients.Participating}`;
      coefficients.UploadImage = coefficients.UploadImage === undefined || coefficients.UploadImage === '' ? undefined : `${coefficients.UploadImage}`;

      await this.changeNominationSettings();

      var self = this;
      setTimeout(async function() {
        await self.getNomination();
      }, 200);
      setTimeout(async function() {
        await self.getNomination();
      }, 2000);

      this.isEditSettings = false;
    },

    async createNomination() {
      var nominationId = uuid.v4();

      await this.ratingsApi.createNomination(nominationId, {
        title: this.nomination.title,
        rules: this.nomination.rules,
        awards: this.nomination.awards,
        teamNomination: this.nomination.teamNomination,
        activityType: this.nomination.activityType,
        participantFilter: this.nomination.settings.participantFilter,
        coefficients: this.nomination.settings.calculationSettings.coefficients,
        startDate: Moment(this.nomination.startDate, 'DD.MM.YYYY HH:mm').toDate(),
        finishDate: Moment(this.nomination.finishDate, 'DD.MM.YYYY HH:mm').toDate(),
      });

      if (this.desktopImageFile) {
        const destResp = await this.ratingsApi.uploadNominationDesktopImage(nominationId, this.desktopImageFile);
        if (destResp.success) {
          if (this.mobileImageFile) {
            const mobileResp = await this.ratingsApi.uploadNominationMobileImage(nominationId, this.mobileImageFile);
            if (!mobileResp.success) {
              this.showError('Ошибка при загрузке изображения mobile', this.getUploadAfterSaveText(mobileResp));
            }
          }
        } else {
          this.showError('Ошибка при загрузке изображения desktop', this.getUploadAfterSaveText(destResp));
        }
      }

      return nominationId;
    },

    async changeNominationInformation() {
      await this.ratingsApi.changeNomination(this.nominationId, {
        title: this.nomination.title,
        rules: this.nomination.rules,
        awards: this.nomination.awards,
        activityType: this.nomination.activityType,
        teamNomination: this.nomination.teamNomination
      });

      if (this.desktopImageFile) {
        const destResp = await this.ratingsApi.uploadNominationDesktopImage(this.nominationId, this.desktopImageFile);
        if (destResp.success) {
          if (this.mobileImageFile) {
            const mobileResp = await this.ratingsApi.uploadNominationMobileImage(this.nominationId, this.mobileImageFile);
            if (!mobileResp.success) {
              this.showError('Ошибка при загрузке изображения mobile', this.getUploadAfterSaveText(mobileResp));
            }
          }
        } else {
          this.showError('Ошибка при загрузке изображения desktop', this.getUploadAfterSaveText(destResp));
        }
      }
    },

    async changeNominationSettings() {
      await this.ratingsApi.changeNominationSettings(this.nominationId, {
        participantFilter: this.nomination.settings.participantFilter,
        coefficients: this.nomination.settings.calculationSettings.coefficients,
        startDate: Moment(this.nomination.startDate, 'DD.MM.YYYY HH:mm').toDate(),
        finishDate: Moment(this.nomination.finishDate, 'DD.MM.YYYY HH:mm').toDate(),
      });
    },
    
    getUploadAfterSaveText(resp) {
        const e = resp.error;
        if (!e) return 'Произошла ошибка';
        if (e.status === 413) return 'Фото не было загружено. Превышен максимальный размер изображения';
        if (e.status === 400 && e.responseData && e.responseData.errors && e.responseData.errors.File) {
          const errFile = e.responseData.errors.File;
          const max = errFile.find(x => x.startsWith('Maximum '));
          if (max)
            return 'Фото не было загружено. Превышен максимальный размер изображения.';
        }

        return e.detail;
    },

    showError(title, text) {
      this.dialog.title = title;
      this.dialog.text = text;
      this.dialog.opened = true;
    },

    hideError() {
      this.dialog.title = undefined;
      this.dialog.text = undefined;
      this.dialog.opened = false;
    },

    async goToNominations() {
      this.$router.push('/nominations');
    },

    async goToNomination(nominationId) {
      this.$router.push(`/nomination/${nominationId}`);
    },

    async activateNomination() {
      if (!this.nomination.images || !this.nomination.images.mobile || !this.nomination.images.desktop) {
        this.showError('Ошибка активации номинации', 'Перед активацией номинации необходимо загрузить картинки');
        return;
      }

      await this.ratingsApi.activateNomination(this.nominationId);
      await this.getNomination();
    },

    async inactivateNomination() {
      await this.ratingsApi.inactivateNomination(this.nominationId);
      await this.getNomination();
    },

    changeDesktopImage(file) {
      this.desktopImageFile = file;
      this.desktopImageSubTitle = `${file.name} (${file.size / 1024}KB)`;
    },

    changeMobileImage(file) {
      this.mobileImageFile = file;
      this.mobileImageSubTitle = `${file.name} (${file.size / 1024}KB)`;
    },

    showImagePreview(type) {
      this.showPreview = true;
      this.showPreviewType = type;
    },

    addParticipantFilter(key) {
      this.nomination.settings.participantFilter[key] = null;
      this.participantFilterChanged = true;
    },

    isParticipantFilterSystemKey(key) {
      return key && ['isunionmember', 'age', 'gender', 'teamid'].indexOf(key.toLowerCase()) > -1;
    },

    deleteParticipantFilterItem(key) {
      Vue.delete(this.nomination.settings.participantFilter, key);
      this.participantFilterChanged = true;
    },

    openDialog(title, text) {
      this.dialog.title = title;
      this.dialog.text = text;
      this.dialog.opened = true;
    },

    closeDialog() {
      this.dialog.opened = false;
    }
  },

  async mounted() {
    this.loading = true;
    try {
      if (this.nominationId)
        await this.getNomination();
      else {
        this.nomination = {
          title: undefined,
          rules: undefined,
          awards: undefined,
          teamNomination: false,
          startDate: undefined,
          finishDate: undefined,
          activityType: undefined,
          settings: {
            participantFilter: {},
            calculationSettings: {
              coefficients: {
                Running: undefined,
                UploadImage: undefined,
                Cycling: undefined,
                Swimming: undefined,
                Skiing: undefined,
                Quiz: undefined,
                Participating: undefined,
              }
            }
          }
        };

        this.isEdit = true;
        this.isEditInformation = true;
        this.isEditSettings = true;
      }

      this.searchTeams();
    } finally {
      this.loading = false;
    }
  },
}
</script>

<style lang="scss">
.complexity {
  display: inline-block;
  margin-left: 8px;
  padding: 0 4px;
  border-radius: 4px;
  &.__low {
    background-color: #93de64;
  }
  &.__medium {
    background-color: #ffd666;
  }
  &.__high {
    background-color: #fa4646;
    color: white;
  }
}
</style>

<style lang="scss" scoped>
  @import '../../assets/global';
</style>