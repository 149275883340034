<template>
  <div>
    <v-progress-linear
      v-if="isLoading && !calendar"
      indeterminate
      color="rgb(77, 65, 246)"
    />
    <div v-else class="calendar">
      <v-row class="mb-6">
        <v-col cols="12" class="d-flex">
          <v-btn large @click="changeCalendar">
            <v-icon left>mdi-content-save</v-icon>
              Сохранить изменения
          </v-btn>
          <v-switch class="ml-4 mt-0" v-model="isActive" :label="isActive ? 'Выключить календарь' : 'Включить календарь'" />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="title"
            label="Заголовок календаря"
            dense
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="subtitle"
            label="Подзаголовок календаря"
            dense
          />
        </v-col>
        <v-col cols="12">
          <v-alert
              border="top"
              colored-border
              type="info"
              color="rgb(77, 65, 246)"
              elevation="2"
              class="mt-4"
            >
            Используйте переменную {{currentDate}} для текущей даты, {{sportEventItems}} для количества поорщений категории «спортивно-массовые события»
            </v-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <upload-images
            @changeFile="changeDesktopImageCalendar"
            @fileInputClick="clickMonth"
            @fileDelete="dialogImageCalendarOpen('Desktop')"
            :imageSelected="imageDesktopSelected"
            :deleteButton="imageDesktopSelected ? true : false"
            width="490"
            height="128"
            title="Изображение Desktop"
            accept=".png, .jpg, .jpeg"
            :fullNameUpdate="false"
            imageSize="1920x500"
          />
        </v-col>
        <v-col cols="6">
          <upload-images
            @changeFile="changeMobileImageCalendar"
            @fileInputClick="clickMonth"
            @fileDelete="dialogImageCalendarOpen('Mobile')"
            :imageSelected="imageMobileSelected"
            :deleteButton="imageMobileSelected ? true : false"
            width="128"
            height="128"
            title="Изображение Mobile"
            accept=".png, .jpg, .jpeg"
            :fullNameUpdate="false"
            imageSize="688х748"
          />
        </v-col>
      </v-row>
      <v-divider class="my-12"></v-divider>
    <v-expansion-panels :multiple="multiple" v-model="panel">
      <v-expansion-panel  class="mb-6" v-for="item in calendar.calendarMonths" :key="item.id" @click="onPanelClick($event, item)">
        <v-expansion-panel-header>
          <p class="calendar__title">{{getMonthName(item.month)}} {{ item.year }}</p>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <v-progress-linear
            v-if="isLoadingMonths"
            indeterminate
            color="rgb(77, 65, 246)"
          />
          <v-form
            v-else
            ref="form"
            v-model="isValid"
            lazy-validation
          >
            <v-row>
              <v-col cols="12" lg="6">
                <v-row class="pb-8">
                  <v-col cols="6">
                    <v-text-field
                      v-model="month"
                      :rules="validation.month"
                      label="Месяц"
                      hide-details="auto"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="year"
                      :rules="validation.year"
                      label="Год"
                      hide-details="auto"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-checkbox
                      v-model="isPastMonth"
                      label="Отметить месяц как прошедший"
                      hide-details="auto"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-checkbox
                      v-model="invertColors"
                      label="Инвертировать цвета"
                      hide-details="auto"
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" lg="6">
                <v-row>
                  <v-col cols="6">
                    <upload-images
                    @changeFile="changeFileActiveMonth"
                    @fileInputClick="clickMonth"
                    @fileDelete = "deleteImageMouth('Active')"
                    :imageSelected="imageActiveMonthSelected"
                    :deleteButton="imageActiveMonthSelected ? true : false"
                      width="241"
                      height="241"
                      title="Фон активного месяца(необязательно)"
                      accept=".png"
                    />
                  </v-col>
                  <v-col cols="6">
                    <upload-images
                      @changeFile="changeFilePastMonth"
                      @fileInputClick="clickMonth"
                      @fileDelete = "deleteImageMouth('Past')"
                      :imageSelected="imagePastMonthSelected"
                      :deleteButton="imagePastMonthSelected ? true : false"
                      width="241"
                      height="241"
                      title="Фон прошедшего месяца(необязательно)"
                      accept=".png"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <div v-if="events" class="calendar__events">
              <v-expansion-panels v-model="panelEvent">
                <v-expansion-panel class="mb-2" v-for="(event, index) in events" :key="index" @click="onPanelEventClick($event, event)">
                  <v-expansion-panel-header>
                    <div class="calendar__event-header">
                      <span class="calendar__event-title">{{ event.title }}</span>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-progress-linear
                      v-if="isLoadingEvent"
                      indeterminate
                      color="rgb(77, 65, 246)"
                    />
                    <div class="mb-4">
                      <div class="calendar__event-content__header">
                        <v-btn
                          class="mr-2"
                          @click="updateCalendarEvent(event)"
                          :disabled="!isValid"
                        >
                          <v-progress-circular
                            v-if="isLoadingButton"
                            indeterminate
                            color="white"
                            :size="20"
                          />
                          <v-icon v-else small>mdi-content-save</v-icon>
                        </v-btn>
                        <v-btn
                          @click="confirmDialogEventOpen(event)"
                        >
                          <v-progress-circular
                            v-if="isLoadingButton"
                            indeterminate
                            color="white"
                            :size="20"
                          />
                          <v-icon v-else small>mdi-delete</v-icon>
                        </v-btn>
                      </div>
                    <v-form ref="formEvent" v-model="isValid" lazy-validation>
                      <v-row class="mb-4">
                        <v-col cols="4">
                          <v-text-field
                            v-model="eventData.title"
                            :rules="validation.title"
                            label="Название"
                            hide-details="auto"
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            v-model="eventData.day"
                            :rules="validation.day"
                            label="Число месяца"
                            hide-details="auto"
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-select
                            v-model="eventData.discipline"
                            :rules="validation.discipline"
                            label="Дисциплина"
                            :items="exerciseDisciplines"
                            item-text="name"
                            item-value="type"
                            clearable
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="eventData.subTitle"
                            :rules="validation.subTitle"
                            label="Подзаголовок (необязательно)"
                            hide-details="auto"
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                    <!-- <div class="calendar__event-header">
                      <p class="calendar__event-title">Логотипы (png, необязательно)</p>
                    </div>
                    <div class="calendar__event-logos">
                      <div class="calendar__event-logo" v-for="(logo, index) in eventData.logos" :key="`logo-${index}`">
                        <div class="calendar__event-block">
                          <div class="calendar__event-block__loading">
                            <v-progress-circular
                              v-if="isLoadingLogo"
                              indeterminate
                              color="white"
                              :size="20"
                            />
                          </div>
                          <img v-if="logo.url" :src="logo.url" alt="" />
                          <img v-else src="/img/empty.png" alt="" />
                        </div>
                        <div class="calendar__event-block__footer">
                          <span>36X36</span>
                          <v-icon @click="deleteCalendarEventLogo(logo)" left small color="#FF8233" :disabled="isLoadingLogo">mdi-close</v-icon>
                        </div>
                      </div>
                      <v-btn @click="handleFileInputLogoClick" :disabled="isLoadingLogo || countLogos >= 5">
                        <v-icon left>mdi-plus</v-icon>
                        лого
                      </v-btn>
                      <input id="fileUploadLogo" type="file" @change="handleLogoFileChange" accept=".png" hidden>
                    </div> -->
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-btn v-if="events.length < 20" color="#FF8233" plain @click="addNewEvent(item)" :disabled="isLoadingEvent">
                <v-progress-circular
                  v-if="isLoadingButton"
                  indeterminate
                  color="#FF8233"
                  :size="20"
                />
                <v-icon  v-if="!isLoadingButton" left>mdi-plus</v-icon>
                <span v-if="!isLoadingButton">добавить событие</span>
              </v-btn>
            </div>
            <div class="calendar__events-footer">
              <v-btn
                @click="updateCalendarMonth(item)"
                :disabled="!isValid"
              >
                <v-icon left>mdi-content-save</v-icon>
                Сохранить
              </v-btn>
              <v-btn @click="openConfirmDialog(item)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div class="calendar__footer">
      <v-btn large @click="createNewMonthDialogOpen" :disabled="isLoadingMonths">
        <v-icon left>mdi-plus</v-icon>
        Добавить Месяц
      </v-btn>
    </div>
    <notifications :duration="5000" position="bottom right"/>
    <v-dialog
      v-model="confirmDialogEvent"
      max-width="500"
    >
      <v-card>
        <v-card-title class="text-h6">
          Вы уверены что хотите удалить событие?
        </v-card-title>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn
            @click="confirmDialogEvent = false"
            :disabled="isLoadingButton"
          >
            Отменить
          </v-btn>

          <v-btn
            color="#FF8233"
            dark
            @click="deleteCalendarEvent"
            :disabled="isLoadingButton"
          >
          <v-progress-circular
              v-if="isLoadingButton"
              indeterminate
              color="white"
              :size="20"
            />
            <span v-else>
              Удалить
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="confirmDialog"
      max-width="500"
    >
      <v-card>
        <v-card-title class="text-h6">
          Вы уверены что хотите удалить месяц?
        </v-card-title>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn
            @click="confirmDialog = false"
            :disabled="isLoadingButton"
          >
            Отменить
          </v-btn>

          <v-btn
            color="#FF8233"
            dark
            @click="deleteCalendarMonth"
            :disabled="isLoadingButton"
          >
          <v-progress-circular
              v-if="isLoadingButton"
              indeterminate
              color="white"
              :size="20"
            />
            <span v-else>
              Удалить
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="createNewMonthDialog"
      max-width="500"
    >
      <v-card>
        <v-card-title class="text-h6">
          Создание нового месяца
        </v-card-title>
        <v-card-text>
          <v-form
            ref="formCreate"
            v-model="isValid"
            lazy-validation>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="month"
                  :rules="validation.month"
                  label="Месяц"
                  hide-details="auto"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="year"
                  :rules="validation.year"
                  label="Год"
                  hide-details="auto"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn
            @click="createNewMonthDialog = false"
          >
            Отменить
          </v-btn>
          <v-btn
            color="accent"
            @click="createCalendarMonth"
            :disabled="!isValid"
          >
            <v-progress-circular
              v-if="isLoadingButton"
              indeterminate
              color="white"
              :size="20"
            />
            <span v-else>Сохранить</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="confirmDialogImageCalendar"
      max-width="600"
    >
      <v-card>
        <v-card-title class="text-h6">
          Вы уверены что хотите удалить изображение?
        </v-card-title>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn
            @click="confirmDialogImageCalendar = false"
            :disabled="isLoadingButton"
          >
            Отменить
          </v-btn>

          <v-btn
            color="#FF8233"
            dark
            @click="deleteImageCalendar"
            :disabled="isLoadingButton"
          >
          <v-progress-circular
              v-if="isLoadingButton"
              indeterminate
              color="white"
              :size="20"
            />
            <span v-else>
              Удалить
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
  </div>
</template>

<script>
import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
import scountApiInitializer from '@/api/scountApi';
import { uuid } from "vue-uuid";
import uploadImages from '@/components/uploadImages.vue';
  export default {
    components: {uploadImages},
    setup() {
      const marketingProgramStore = useMarketingProgramStore()
      return { marketingProgramStore }
    },
    data: () => ({
      imageCalendarType: "",
      confirmDialogImageCalendar: false,
      currentDate: '{{currentDate}}',
      sportEventItems: '{{sportEventItems}}',
      isActive: true,
      title: "",
      subtitle: "",
      createNewMonthDialog: false,
      month: null,
      year: null,
      isPastMonth: false,
      invertColors: false,
      isValid: true,
      isLoading: false,
      isLoadingMonths: false,
      isLoadingEvent: false,
      isLoadingButton: false,
      isLoadingLogo: false,
      panel:[0],
      panelEvent: [],
      multiple: false,
      confirmDialog: false,
      confirmDialogEvent: false,
      calendarMonthId: null,
      calendarEventId: null,
      exerciseDisciplines: [
        {type: 'Running', name: 'Бег'},
        {type: 'Walking', name: 'Ходьба'},
        {type: 'Cycling', name: 'Велосипед'},
        {type: 'Swimming', name: 'Плавание'},
        {type: 'Skiing', name: 'Лыжи'},
      ],
      validation: {
        month: [
          value => !!value || 'Обязательное поле.',
          value => (value && (value >= 1 && value <= 12)) || 'Значение должно быть числом от 1 до 12.',
        ],
        year : [
          value => !!value || 'Обязательное поле.',
          value => (value && /^\d{4}$/.test(value) && parseInt(value) >= 1900) || 'Значение должно быть годом.'
        ],
        day: [
          value => !!value || 'Обязательное поле.',
          value => (!value || /^\d{1,2}$/.test(value) && parseInt(value) >= 1 && parseInt(value) <= 31) || 'Некорректное значение для числа от 1 до 31.'
        ],
        discipline: [
          value => !!value || 'Обязательное поле.',
        ],
        title: [
          value => !!value || 'Обязательное поле.',
          value => (value && value.length <= 48) || 'Не более 48 символов.'
        ],
          subTitle: [
          value => (!value || value.length <= 110) || 'Не более 110 символов.'
        ]
      },
    imageActiveMonthSelected: null,
    imageActiveMonthFile: null,
    imagePastMonthSelected: null,
    imagePastMonthFile: null,
    imageLogoSelected: null,
    imageLogoFiles: null,
    imageDesktopSelected: null,
    imageDesktopFile: null,
    imageMobileSelected: null,
    imageMobileFile: null,
    calendarData: {
      isPastMonth: false,
      isInvertColor: false
    },
    events: [],
    eventData: {},
    originalEventData: {},
    calendar: [],

    }),
    computed: {
      mp() {
        if (this.marketingProgramStore.isInitialized === false)
          return null;
        return this.marketingProgramStore;
      },
      marketApi() {
        if (!this.mp)
          return null;
        return scountApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
      },
      countLogos() {
        if(this.eventData.logos) {
          const logos = this.eventData?.logos
          return logos.length;
        }
        return null
      }
    },
    methods: {
      // Загрузка календаря
      async getCalendar () {
        const calendarId = this.mp.marketingProgramId
        const response = await this.marketApi.getCalendar(calendarId);
        this.isLoading = true;
        if(response.status === 200) {
          this.$notify({type: 'success', text: `Данные успешно загружены`});
          this.calendar = response.data;
          this.title = this.calendar.title;
          this.subtitle = this.calendar.subtitle;
          this.imageDesktopSelected = this.calendar.calendarImages.desktop
          this.imageMobileSelected = this.calendar.calendarImages.mobile
          this.isLoading = false;
        } else {
          this.isLoading = false;
          this.$notify({type: 'error', text: `Данные не были загружены`});
        }
      },
      // Сохранение календаря
      async changeCalendar() {
        const payload = {
          isActive: this.isActive,
          title: this.title,
          subtitle: this.subtitle,
        }
        const calendarId = this.mp.marketingProgramId
        this.isLoading = true;
        const response = await this.marketApi.updateCalendar(calendarId, payload)
        if (response.status === 204) {
          this.getCalendar();
          this.$notify({type: 'success', text: `Календарь успешно обновлен`});
          this.isLoading = false;
          if (this.mp.marketingProgramId && this.imageDesktopFile || this.imageMobileFile) {
            this.marketApi.uploadImageCalendar(this.mp.marketingProgramId, this.imageDesktopFile, this.imageMobileFile)
            .then (() => {
              this.$notify({type: 'success', text: `Изображение календаря успешно загружено`});
              this.getCalendar();
            })
          }
        } else {
          this.$notify({type: 'error', text: `Произошла ошибка`});
          this.isLoading = false;

        }
      },
      // Диалогвое окно удаления изображения календаря
      dialogImageCalendarOpen (item) {
        this.imageCalendarType = item
        this.confirmDialogImageCalendar = true
      },
      // Удаление изображения календаря
      async deleteImageCalendar() {
        const calendarId = this.mp.marketingProgramId
        const imageType = this.imageCalendarType
        this.isLoadingButton = true
        const response = await this.marketApi.deleteImagesCalendar(calendarId, imageType);
        if (response.status === 204) {
          this.confirmDialogImageCalendar = false
          this.getCalendar()
          this.$notify({type: 'success', text: `Изображение ${imageType} успешно удалено`});
          this.isLoadingButton = false
        } else {
          this.$notify({type: 'error', text: `Произошла ошибка`});
          this.isLoadingButton = false
        }
      },
      // Диалоговое окно создания нового месяца
      createNewMonthDialogOpen() {
        if(this.$refs.formCreate) {
          this.$refs.formCreate.reset();
        }
        this.panel = [];
        this.month = null;
        this.year = null;
        this.createNewMonthDialog = true;
      },
      // Создание нового месяца
      async createCalendarMonth() {
        try {
        const valid = await this.$refs.formCreate.validate();
        if (!valid)
          return;
        this.isLoadingButton = true;
        const calendarMonthId = uuid.v4();
        const data = {
          calendarMonthId,
          month: +this.month,
          year: +this.year,
        }
          const response = await this.marketApi.createCalendarMonth(data)
          if(response.success) {
            this.createNewMonthDialog = false;
            this.getCalendar(this.mp.marketingProgramId);
            this.panel = [];
            this.$notify({type: 'success', text: `Месяц сохранен`});
          }
          this.isLoadingButton = false;
        } catch(error) {
          this.$notify({type: 'error', text: `Данные не были загружены ${error}`});
        }
      },
      // Клик по панели месяца
      async onPanelClick(event, item) {
        try {
          this.isLoadingMonths = true;
          this.calendarMonthId = item.calendarMonthId;
          const panel = event.currentTarget;
          const isPanelActive = panel.classList.value.includes('v-expansion-panel-header--active')
          if (!isPanelActive && item.calendarMonthId) {
            this.getCalendarMonth(item.calendarMonthId)
            .then (() => {
              this.isLoadingMonths = false;
            }) 
          } else {
            this.isLoadingMonths = false;
          }
        } catch(error){
          this.$notify({type: 'error', text: `Произошла ошика ${error.detail}`});
          this.isLoadingMonths = false;
        }
      },
      // Загрузка данных месяца
      async getCalendarMonth() {
        try {
          const calendarMonthId = this.calendarMonthId;
          const response = await this.marketApi.getCalendarMonth(calendarMonthId);
          if(response.status === 200) {
            this.$notify({type: 'success', text: `Данные успешно загружены`});
            const {year, month, images, isPastMonth, invertColors} = response.data;
            this.getCalendarEvents();
            this.isLoading = false;
            this.year = year;
            this.month = month;
            this.isPastMonth = isPastMonth;
            this.invertColors = invertColors;
            this.imageActiveMonthSelected = images.active
            this.imagePastMonthSelected = images.past
          } else {
            this.panel = [];
            this.$notify({type: 'error', text: `Данные не были загружены`});
          }
        } catch(error) {
          this.$notify({type: 'error', text: `Данные не были загружены: ${error}`});
        }
      },
      // Обновление месяца
      async updateCalendarMonth(item) {
        this.calendarMonthId = item.calendarMonthId;
        const calendarMonthId = this.calendarMonthId;
        const payload = {
          month: +this.month,
          year: +this.year,
          isPastMonth: this.isPastMonth,
          invertColors: this.invertColors
        }
        try {
          this.isLoadingMonths = true;
          const response = await this.marketApi.updateCalendarMonth(calendarMonthId, payload)
          if(response.success) {
            if(calendarMonthId && this.imageActiveMonthFile || this.imagePastMonthFile) {
              await this.marketApi.uploadAciveMonthImage(calendarMonthId, this.imagePastMonthFile, this.imageActiveMonthFile)
              .then (() => {
                this.$notify({type: 'success', text: `Изображение месяца успешно загружено`});
                this.imageActiveMonthFile = null;
                this.imagePastMonthFile = null;
              })
              .catch(() => {
                this.$notify({type: 'error', text: `Изображение активного месяца не было загружено`});
              })
            }
            this.$notify({type: 'success', text: `Месяц сохранен`});
            this.getCalendarMonth();
            this.getCalendar();
            this.isLoadingMonths = false;
          } else {
            this.$notify({type: 'error', text: `Данные не были обновлены`});
            this.panel = [];
            this.isLoadingMonths = false;
          }
        } catch(error) {
          this.$notify({type: 'error', text: `Данные не были загружены ${error}`});
        }
      },
      // Удаление месяца
      async deleteCalendarMonth() {
        if(this.calendarMonthId) {
          try {
            const response = await this.marketApi.deleteCalendarMonth(this.calendarMonthId)
            if(response.status === 204) {
              this.getCalendar(this.mp.marketingProgramId);
              this.confirmDialog = false;
              this.panel = [];
              this.$notify({type: 'success', text: `Месяц успешно удален`});
            } else {
              this.$notify({type: 'error', text: `Произошла ошибка при удалении`});
            }
          } catch(error) {
            this.$notify({type: 'error', text: `Произошла ошибка: ${error.detail}`});

          }
        }
      },
      // Удаление картинки месяца
      async deleteImageMouth(type) {
        const calendarMonthId = this.calendarMonthId;
        const imageType = type;
        const response = await this.marketApi.deleteImagesMounth(calendarMonthId, imageType);
        if(response.status === 204) {
          this.$notify({type: 'success', text: `Месяц ${imageType} успешно удален`});
          this.getCalendarMonth();
        }
      },
      // Клик по панели события
      async onPanelEventClick(event, item) {
        this.calendarEventId = item.calendarEventId;
        const panel = event.currentTarget;
        const isPanelActive = panel.classList.value.includes('v-expansion-panel-header--active');
        if (!isPanelActive &&  this.calendarEventId) {
          this.getCalendarEvent(this.calendarEventId);
        }
      },
      // Загрузка событий месяца
      async getCalendarEvents() {
        const calendarMonthId = this.calendarMonthId;
        try {
          const response = await this.marketApi.getCalendarMonthEvents(calendarMonthId);
          if(response.status === 200) {
            this.events = response.data
            this.$notify({type: 'success', text: `События успешно загружены`});
          }
        } catch(error) {
          this.$notify({type: 'error', text: `Данные не были загружены ${error}`});
        }
      },
      // Загрузка одного события
      async getCalendarEvent() {
        try {
          this.isLoadingEvent = true;
          const response = await this.marketApi.getCalendarEvent(this.calendarEventId)
          if(response.status === 200) {
          this.eventData = response.data
          } else {
            this.panelEvent = [];
            this.$notify({type: 'error', text: `Данные не были загружены`});
          }
          this.isLoadingEvent = false;
          this.isLoadingButton = false;
        } catch(error) {
          this.$notify({type: 'error', text: `Данные не были загружены ${error}`});
        }
      },
      // Создание события
      async createCalendarEvent() {
        try {
          this.isLoadingButton = true;
          const calendarEventId = uuid.v4();
          const newEvent = {
            calendarMonthId: this.calendarMonthId,
            title: 'Название',
            day: null,
            discipline: '',
            subTitle: '',
          };
        const response = await this.marketApi.createCalendarEvent(calendarEventId, newEvent);
        if(response.success) {
          this.events.unshift(newEvent);
          this.isLoadingButton = false;
          this.getCalendarEvents();

        } else {
          this.$notify({type: 'error', text: `Ошибка при создании`});
          this.isLoadingButton = false;
        }
        } catch(error) {
          this.$notify({type: 'error', text: `Данные не были загружены ${error}`});
          this.isLoadingButton = false;
        }
      },

      // Обновление события
      async updateCalendarEvent(item) {
        this.isLoadingEvent = true;
        this.isLoadingButton = true;
        try {
          const newEvent = {
            title: this.eventData.title,
            day: +this.eventData.day,
            discipline: this.eventData.discipline,
            subTitle: this.eventData.subTitle,
            calendarMonthId: this.calendarMonthId
          };
          this.calendarEventId = item.calendarEventId;
          if (this.calendarEventId && this.calendarMonthId) {
            const response = await this.marketApi.updateCalendarEvent(this.calendarEventId, newEvent);
            if(response.success) {
              this.$notify({ type: 'success', text: 'Данные события обновлены' });
              this.getCalendarEvents();
              this.isLoadingEvent = false;
              this.isLoadingButton = false;
            } else {
              this.$notify({ type: 'error', text: 'Произошла ошибка: при обновлении события календаря.' });
              this.isLoadingEvent = false;
              this.isLoadingButton = false;
            }
          }
        } catch (error) {
          this.$notify({ type: 'error', text: 'Произошла ошибка: при обновлении события календаря.' });
        }
      },

      // Удаление события
      async deleteCalendarEvent() {
        try {
          this.isLoadingButton = true;
          const response = await this.marketApi.deleteCalendarEvent(this.calendarEventId)
          if(response.status === 204) {
            this.$notify({type: 'success', text: `Событие успешно удалено`});
            this.confirmDialogEvent = false;
            this.isLoadingButton = false;
            this.panelEvent = [];
            this.getCalendarEvents();
          } else {
            this.$notify({type: 'error', text: `Ошибка при удалении`});
            this.confirmDialogEvent = false;
            this.isLoadingButton = false;
          }
        } catch(error) {
          this.$notify({type: 'error', text: `Произошла ошика ${error.detail}`});
          this.confirmDialogEvent = false;
        }
      },

      // Обработка картинки логотипа
      async handleLogoFileChange(event) {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
          const reader = new FileReader();
          reader.onload = () => {
            this.imageLogoSelected = reader.result;
            this.imageLogoFiles = selectedFile;
            this.isLoadingLogo = true;
            if (selectedFile) {
              this.uploadAndRefresh();
            }
          };
          reader.readAsDataURL(selectedFile);
        }
      },

      // Загрузка логотипа
      async uploadCalendarLogo() {
        try {
          const imageKey = uuid.v4();
          const response = await this.marketApi.uploadCalendarLogo(this.calendarEventId, imageKey, this.imageLogoFiles);
          if(response.success) {
            this.getCalendarEvent();
            this.$notify({type: 'success', text: `Логотип загружен`});
          } else {
            this.$notify({type: 'error', text: `Проиошла ошибка при загрузке логотипа`});
          }
        } catch(error) {
          this.$notify({type: 'error', text: `Проиошла ошибка ${error.detail}`});
        }
      },

      // Удаление логотипа
      async deleteCalendarEventLogo(imageKey){
        const imageKeyGuid = this.stringToGuid(imageKey.logo);
        try {
          this.isLoadingLogo = true;
          if(this.calendarMonthId && this.calendarEventId) {
          const response =  await this.marketApi.deleteCalendarEventLogo( this.calendarEventId, imageKeyGuid);
          if(response.status === 204) {
            this.$notify({type: 'success', text: `Логотип успешно удален`});
            this.getCalendarEvent();
            this.isLoadingLogo = false;
          } else {
            this.isLoadingLogo = false;
          }
        }} catch(error) {
          this.$notify({type: 'error', text: `Произошла ошибка: ${error.detail}`});
          this.isLoadingLogo = false;
        }
      },
      // Формирование имени месяца
      getMonthName(monthNumber) {
        const months = [
          'Январь', 'Февраль', 'Март', 'Апрель',
          'Май', 'Июнь', 'Июль', 'Август',
          'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'
        ];
        if (monthNumber >= 1 && monthNumber <= 12) {
          return months[monthNumber - 1];
        } else {
          return '';
        }
      },
      // Преобразование строки в guid
      stringToGuid(str) {
          return str.replace(/^(.{8})(.{4})(.{4})(.{4})(.{12})$/, '$1-$2-$3-$4-$5');
      },

      openConfirmDialog() {
        this.confirmDialog = true;
      },

      confirmDialogEventOpen(item) {
        this.calendarEventId = item.calendarEventId;
        this.confirmDialogEvent = true;
      },

      handleFileInputLogoClick() {
        document.getElementById("fileUploadLogo").click()
      },

      async uploadAndRefresh() {
        await this.uploadCalendarLogo();
        setTimeout(() => {
          this.isLoadingLogo = false;
          this.getCalendarEvent();
        }, 1000);
      },

      addNewEvent() {
        this.createCalendarEvent()
        this.getCalendarEvents();
      },
      clickMonth(fileUploadId) {
        document.getElementById(fileUploadId).click();
      },
      changeDesktopImageCalendar(imageFile, file) {
        if(imageFile) {
          this.imageDesktopSelected = imageFile
          this.imageDesktopFile = file
        }
      },
      changeMobileImageCalendar(imageFile, file) {
        if(imageFile) {
          this.imageMobileSelected = imageFile
          this.imageMobileFile = file
        }
      },
      changeFileActiveMonth(imageFile, file) {
        if(imageFile) {
          this.imageActiveMonthFile = file
          this.imageActiveMonthSelected = imageFile
        }
      },
      changeFilePastMonth(imageFile, file) {
        if(imageFile) {
          this.imagePastMonthFile = file
          this.imagePastMonthSelected = imageFile
        }
      },
    },
    mounted() {
      this.getCalendar()
    }
  }
</script>

<style lang="scss" scoped>
.calendar {
  padding: 0 12px;
  .v-expansion-panels .v-expansion-panel {
    background-color: rgba(1, 1, 1, 0.02);
  }
  .v-expansion-panel--active:not(:first-child), .v-expansion-panel--active + .v-expansion-panel {
    margin: 0;
  }
  &__title {
    font-size: 23px;
    line-height: 32px;
    margin: 0;
  }
  &__image {
    max-width: 241px;

    &-title {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.5px;
      margin-bottom: 12px;
    }
    &-main {
      width: 241px;
      height: 241px;
      border-radius: 16px;
      overflow: hidden;
      img {
        max-width: 100%;
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 16px;
      }
    }
    &-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 12px;
      padding-bottom: 12px;
    }
    &-size {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
    }
    .v-btn {
      padding: 0;
    }
  }
  &__footer {
    padding: 12px 0;
  }
  &__events {
    background-color: #fff;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.12), 0px 1px 2px 0px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    border: 1px solid rgba(1, 1, 1, 0.12);
    padding: 16px;
    margin-bottom: 12px;
    &-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px;
    }
  }
  &__event {
    border-radius: 4px;
    border: 1px solid rgba(1, 1, 1, 0.25);
    padding: 12px 12px 24px 12px;
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-block {
      position: relative;
      width: 36px;
      height: 36px;
      &__footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        gap: 12px;
      }
      &__loading {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.2);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &-content__header {
      display: flex;
      justify-content: flex-end;
    }
    &-logos {
      display: flex;
      align-items: center;
      gap: 12px;
    }
    &-logo {

      img {
        max-width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

</style>