var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-btn',{on:{"click":_vm.openCreateTaskDialog}},[_vm._v("Добавить работу")])],1)],1),_c('DataTable',{key:_vm.filtersHash,attrs:{"controller":"inRoute","headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.total,"sort-by":"date","sort-desc":true,"search-fields":_vm.searchFields,"loading":_vm.updating},on:{"update:table-options":_vm.tableUpdated},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.title)+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTypeDisplay(item.type))+" ")]}},{key:"item.order",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.order)+" ")]}},{key:"item.reward",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.reward)+" ")]}},{key:"item.settings",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(_vm.getDiscipline(item.settings.Discipline).icon)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Дисциплина: "+_vm._s(_vm.getDiscipline(item.settings.Discipline).text))])])]}},{key:"item.createdOn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.createdOn))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.tableRowClicked(item)}}},[_vm._v("mdi-pencil")])]}}],null,true)}),(_vm.exerciseTaskEditorDialogShow)?_c('ExerciseTaskEditorDialog',{attrs:{"object-id":_vm.taskId,"object":_vm.task,"exercise-type":_vm.exerciseType,"save-func":_vm.saveExerciseTask,"formTask":"formTask"},on:{"closed":function($event){_vm.exerciseTaskEditorDialogShow = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }