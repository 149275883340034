var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-alert',{attrs:{"border":"left","colored-border":"","type":"info","elevation":"2"}},[_vm._v(" Изменения параметров в заданиях каталога не применяются к экземплярам заданиям в МП. Не забудьте обновить задания в соответствующих МП ")]),_c('DataTable',{key:_vm.filtersHash,attrs:{"controller":"inRoute","headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.total,"sort-by":"date","sort-desc":true,"search-title":"Найти задание","search-fields":_vm.searchFields,"loading":_vm.updating},on:{"update:table-options":_vm.tableUpdated},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{on:{"click":function($event){return _vm.editItem()}}},[_vm._v("Добавить шаблон задания")])]},proxy:true},{key:"item.attributes",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"color":item.isAutomaticAssign ? 'success' : ''}},[_vm._v(" mdi-refresh-auto ")])],1)]}}],null,true)},[_c('span',[_vm._v("Авто")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.getType(item.type).color}},[_vm._v(" "+_vm._s(_vm.getType(item.type).icon)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getType(item.type).text))])])]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.title)+" "),_c('div',{staticClass:"exercise-id"},[_vm._v("ID: "+_vm._s(item.exerciseTemplateId))])]}},{key:"item.complexity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.complexity)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!item.images.desktop || !item.images.mobile)?_c('v-tooltip',{attrs:{"color":"gray","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v("mdi-alert")])]}}],null,true)},[_c('span',{staticClass:"tooltip__span"},[_vm._v(" Не загружены картинки ")])]):_vm._e(),_vm._v("   "),_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{on:{"click":function($event){return _vm.openDeleteConfirm(item)}}},[_vm._v("mdi-delete")])]}}],null,true)}),_c('v-dialog',{attrs:{"width":"720"},model:{value:(_vm.dialog.opened),callback:function ($$v) {_vm.$set(_vm.dialog, "opened", $$v)},expression:"dialog.opened"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(_vm._s(_vm.dialog.title))]),_c('v-card-text',{domProps:{"innerHTML":_vm._s(_vm.dialog.text)}}),_c('v-card-actions',[_c('v-btn',{on:{"click":_vm.closeDialog}},[_vm._v("Закрыть")]),(_vm.dialog.actionDialog)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.dialog.actionDialog}},[_vm._v(_vm._s(_vm.dialog.actionName))]):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }