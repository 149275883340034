<template>
  <div v-if="task">
    <p><b>ID:</b> {{taskId}}</p>

    <v-row>
      <v-col>
        <v-btn @click="exerciseTaskEditorDialogShow = true">Редактировать</v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-text-field
          label="Название"
          :value="task.title"
          disabled
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-text-field
          label="Тип"
          :value="task.type"
          disabled
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          label="Порядок"
          :value="task.order"
          disabled
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-text-field
          :label="labelReward"
          :value="task.reward"
          disabled
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          label="Максимальное вознаграждение"
          :value="task.maxReward"
          disabled
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-text-field
          label="Дисциплина"
          :value="task.settings.Discipline"
          disabled
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-text-field
          label="Мин дистанция"
          :value="task.settings.MinDistance"
          disabled
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          label="Макс дистанция"
          :value="task.settings.MaxDistance"
          disabled
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-text-field
          label="Мин темп"
          :value="task.settings.MinPace"
          disabled
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          label="Макс темп"
          :value="task.settings.MaxPace"
          disabled
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          label="Макс время (сек)"
          :value="task.settings.MaxTimeInSeconds"
          disabled
        ></v-text-field>
      </v-col>
    </v-row>

    <ExerciseTaskEditorDialog
      v-if="exerciseTaskEditorDialogShow"
      :object-id="taskId"
      :object="task"
      :save-func="changeExerciseTask"
      @closed="exerciseTaskEditorDialogShow = false"
    />
  </div>
</template>

<script>
import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
import scountApiInitializer from '@/api/scountApi';
import ExerciseTaskEditorDialog from './ExerciseTaskEditorDialog.vue';

export default {
  name: 'ExerciseTaskView',
  components: { ExerciseTaskEditorDialog },

  setup() {
    const marketingProgramStore = useMarketingProgramStore()
    return { marketingProgramStore }
  },

  data: () => ({
    task: null,
    updating: false,
    exerciseTaskEditorDialogShow: false,
  }),

  computed: {
    exerciseId() {
      return this.$route.params.exerciseId;
    },

    taskId() {
      return this.$route.params.taskId;
    },

    mp() {
      if (this.marketingProgramStore.isInitialized === false)
        return null;

      return this.marketingProgramStore;
    },

    scountApi() {
      if (!this.mp)
        return null;

      return scountApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
    },

    labelReward: function() {
      if (this.model && this.model.settings) {
        const d = this.model.settings.Discipline
        if (d === 'Swimming') return 'Вознаграждение за 100м';
        else return "Вознаграждение за км";
      }
      return "Вознаграждение";
    }
  },

  methods: {
    async getExerciseTask() {
      const response = await this.scountApi.getExerciseTask(this.exerciseId, this.taskId);
      this.task = response.data;
    },

    async changeExerciseTask(model) {
      await this.scountApi.changeExerciseTask(this.exerciseId, this.taskId, model);
      await this.getExerciseTask();
    },
  },

  async mounted() {
    this.updating = true;
    try {
      await this.getExerciseTask();
    } finally {
      this.updating = false;
    }
  },
}
</script>
