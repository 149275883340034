<template>
  <div>
    <div class="article__h1 mb-6 pl-2">Кнопка подписки на группу в ВК</div>
    <v-form ref="form" lazy-validation class="mb-6">
      <v-row>
        <v-col>
          <v-text-field
            label="ID группы в ВК"
            v-model="externalId"
            :rules="validation.externalId"
            hide-details="auto"
          ></v-text-field>
        </v-col>
        <v-col cols="2"><v-btn @click="saveId">Сохранить</v-btn></v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-col class="article__h2"><span class="pl-2">Статьи</span></v-col>
      <v-col cols="2"><v-btn @click="addArticle" :disabled="newArticleAdded">Добавить статью</v-btn></v-col>
    </v-row>
    <DataTable
      :key="filtersHash"
      controller="inRoute"
      :headers="headers"
      :items="items"
      :server-items-length="total"
      :loading="updating"
      @update:table-options="tableUpdated"
    >
      <template v-slot:[`item.title`]="{item}">
        <v-text-field
          label="Заголовок"
          v-model="item.title"
          :rules="validation.articleTitle"
          hide-details="auto"
        ></v-text-field>
      </template>
      <template v-slot:[`item.link`]="{item}">
        <v-text-field
          label="Ссылка на статью"
          v-model="item.link"
          :rules="validation.articleLink"
          hide-details="auto"
        ></v-text-field>
      </template>
      <template v-slot:[`item.type`]="{item}">
        <v-select
          v-model="item.type"
          label="Тип"
          :items="types"
          item-text="name"
          item-value="value"
          :disabled="true"
        ></v-select>
      </template>
      <template v-slot:[`item.number`]="{item}">
        <v-text-field
          label="№"
          v-model="item.order"
          hide-details="auto"
        ></v-text-field>
      </template>
      <template v-slot:[`item.actions`]="{item}">
        <div class="d-flex mt-auto mt-2">
          <v-radio-group v-model="item.isMain">
            <v-radio
              label="Топ"
              :value="true"
              class="mr-4"
              :readonly="true"
              :disabled="!item.articleId"
              @click="setMainClick(item)"
            ></v-radio>
          </v-radio-group>
          <v-btn @click="saveArticle(item)" class="mr-3" minWidth="40" width="40" :disabled="item.state === 'Active' && !!item.articleId"><v-icon>mdi-floppy</v-icon></v-btn>
          <v-btn @click="activateArticle(item)" class="mr-4" minWidth="40" width="40" :disabled="!item.articleId">
            <v-icon v-if="item.state !== 'Active'">mdi-play-circle</v-icon>
            <v-icon v-if="item.state === 'Active'">mdi-eye-off</v-icon>
          </v-btn>
          <v-icon class="jsp-cp" @click="deleteArticle(item)" :disabled="!item.articleId">mdi-delete</v-icon>
        </div>
        <div v-if="item.state === 'Active' && !!item.articleId" class="mb-2">
          <v-icon>mdi-alert-circle</v-icon>
          Для изменения статьи необходимо ее деактивировать.
        </div>
      </template>
    </DataTable>
    <v-dialog v-model="dialog.opened" width="720">
      <v-card>
        <v-card-title class="headline">{{ dialog.title }}</v-card-title>
        <v-card-text v-html="dialog.text">
        </v-card-text>
        <v-card-actions>
          <v-btn @click="hideError">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay :value="loading"></v-overlay>
  </div>
</template>

<script>
import { uuid } from "vue-uuid";
import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
import communityApiInitializer from '@/api/communityApi';

export default {
  name: 'CommunityView',

  setup() {
    const marketingProgramStore = useMarketingProgramStore()
    return { marketingProgramStore }
  },

  computed: {
    communityId() {
      return this.$route.params.communityId;
    },

    mp() {
      if (this.marketingProgramStore.isInitialized === false)
        return null;

      return this.marketingProgramStore;
    },

    communityApi() {
      if (!this.mp)
        return null;

      return communityApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
    },
  },

  data: () => ({
    newArticleAdded: false,
    loading: false,
    externalId: undefined,
    filtersHash: Date.now(),
    headers: [
      { value: 'title', text: '', align: 'start', sortable: false },
      { value: 'link', text: '', align: 'start', sortable: false },
      { value: 'type', text: '', align: 'start', sortable: false },
      { value: 'number', text: '', align: 'start', sortable: false, width: 60 },
      { value: 'actions', text: '', align: 'start', sortable: false, width: 440 },
    ],
    items: [],
    total: 0,
    searchFields: [
      { key: 'name', label: 'Наименование' },
    ],
    updating: false,
    types: [],
    validation: {
      articleTitle: [
        value => !!value || 'Обязательное поле.',
        value => (value && value.length <= 256) || 'Не более 256 символов.',
      ],
      articleLink: [
        value => !!value || 'Обязательное поле.',
        value => (value && value.length <= 512) || 'Не более 512 символов.',
      ],
    },
    dialog: {
      opened: false,
      title: undefined,
      text: undefined
    },
  }),

  methods: {
    async tableUpdated(pagination, sorting, search) {
      await this.getArticles(pagination, sorting, search);
      this.newArticleAdded = false;
    },

    async getCommunity() {
      const response = await this.communityApi.getCommunity(this.communityId);
      if (!response.success) {
        if (response.error && response.error.detail && response.error.detail.startsWith('The community with ID')) {
          this.goToCommunities();
          return;
        }

        this.showError('Ошибка при получении сообщества', this.getAfterSaveText(response.error));
        return;
      }

      this.externalId = response.data.externalId;
    },

    async getArticles(pagination, sorting, search) {      
      if (!this.communityApi) return;

      this.updating = true;

      try {
        const response = await this.communityApi.getArticles(
          this.communityId,
          pagination,
          sorting,
          search,
        );

        const {values, total} = response.data;

        this.total = total;
        this.items = values;
      } finally {
        this.updating = false;
      }
    },

    async saveId() {
      this.loading = true;
      try {
        await this.updateCommunity();
        await this.getCommunity();
      } finally {
        this.loading = false;
      }
    },

    addArticle() {
      this.items.unshift({});
      if (!this.total || this.total < 1)
        this.total = 1;
      this.newArticleAdded = true;
    },

    async saveArticle(article) {
      this.loading = true;

      const isNewArticle = !article.articleId;

      let request = {
        articleId: isNewArticle ? uuid.v4() : article.articleId,
        title: article.title,
        link: article.link,
        order: article.order,
      };

      let response = isNewArticle 
        ? await this.communityApi.createArticle(this.communityId, request)
        : await this.communityApi.updateArticle(this.communityId, article.articleId, request);

      if (response.success) {
        this.filtersHash = Date.now();
      }
      else
        this.showError('Ошибка при создании/сохранении статьи', this.getAfterSaveText(response.error));
        
      this.loading = false;
    },

    async setMainClick(article) {
      if (article.isMain)
        return;

      this.loading = true;

      let response = await this.communityApi.setMainArticle(this.communityId, article.articleId);

      if (response.success) {
        this.filtersHash = Date.now();
      }
      else
        this.showError('Ошибка при обработке данных', this.getAfterSaveText(response.error));
        
      this.loading = false;
    },

    async activateArticle(article) {
      this.loading = true;

      let response = article.state === 'Active' 
        ? await this.communityApi.inactiveArticle(this.communityId, article.articleId)
        : await this.communityApi.activeArticle(this.communityId, article.articleId);

      if (response.success) {
        this.filtersHash = Date.now();
      }
      else
        this.showError('Ошибка при обработке данных', this.getAfterSaveText(response.error));
        
      this.loading = false;
    },

    async deleteArticle(article) {
      this.loading = true;

      let response = await this.communityApi.deleteArticle(this.communityId, article.articleId);

      if (response.success) {
        this.filtersHash = Date.now();
      }
      else
        this.showError('Ошибка при удалении статьи', this.getAfterSaveText(response.error));
        
      this.loading = false;
    },

    async updateCommunity() {
      const request = {
        externalId: this.externalId,
      };

      const response = await this.communityApi.updateCommunity(this.communityId, request);
      if (!response.success)
        this.showError('Ошибка при создании сообщества', this.getAfterSaveText(response.error));
    },

    getAfterSaveText(error) {
        const e = error;
        if (!e) return 'Произошла ошибка';
        if (e.status === 409 && e.detail === 'The article should not be the main.')
          return 'Главную статью нельзя сделать неактивной.';
        if (e.status === 409 && e.detail === 'The article must be active.')
          return 'Главной можно сделать только активную статью.';
        if (e.status === 409 && e.detail && e.detail.startsWith('The article with Order'))
            return 'Статья с номером уже есть или номер статьи не заполнен.';
        if (e.status === 409 && e.detail === 'One or more validation errors occurred.')
          return 'Введенные данные некорректны.';
        if (e.status === 409 && e.detail === 'The article must be inactive.')
          return 'Для удаления статьи необходимо ее деактивировать.';
        return e.detail;
    },

    showError(title, text) {
      this.dialog.title = title;
      this.dialog.text = text;
      this.dialog.opened = true;
    },

    hideError() {
      this.dialog.title = undefined;
      this.dialog.text = undefined;
      this.dialog.opened = false;
    },

    goToCommunities() {
      this.$router.push('/communities');
    },
  },

  async mounted() {
    this.loading = true;
    try {
      await this.getCommunity();
    } finally {
      this.loading = false;
    }
  },
}
</script>

<style lang="scss" scoped>
  @import '../../assets/global';
</style>