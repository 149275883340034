<template>
  <EditorDialog
    :title="!objectId ? 'Создание нового задания' : 'Редактирование задания'"
    :max-width="1000"
    :save-func="save"
    @closed="localClose"
  >
    <v-form ref="form" lazy-validation>
    <v-row>
      <v-col>
        <v-text-field
          label="Название"
          v-model="model.title"
          :rules="validation.title"
          hide-details="auto"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-text-field
          label="Короткое описание"
          v-model="model.shortDescription"
          :rules="validation.shortDescription"
          hide-details="auto"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col md="8">
        <v-row>
          <v-col>
            <!-- <v-textarea
              label="Описание"
              v-model="model.description"
              :rules="validation.description"
              hide-details="auto"
            ></v-textarea> -->
            <vue-editor v-model="model.description" :editorToolbar="customToolbar"></vue-editor>
          </v-col>
        </v-row>

        <v-row v-if="!!objectId">
          <v-col>
            <ImageSelector
              :preview-url="model.images ? model.images.mobile : null"
              :read-only="false"
              :save-image-func="changeMobileImage"
              accept=".jpg"
              title="Превью (608x280)"
              :subTitle="mobileImageSubTitle"
              @show-preview="showImagePreview('mobile')"
            ></ImageSelector>
          </v-col>
          <v-col>
            <ImageSelector
              :preview-url="model.images ? model.images.desktop : null"
              :read-only="false"
              :save-image-func="changeDesktopImage"
              accept=".jpg"
              title="Основное (1181x545)"
              :subTitle="desktopImageSubTitle"
              @show-preview="showImagePreview('desktop')"
            ></ImageSelector>
          </v-col>
        </v-row>
      </v-col>

      <v-col md=4>
        <v-row>
          <v-col>
            <v-text-field
              label="Приоритет"
              v-model="model.priority"
              :rules="validation.priority"
              hide-details="auto"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              label="Сложность"
              v-model="model.complexity"
              :rules="validation.complexity"
              hide-details="auto"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              label="Дата начала"
              v-model="model.startDate"
              :rules="validation.startDate"
              hide-details="auto"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="Дата завершения"
              v-model="model.endDate"
              :disabled="isEndDateExpired"
              :rules="validation.endDate"
              hide-details="auto"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              label="Часов на выполнение(необязательно)"
              v-model="model.timeLimit"
              :rules="validation.timeLimit"
              hide-details="auto"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              v-model="model.type"
              label="Тип задания"
              :items="exerciseTypes"
              item-text="name"
              item-value="type"
              :disabled="!!objectId"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-switch
              label="Назначать автоматически"
              v-model="model.isAutomaticAssign"
            ></v-switch>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    </v-form>
  </EditorDialog>
</template>

<script>
import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
import editorDialogMixin from '@/global-components/dialog/mixins';
import Moment from 'moment'
import { VueEditor } from "vue2-editor";

export default {
  name: 'ExerciseEditorDialog',
  mixins: [editorDialogMixin],
  components: {
        VueEditor
      },
  props: {
    object: {
      type: Object,
      default: () => {
        return {
          title: null,
          shortDescription: null,
          description: null,
          images: null,
          type: null,
          priority: 1,
          complexity: 1,
          startDate: null,
          endDate: null,
          isAutomaticAssign: false,
          timeLimit: null
        }
      }
    },
    saveFunc: Function,
  },

  setup() {
    const marketingProgramStore = useMarketingProgramStore()
    return { marketingProgramStore }
  },

  data: () => ({
    isEndDateExpired: false,
    exerciseTypes: [
      {type: 'Disposable', name: 'Невозобновляемое', hint: 'После выполнения можно взять руками'},
      {type: 'Reusable', name: 'Возобновляемое', hint: 'После выполнения будет назначено автоматически'},
      {type: 'Regular', name: 'Регулярное', hint: 'Выполняется регулярно'},
    ],
    customToolbar: [
          ["bold",{ list: "ordered"}, {list: "bullet" }, "link"],
        ],
    validation: {
      title: [
        value => !!value || 'Обязательное поле.',
        value => (value && value.length <= 256) || 'Не более 256 символов.',
      ],
      shortDescription: [
        value => !!value || 'Обязательное поле.',
        value => (value && value.length <= 10000) || 'Не более 10000 символов.',
      ],
      description: [
        value => !!value || 'Обязательное поле.',
        value => (value && value.length <= 10000) || 'Не более 10000 символов.',
      ],
      priority: [
        value => !!value || 'Обязательное поле.',
        value => /^\d+(\.\d{1,2})?$/.test(value) || 'Значение должно быть числом не меньше 0',
      ],
      complexity: [
        value => !!value || 'Обязательное поле.',
        value => /^\d+(\.\d{1,2})?$/.test(value) || 'Значение должно быть числом не меньше 0',
      ],
      startDate: [
        value => !!value || 'Обязательное поле.',
        value => /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[012])\.(19|20)\d\d (0[0-9]|1[0-9]|2[0-4]):\d\d$/i.test(value) || 'Некорректная дата. Формат ДД.ММ.ГГГГ ЧЧ:ММ',
      ],
      endDate: [
        value => !!value || 'Обязательное поле.',
        value => /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[012])\.(19|20)\d\d (0[0-9]|1[0-9]|2[0-4]):\d\d$/i.test(value) || 'Некорректная дата. Формат ДД.ММ.ГГГГ ЧЧ:ММ',
      ],
      timeLimit: [
        value => value === null || value === undefined || value === '' || (/^\d+(\.\d{1,2})?$/.test(value) && value >= 1) || 'Значение должно быть числом не меньше 1',
      ],
    },
    desktopImageSubTitle: null,
    mobileImageSubTitle: null,
  }),

  methods: {
    async save() {
      this.saving = true;
      this.valid = await this.$refs.form.validate();

      if (!this.valid)
        return;

      this.saveFunc(this.model);
      console.log(this.model);
    },

    localClose() {
      const saving = this.saving;
      this.saving = false;

      if (saving && !this.valid)
        return;

      this.close();
    },

    changeDesktopImage(file) {
      this.model.desktopImageFile = file;
      this.desktopImageSubTitle = `${file.name} (${file.size / 1024}KB)`;
    },

    changeMobileImage(file) {
      this.model.mobileImageFile = file;
      this.mobileImageSubTitle = `${file.name} (${file.size / 1024}KB)`;
    },

    showImagePreview(type) {
      this.showPreview = true;
      this.showPreviewType = type;
    },
  },

  async mounted() {
    if (this.model.startDate && this.model.endDate) {
      const endDate = Moment(this.model.endDate, 'DD.MM.YYYY hh:mm').toDate();
      this.isEndDateExpired = endDate.getFullYear() !== 1 && endDate < new Date();
    } else
      this.isEndDateExpired = false;
  },
}
</script>