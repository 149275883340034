<template>
  <div>
    <v-chip
      label
      small
    >
      {{attributes.Level}}
    </v-chip>
  </div>
</template>

<script>
export default {
  name: 'OperationInfoParticipantExperienceLevel',

  props: {
    attributes: {
      type: Object,
    },
  },
}
</script>
