<template>
  <EditorDialog
    :title="'Выберите участника для добавления'"
    :max-width="720"
    :save-func="save"
    @closed="close"
  >
    <DataTable
      controller="inRoute"
      :headers="headers"
      :items="items"
      :server-items-length="total"
      sort-by="date"
      :sort-desc="true"
      search-title="Поиск"
      :loading="updating"
      :items-per-page="6"
      @update:table-options="tableUpdated"
    >
      <template v-slot:[`item.check`]="{item}">
        <v-checkbox class="mt-0 mb-1" v-model="item.check" hide-details="true"></v-checkbox>
      </template>
    </DataTable>
  </EditorDialog>
</template>

<script>
import editorDialogMixin from '@/global-components/dialog/mixins';
import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
import scountApiInitializer from '@/api/scountApi';

export default {
  name: 'TeamParticipantsPageDialog',
  mixins: [editorDialogMixin],

  props: {
    object: {
      type: Object,
      default: () => {
        return {
          items: undefined
        }
      }
    },
    saveFunc: Function,
  },

  setup() {
    const marketingProgramStore = useMarketingProgramStore()
    return { marketingProgramStore }
  },

  data: () => ({
    headers: [
      { value: 'check', text: 'Выбрать', align: 'start', sortable: true },
      { value: 'firstName', text: 'Имя', align: 'start', sortable: true },
      { value: 'lastName', text: 'Фамилия', align: 'start', sortable: true },
      { value: 'emailAddress', text: 'Email', align: 'start', sortable: false },
    ],
    items: [],
    total: 0,
    updating: false,
  }),

  computed: {
    mp() {
      if (this.marketingProgramStore.isInitialized === false)
        return null;

      return this.marketingProgramStore;
    },

    scountApi() {
      if (!this.mp)
        return null;

      return scountApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
    },
  },

  methods: {
    async tableUpdated(pagination, sorting, search) {
      await this.getParticipants(pagination, sorting, search);
    },

    async getParticipants(pagination, sorting, search) {
      if (!this.scountApi) return;

      this.updating = true;

      try {
        const response = await this.scountApi.getParticipants(
          pagination,
          sorting,
          search
        );

        const {values, total} = response.data;

        this.total = total;
        this.items = values;
      } finally {
        this.updating = false;
      }
    },

    save() {
      this.saveFunc(this.items.filter(x => x.check).map(x => x.participantId));
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/global';
</style>