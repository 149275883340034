<template>
  <div>
    <div class="mb-6">
      <h2 class="cmn__h1 mb-5">Аналитика по поощрениям</h2>
      <v-row>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            label="Начало отчетного периода"
            v-model="newParticipantsParams.startDate"
            :rules="validation.date"
            hide-details="auto"
            append-outer-icon="mdi-calendar-blank"
            placeholder="ДД.ММ.ГГГГ"
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            label="Конец отчетного периода"
            v-model="newParticipantsParams.endDate"
            :rules="validation.date"
            hide-details="auto"
            append-outer-icon="mdi-calendar-blank"
            placeholder="ДД.ММ.ГГГГ"
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            label="Дата розыгрыша от"
            v-model="newParticipantsParams.startDateRaffle"
            :rules="validation.date"
            hide-details="auto"
            append-outer-icon="mdi-calendar-blank"
            placeholder="ДД.ММ.ГГГГ"
            clearable
          />
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            label="Дата розыгрыша до"
            v-model="newParticipantsParams.endDateRaffle"
            :rules="validation.date"
            hide-details="auto"
            append-outer-icon="mdi-calendar-blank"
            placeholder="ДД.ММ.ГГГГ"
            clearable
          />
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            label="Дата деактивации от"
            v-model="newParticipantsParams.startDateDeactivate"
            :rules="validation.date"
            hide-details="auto"
            append-outer-icon="mdi-calendar-blank"
            placeholder="ДД.ММ.ГГГГ"
            clearable
          />
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            label="Дата деактивации до"
            v-model="newParticipantsParams.endDateDeactivate"
            :rules="validation.date"
            hide-details="auto"
            append-outer-icon="mdi-calendar-blank"
            placeholder="ДД.ММ.ГГГГ"
            clearable
          />
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            label="Срок действия от"
            v-model="newParticipantsParams.startDateExpires"
            :rules="validation.date"
            hide-details="auto"
            append-outer-icon="mdi-calendar-blank"
            placeholder="ДД.ММ.ГГГГ"
            clearable
          />
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            label="Срок действия до"
            v-model="newParticipantsParams.endDateExpires"
            :rules="validation.date"
            hide-details="auto"
            append-outer-icon="mdi-calendar-blank"
            placeholder="ДД.ММ.ГГГГ"
            clearable
          />
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-autocomplete
            label="МП"
            :items="marketingPrograms2"
            item-text="title"
            item-value="marketingProgramId"
            v-model="marketingProgramId"
            no-data-text="Ничего не найдено"
            :clearable="marketingPrograms2.length !== 1"
          />
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-autocomplete
            label="Категория карточки поощрения"
            :items="promotionsCategories"
            v-model="selectPromotionsCategories"
            clearable
          />
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-autocomplete
            label="Партнер"
            :items="promotionsPartners"
            v-model="selectPromotionsPartners"
            clearable
          />
          </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-select
            label="Тип поощрения"
            :items="promotionTypeItems"
            v-model="selectPromotionsTypes"
            item-text="title"
            item-value="value"
            clearable
          />
        </v-col>
        <v-col class="d-flex align-ceter">
          <v-checkbox
            v-model="isActive"
            label="Поощрение активно"
          ></v-checkbox>
          <v-checkbox
            v-model="deactivateDate"
            label="Без даты деактиации"
            class="ml-2"
          ></v-checkbox>
          <v-btn large @click="newParticipantsShow" class="ml-3">Показать</v-btn>
          <v-btn large @click="getPromotionHistoriesCsv" class="ml-3">
            <v-icon left>
                mdi-content-save
            </v-icon>
            Скачать в CSV
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <DataTable
            v-if="newParticipantsTableShow"
            controller="inRoute"
            :key="newParticipantsFiltersHash"
            :headers="newParticipantsHeaders"
            :items="newParticipantsItems"
            :server-items-length="newParticipantsTotal"
            :loading="newParticipantsUpdating"
            @update:table-options="newParticipantsTableUpdated"
          >
            <template v-slot:[`item.category`]="{item}">
              {{ item.category ? item.category : '-' }}
            </template>
            <template v-slot:[`item.createDate`]="{item}">
              {{ item.createDate ? formatDate(item.createDate) : '-' }}
            </template>
            <template v-slot:[`item.deactivateDate`]="{item}">
              {{ item.deactivateDate ? formatDate(item.deactivateDate) : '-' }}
            </template>
            <template v-slot:[`item.expiresOn`]="{item}">
              {{ item.expiresOn ? formatDate(item.expiresOn) : '-' }}
            </template>
            <template v-slot:[`item.raffleOn`]="{item}">
              {{ item.raffleOn ? formatDate(item.raffleOn) : '-' }}
            </template>
            <template v-slot:[`item.link`]="{item}">
              <router-link v-if="item.isActive" :to="`/promotionEdit/${item.promotionId}`">Ссылка</router-link>
              <span v-else>-</span>
            </template>
            <template v-slot:[`item.type`]="{ item }">
              <span v-if="item.type === 'Discount'">Скидка 100%</span>
              <span v-else-if="item.type === 'Promocode'"> Скидка &lt; 100%</span>
              <span v-else-if="item.type === 'Prize'">Розыгрыш</span>
              <span v-else>-</span>
            </template>
          </DataTable>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
import { useKeycloakStore } from '@/stores/keycloakStore';
import marketApiInitializer from '@/api/marketApi';
import ratingsApiInitializer from '@/api/ratingsApi';
import Moment from 'moment'
import { mapGetters, mapActions } from "vuex";

export default {
  name: 'AnaliticsView',

  setup() {
    const marketingProgramStore = useMarketingProgramStore()
    const keycloakStore = useKeycloakStore()
    return { marketingProgramStore, keycloakStore }
  },

  data: () => ({
    validation: {
      date: [
        value => !value || /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[012])\.(19|20)\d\d$/i.test(value) || 'Некорректная дата. Формат ДД.ММ.ГГГГ',
      ],
    },
    promotionDialog: false,
    promotionItems: [],
    promotion: '',
    searchFieldsPromotion: [
      { key: 'name', label: 'Поощрение' },
    ],
    paginationPromotion: {
      page: 1,
      itemsPerPage: 10,
    },
    sortingPromotion: {
      sortBy: 'createdOn',
      sortDesc: true,
    },
    selected: [],
    promotionId: "",
    searchByEmailString: "",
    updatingPromotion: false,
    headersPromotion: [
      { value: 'name', text: '	Поощрение', align: 'start', sortable: true },
      { value: 'promocodeQty', text: 'Количество промокодов', align: 'center', sortable: false, width: '200px' },
      // { value: 'createdOn', text: 'Дата создания', align: 'center', sortable: true },
      // { value: 'state', text: 'Статус', align: 'end', sortable: false },
    ],
    newParticipantsHeaders: [
      { value: 'promotionName', text: 'Название карточки', align: 'start', sortable: false },
      { value: 'partnerName', text: 'Партнер', align: 'start', sortable: false },
      { value: 'mpName', text: 'МП', align: 'start', sortable: false },
      { value: 'createDate', text: 'Дата заведения поощрения', align: 'start', sortable: false },
      { value: 'deactivateDate', text: 'Деактивация карточки', align: 'start', sortable: false },
      { value: 'expiresOn', text: 'Срок действия поощрения', align: 'start', sortable: false },
      { value: 'category', text: 'Категори карточки', align: 'start', sortable: false },
      { value: 'type', text: 'Тип поощрения', align: 'start', sortable: false },
      { value: 'raffleOn', text: 'Дата розыгрыша', align: 'start', sortable: false },
      { value: 'link', text: 'Ссылка', align: 'start', sortable: false },
      { value: 'cost', text: 'Стоимость в баллах', align: 'start', sortable: false },
      { value: 'promocodesCount', text: 'Кол-во промокодов', align: 'start', sortable: false },
    ],
    newParticipantsTotal: 0,
    newParticipantsUpdating: false,
    newParticipantsParams: {
      startDate: undefined,
      endDate: undefined,
      startDateRaffle: undefined,
      endDateRaffle: undefined,
      startDateDeactivate: undefined,
      endDateDeactivate: undefined,
      startDateExpires: undefined,
      endDateExpires: undefined,
    },
    newParticipantsItems: undefined,
    newParticipantsFiltersHash: Date.now(),
    newParticipantsTableShow: false,
    searchByPromocodeString: "",

    transactionsOnPage: null,
    promotionsOnPage: null,
    participantsOnPage: null,
    totalBallsOnPage: null,
    transactionsAll: null,
    promotionsAll: null,
    participantsAll: null,
    totalBallsAll: null,
    searchFields: [
      { key: 'participantEmail', label: 'Email Участника' },
    ],
    marketingProgramId: '',
    promotionsTypes: [],
    promotionsPartners: [],
    promotionsCategories: [],
    selectPromotionsTypes: '',
    selectPromotionsPartners: '',
    selectPromotionsCategories: '',
    isActive: true,
    deactivateDate: false,
    promotionTypeItems: [
      {title:'Скидка 100%', value: 'Discount'},
      {title:'Скидка < 100%', value: 'Promocode'},
      {title:'Розыгрыш', value: 'Prize'}
    ],
  }),

  computed: {
    ...mapGetters("promotions", ["marketingPrograms", "promotions"]),
    ...mapGetters("promotions", ["total"]),
    mp() {
      if (this.marketingProgramStore.isInitialized === false)
        return null;

      return this.marketingProgramStore;
    },

    marketingPrograms2() {
      if (this.kl.isAnyMp) {
        return this.mp ? [ { marketingProgramId: this.mp.marketingProgramId, title: this.mp.title }] : [];
      } else {
        return this.marketingPrograms;
      }
    },

    marketApi() {
      if (!this.mp)
        return null;

      return marketApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
    },

    ratingsApi() {
      if (!this.mp)
        return null;

      return ratingsApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
    },

    kl() {
      return this.keycloakStore;
    },
  },

  methods: {
    ...mapActions("promotions", ["getMarketingPrograms", "getPromotions"]),
    newParticipantsShow() {
      this.newParticipantsTableShow = true;
      this.newParticipantsFiltersHash = Date.now();
    },
    async getPromotionsTypes() {
      const response = await this.marketApi.getPromotionsTypes()
      this.promotionsTypes = response.data
    },
    async getPromotionsPartners() {
      const response = await this.marketApi.getPromotionsPartners({ creatorId: this.kl.filterByCreatorId })
      this.promotionsPartners = response.data
    },
    async getPromotionsCategories() {
      const response = await this.marketApi.getPromotionsCategories()
      this.promotionsCategories = response.data
    },
    formatDate(date) {
      return Moment(date).format('DD.MM.YYYY');
    },
    async newParticipantsTableUpdated(pagination, sorting, search) {
      await this.getNewParticipants(pagination, sorting, search);
    },

    async getNewParticipants(pagination, sorting) {
      if (!this.marketApi) return;
      this.newParticipantsUpdating = true;
      try {
        const response = await this.marketApi.getPromotionHistories(
          pagination,
          sorting,
          this.newParticipantsParams.startDate ? Moment(this.newParticipantsParams.startDate, 'DD.MM.YYYY').toDate() : null,
          this.newParticipantsParams.endDate ? Moment(this.newParticipantsParams.endDate, 'DD.MM.YYYY').toDate() : null,
          this.newParticipantsParams.startDateRaffle ? Moment(this.newParticipantsParams.startDateRaffle, 'DD.MM.YYYY').toDate() : null,
          this.newParticipantsParams.endDateRaffle ? Moment(this.newParticipantsParams.endDateRaffle, 'DD.MM.YYYY').toDate() : null,
          this.newParticipantsParams.startDateDeactivate ? Moment(this.newParticipantsParams.startDateDeactivate, 'DD.MM.YYYY').toDate() : null,
          this.newParticipantsParams.endDateDeactivate ? Moment(this.newParticipantsParams.endDateDeactivate, 'DD.MM.YYYY').toDate() : null,
          this.newParticipantsParams.startDateExpires ? Moment(this.newParticipantsParams.startDateExpires, 'DD.MM.YYYY').toDate() : null,
          this.newParticipantsParams.endDateExpires ? Moment(this.newParticipantsParams.endDateExpires, 'DD.MM.YYYY').toDate() : null,
          this.marketingProgramId,
          this.selectPromotionsTypes,
          this.selectPromotionsPartners,
          this.selectPromotionsCategories,
          this.isActive,
          this.deactivateDate,
          this.kl.filterByCreatorId


        );
        const {values, total} = response.data;
        this.newParticipantsTotal = total;
        this.newParticipantsItems = values;
      } finally {
        this.newParticipantsUpdating = false;
      }
    },

    async getPromotionHistoriesCsv() {
      const response = await this.marketApi.getPurchasePromotionsCsv(
        this.newParticipantsParams.startDate ? Moment(this.newParticipantsParams.startDate, 'DD.MM.YYYY').toDate() : null,
        this.newParticipantsParams.endDate ? Moment(this.newParticipantsParams.endDate, 'DD.MM.YYYY').toDate() : null,
        this.newParticipantsParams.startDateRaffle ? Moment(this.newParticipantsParams.startDateRaffle, 'DD.MM.YYYY').toDate() : null,
        this.newParticipantsParams.endDateRaffle ? Moment(this.newParticipantsParams.endDateRaffle, 'DD.MM.YYYY').toDate() : null,
        this.newParticipantsParams.startDateDeactivate ? Moment(this.newParticipantsParams.startDateDeactivate, 'DD.MM.YYYY').toDate() : null,
        this.newParticipantsParams.endDateDeactivate ? Moment(this.newParticipantsParams.endDateDeactivate, 'DD.MM.YYYY').toDate() : null,
        this.newParticipantsParams.startDateExpires ? Moment(this.newParticipantsParams.startDateExpires, 'DD.MM.YYYY').toDate() : null,
        this.newParticipantsParams.endDateExpires ? Moment(this.newParticipantsParams.endDateExpires, 'DD.MM.YYYY').toDate() : null,
        this.marketingProgramId,
        this.selectPromotionsTypes,
        this.selectPromotionsPartners,
        this.selectPromotionsCategories,
        this.isActive,
        this.deactivateDate,
          this.kl.filterByCreatorId
    );

      const fileUrl = window.URL.createObjectURL(response.data);

      const anchor = document.createElement("a");
      anchor.download = decodeURI(response.request.getResponseHeader("X-Data-FileName")).replace(/\+/g, " ");
      anchor.href = fileUrl;
      anchor.target = "_blank";
      anchor.click();

      anchor.remove();
    },
    promotionDialogOpen() {
      this.promotionDialog = true;
    },
    updateTablePromotions(pagination, sorting, search) {
      this.getPromotions({ pagination, sorting, search })
      .then(() => {
        this.promotionItems = this.promotions.values
        this.updatingPrmotions = false;
      });
    },
    clickPromotion(e) {
      this.promotionId = e.promotionId
      this.promotionDialog = false
      this.promotion = e.name
    },
  },
  async mounted() {
    if (this.kl.isAnyMp)
      this.marketingProgramId = this.mp.marketingProgramId;
    else
      this.getMarketingPrograms();
      await this.getPromotionsTypes()
      await this.getPromotionsPartners()
      await this.getPromotionsCategories()
  }
}
</script>