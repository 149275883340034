<template>
  <div class="upload">
    <div class="upload__header">
      <h3 v-if="title" class="upload__title">{{ title }}</h3>
      <slot name="header"/>
    </div>
    <div class="upload__main" :style="{width: `${width}px`, height: `${height}px`, borderRadius: `${radius}px`}">
      <div v-if="deleteButton" class="upload__delete">
        <v-btn class="pa-0" @click="fileDelete" plain color="#FF8233">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </div>
      <img v-if="imageSelected" :src="image ? image : imageSelected" alt="" />
      <img v-else src="img/empty.png" alt="" />
    </div>
    <input :id="fileUploadId" type="file" @change="handleFileChange" hidden :accept="accept">
    <div v-if="readonly" class="upload__footer">
      <div class="upload__size">{{ imageSize }}</div>
      <v-btn class="pa-0" @click="handleFileInputClick()" plain color="#FF8233"
      >
        <v-icon left>mdi-pencil</v-icon>
        <span v-if="fullNameUpdate">Изменить</span>
      </v-btn>
      <slot name="footer"/>
    </div>
  </div>
</template>

<script>
import { uuid } from "vue-uuid";

export default {
  name: 'UploadImages',
  data: () => ({
    fileUploadId: `fileUpload_${uuid.v4()}`,
    imageFile: null,
    image: null
  }),
  props: {
    imageSelected: {
      type: String,
      default: ''
    },
    title: {
      type: String,
    },
    width: {
      type: String,
      default: '300'
    },
    height: {
      type: String,
      default: '300'
    },
    radius: {
      type: String,
      default: '12'
    },
    accept: {
      type: String,
      default: '.jpeg, .png, .jpg',
    },
    readonly: {
      type: Boolean,
      default: true,
    },
    fullNameUpdate: {
      type: Boolean,
      default: true,
    },
    imageSize: {
      type: String,
      default: '300х600'
    },
    deleteButton: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleFileChange(event) {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        const reader = new FileReader();
        reader.onload = () => {
          this.imageFile = selectedFile;
          this.image = reader.result;
          this.$emit('changeFile', this.image, selectedFile);
        };
        reader.onerror = (error) => {
          console.error('Error reading the file:', error);
        };
        reader.readAsDataURL(selectedFile);
      }
    },
    handleFileInputClick() {
      this.$emit('fileInputClick', this.fileUploadId);
    },
    fileDelete() {
      this.$emit('fileDelete');
    }
  },
};
</script>

<style lang="scss" scoped>
.upload {
  width: fit-content;
  &__title {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #010101D9;
    padding-bottom: 12px;
  }
  &__main {
    position: relative;
    overflow: hidden;
    img {
      max-width: 100%;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__delete {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 24px;
    height: 24px;
    background: #FFFFFF;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>