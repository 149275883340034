var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"exercies__hr"},[_c('v-row',[_c('v-col',{staticClass:"exercies__h2"},[_vm._v(" Работы в задании ")]),_c('v-col',{staticClass:"d-flex"},[_c('div',{staticClass:"ml-auto"},[_c('v-btn',{attrs:{"disabled":!_vm.isEdit},on:{"click":function($event){return _vm.openCreateTaskDialog()}}},[_vm._v("Добавить работы")])],1)])],1),_c('DataTable',{key:_vm.filtersHash,attrs:{"controller":"inRoute","headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.total,"sort-by":"date","sort-desc":true,"search-fields":_vm.searchFields,"loading":_vm.updating},on:{"update:table-options":_vm.tableUpdated},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.title)+" ")]}},{key:"item.order",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.order)+" ")]}},{key:"item.reward",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.reward)+" ")]}},{key:"item.settings",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(_vm.getDiscipline(item.settings.Discipline).icon)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Дисциплина: "+_vm._s(_vm.getDiscipline(item.settings.Discipline).text))])])]}},{key:"item.createdOn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.createdOn))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"disabled":!_vm.isEdit},on:{"click":function($event){return _vm.openUpdateTaskDialog(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"disabled":!_vm.isEdit},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])]}}],null,true)}),(_vm.exerciseTemplateTaskEditorDialogShow)?_c('ExerciseTemplateTaskEditorDialog',{attrs:{"save-func":_vm.saveExerciseTemplateTask,"object-id":_vm.taskId,"object":_vm.task,"exercise-type":_vm.exerciseType},on:{"closed":function($event){_vm.exerciseTemplateTaskEditorDialogShow = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }