<template>
  <div class="mt-6">
    <v-row>
      <v-col cols="12">
        <h4 class="theme__title">{{titleTheme}}</h4>
      </v-col>
    </v-row>
    <DataTable
      controller="inRoute"
      :headers="headers"
      :items="items"
      :server-items-length="total"
      sort-by="order"
      :sort-desc="false"
      search-title="Найти по названию"
      :search-fields="searchFields"
      :loading="updating"
      @update:table-options="tableUpdated"
      @click:row = "clickQuestionRow"
    >
      <template #actions>
        <v-btn large class="mr-4" @click="questionDialogOpen">
          <v-icon left>mdi-plus-circle</v-icon>
          Создать вопрос
        </v-btn>
      </template>
      <template v-slot:[`item.actions`]="{item}">
        <v-icon class="mr-2" @click.stop = "editQuestionDialog(item)">mdi-pencil</v-icon>
        <v-icon class="mr-2" @click.stop = "confirmDialogOpen(item)">mdi-trash-can-outline</v-icon>
      </template>
    </DataTable>
    <v-dialog
      v-model="questionDialog"
      max-width="720"
    >
      <v-card >
        <v-card-title class="modal__title text-h6 mb-8">
         {{ themeQuestionId ? 'Редактировать вопрос' : 'Создать вопрос' }}
        </v-card-title>
        <v-form 
          class="px-6"
          lazy-validation
        >
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="questionData.title"
                :rules="validation.title"
                label="Вопрос"
                dense
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="questionData.order"
                :rules="validation.order"
                label="Приоритет"
                dense
              />
            </v-col>
          </v-row>
        </v-form>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn
            @click="questionDialog = false"
          >
            Отмена
          </v-btn>
          <v-btn
            color="warning"
            @click="saveQuestionDialog"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="confirmDialog"
      max-width="500"
    >
      <v-card>
        <v-card-title class="text-h6">
          Вы уверены что хотите удалить вопрос?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="confirmDialog = false"
          >
            Отменить
          </v-btn>

          <v-btn
            color="red"
            dark
            @click="deletequestion"
          >
            Удалить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <notifications :duration="5000" position="bottom right"/>
  </div>
</template>
  
<script>
import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
import onboardingApiInitializer from '@/api/onboardingApi';
import { uuid } from "vue-uuid";


export default {
  name: 'FAQItemView',
  components: {  },
  setup() {
        const marketingProgramStore = useMarketingProgramStore()
        return { marketingProgramStore }
      },
      computed: {
        mp() {
          if (this.marketingProgramStore.isInitialized === false)
            return null;
          return this.marketingProgramStore;
        },
        onboardingApi() {
          if (!this.mp)
            return null;
          return onboardingApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
        },
      },

  data: () => ({
    headers: [
      { value: 'title', text: 'Название темы', align: 'left', sortable: true },
      { value: 'order', text: 'Приоритет', align: 'center', width: 160, sortable: true },
      { value: 'actions', text: 'Действия', align: 'center', width: 120, sortable: false },
    ],
    validation: {        
      title: [
        value => !!value || 'Обязательное поле.',
      ],
      order: [
        value => !!value || 'Обязательное поле.',
        value => !value || /^\d+(\.\d{1,2})?$/i.test(value) || 'Значение должно быть числом не меньше 0',
      ],
    },
    items: [],
    total: 0,
    searchFields: [
      { key: 'title', label: 'Название' },
    ],
    questionData: {
      title: "",
      order: 0,
    },
    themeItemId: null,
    updating: false,
    questionDialog: false,
    confirmDialog: false,
    titleTheme: "",
    idTheme: "",
    themeQuestionId: ""
  }),

  methods: {
    async tableUpdated(pagination, sorting, search) {
      await this.getFAQItems(pagination, sorting, search);
    },

    async getFAQItems(pagination, sorting, search) {
      if (!this.onboardingApi) return;

      this.updating = true;

      try {
        const response = await this.onboardingApi.getFAQThemeQuestions(
          pagination,
          sorting,
          search,
          this.themeItemId
        );

        const {values, total} = response.data;

        this.total = total;
        this.items = values;
      } finally {
        this.updating = false;
      }
    },
    clickQuestionRow (e) {
      console.log(e);
      const questionId = e.id
      const themeId = e.themeId
      this.$router.push({ name: 'FAQQuestionItemView', params: { questionId: questionId, themeId: themeId}});
    },
    questionDialogOpen () {
      this.questionDialog = true
      this.themeQuestionId = null
      this.questionData = {}
    },
    confirmDialogOpen (item) {
      this.confirmDialog = true
      this.themeQuestionId = item.id;
    },
    deletequestion() {
      this.confirmDialog = false;
        this.onboardingApi.deleteFAQThemeQuestion(this.idTheme, this.themeQuestionId)
        .then((response) => {
            if(response.success) {
              this.$notify({type: 'success', text: 'Вопрос успешно удален'})
              this.getFAQItems();
            } else {
              this.$notify({ type: 'error', text: `Вопрос не удален. Ошибка: ${response.error.detail}` });
            }
          })
          .catch((error) => {
            console.error('Error updating slide:', error);
          });
    },
    saveQuestionDialog () {
      if (this.themeQuestionId) {
        this.updateQuestion();
      } else {
        this.createQuestion();
      }
    },
    createQuestion() {
      let questionId = uuid.v4();
      let themeId = this.idTheme
        const model = {
          title: this.questionData.title,
          order: +this.questionData.order,
          faqThemeQuestionId: questionId
        };
        this.onboardingApi
          .createFAQThemeQuestion(model, themeId)
          .then((response) => {
            if(response.success) {
              this.questionData = {};
              this.questionDialog = false;
              this.$notify({ type: 'success', text: 'Вопрос успешно создан' });
              this.getFAQItems();
            } else {
                this.$notify({ type: 'error', text: `Вопрос не создан. Ошибка: ${response.error.detail}` });
              }
          })
          .catch((error) => {
            console.error('Error creating slide:', error);
          });
    },
    updateQuestion() {
      const model = {
        title: this.questionData.title,
        order: +this.questionData.order,
      };
      this.onboardingApi
        .updateFAQThemeQuestion(this.idTheme, this.themeQuestionId, model)
        .then((response) => {
          if(response.success) {
            this.questionData = {};
            this.questionDialog = false;
            this.$notify({ type: 'success', text: 'Вопрос успешно обновлен' });
            this.getFAQItems();
          } else {
            this.$notify({ type: 'error', text: `Вопрос не обновлен. Ошибка: ${response.error.detail}` });
          }
        })
        .catch((error) => {
          console.error('Error updating slide:', error);
        });
    },
    editQuestionDialog (item) {
      this.questionDialog = true;
      this.questionData.title = item.title;
      this.questionData.order = item.order;
      this.themeQuestionId = item.id;
      console.log(this.themeQuestionId);
    },
  },
  async mounted() {
      this.themeItemId = this.$route.params.themeId
      const resp = await this.onboardingApi.getTheme(this.themeItemId)
      this.titleTheme = resp.data.title
      this.idTheme = resp.data.id
      
  }
}
</script>
  
<style lang="scss" scoped>
  .theme__title{
    font-size: 34px;
    font-weight: 400;
  }
</style>