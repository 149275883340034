<template>
  <div>
    <v-row>
      <v-col class="mt-2" @click="bannerDialogOpen">
        <v-btn left large>
          <v-icon left>mdi-image-plus</v-icon>
          добавить баннер
        </v-btn>
      </v-col>
    </v-row>
    <DataTable
      v-if="items && items.length > 0"
      controller="inRoute"
      :headers="headers"
      :items="items"
      :server-items-length="total"
      sort-by="order"
      :sort-desc="true"
      :loading="updating"
      @update:table-options="tableUpdated"
    >
      <template v-slot:[`item.desctopImageUrl`]="{item}">
        <div class="d-flex align-center py-2">
          <img height="40" width="145" v-if="item.desktopImageUrl" :src="item.desktopImageUrl" />
          <img height="40" width="145" v-else src="https://cdn1.ozone.ru/s3/multimedia-x/6391435521.jpg"/>
        </div>
      </template>
      <template v-slot:[`item.redirectUrl`]="{item}">
        <span class="trancate">{{ item.redirectUrl && item.redirectUrl !== null ? item.redirectUrl : '--' }}</span>
      </template>
      <template v-slot:[`item.mobileImageUrl`]="{item}">
        <div class="d-flex align-center py-2">
          <img height="40" width="37" v-if="item.mobileImageUrl" :src="item.mobileImageUrl" />
          <img height="40" width="145" v-else src="https://cdn1.ozone.ru/s3/multimedia-x/6391435521.jpg"/>
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon class="mr-2" @click="bannerDialogOpen(item)">mdi-pencil</v-icon>
        <v-icon class="mr-2" @click="confirmDialogOpen(item)" >mdi-trash-can-outline</v-icon>
      </template>
    </DataTable>
    <v-dialog
      v-model="bannerDialog"
      max-width="720"
    >
      <v-card >
        <v-card-title class="text-h6 mb-4 pa-6 grey lighten-2">
          {{ bannerId ? 'Редактировать баннер' : 'Добавить баннер' }}
        </v-card-title>
        <v-form
          ref="form"
          class="px-6 py-3"
          v-model="valid"
          lazy-validation
        >
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Ссылка"
                dense
                v-model="formData.link"
                :rules="validation.link"
                required
              />
            </v-col>
            <v-col cols="6">
              <v-select
                label="Местоположение"
                :items="locationPlacement"
                v-model="formData.selectLocationPlacement"
                item-text="title"
                item-value="value"
                dense
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Позиция в слайдере"
                dense
                v-model="formData.order"
                :rules="validation.order"
                required
              />
            </v-col>
            <v-col cols="6">
              <v-file-input
                v-model="formData.selectedFileDesktop"
                accept="image/jpg"
                dense
                truncate-length="15"
                label="Desktop (JPG, 1920х500)"
                prepend-icon=""
                append-outer-icon ="mdi-paperclip"
                @change="handleFileChangeDesktop"
                :rules="!bannerId ? validation.images : []"
              ></v-file-input>
            </v-col>
            <v-col cols="6">
              <v-file-input
                v-model="formData.selectedFileMobile"
                accept="image/jpg"
                dense
                truncate-length="15"
                label="Mobile (JPG, 688x748)"
                prepend-icon=""
                append-outer-icon ="mdi-paperclip"
                @change="handleFileChangeMobile"
                :rules="!bannerId ? validation.images : []"
              ></v-file-input>
            </v-col>
            <v-col cols="6">
              <div class="preview preview__desktop">
                <img v-if="imageUrlDesktop" :src="imageUrlDesktop" alt="" />
              </div>
            </v-col>
            <v-col cols="6">
              <div class="preview preview__mobile">
                <img v-if="imageUrlMobile" :src="imageUrlMobile" alt="" />
              </div>
            </v-col>
          </v-row>
          <v-card-actions class="mt-8">
            <v-spacer></v-spacer>
            <v-btn
            @click="bannerDialogClose"
            >
              Отменить
            </v-btn>
            <v-btn
              color="#FF8233"
              :dark="valid"
              @click="saveBanner"
              :disabled="!valid"
            >
              <v-progress-circular
                v-if="loading"
                :size="20"
                indeterminate
              />
              <span v-if="!loading">сохранить</span>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="confirmDialog"
      max-width="500"
    >
    <v-card>
      <v-card-title class="text-h6">
        Вы уверены что хотите удалить баннер?
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="confirmDialogClose"
        >
          Отменить
        </v-btn>

        <v-btn
          color="#FF8233"
          dark
          class="ml-4"
          @click="deleteBanner"
        >
          <v-progress-circular
            v-if="loading"
            :size="20"
            indeterminate
          />
          <span v-if="!loading">Удалить</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <notifications :duration="8000" position="bottom right"/>
  </div>
</template>

<script>
import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
import scountApiInitializer from '@/api/onboardingApi';
import { uuid } from "vue-uuid";

  export default {
    name: 'BannersView',
    data: () => ({
      bannerDialog: false,
      confirmDialog: false,
      valid: true,
      bannerId: '',
      imageUrlDesktop: null,
      imageUrlMobile: null,
      formData: {
        link: '',
        selectLocationPlacement: null,
        order: 0,
        selectedFileDesktop: null,
        selectedFileMobile: null,
      },
      headers: [
        { value: 'desctopImageUrl', text: 'Изображения', align: 'start', sortable: false, width: '160px' },
        { value: 'mobileImageUrl', text: '', align: 'start', sortable: false },
        { value: 'redirectUrl', text: 'Ссылка', align: 'start', sortable: true },
        { value: 'locationPlacement', text: 'Местоположение', align: 'start', sortable: false },
        { value: 'order', text: 'Порядок в слайдере', align: 'center', sortable: true, width: '100px' },
        { value: 'actions', text: 'Действия', align: 'center', sortable: false, width: '100px'  },
      ],
      items: [],
      total: 0,
      updating: false,
      loading: false,
      selectLocationPlacement: 'calendar',
      locationPlacement: [
        {title:'Календарь', value: 'calendar'},
        {title:'Баннер ВК', value: 'vk'},
        {title:'Баннер ЗОЖ', value: 'community'},
        {title:'Слайдер маркета', value: 'slider-market'}
      ],
      validation: {
        link: [
        value => !value || /https?:\/\//.test(value) || 'Формат: http:// или https://.',
        ],
        order: [
          value => !!value || 'Обязательное поле.',
          value => value === null || value === undefined || value === '' || (/^\d+(\.\d{1,2})?$/.test(value) && value >= 0) || 'Значение должно быть числом не меньше 0',
        ],
        images: [
          value => !!value || 'Обязательное поле.',
        ],
      },
      pagination: {
        offset: 0,
        count: 10,
      },
      search: '',
    }),
    setup() {
      const marketingProgramStore = useMarketingProgramStore()
      return { marketingProgramStore }
    },
    methods: {
      async saveBanner() {
        const action = this.bannerId ? this.updateBanner : this.createBanner;
        await action();
      },
      async tableUpdated(pagination, sorting, search) {
        await this.getBanners(pagination, sorting, search);
      },
      bannerDialogOpen(item) {
        if (this.$refs.form && typeof this.$refs.form.resetValidation === 'function') {
          this.$refs.form.resetValidation();
        }
        this.bannerDialog = true;
        this.bannerId = item.bannerId || null;
        this.formData.link = item.redirectUrl || '';
        this.formData.selectLocationPlacement = item.locationPlacement || null;
        this.formData.order = item.order || 0;
        this.imageUrlDesktop = item.desktopImageUrl || null;
        this.imageUrlMobile = item.mobileImageUrl || null;
      },
      bannerDialogClose() {
        this.$refs.form.resetValidation()
        this.bannerDialog = false
      },
      confirmDialogOpen(item) {
        this.bannerId = item.bannerId
        this.confirmDialog = true
      },
      confirmDialogClose() {
        this.confirmDialog = false
      },
      // Создание баннера
      async createBanner() {
        if (!this.scountApi) return;
        if (!this.$refs.form.validate()) {
          return;
        }
        this.loading = true;
        const data = {
          bannerId: uuid.v4(),
          order: this.formData.order,
          locationPlacement: this.formData.selectLocationPlacement,
          redirectUrl: this.formData.link,
          desktopFile: this.formData.selectedFileDesktop,
          mobileFile: this.formData.selectedFileMobile,
        }
        await this.scountApi.createBanner(data)
        .then((response) => {
          if(response.status === 201) {
            this.$notify({ type: 'success', text: 'Баннер успешно создан' });
            this.bannerDialog = false;
            this.resetForm();
            this.getBanners(this.pagination, this.sorting, this.search);
            this.loading = false;
          } else {
            this.$notify({ type: 'error', text: `Баннер не создан. Ошибка: ${response.error.detail}` });
          }
        })
        .catch((error) => {
          console.error('Error creating banner:', error);
          this.$notify({ type: 'error', text: `Баннер не создан. Ошибка: ${error.title}` });
          this.loading = false;
        })
      },
      // Получение баннеров
      async getBanners(pagination, sorting, search) {
        if (!this.scountApi) return;
        this.updating = true;
        await this.scountApi.getBanners(
          pagination,
          sorting,
          search,
        )
        .then((response) => {
          const {values, total} = response.data;
          this.total = total;
          this.items = values;
          this.updating = false;
        })
        .catch((error) => {
          this.$notify({ type: 'error', text: `Баннеры не получены. Ошибка: ${error.title}` });
          this.updating = false;
        });
      },
      // Обновление баннера
      async updateBanner() {
        if (!this.scountApi) return;
        if (!this.$refs.form.validate()) {
          return;
        }
        this.loading = true;
        const payload = {
          order: this.formData.order,
          locationPlacement: this.formData.selectLocationPlacement,
          redirectUrl: this.formData.link,
          desktopFile: this.formData.selectedFileDesktop,
          mobileFile: this.formData.selectedFileMobile,
        };

        await this.scountApi.updateBanner(this.bannerId, payload)
        .then((response) => {
          if(response.status === 204) {
            this.bannerDialog = false
            this.$notify({ type: 'success', text: 'Баннер успешно обновлен' });
            this.getBanners(this.pagination, this.sorting, this.search)
            this.resetForm()
            this.loading = false;
          } else {
            this.$notify({ type: 'error', text: `Баннер не обновлен. Ошибка: ${response.error.detail}` });
          }
        })
        .catch((error) => {
          console.error('Error creating slide:', error);
          this.$notify({ type: 'error', text: `Баннер не обновлен. Ошибка: ${error.title}` });
          this.loading = false;
        })
      },
      // Удаление баннера
      async deleteBanner() {
        this.loading = true;
        await this.scountApi.deleteBanner(this.bannerId)
        .then((response) => {
          if(response.status === 204) {
            this.loading = false
            this.confirmDialog = false
            this.getBanners(this.pagination, this.sorting, this.search)
            this.resetForm()
            this.$notify({ type: 'success', text: 'Баннер успешно удален' });
          } else {
            this.$notify({ type: 'error', text: `Баннер не удален. Ошибка: ${response.error.detail}` });
          }
        })
        .catch((error) => {
          this.$notify({ type: 'error', text: `Баннер не удален. Ошибка: ${error.title}` });
          this.loading = false;
        });
      },
      // Очистка формы
      resetForm() {
        if(this.$refs.form) {
          this.$refs.form.reset();
        }
      },
      // Загрузка десктопного изображения
      handleFileChangeDesktop(event) {
      const file = event;
      this.formData.selectedFileDesktop = file;
      if (this.formData.selectedFileDesktop) {
        this.imageUrlDesktop = URL.createObjectURL(this.formData.selectedFileDesktop);
      }
    },
      // Загрузка мобильного изображения
      handleFileChangeMobile(event) {
        const file = event;
        this.formData.selectedFileMobile = file;
        if (this.formData.selectedFileMobile) {
          this.imageUrlMobile = URL.createObjectURL(this.formData.selectedFileMobile);
        }
      },
    },
    computed: {
      mp() {
        if (this.marketingProgramStore.isInitialized === false)
          return null;
        return this.marketingProgramStore;
      },
      scountApi() {
          if (!this.mp)
            return null;
          return scountApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
        },
    },
    mounted() {
      this.getBanners(this.pagination, this.sorting, this.search)
    }
  }
</script>

<style lang="scss" scoped>
  .preview {
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      max-width: 100%;
      object-fit: cover;
    }
    &__desktop {
      width: 288px;
      height: 78px;
      // background-color: #eee;
    }
    &__mobile {
      width: 92px;
      height: 100px;
      // background-color: #ee2;
    }
  }

</style>