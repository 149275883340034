<template>
  <div>
    <v-alert
      border="left"
      colored-border
      type="info"
      elevation="2"
    >
      Изменения параметров в номинациях каталога не применяются к экземплярам номинации в МП. Не забудьте обновить номинации в соответствующих МП
    </v-alert>

    <DataTable
      controller="inRoute"
      :key="filtersHash"
      :headers="headers"
      :items="items"
      :server-items-length="total"
      sort-by="date"
      :sort-desc="true"
      search-title="Найти номинации"
      :search-fields="searchFields"
      :loading="updating"
      @update:table-options="tableUpdated"
      @click:row="tableRowClicked"
    >
      <template #actions>
        <v-btn @click="goToCreateNominationTemplate">Добавить шаблон номинации</v-btn>
      </template>

      <template v-slot:[`item.attributes`]="{item}">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
            >
              <v-icon class="mr-2" :color="item.isAutomaticAssign ? 'success' : ''">
                mdi-refresh-auto
              </v-icon>
            </span>
          </template>
          <span>Авто</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
            >
              <v-icon class="mr-2" :color="getType(item.type).color">
                {{ getType(item.type).icon }}
              </v-icon>
            </span>
          </template>
          <span>{{ getType(item.type).text }}</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.title`]="{item}">
        {{ item.title }}
        <div class="nomination-id">ID: {{ item.nominationTemplateId }}</div>
      </template>
      <template v-slot:[`item.complexity`]="{item}">
        {{ item.complexity }}
      </template>
    </DataTable>
    
    <v-dialog v-model="dialog.opened" width="720">
      <v-card>
        <v-card-title class="headline">{{ dialog.title }}</v-card-title>
        <v-card-text v-html="dialog.text">
        </v-card-text>
        <v-card-actions>
          <v-btn @click="closeDialog">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { uuid } from "vue-uuid";
import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
import ratingsApiInitializer from '@/api/ratingsApi';

export default {
  name: 'NominationTemplatesView',

  setup() {
    const marketingProgramStore = useMarketingProgramStore()
    return { marketingProgramStore }
  },

  data: () => ({
    filtersHash: Date.now(),
    dialog: {
      opened: false,
      title: undefined,
      text: undefined
    },
    headers: [
      { value: 'title', text: 'Название', align: 'start', sortable: true },
    ],
    items: [],
    total: 0,
    searchFields: [
      { key: 'title', label: 'Название' },
    ],
    updating: false,
    nominationEditorDialogShow: false,
  }),

  computed: {
    mp() {
      if (this.marketingProgramStore.isInitialized === false)
        return null;

      return this.marketingProgramStore;
    },

    ratingsApi() {
      if (!this.mp)
        return null;

      return ratingsApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
    },
  },

  methods: {
    async tableUpdated(pagination, sorting, search) {
      await this.getNominations(pagination, sorting, search);
    },

    async getNominations(pagination, sorting, search) {
      if (!this.ratingsApi) return;

      this.updating = true;

      try {
        const response = await this.ratingsApi.getNominationTemplates(
          pagination,
          sorting,
          search,
        );

        const {values, total} = response.data;

        this.total = total;
        this.items = values;
      } finally {
        this.updating = false;
      }
    },

    //todo: Не используется
    async createNominationTemplate(model) {
      var nominationTemplateId = uuid.v4();
      await this.ratingsApi.createNominationTemplate(nominationTemplateId, model);
      this.goToNominationTemplate(nominationTemplateId);
    },

    tableRowClicked(nominationTemplate) {
      this.goToNominationTemplate(nominationTemplate.nominationTemplateId);
    },

    goToNominationTemplates() {
      this.$router.push('/nomination-templates');
    },

    goToNominationTemplate(nominationId) {
      this.$router.push(`/nomination-template/${nominationId}`);
    },

    goToCreateNominationTemplate() {
      this.$router.push(`/nomination-template/`);
    },

    async deleteItem(nominationTemplate) {
      this.updating = true;

      try {
        const response = await this.ratingsApi.deleteNominationTemplate(nominationTemplate.nominationTemplateId);
        if (response.status === 204) {
          this.filtersHash = Date.now();
          this.updating = false;
        } else {
          const e = response.error;
          const errorText = !e
            ? 'Произошла ошибка'
            : `Код статуса ответа: ${e.status}`
              + `<br>Код: ${e.code}`
              + `<br>Ошибка: ${e.title}`
              + `<br>Подробности: ${e.detail}`;

          this.openDialog('Ошибка при удалении номинации', errorText); 
          this.updating = false;
        }
      } catch {
        this.updating = false;
      }
    },

    openDialog(title, text) {
      this.dialog.title = title;
      this.dialog.text = text;
      this.dialog.opened = true;
    },

    closeDialog() {
      this.dialog.opened = false;
    },

    getType(type) {
      switch (type) {
        case 'Disposable': return { text: 'Одноразовое', icon: 'mdi-autorenew-off', color: '' };
        case 'Reusable': return { text: 'Многоразовое', icon: 'mdi-autorenew', color: 'success' };
        default: return { text: 'undefined' };
      }
    }
  },
}
</script>

<style scoped>
.nomination-id {
  color: #999;
  font-size: 80%;
}
</style>