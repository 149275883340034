<template>
    <div class="image-thumbnail-wrapper">
      <div class="image-thumbnail">
        <div class="image-preview">
          <v-img v-if="!previewUrl && !newSrc"  lazy-src="" src="/img/empty-image.jpg" ></v-img>
          <v-img v-else ref="imagePreview" lazy-src="/img/empty-image.jpg" :src="newSrc ? newSrc : previewUrl" :style="{background: background}"></v-img>
          <div :id="`image_preview_${_uid}`" class="preview"></div>
        </div>
        <v-file-input
          :id="`image_input_${_uid}`"
          ref="imageInput" @change="imageSelected" :accept="accept" :rules="rules"
          truncate-length="15"
        ></v-file-input>
      </div>
      <div v-if="!readOnly" class="image-title">{{title}} <a class="ml-4" @click.stop="selectImage">Изменить</a></div>
      <div class="image-subtitle">{{subTitle}}</div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ImageSelector',
  
    props: {
      previewUrl: String,
      readOnly: Boolean,
      saveImageFunc: Function,
      accept: String,
      title: String,
      subTitle: String,
      rules: Array,
      background: {
        type: String,
        default: '#000',
      }
    },
  
    data() {
      return {
        error: null,
        saving: false,
        newSrc: null,
      };
    },
  
    methods: {
      showPreview() {
        this.$emit('show-preview');
      },
  
      selectImage() {
        document.getElementById(this.$refs.imageInput.id).click();
      },
  
      async imageSelected(file) {
        if (!file) return;
        let reader = new FileReader();
        reader.onload = (e) => {
          this.newSrc = e.target.result;
        };
        reader.readAsDataURL(file);
  
        this.saving = true;
        try {
          await this.saveImageFunc(file);
        } finally {
          this.saving = false;
        }
      },
    },
  }
  </script>
  
  <style lang="scss" scoped>
  .image-preview {
    position: relative;
  
    .preview {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
  
      .v-image {
        z-index: 0;
      }
  
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  
  .image-thumbnail {
    width: 100%;
    height: 280px;
    display: inline-block;
    position: relative;
    cursor: pointer;
  
    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
    }
  
    .preview-icon-wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
    }
  
    .preview-icon {
      position: absolute;
      margin-top: -20px;
      margin-left: -40px;
      top: 50%;
      left: 50%;
      visibility: hidden;
      color:#fff;
      z-index: 1;
    }
  
    .empty-image {
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      margin-top: -28px;
      z-index: 1;
      color:#fff;
      text-align: center;
  
      .v-icon {
        color: inherit;
      }
    }
  
    .saving-progress {
      position: absolute;
      margin-top: -16px;
      margin-left: -16px;
      top: 50%;
      left: 50%;
      color:#fff;
      z-index: 2;
    }
  
    .change-image-button {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 3;
      visibility: hidden;
    }
  
    &:hover .preview-icon-wrapper {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: black;
      border-radius: 0.25rem;
      opacity: 0.2;
    }
  
    &:hover .change-image-button {
      visibility: visible;
    }
  
    &:hover .c-icon {
      visibility: visible;
    }
  
    .image-title {
      font-size: 12px;
      font-weight: bold;
    }
  
    .v-file-input .v-input__prepend-outer, .v-file-input .v-input__slot {
      display: none;
    }
  
    .image-subtitle {
      font-size: 10px;
    }
  }
  </style>
  