<template>
  <EditorDialog
    title="Сообщество"
    :max-width="1200"
    :save-func="save"
    @closed="localClose"
  >
  <v-form ref="form" lazy-validation>
    <v-row>
      <v-col>     
        <v-text-field
          label="VK ID"
          v-model="model.externalId"
          :rules="validation.externalId"
          hide-details="auto"
          class="mb-5"
        ></v-text-field>
      </v-col>  
    </v-row>
  </v-form>
  </EditorDialog>
</template>

<script>
import { uuid } from "vue-uuid";
import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
import editorDialogMixin from '@/global-components/dialog/mixins';
import communityApiInitializer from '@/api/communityApi';

export default {
  name: 'CommunityEditorDialog',
  mixins: [editorDialogMixin],

  props: {
    object: {
      type: Object,
      default: () => {
        return {
          externalId: undefined
        }
      }
    },
    saveFunc: Function,
  },

  setup() {
    const marketingProgramStore = useMarketingProgramStore()
    return { marketingProgramStore }
  },

  computed: {
    mp() {
      if (this.marketingProgramStore.isInitialized === false)
        return null;

      return this.marketingProgramStore;
    },

    communityApi() {
      if (!this.mp)
        return null;

      return communityApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
    },
  },

  data: () => ({
    saving: false,
    validation: {
      externalId: [
        value => !!value || 'Обязательное поле.',
        value => (value && value.length <= 32) || 'Не более 32 символов.',
      ]
    },
  }),

  methods: {
    async save() {
      this.saving = true;
      
      const valid = await this.$refs.form.validate();

      if (!valid)
        return;

      this.saveCommunity(this.model);
    },

    localClose() {
      const saving = this.saving;
      this.saving = false;

      if (saving)
        return;

      this.close();
    },

    async saveCommunity(community) {
      if (community.communityId) {
        await this.updateCommunity(community);
      } else {
        await this.createCommunity(community);
      }
    },

    async createCommunity(community) {
      var communityId = uuid.v4();
      const request = {
        externalId: community.externalId,
      };

      const response = await this.communityApi.createCommunity(communityId, request);
      if (response.success) {
        this.saveFunc({ success: true });
        this.close();
      } else {
        this.saveFunc({ error: response.error });
      }
    },

    async updateCommunity(community) {
      const request = {
        externalId: community.externalId,
      };

      const response = await this.communityApi.updateCommunity(community.communityId, request);
      if (response.success) {
        this.saveFunc({ success: true });
        this.close();
      } else {
        this.saveFunc({ error: response.error });
      }
    }
  }
}
</script>