var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DataTable',{key:_vm.filtersHash,attrs:{"controller":"inRoute","headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.total,"sort-by":"date","sort-desc":true,"search-title":"Найти партнера","search-fields":_vm.searchFields,"loading":_vm.updating},on:{"update:table-options":_vm.tableUpdated},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{on:{"click":function($event){return _vm.openCreateDialog()}}},[_vm._v("Добавить партнера")])]},proxy:true},{key:"item.logo",fn:function(ref){
var item = ref.item;
return [_c('img',{staticStyle:{"width":"60px"},attrs:{"src":item.logos.mobile}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "),_c('div',{staticClass:"partner-id"},[_vm._v("ID: "+_vm._s(item.partnerId))])]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTimeRus")(item.createdOn))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.openEditDialog(item)}}},[_vm._v("mdi-pencil")])]}}],null,true)}),(_vm.partnerEditorDialogShow)?_c('PartnerEditorDialog',{attrs:{"save-func":_vm.afterSave,"object-id":_vm.partnerId,"object":_vm.partner},on:{"closed":function($event){_vm.partnerEditorDialogShow = false}}}):_vm._e(),_c('v-dialog',{attrs:{"width":"720"},model:{value:(_vm.dialog.opened),callback:function ($$v) {_vm.$set(_vm.dialog, "opened", $$v)},expression:"dialog.opened"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(_vm._s(_vm.dialog.title))]),_c('v-card-text',{domProps:{"innerHTML":_vm._s(_vm.dialog.text)}}),_c('v-card-actions',[_c('v-btn',{on:{"click":_vm.hideError}},[_vm._v("Закрыть")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }