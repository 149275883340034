<template>
  <div>
    <v-alert
      border="left"
      colored-border
      type="info"
      elevation="2"
    >
      Изменения параметров в заданиях каталога не применяются к экземплярам заданиям в МП. Не забудьте обновить задания в соответствующих МП
    </v-alert>

    <DataTable
      controller="inRoute"
      :key="filtersHash"
      :headers="headers"
      :items="items"
      :server-items-length="total"
      sort-by="date"
      :sort-desc="true"
      search-title="Найти задание"
      :search-fields="searchFields"
      :loading="updating"
      @update:table-options="tableUpdated"
    >
      <template #actions>
        <v-btn @click="editItem()">Добавить шаблон задания</v-btn>
      </template>

      <template v-slot:[`item.attributes`]="{item}">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
            >
              <v-icon class="mr-2" :color="item.isAutomaticAssign ? 'success' : ''">
                mdi-refresh-auto
              </v-icon>
            </span>
          </template>
          <span>Авто</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
            >
              <v-icon class="mr-2" :color="getType(item.type).color">
                {{ getType(item.type).icon }}
              </v-icon>
            </span>
          </template>
          <span>{{ getType(item.type).text }}</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.title`]="{item}">
        {{ item.title }}
        <div class="exercise-id">ID: {{ item.exerciseTemplateId }}</div>
      </template>
      <template v-slot:[`item.complexity`]="{item}">
        {{ item.complexity }}
      </template>
      <template v-slot:[`item.actions`]="{item}">
        <v-tooltip color="gray" top v-if="!item.images.desktop || !item.images.mobile">
          <template v-slot:activator="{ on, attrs }">
            <v-icon 
              color="red"
              v-bind="attrs"
              v-on="on">mdi-alert</v-icon>
          </template>
          <span class="tooltip__span">
            Не загружены картинки
          </span>
        </v-tooltip>
        &nbsp;
        <v-icon class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
        <v-icon @click="openDeleteConfirm(item)">mdi-delete</v-icon>
      </template>
    </DataTable>
    
    <v-dialog v-model="dialog.opened" width="720">
      <v-card>
        <v-card-title class="headline">{{ dialog.title }}</v-card-title>
        <v-card-text v-html="dialog.text">
        </v-card-text>
        <v-card-actions>
          <v-btn @click="closeDialog">Закрыть</v-btn>
          <v-btn v-if="dialog.actionDialog" @click="dialog.actionDialog" color="primary">{{ dialog.actionName }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { uuid } from "vue-uuid";
import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
import scountApiInitializer from '@/api/scountApi';

export default {
  name: 'ExerciseTemplatesView',

  setup() {
    const marketingProgramStore = useMarketingProgramStore()
    return { marketingProgramStore }
  },

  data: () => ({
    filtersHash: Date.now(),
    dialog: {
      opened: false,
      title: undefined,
      text: undefined,
      actionDialog: undefined,
      actionName: undefined
    },
    headers: [
      { value: 'attributes', text: 'Параметры', align: 'start', sortable: false },
      { value: 'title', text: 'Название', align: 'start', sortable: true },
      { value: 'complexity', text: 'Сложность', align: 'start', sortable: false },
      { value: 'actions', text: 'Действия', align: 'center', sortable: false },
    ],
    items: [],
    total: 0,
    searchFields: [
      { key: 'title', label: 'Название' },
    ],
    updating: false,
    exerciseEditorDialogShow: false,
  }),

  computed: {
    mp() {
      if (this.marketingProgramStore.isInitialized === false)
        return null;

      return this.marketingProgramStore;
    },

    scountApi() {
      if (!this.mp)
        return null;

      return scountApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
    },
  },

  methods: {
    async tableUpdated(pagination, sorting, search) {
      await this.getExercises(pagination, sorting, search);
    },

    async getExercises(pagination, sorting, search) {
      if (!this.scountApi) return;

      this.updating = true;

      try {
        const response = await this.scountApi.getExerciseTemplates(
          pagination,
          sorting,
          search,
        );

        const {values, total} = response.data;

        this.total = total;
        this.items = values;
      } finally {
        this.updating = false;
      }
    },

    async createExercise(model) {
      var exerciseTemplateId = uuid.v4();
      await this.scountApi.createExerciseTemplate(exerciseTemplateId, model);
      this.goToExercise(exerciseTemplateId);
    },

    tableRowClicked(exercise) {
      this.goToExercise(exercise.exerciseId);
    },

    editItem(item) {
      this.$router.push(`/exercise-template/${item ? item.exerciseTemplateId : ''}`);
    },

    async deleteItem(exerciseTemplate) {
      this.updating = true;

      try {
        const response = await this.scountApi.deleteExerciseTemplate(exerciseTemplate.exerciseTemplateId);
        if (response.status === 204) {
          this.filtersHash = Date.now();
          this.updating = false;
        } else {
          const e = response.error;
          const errorText = !e
            ? 'Произошла ошибка'
            : `Код статуса ответа: ${e.status}`
              + `<br>Код: ${e.code}`
              + `<br>Ошибка: ${e.title}`
              + `<br>Подробности: ${e.detail}`;

          this.openDialog('Ошибка при добавлении задания', errorText); 
          this.updating = false;
        }
      } catch {
        this.updating = false;
      }
    },

    openDeleteConfirm(item) {
      this.openDialog('Подтверждение удаления',
        'Вы уверены, что хотите удалить?',
        () => { 
          this.deleteItem(item);
          this.closeDialog();
        },
        'Удалить')
    },

    openDialog(title, text, actionDialog, actionName) {
      this.dialog.title = title;
      this.dialog.text = text;
      this.dialog.actionDialog = actionDialog;
      this.dialog.actionName = actionName;
      this.dialog.opened = true;
    },

    closeDialog() {
      this.dialog.title = undefined;
      this.dialog.text = undefined;
      this.dialog.actionDialog = undefined;
      this.dialog.actionName = undefined;
      this.dialog.opened = false;
    },

    getType(type) {
      switch (type) {
        case 'Disposable': return { text: 'Одноразовое', icon: 'mdi-autorenew-off', color: '' };
        case 'Reusable': return { text: 'Многоразовое', icon: 'mdi-autorenew', color: 'success' };
        case 'Regular': return { text: 'Регулярное', icon: 'mdi-regex', color: 'blue' };
        default: return { text: 'undefined' };
      }
    }
  },
}
</script>

<style lang="scss" scoped>
  @import '../../assets/global';
</style>

<style scoped>
.exercise-id {
  color: #999;
  font-size: 80%;
}
</style>