<template>
  <EditorDialog
    :title="'Редактирование достижений участника'"
    :max-width="720"
    :save-func="() => saveFunc(model)"
    @closed="close"
  >
    <v-row>
      <v-col>
        <v-text-field
          label="Баллы"
          v-model="model.amount"
          :rules="validation.amount"
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col>
        &nbsp;
      </v-col>
    </v-row>    
    <v-row>
      <v-col>
        &nbsp;
      </v-col>
      <v-col>
        &nbsp;
      </v-col>
    </v-row>
  </EditorDialog>
</template>

<script>
import editorDialogMixin from '@/global-components/dialog/mixins';

export default {
  name: 'ParticipantBalanceEditorDialog',
  mixins: [editorDialogMixin],

  props: {
    object: {
      type: Object,
      default: () => {
        return {
          amount: 0
        }
      }
    },
    saveFunc: Function,
  },

  data: () => ({
    validation: {
      amount: [
        value => !!value || 'Обязательное поле.',
        value => /\D/i.test(value) === false || 'Значение должно быть целым числом не меньше 0',
      ]
    }
  })
}
</script>