<template>
  <div>
    <v-row>
      <v-col cols="8">
        <div class="d-flex">
          <v-avatar class="mr-4" :color="logo ? logo : 'rgba(217, 217, 217, 1)'">
            <img :src="logo" alt="">
          </v-avatar>
          <div class="main__title">
            <v-card-text class="nominations__h1 text-truncate">
              {{name ? name : ''}}
            </v-card-text>
          </div>
        </div>
      </v-col>
      <v-col cols="4" class="d-flex">
        <div class="ml-auto">
          <v-btn v-if="isEdit" @click="switchingIsEdit">
            <v-icon left>mdi-pencil</v-icon>
            редактировать
          </v-btn>
          <v-btn v-else @click="save" :disabled="!valid">
            <v-icon left>mdi-content-save</v-icon>
            сохранить
          </v-btn>
          <v-btn class="ml-2">
            <v-icon>mdi-play-circle</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <img :src="url" alt="" />
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row class="mt-4">
        <v-col cols="6">
          <v-text-field
            v-model="newMarketingProgramId"
            label="ID Программы"
            disabled
            dense
            class="noborder"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="organizerId"
            label="ID Организатора"
            disabled
            dense
            class="noborder"
          />
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="name"
            label="Название МП"
            dense
            :disabled="isEdit"
          />
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="url"
            label="URL"
            dense
            :disabled="isEdit"
          />
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="code"
            label="Код программы"
            dense
            :disabled="isEdit"
          />
        </v-col>
        <v-col cols="3">
          <v-checkbox
            v-model="isClosed"
            label="Закрытая МП"
            dense
            :disabled="isEdit"
          />
        </v-col>
        <v-col cols="12">
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12">
          <h5 class="text-h5">Установки МП</h5>
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="reward"
            :rules="validation.reward"
            label="Баллов за 1000 шагов"
            dense
            :disabled="isEdit"
          />
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
import marketingProgramSteps from '@/api/scountStepsApi';
import scountApiInitializer from '@/api/scountApi';
  export default {
    data: () => ({
      name: '',
      reward: 2,
      logo: null,
      newMarketingProgramId: null,
      organizerId: null,
      url: null,
      code: null,
      isClosed: false,
      isEdit: true,
      valid: false,
      validation: {
        reward: [
          value => !!value || 'Обязательное поле.',
          value => (value && Number.isInteger(value * 1)) || 'Требуется целое число.',
          value => (value && value > 0 && value < 101) || 'Требуется целое число от 1 до 100.',
        ],
      }
    }),
    setup() {
      const marketingProgramStore = useMarketingProgramStore()
      return { marketingProgramStore }
  },
  methods: {
    marketingProgramData() {
      this.name = this.mp ? this.mp.title : null,
      this.logo = this.mp ? this.mp.parameters?.Logo : null,
      this.url = this.mp ? this.mp.parameters?.Url : null,
      this.newMarketingProgramId = this.mp ? this.mp.marketingProgramId : null
      this.organizerId = this.mp ? this.mp?.organizerId : null,
      this.code = this.mp ? this.mp.code : null,
      this.isClosed = this.mp ? this.mp.isClosed : null,
      this.reward = this.mp ? this.mp.reward : null
    },
    switchingIsEdit() {
      this.isEdit = false;
    },
    changeReward () {
      this.marketingProgramSteps.changeProgramsReward({raward: this.reward})
    },
    async changeMarketingProgramCode() {
      const payload = {
        code: this.code,
        attributes: []
      }
      await this.scountApi.changeMarketingProgramCode(this.mp.marketingProgramId, this.mp.marketingProgramId, payload);
    },
    async save() {
      this.valid = await this.$refs.form.validate();

    if (!this.valid)
      return;
        this.changeReward()
        this.marketingProgramSteps.getProgramsReward()
        .then (() => {
          this.changeMarketingProgramCode()
        })
        this.isEdit = true;
      }
  },
  computed: {
    mp() {
      if (this.marketingProgramStore.isInitialized === false)
        return null;
      return this.marketingProgramStore;
    },
    marketingProgramSteps() {
      if (!this.mp)
        return null;
      return marketingProgramSteps.marketingProgramSteps(this.mp.marketingProgramId);
    },
    scountApi() {
        if (!this.mp)
          return null;
        return scountApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
      },
  },
  mounted() {
    this.marketingProgramData();
  }
  }
</script>

<style lang="scss" scoped>

</style>