var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-alert',{attrs:{"border":"left","colored-border":"","type":"info","elevation":"2"}},[_c('b',[_vm._v("Приоритет")]),_vm._v(" и "),_c('b',[_vm._v("сложность")]),_vm._v(" - одно и то же. Тренировки учитываются в первую очередь на заданиях с наивысшим приоритетом. ")]),_c('DataTable',{attrs:{"controller":"inRoute","headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.total,"sort-by":"date","sort-desc":true,"search-title":"Найти задание","search-fields":_vm.searchFields,"loading":_vm.updating},on:{"update:table-options":_vm.tableUpdated,"click:row":_vm.tableRowClicked},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{staticClass:"ml-3",on:{"click":function($event){_vm.exerciseEditorDialogShow = true}}},[_vm._v("Добавить задание")]),_c('v-btn',{staticClass:"ml-3",on:{"click":function($event){_vm.exerciseFromTempalteDialogShow = true}}},[_vm._v("Добавить из каталога")])]},proxy:true},{key:"item.attributes",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"color":item.isAutomaticAssign ? 'success' : ''}},[_vm._v(" mdi-refresh-auto ")])],1)]}}],null,true)},[_c('span',[_vm._v("Авто")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.getType(item.type).color}},[_vm._v(" "+_vm._s(_vm.getType(item.type).icon)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getType(item.type).text))])])]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.title)+" "),_c('div',{staticClass:"exercise-id"},[_vm._v("ID: "+_vm._s(item.exerciseId))])]}},{key:"item.complexity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.complexity)+" ")]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.startDate))+" ")]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.endDate))+" ")]}},{key:"item.createdOn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.createdOn))+" ")]}},{key:"item.publishedOn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.publishedOn))+" ")]}}],null,true)}),(_vm.exerciseEditorDialogShow)?_c('ExerciseEditorDialog',{attrs:{"save-func":_vm.createExercise},on:{"closed":function($event){_vm.exerciseEditorDialogShow = false}}}):_vm._e(),(_vm.exerciseFromTempalteDialogShow)?_c('ExerciseFromTempalteDialog',{attrs:{"save-func":_vm.createExerciseFromTemplate},on:{"closed":function($event){_vm.exerciseFromTempalteDialogShow = false}}}):_vm._e(),_c('v-dialog',{attrs:{"width":"720"},model:{value:(_vm.dialog.opened),callback:function ($$v) {_vm.$set(_vm.dialog, "opened", $$v)},expression:"dialog.opened"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(_vm._s(_vm.dialog.title))]),_c('v-card-text',{domProps:{"innerHTML":_vm._s(_vm.dialog.text)}}),_c('v-card-actions',[_c('v-btn',{on:{"click":_vm.closeDialog}},[_vm._v("Закрыть")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }