import {initializeApi} from "@/api/base";

const api = initializeApi("/api/market", {});

async function getMarketingPrograms() {
  return api.get('/api/v1/marketing-programs', {
    params: {},
  });
}
async function getPromocodes({ pagination, sorting, search, marketingProgramId, promotionId, state, creatorId }) {
  return api.get('/api/v1/promocodes', {
    params: {
      ...pagination,
      ...sorting,
      marketingProgramId,
      promotionId,
      state,
      searchString: search,
      creatorId,
    },
  });
}

async function createPromocode(data) {
  return api.post(`/api/v1/promocodes`, {
    code: data.code,
    promotionId: data.promotionId, 
    marketingProgramId: data.marketingProgramId, 
    creatorId: data.creatorId
  });
}

async function importPromocode(data) {
  const formData = new FormData();
  formData.append("PromotionId", data.promotionId);
  formData.append("MarketingProgramId", data.marketingProgramId);
  formData.append("CreatorId", data.creatorId);
  formData.append("File", data.file, data.file.name);
  return api.post(`/api/v1/promocodes/import`, formData, {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
  });
}

async function deletePromocode(data) {
  return api.delete(`/api/v1/promocodes/${data.promocodeId}`);
}

async function deleteSelectedPromocodes(data) {
  return api.post(`/api/v1/promocodes/selected`, {
    promocodeIds: data.promocodeIds
  });
}
async function getPromotions({ pagination, sorting, search, marketingProgramId, creatorId }) {
    return  api.get('/api/v1/promotions', {
      params: {
        ...pagination,
        ...sorting,
        searchString: search,
        marketingProgramId, 
        creatorId,
        includeDisabled: true,
      },
    });
  }

async function getPromotion({ promotionId }) {
  return api.get(`/api/v1/promotions/${promotionId}`, { });
}

async function createPromotion(data) {
  return api.post(`/api/v1/promotions`,  data
    // name: data.name,
    // subTitle: data.subTitle, 
    // description: data.description,
    // type: data.type,
    // sellPriceInRub: data.sellPriceInRub,
    // partnerId: data.partnerId,
    // categoryId: data.categoryId,
    // expiresOn: data.expiresOn
  );
}

async function copyPromotion(data) {
  return api.post(`/api/v1/promotions/copy`, data
  // {
  //   promotionId: data.promotionId,
  //   newPromotionId: data.newPromotionId
  // }
  );
}

async function  updatePromotion(data) {
  return api.patch(`/api/v1/promotions/${data.promotionId}`, data
  // {
  //   name: data.name,
  //   subTitle: data.subTitle, 
  //   description: data.description,
  //   type: data.type,
  //   sellPriceInRub: data.sellPriceInRub,
  //   partnerId: data.partnerId,
  //   categoryId: data.categoryId,
  //   expiresOn: data.expiresOn
  // }
  );
}

async function disablePromotion(data) {
  return api.put(`/api/v1/promotions/${data.promotionId}/disable`);
}

async function enablePromotion(data) {
  return api.put(`/api/v1/promotions/${data.promotionId}/enable`);
}

async function getPromotionsSelect({ offset, count, searchString, marketingProgramId }) {
  return api.get('/api/v1/promotions', {
    params: {
      offset,
      count,
      searchString,
      marketingProgramId,
      includeDisabled: true,
    },
  });
}

async function getMarketingProgramPromotion({ marketingProgramPromotionId }) {
  return api.get(`/api/v1/marketing-program-promotions/${marketingProgramPromotionId}`);
}

async function getMarketingProgramPromotions({ pagination, sorting, search, promotionId }) {
  return api.get('/api/v1/marketing-program-promotions', {
    params: {
      ...pagination,
      ...sorting,
      searchString: search,
      promotionId,
    },
  });
}

async function createMarketingProgramPromotion(data) {
  return api.put(`/api/v1/promotions/${data.promotionId}/link-to-mp`, {
    marketingProgramId: data.marketingProgramId,
    howToGet: data.howToGet,
    priceInPoints: data.priceInPoints, 
    limitOnParticipant: data.limitOnParticipant,
    unitsOfValue: data.unitsOfValue,
    sort: data.sort
  });
}

async function updateMarketingProgramPromotion(data) {
  return api.patch(`/api/v1/marketing-program-promotions/${data.marketingProgramPromotionId}`, {
    howToGet: data.howToGet,
    priceInPoints: data.priceInPoints, 
    limitOnParticipant: data.limitOnParticipant,
    unitsOfValue: data.unitsOfValue,
    sort: data.sort
  });
}

async function disableMarketingProgramPromotion(data) {
  return api.put(`/api/v1/marketing-program-promotions/${data.marketingProgramPromotionId}/disable`);
}

async function enableMarketingProgramPromotion(data) {
  return api.put(`/api/v1/marketing-program-promotions/${data.marketingProgramPromotionId}/enable`);
}

async function uploadPromotionDesktopImage(data) {
  return this.uploadFileMethod(`/api/v1/promotions/${data.promotionId}/images/desktop`, data.file);
}

async function uploadPromotionMobileImage(data) {
  return this.uploadFileMethod(`/api/v1/promotions/${data.promotionId}/images/mobile`, data.file);
}

async function uploadFileMethod(methodUrl, file) {
  const formData = new FormData();
  formData.append("File", file);
  return api.patch(methodUrl, formData, {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
  });
}

async function getPartners(data) {
  return api.get('/api/v1/partners', {
    params: {
      ...data.pagination,
      ...data.sorting,
      searchString: data.search,
      creatorId: data.creatorId
    },
  });
}

async function getCategories({pagination, sorting, search}) {
  return api.get('/api/v1/categories', {
    params: {
      ...pagination,
      ...sorting,
      searchString: search,
    },
  });
}

export default {
  getMarketingPrograms,
  getMarketingProgramPromotion,
  getMarketingProgramPromotions,
  createMarketingProgramPromotion,
  updateMarketingProgramPromotion,
  disableMarketingProgramPromotion,
  enableMarketingProgramPromotion,
  getPromocodes,
  importPromocode,
  createPromocode,
  deletePromocode,
  deleteSelectedPromocodes,
  getPromotionsSelect,
  getPromotions,
  getPromotion,
  createPromotion,
  copyPromotion,
  updatePromotion,
  disablePromotion,
  enablePromotion,
  uploadPromotionDesktopImage,
  uploadPromotionMobileImage,
  uploadFileMethod,
  getPartners,
  getCategories
};
